import React from 'react'
import { useEffect } from 'react';
import { Grid, Typography, TextField, Button, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import NumberFormat from 'react-number-format';
import { isValidPostalCode, isValidEmailAddress } from '../utils/utilityFunctions';
import { PolicyChangeDisclaimerSection } from './PolicyChangeDisclaimerSection';


const policyTypes = [
  {
    value: "Additional Insured",
    key : "AI"
  },
  {
    value: "Loss Payee",
    key : "LP"
  },
  {
    value: "Mortgagee/Lienholder",
    key : "MORT"
  },
  {
    value: "Waiver of Subrogation",
    key : "WOS"
  }
]


export default function BusinessInsuranceReplaceAdditionalInterestForm({insuranceType, requestPolicyChangeHandler}) {
  useEffect(()=>{
    resetForm();
  },[insuranceType])

  const [businessName, setBusinessName] = useState("");
  const [contactName, setContactName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [policyType, setPolicyType] = useState("");
  const [lenderName, setLenderName] = useState("");
  const [lenderAddress, setLenderAddress] = useState("");
  const [lenderAddress2, setLenderAddress2] = useState("");
  const [lenderCity, setLenderCity] = useState("");
  const [lenderState, setLenderState] = useState("");
  const [lenderZipcode, setLenderZipcode] = useState("");
  const [loanNumber, setLoanNumber] = useState("");
  const [newLenderName, setNewLenderName] = useState("");
  const [newLenderAddress, setNewLenderAddress] = useState("");
  const [newLenderAddress2, setNewLenderAddress2] = useState("");
  const [newLenderCity, setNewLenderCity] = useState("");
  const [newLenderState, setNewLenderState] = useState("");
  const [newLenderZipcode, setNewLenderZipcode] = useState("");
  const [newLoanNumber, setNewLoanNumber] = useState("");

  const [isBusinessNameFocused, setIsBusinessNameFocused] = useState(false)
  const [isContactNameFocused, setIsContactNameFocused] = useState(false)
  const [isEmailAddressFocused, setIsEmailAddressFocused] = useState(false)
  const [isLenderNameFocused, setIsLenderNameFocused] = useState(false)
  const [isLenderAddressFocused, setIsLenderAddressFocused] = useState(false)
  const [isLenderCityFocused, setIsLenderCityFocused] = useState(false)
  const [isLenderStateFocused, setIsLenderStateFocused] = useState(false)
  const [isLenderZipcodeFocused, setIsLenderZipcodeFocused] = useState(false)
  const [isLoanNumberFocused, setIsLoanNumberFocused] = useState(false)
  const [isNewLenderNameFocused, setIsNewLenderNameFocused] = useState(false)
  const [isNewLenderAddressFocused, setIsNewLenderAddressFocused] = useState(false)
  const [isNewLenderCityFocused, setIsNewLenderCityFocused] = useState(false)
  const [isNewLenderStateFocused, setIsNewLenderStateFocused] = useState(false)
  const [isNewLenderZipcodeFocused, setIsNewLenderZipcodeFocused] = useState(false)
  const [isNewLoanNumberFocused, setIsNewLoanNumberFocused] = useState(false)

  const [isBusinessNameTouched, setIsBusinessNameTouched] = useState(false)
  const [isContactNameTouched, setIsContactNameTouched] = useState(false)
  const [isEmailAddressTouched, setIsEmailAddressTouched] = useState(false)
  const [isLenderNameTouched, setIsLenderNameTouched] = useState(false)
  const [isLenderAddressTouched, setIsLenderAddressTouched] = useState(false)
  const [isLenderCityTouched, setIsLenderCityTouched] = useState(false)
  const [isLenderStateTouched, setIsLenderStateTouched] = useState(false)
  const [isLenderZipcodeTouched, setIsLenderZipcodeTouched] = useState(false)
  const [isLoanNumberTouched, setIsLoanNumberTouched] = useState(false)
  const [isNewLenderNameTouched, setIsNewLenderNameTouched] = useState(false)
  const [isNewLenderAddressTouched, setIsNewLenderAddressTouched] = useState(false)
  const [isNewLenderCityTouched, setIsNewLenderCityTouched] = useState(false)
  const [isNewLenderStateTouched, setIsNewLenderStateTouched] = useState(false)
  const [isNewLenderZipcodeTouched, setIsNewLenderZipcodeTouched] = useState(false)
  const [isNewLoanNumberTouched, setIsNewLoanNumberTouched] = useState(false)

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)


  const businessNameChangeHandler = (event) =>{
    !isBusinessNameTouched && setIsBusinessNameTouched(true)
    setBusinessName(event.target.value)
  }

  const emailAddressChangeHandler = (event) =>{
    !isEmailAddressTouched && setIsEmailAddressTouched(true)
    setEmailAddress(event.target.value.trim())
  }

  const contactNameChangeHandler = (event) =>{
    !isContactNameTouched && setIsContactNameTouched(true)
    setContactName(event.target.value)
  }

  const lenderNameChangeHandler = (event) =>{
    !isLenderNameTouched && setIsLenderNameFocused(true)
    setLenderName(event.target.value)
  }

  const lenderAddressChangeHandler = (event) =>{
    !isLenderAddressTouched && setIsLenderAddressTouched(true)
    setLenderAddress(event.target.value)
  }

  const lenderAddress2ChangeHandler = (event) =>{
    setLenderAddress2(event.target.value)
  }

  const lenderCityChangeHandler = (event) =>{
    !isLenderCityTouched && setIsLenderCityTouched(true)
    setLenderCity(event.target.value)
  }
  const lenderStateChangeHandler = (event) =>{
    !isLenderStateTouched && setIsLenderStateTouched(true)
    setLenderState(event.target.value)
  }
  const lenderZipcodeChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    !isLenderZipcodeTouched && setIsLenderZipcodeTouched(true)
    setLenderZipcode(value)
  }

  const loanNumberChangeHandler = (event) =>{
    !isLoanNumberTouched && setIsLoanNumberTouched(true)
    setLoanNumber(event.target.value)
  }

  const newLenderNameChangeHandler = (event) =>{
    !isNewLenderNameTouched && setIsNewLenderNameTouched(true)
    setNewLenderName(event.target.value)
  }

  const newLenderAddressChangeHandler = (event) =>{
    !isNewLenderAddressTouched && setIsNewLenderAddressTouched(true)
    setNewLenderAddress(event.target.value)
  }

  const newLenderAddress2ChangeHandler = (event) =>{
    setNewLenderAddress2(event.target.value)
  }

  const newLenderCityChangeHandler = (event) =>{
    !isNewLenderCityTouched && setIsNewLenderCityTouched(true)
    setNewLenderCity(event.target.value)
  }
  const newLenderStateChangeHandler = (event) =>{
    !isNewLenderStateTouched && setIsNewLenderStateTouched(true)
    setNewLenderState(event.target.value)
  }
  const newLenderZipcodeChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    !isNewLenderZipcodeTouched && setIsNewLenderZipcodeTouched(true)
    setNewLenderZipcode(value)
  }

  const newLoanNumberChangeHandler = (event) =>{
    !isNewLoanNumberTouched && setIsNewLoanNumberTouched(true)
    setNewLoanNumber(event.target.value)
  }

  const handlePolicyTypeChange = (event)=>{
    setPolicyType(event.target.value)
  }
  
  const submitDocumentRequestHandler = async ()=>{
    let requestData = {
      contactName: contactName,
      email: emailAddress,
      accountName: businessName,
      policyType: policyType,
      details : {
        previousLender: {
          name: lenderName,
          address1: lenderAddress,
          address2: lenderAddress2,
          city: lenderCity,
          state: lenderState,
          postalCode: lenderZipcode,
          loanNumber: loanNumber
        },
        newLender: {
          name: newLenderName,
          address1: newLenderAddress,
          address2: newLenderAddress2,
          city: newLenderCity,
          state: newLenderState,
          postalCode: newLenderZipcode,
          loanNumber: newLoanNumber
        }
      }
    }
    await requestPolicyChangeHandler(requestData)
  }

  const isValidFormData = () =>{
    return isRecaptchaVerified && businessName && isValidEmailAddress(emailAddress) && 
           contactName  && newLenderName && newLenderAddress && 
           newLenderCity && newLenderState && isValidPostalCode(newLenderZipcode) && 
           newLoanNumber && lenderName && lenderAddress  && 
           lenderCity && lenderState && isValidPostalCode(lenderZipcode) && 
           loanNumber && policyType
  }

  const resetForm = () =>{
    setBusinessName("");
    setContactName("");
    setEmailAddress("");
    setPolicyType("");
    setLenderName("");
    setLenderAddress("");
    setLenderAddress2("");
    setLenderCity("");
    setLenderState("");
    setLenderZipcode("");
    setLoanNumber("");
    setNewLenderName("");
    setNewLenderAddress("");
    setNewLenderAddress2("");
    setNewLenderCity("");
    setNewLenderState("");
    setNewLenderZipcode("");
    setNewLoanNumber("");

    setIsBusinessNameFocused(false);
    setIsContactNameFocused(false);
    setIsEmailAddressFocused(false);
    setIsLenderNameFocused(false);
    setIsLenderAddressFocused(false);
    setIsLenderCityFocused(false);
    setIsLenderStateFocused(false);
    setIsLenderZipcodeFocused(false);
    setIsLoanNumberFocused(false);
    setIsNewLenderNameFocused(false);
    setIsNewLenderAddressFocused(false);
    setIsNewLenderCityFocused(false);
    setIsNewLenderStateFocused(false);
    setIsNewLenderZipcodeFocused(false);
    setIsNewLoanNumberFocused(false);

    setIsBusinessNameTouched(false);
    setIsContactNameTouched(false);
    setIsEmailAddressTouched(false);
    setIsLenderNameTouched(false);
    setIsLenderAddressTouched(false);
    setIsLenderCityTouched(false);
    setIsLenderStateTouched(false);
    setIsLenderZipcodeTouched(false);
    setIsLoanNumberTouched(false);
    setIsNewLenderNameTouched(false);
    setIsNewLenderAddressTouched(false);
    setIsNewLenderCityTouched(false);
    setIsNewLenderStateTouched(false);
    setIsNewLenderZipcodeTouched(false);
    setIsNewLoanNumberTouched(false);

    setIsRecaptchaVerified(false);
  }

  return (
    <Grid container rowSpacing={4}>  
        <Grid item xs={12}>
            <Typography                                 
                variant='h4'
                fontFamily="Sofia Sans Condensed"
                sx={{
                    color:"#0C709B"
                }}>
                REQUEST FORM
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container rowSpacing={2} columnSpacing={2}>
                <>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          CLIENT DETAILS
                      </Typography>
                    </Grid>
                    <Grid item md={12} sx={{width:"100%"}}>
                        <TextField
                            error={isBusinessNameTouched && !businessName && !isBusinessNameFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            onFocus={()=>{setIsBusinessNameFocused(true)}}
                            onBlur={()=>{setIsBusinessNameFocused(false)}}
                            type="text" 
                            value= {businessName}
                            onChange= {businessNameChangeHandler}                        
                            label = "Business Name"/>
                    </Grid>   
                    <Grid item md={6} sx={{width:"100%"}}>
                        <TextField
                            error={isContactNameTouched && !contactName && !isContactNameFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            onFocus={()=>{setIsContactNameFocused(true)}}
                            onBlur={()=>{setIsContactNameFocused(false)}}
                            type="text" 
                            value= {contactName}
                            onChange= {contactNameChangeHandler}                        
                            label = "Contact Name"/>
                    </Grid>
                    <Grid item md={6} sx={{width:"100%"}}>
                        <TextField
                            error={isEmailAddressTouched && !isValidEmailAddress(emailAddress) && !isEmailAddressFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            onFocus={()=>{setIsEmailAddressFocused(true)}}
                            onBlur={()=>{setIsEmailAddressFocused(false)}}
                            type="text" 
                            value= {emailAddress}
                            onChange= {emailAddressChangeHandler}                        
                            label = "Email Address"/> 
                    </Grid>
                </> 
                <>
                    <Grid item md={12} sx={{width:"100%", marginTop: 2}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          POLICY TYPE
                      </Typography>
                    </Grid>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <FormControl required size="small" sx={{width:"100%"}}>
                        <InputLabel>Policy Type</InputLabel>
                        <Select
                          sx={{width:"100%"}}
                          value={policyType}                          
                          label="Policy Type"
                          size="small"
                          onChange={handlePolicyTypeChange}>
                          {
                              policyTypes.map( type => <MenuItem value={type.key}>{type.value}</MenuItem>)
                          }
                        </Select>
                      </FormControl> 
                    </Grid>                                        
                </>         
                <>  
                    <Grid item md={12} sx={{width:"100%", marginTop: 2}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          PREVIOUS LENDER
                      </Typography>
                    </Grid>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isLenderNameTouched && !lenderName && !isLenderNameFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          onFocus={()=>{setIsLenderNameFocused(true)}}
                          onBlur={()=>{setIsLenderNameFocused(false)}}
                          type="text" 
                          value= {lenderName}
                          onChange= {lenderNameChangeHandler}                        
                          label = "Lender Name"/>
                    </Grid>        
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isLenderAddressTouched && !lenderAddress && !isLenderAddressFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          onFocus={()=>{setIsLenderAddressFocused(true)}}
                          onBlur={()=>{setIsLenderAddressFocused(false)}}
                          type="text" 
                          value= {lenderAddress}
                          onChange= {lenderAddressChangeHandler}                        
                          label = "Address"/>
                    </Grid>     
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text" 
                          value= {lenderAddress2}
                          onChange= {lenderAddress2ChangeHandler}                        
                          label = "Address 2"/>
                    </Grid> 
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isLenderCityTouched && !lenderCity & !isLenderCityFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsLenderCityFocused(true)}}
                          onBlur={()=>{setIsLenderCityFocused(false)}}                         
                          value= {lenderCity}
                          onChange= {lenderCityChangeHandler}                        
                          label = "City"/> 
                    </Grid>          
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isLenderStateTouched && !lenderState & !isLenderStateFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsLenderStateFocused(true)}}
                          onBlur={()=>{setIsLenderStateFocused(false)}}                         
                          value= {lenderState}
                          onChange= {lenderStateChangeHandler}                        
                          label = "State"/> 
                    </Grid>  
                    <Grid item md={4} sx={{width:"100%"}}>
                      <NumberFormat
                          sx={{width: "100%"}}
                          error={isLenderZipcodeTouched && !isValidPostalCode(lenderZipcode) & !isLenderZipcodeFocused}
                          required
                          label="Zipcode"
                          size="small"
                          type="text" 
                          value={lenderZipcode} 
                          format="#####" 
                          mask="_" 
                          customInput={TextField}
                          onFocus={()=>{setIsLenderZipcodeFocused(true)}}
                          onBlur={()=>{setIsLenderZipcodeFocused(false)}}   
                          onValueChange={lenderZipcodeChangeHandler}/>                       
                    </Grid>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          sx={{width: "100%"}}
                          error={isLoanNumberTouched && !loanNumber & !isLoanNumberFocused}
                          required
                          label="Loan Number"
                          size="small"
                          type="text" 
                          value={loanNumber} 
                          onFocus={()=>{setIsLoanNumberFocused(true)}}
                          onBlur={()=>{setIsLoanNumberFocused(false)}}   
                          onChange={loanNumberChangeHandler}/>                       
                    </Grid>                                                                       
                </>   
                <>  
                    <Grid item md={12} sx={{width:"100%", marginTop: 2}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          NEW LENDER
                      </Typography>
                    </Grid>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isNewLenderNameTouched && !newLenderName && !isNewLenderNameFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          onFocus={()=>{setIsNewLenderNameFocused(true)}}
                          onBlur={()=>{setIsNewLenderNameFocused(false)}}
                          type="text" 
                          value= {newLenderName}
                          onChange= {newLenderNameChangeHandler}                        
                          label = "Lender Name"/>
                    </Grid>        
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isNewLenderAddressTouched && !newLenderAddress && !isNewLenderAddressFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          onFocus={()=>{setIsNewLenderAddressFocused(true)}}
                          onBlur={()=>{setIsNewLenderAddressFocused(false)}}
                          type="text" 
                          value= {newLenderAddress}
                          onChange= {newLenderAddressChangeHandler}                        
                          label = "Address"/>
                    </Grid>     
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text" 
                          value= {newLenderAddress2}
                          onChange= {newLenderAddress2ChangeHandler}                        
                          label = "Address 2"/>
                    </Grid> 
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isNewLenderCityTouched && !lenderCity & !isNewLenderCityFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsNewLenderCityFocused(true)}}
                          onBlur={()=>{setIsNewLenderCityFocused(false)}}                         
                          value= {newLenderCity}
                          onChange= {newLenderCityChangeHandler}                        
                          label = "City"/> 
                    </Grid>          
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isNewLenderStateTouched && !newLenderState & !isNewLenderStateFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsNewLenderStateFocused(true)}}
                          onBlur={()=>{setIsNewLenderStateFocused(false)}}                         
                          value= {newLenderState}
                          onChange= {newLenderStateChangeHandler}                        
                          label = "State"/> 
                    </Grid>  
                    <Grid item md={4} sx={{width:"100%"}}>
                      <NumberFormat
                          sx={{width: "100%"}}
                          error={isNewLenderZipcodeTouched && !isValidPostalCode(newLenderZipcode) & !isNewLenderZipcodeFocused}
                          required
                          label="Zipcode"
                          size="small"
                          type="text" 
                          value={newLenderZipcode} 
                          format="#####" 
                          mask="_" 
                          customInput={TextField}
                          onFocus={()=>{setIsNewLenderZipcodeFocused(true)}}
                          onBlur={()=>{setIsNewLenderZipcodeFocused(false)}}   
                          onValueChange={newLenderZipcodeChangeHandler}/>                       
                    </Grid>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          sx={{width: "100%"}}
                          error={isNewLoanNumberTouched && !newLoanNumber & !isNewLoanNumberFocused}
                          required
                          label="Loan Number"
                          size="small"
                          type="text" 
                          value={newLoanNumber} 
                          onFocus={()=>{setIsNewLoanNumberFocused(true)}}
                          onBlur={()=>{setIsNewLoanNumberFocused(false)}}   
                          onChange={newLoanNumberChangeHandler}/>                       
                    </Grid>                                                                       
                </>       
                <PolicyChangeDisclaimerSection/>                                                                                                                                                         
                <Grid item xs={12}>
                      <ReCAPTCHA
                          size="normal"
                          onChange={()=>{setIsRecaptchaVerified(true)}}
                          onExpired={()=>{setIsRecaptchaVerified(false)}}
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/>   
                </Grid>                                       
                <Grid item xs={12}>
                      <Button
                          disabled={!isValidFormData()}
                          variant="contained"
                          backgroundColor= "#0C709B" 
                          startIcon={<SendIcon />}
                          size="medium"
                          onClick={submitDocumentRequestHandler}
                          sx={{
                            borderRadius: '20px',
                            fontFamily: "Nunito",
                            fontWeight: "bolder",
                          }}          
                          label = "Claim">  
                          Submit Request
                      </Button>   
                </Grid> 
            </Grid>
        </Grid>       
    </Grid>
  )
}




        

