import * as React from 'react';
import { Stack, Chip, Box} from '@mui/material';

export default function BlogLabels({labels}) {
    return (
        <Box sx={{
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }}>            
          <Stack direction="row" spacing={1}>    
                {        
                    labels.length > 0 ?
                    labels.map((label, index)=>{
                    return (
                            <Chip 
                                key={index}
                                size="small"
                                fontFamily="Nunito"  
                                label={label} 
                                sx={{
                                    color: '#0C709B'
                            }}/>
                    ) 
                    }) :
                    <Chip 
                        size="small"
                        label="Modern Shore Insurance"
                        sx={{
                            color: '#0C709B'
                        }}/>
                }
            </Stack>
        </Box>
    );
}
