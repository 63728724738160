import React from 'react'
import { useEffect } from 'react';
import { Grid, Typography, TextField, Button, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import moment from 'moment';
import usaStates from '../constants/usaStatesList.js'
import { PolicyChangeDisclaimerSection } from './PolicyChangeDisclaimerSection';
import { isValidEmailAddress } from '../utils/utilityFunctions/isValidEmailAddress.js';


export default function InsuranceDriverChangeForm({insuranceType, requestPolicyChangeHandler}) {
  useEffect(()=>{
    resetForm();
  },[insuranceType])

  const [insuredName, setInsuredName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [contactName, setContactName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [driverFirstName, setDriverFirstName] = useState("");
  const [driverLastName, setDriverLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [driverLicenseNumber, setDriverLicenseNumber] = useState("");
  const [driverLicenseState, setDriverLicenseState] = useState("");
  const [driverToRemoveFirstName, setDriverToRemoveFirstName] = useState("");
  const [driverToRemoveLastName, setDriverToRemoveLastName] = useState("");
  const [driverToRemoveDateOfBirth, setDriverToRemoveDateOfBirth] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [driverToRemoveLicenseNumber, setDriverToRemoveLicenseNumber] = useState("");
  const [driverToRemoveLicenseState, setDriverToRemoveLicenseState] = useState("");

  const [isInsuredNameFocused, setIsInsuredNameFocused] = useState(false)
  const [isBusinessNameFocused, setIsBusinessNameFocused] = useState(false)
  const [isContactNameFocused, setIsContactNameFocused] = useState(false)
  const [isEmailAddressFocused, setIsEmailAddressFocused] = useState(false)
  const [isDriverFirstNameFocused, setIsDriverFirstNameFocused] = useState(false)
  const [isDriverLastNameFocused, setIsDriverLastNameFocused] = useState(false)
  const [isDateOfBirthFocused, setIsDateOfBirthFocused] = useState(false)
  const [isDriverLicenseNumberFocused, setIsDriverLicenseNumberFocused] = useState(false)
  const [isDriverToRemoveFirstNameFocused, setIsDriverToRemoveFirstNameFocused] = useState(false)
  const [isDriverToRemoveLastNameFocused, setIsDriverToRemoveLastNameFocused] = useState(false)
  const [isDriverToRemoveDateOfBirthFocused, setIsDriverToRemoveDateOfBirthFocused] = useState(false)
  const [isDriverToRemoveLicenseNumberFocused, setIsDriverToRemoveLicenseNumberFocused] = useState(false)

  const [isInsuredNameTouched, setIsInsuredNameTouched] = useState(false)
  const [isBusinessNameTouched, setIsBusinessNameTouched] = useState(false)
  const [isContactNameTouched, setIsContactNameTouched] = useState(false)
  const [isEmailAddressTouched, setIsEmailAddressTouched] = useState(false)
  const [isDriverFirstNameTouched, setIsDriverFirstNameTouched] = useState(false)
  const [isDriverLastNameTouched, setIsDriverLastNameTouched] = useState(false)
  const [isDateOfBirthTouched, setIsDateOfBirthTouched] = useState(false)
  const [isDriverLicenseNumberTouched, setIsDriverLicenseNumberTouched] = useState(false)
  const [isDriverToRemoveFirstNameTouched, setIsDriverToRemoveFirstNameTouched] = useState(false)
  const [isDriverToRemoveLastNameTouched, setIsDriverToRemoveLastNameTouched] = useState(false)
  const [isDriverToRemoveDateOfBirthTouched, setIsDriverToRemoveDateOfBirthTouched] = useState(false)
  const [isDriverToRemoveLicenseNumberTouched, setIsDriverToRemoveLicenseNumberTouched] = useState(false)

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)

  const insuredNameChangeHandler = (event) =>{
    !isInsuredNameTouched && setIsInsuredNameTouched(true)
    setInsuredName(event.target.value)
  }

  const businessNameChangeHandler = (event) =>{
    !isBusinessNameTouched && setIsBusinessNameTouched(true)
    setBusinessName(event.target.value)
  }

  const emailAddressChangeHandler = (event) =>{
    !isEmailAddressTouched && setIsEmailAddressTouched(true)
    setEmailAddress(event.target.value.trim())
  }

  const contactNameChangeHandler = (event) =>{
    !isContactNameTouched && setIsContactNameTouched(true)
    setContactName(event.target.value)
  }

  const driverFirstNameChangeHandler = (event) =>{
    !isDriverFirstNameTouched && setIsDriverFirstNameTouched(true)
    setDriverFirstName(event.target.value)
  }

  const driverLastNameChangeHandler = (event) =>{
    !isDriverLastNameTouched && setIsDriverLastNameTouched(true)
    setDriverLastName(event.target.value)
  }

  const dateOfBirthChangeHandler = (event) =>{
    !isDateOfBirthTouched && setIsDateOfBirthTouched(true)
    setDateOfBirth(event.target.value)
  }

  const driverLicenseNumberChangeHandler = (event) =>{
    !isDriverLicenseNumberTouched && setIsDriverLicenseNumberTouched(true)
    setDriverLicenseNumber(event.target.value)
  }

  const driverLicenseStateChangeHandler = (event) =>{
    setDriverLicenseState(event.target.value)
  }

  const driverToRemoveFirstNameChangeHandler = (event) =>{
    !isDriverToRemoveFirstNameTouched && setIsDriverToRemoveFirstNameTouched(true)
    setDriverToRemoveFirstName(event.target.value)
  }

  const driverToRemoveLastNameChangeHandler = (event) =>{
    !isDriverToRemoveLastNameTouched && setIsDriverToRemoveLastNameTouched(true)
    setDriverToRemoveLastName(event.target.value)
  }

  const driverToRemoveDateOfBirthChangeHandler = (event) =>{
    !isDriverToRemoveDateOfBirthTouched && setIsDriverToRemoveDateOfBirthTouched(true)
    setDriverToRemoveDateOfBirth(event.target.value)
  }

  const driverToRemoveLicenseNumberChangeHandler = (event) =>{
    !isDriverToRemoveLicenseNumberTouched && setIsDriverToRemoveLicenseNumberTouched(true)
    setDriverToRemoveLicenseNumber(event.target.value)
  }

  const driverToRemoveLicenseStateChangeHandler = (event) =>{
    setDriverToRemoveLicenseState(event.target.value)
  }

  const submitDocumentRequestHandler = async ()=>{
    let requestData = {
      contactName: insuranceType === 1 ? contactName : insuredName,
      email: emailAddress,
      accountName: insuranceType === 1 ? businessName : insuredName,
      details : {
        previousDriver: {
          firstName: driverToRemoveFirstName,
          lastName: driverToRemoveLastName,
          dateOfBirth: driverToRemoveDateOfBirth,
          licenseNumber: driverToRemoveLicenseNumber,
          licenseState: driverToRemoveLicenseState
        },
        newDriver: {
          firstName: driverFirstName,
          lastName: driverLastName,
          dateOfBirth: dateOfBirth,
          licenseNumber: driverLicenseNumber,
          licenseState: driverLicenseState
        }      
      }
    }
    await requestPolicyChangeHandler(requestData);
  }

  const isValidFormData = () =>{
    let isValid = isRecaptchaVerified && isValidEmailAddress(emailAddress) && driverFirstName && 
                  driverLastName && dateOfBirth && driverLicenseNumber && 
                  driverLicenseState && driverToRemoveFirstName && 
                  driverToRemoveLastName && driverToRemoveDateOfBirth && driverToRemoveLicenseNumber && 
                  driverToRemoveLicenseState

    if(insuranceType === 1 )
    {
      isValid = businessName && contactName && isValid 
    }
    else if(insuranceType === 0 )
    {
      isValid = insuredName && isValid 
    }
    return isValid
  }

  const resetForm = () =>{    
    setBusinessName("");
    setContactName("");
    setEmailAddress("");
    setDriverFirstName("");
    setDriverLastName("");
    setDateOfBirth(moment(new Date()).format('YYYY-MM-DD'));
    setDriverLicenseNumber("");
    setDriverLicenseState("");
    setDriverToRemoveFirstName("");
    setDriverToRemoveLastName("");
    setDriverToRemoveDateOfBirth(moment(new Date()).format('YYYY-MM-DD'));
    setDriverToRemoveLicenseNumber("");
    setDriverToRemoveLicenseState("");

    setIsBusinessNameFocused(false);
    setIsContactNameFocused(false);
    setIsEmailAddressFocused(false);
    setIsDriverFirstNameFocused(false);
    setIsDriverLastNameFocused(false);
    setIsDateOfBirthFocused(false);
    setIsDriverLicenseNumberFocused(false);
    setIsDriverToRemoveFirstNameFocused(false);
    setIsDriverToRemoveLastNameFocused(false);
    setIsDriverToRemoveDateOfBirthFocused(false);
    setIsDriverToRemoveLicenseNumberFocused(false);

    setIsBusinessNameTouched(false);
    setIsContactNameTouched(false);
    setIsEmailAddressTouched(false);
    setIsDriverFirstNameTouched(false);
    setIsDriverLastNameTouched(false);
    setIsDateOfBirthTouched(false);
    setIsDriverLicenseNumberTouched(false);
    setIsDriverToRemoveFirstNameTouched(false);
    setIsDriverToRemoveLastNameTouched(false);
    setIsDriverToRemoveDateOfBirthTouched(false);
    setIsDriverToRemoveLicenseNumberTouched(false);

    setIsRecaptchaVerified(false);
  }

  return (
    <Grid container rowSpacing={4}>  
        <Grid item xs={12}>
            <Typography                                 
                variant='h4'
                fontFamily="Sofia Sans Condensed"
                sx={{
                    color:"#0C709B"
                }}>
                REQUEST FORM
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container rowSpacing={2} columnSpacing={2}>
                {
                  insuranceType === 0 &&
                  <>
                      <Grid item md={12} sx={{width:"100%"}}>
                        <Typography                                 
                            variant='h6'
                            fontFamily="Sofia Sans Condensed"
                            sx={{
                                color:"#0C709B"
                            }}>
                            CLIENT DETAILS
                        </Typography>
                      </Grid>
                      <Grid item md={6} sx={{width:"100%"}}>
                        <TextField
                            error={isInsuredNameFocused && !insuredName && !isInsuredNameFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            onFocus={()=>{setIsInsuredNameFocused(true)}}
                            onBlur={()=>{setIsInsuredNameFocused(false)}}
                            type="text" 
                            value= {insuredName}
                            onChange= {insuredNameChangeHandler}                        
                            label = "Insured Name"/>
                      </Grid>
                      <Grid item md={6} sx={{width:"100%"}}>
                        <TextField
                            error={isEmailAddressTouched && !isValidEmailAddress(emailAddress) & !isEmailAddressFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            type="text"   
                            onFocus={()=>{setIsEmailAddressFocused(true)}}
                            onBlur={()=>{setIsEmailAddressFocused(false)}}                         
                            value= {emailAddress}
                            onChange= {emailAddressChangeHandler}                        
                            label = "Email"/> 
                      </Grid>
                  </>                  
                }
                {
                  insuranceType === 1 &&
                  <>
                      <Grid item md={12} sx={{width:"100%"}}>
                        <Typography                                 
                            variant='h6'
                            fontFamily="Sofia Sans Condensed"
                            sx={{
                                color:"#0C709B"
                            }}>
                            CLIENT DETAILS
                        </Typography>
                      </Grid>
                      <Grid item md={12} sx={{width:"100%"}}>
                          <TextField
                              error={isBusinessNameTouched && !businessName && !isBusinessNameFocused}
                              required
                              size="small"
                              fontFamily="Nunito"
                              sx={{width:"100%"}}
                              onFocus={()=>{setIsBusinessNameFocused(true)}}
                              onBlur={()=>{setIsBusinessNameFocused(false)}}
                              type="text" 
                              value= {businessName}
                              onChange= {businessNameChangeHandler}                        
                              label = "Business Name"/>
                      </Grid>   
                      <Grid item md={6} sx={{width:"100%"}}>
                          <TextField
                              error={isContactNameTouched && !contactName && !isContactNameFocused}
                              required
                              size="small"
                              fontFamily="Nunito"
                              sx={{width:"100%"}}
                              onFocus={()=>{setIsContactNameFocused(true)}}
                              onBlur={()=>{setIsContactNameFocused(false)}}
                              type="text" 
                              value= {contactName}
                              onChange= {contactNameChangeHandler}                        
                              label = "Contact Name"/>
                      </Grid>
                      <Grid item md={6} sx={{width:"100%"}}>
                          <TextField
                              error={isEmailAddressTouched && !isValidEmailAddress(emailAddress) && !isEmailAddressFocused}
                              required
                              size="small"
                              fontFamily="Nunito"
                              sx={{width:"100%"}}
                              onFocus={()=>{setIsEmailAddressFocused(true)}}
                              onBlur={()=>{setIsEmailAddressFocused(false)}}
                              type="text" 
                              value= {emailAddress}
                              onChange= {emailAddressChangeHandler}                        
                              label = "Email Address"/> 
                      </Grid>
                  </>
                }              
                <>  
                    <Grid item md={12} sx={{width:"100%", marginTop: 2}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          PREVIOUS DRIVER
                      </Typography>
                    </Grid>   
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isDriverToRemoveLicenseNumberTouched && !driverToRemoveLicenseNumber & !isDriverToRemoveLicenseNumberFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsDriverToRemoveLicenseNumberFocused(true)}}
                          onBlur={()=>{setIsDriverToRemoveLicenseNumberFocused(false)}}                         
                          value= {driverToRemoveLicenseNumber}
                          onChange= {driverToRemoveLicenseNumberChangeHandler}                        
                          label = "License Number"/> 
                    </Grid>                     
                    <Grid item md={6} sx={{width:"100%"}}>
                      <TextField
                          error={isDriverToRemoveFirstNameTouched && !driverToRemoveFirstName & !isDriverToRemoveFirstNameFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsDriverToRemoveFirstNameFocused(true)}}
                          onBlur={()=>{setIsDriverToRemoveFirstNameFocused(false)}}                         
                          value= {driverToRemoveFirstName}
                          onChange= {driverToRemoveFirstNameChangeHandler}                        
                          label = "First Name"/> 
                    </Grid>     
                    <Grid item md={6} sx={{width:"100%"}}>
                      <TextField
                          error={isDriverToRemoveLastNameTouched && !driverToRemoveLastName & !isDriverToRemoveLastNameFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsDriverToRemoveLastNameFocused(true)}}
                          onBlur={()=>{setIsDriverToRemoveLastNameFocused(false)}}                         
                          value= {driverToRemoveLastName}
                          onChange= {driverToRemoveLastNameChangeHandler}                        
                          label = "Last Name"/> 
                    </Grid>          
                    <Grid item md={6} sx={{width:"100%"}}>
                        <TextField
                            error={isDriverToRemoveDateOfBirthTouched && !driverToRemoveDateOfBirth & !isDriverToRemoveDateOfBirthFocused}
                            id="startDate" 
                            size="small"
                            fontFamily="Nunito"
                            type="date"
                            sx={{width:"100%"}}
                            onFocus={()=>{setIsDriverToRemoveDateOfBirthFocused(true)}}
                            onBlur={()=>{setIsDriverToRemoveDateOfBirthFocused(false)}}  
                            InputProps={{inputProps: { max: dateOfBirth }}}
                            onChange= {driverToRemoveDateOfBirthChangeHandler}                        
                            value= {driverToRemoveDateOfBirth}
                            fullWidth 
                            label = "Date of Birth"/> 
                    </Grid>                                           
                    <Grid item md={6} sx={{width:"100%"}}>
                        <FormControl required size="small" sx={{width:"100%"}}>
                              <InputLabel>License State</InputLabel>
                              <Select
                                required
                                value={driverToRemoveLicenseState}
                                label="License State"
                                onChange= {driverToRemoveLicenseStateChangeHandler}>                        
                                {
                                    usaStates.map((state)=>{
                                        return (
                                          <MenuItem value={state.abbreviation}>{state.name}</MenuItem>
                                        )
                                    })
                                  }
                              </Select>
                            </FormControl>                        
                    </Grid>                                                                                           
                </> 
                <>  
                    <Grid item md={12} sx={{width:"100%", marginTop: 2}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          NEW DRIVER
                      </Typography>
                    </Grid>   
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isDriverLicenseNumberTouched && !driverLicenseNumber & !isDriverLicenseNumberFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsDriverLicenseNumberFocused(true)}}
                          onBlur={()=>{setIsDriverLicenseNumberFocused(false)}}                         
                          value= {driverLicenseNumber}
                          onChange= {driverLicenseNumberChangeHandler}                        
                          label = "License Number"/> 
                    </Grid>                     
                    <Grid item md={6} sx={{width:"100%"}}>
                      <TextField
                          error={isDriverFirstNameTouched && !driverFirstName & !isDriverFirstNameFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsDriverFirstNameFocused(true)}}
                          onBlur={()=>{setIsDriverFirstNameFocused(false)}}                         
                          value= {driverFirstName}
                          onChange= {driverFirstNameChangeHandler}                        
                          label = "First Name"/> 
                    </Grid>     
                    <Grid item md={6} sx={{width:"100%"}}>
                      <TextField
                          error={isDriverLastNameTouched && !driverLastName & !isDriverLastNameFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsDriverLastNameFocused(true)}}
                          onBlur={()=>{setIsDriverLastNameFocused(false)}}                         
                          value= {driverLastName}
                          onChange= {driverLastNameChangeHandler}                        
                          label = "Last Name"/> 
                    </Grid>          
                    <Grid item md={6} sx={{width:"100%"}}>
                        <TextField
                            error={isDateOfBirthTouched && !dateOfBirth & !isDateOfBirthFocused}
                            id="startDate" 
                            size="small"
                            fontFamily="Nunito"
                            type="date"
                            sx={{width:"100%"}}
                            onFocus={()=>{setIsDateOfBirthFocused(true)}}
                            onBlur={()=>{setIsDateOfBirthFocused(false)}}  
                            InputProps={{inputProps: { max: dateOfBirth }}}
                            onChange= {dateOfBirthChangeHandler}                        
                            value= {dateOfBirth}
                            fullWidth 
                            label = "Date of Birth"/> 
                    </Grid>                                           
                    <Grid item md={6} sx={{width:"100%"}}>
                        <FormControl required size="small" sx={{width:"100%"}}>
                              <InputLabel>License State</InputLabel>
                              <Select
                                required
                                value={driverLicenseState}
                                label="License State"
                                onChange= {driverLicenseStateChangeHandler}>                        
                                {
                                    usaStates.map((state)=>{
                                        return (
                                          <MenuItem value={state.abbreviation}>{state.name}</MenuItem>
                                        )
                                    })
                                  }
                              </Select>
                            </FormControl>                        
                    </Grid>                                                                                           
                </>     
                <PolicyChangeDisclaimerSection/>                                                                                                                                                    
                <Grid item xs={12}>
                      <ReCAPTCHA
                          size="normal"
                          onChange={()=>{setIsRecaptchaVerified(true)}}
                          onExpired={()=>{setIsRecaptchaVerified(false)}}
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/>   
                </Grid>                                       
                <Grid item xs={12}>
                      <Button
                          disabled={!isValidFormData()}
                          variant="contained"
                          backgroundColor= "#0C709B" 
                          startIcon={<SendIcon />}
                          size="medium"
                          onClick={submitDocumentRequestHandler}
                          sx={{
                            borderRadius: '20px',
                            fontFamily: "Nunito",
                            fontWeight: "bolder",
                          }}           
                          label = "Claim">  
                          Submit Request
                      </Button>   
                </Grid> 
            </Grid>
        </Grid>       
    </Grid>
  )
}




        

