import React from 'react'
import Fade from 'react-reveal/Fade';
import PersonalHeader from '../assets/images/PersonalInsuranceHeader.png'
import { Box, Stack, Typography, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router';

export default function PersonalInsuranceHeader() {
  const navigate = useNavigate();

  return (
    <Box 
      component="div" 
      sx={{
        backgroundImage: "linear-gradient(#0C709B, #B6ECF6);",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: {
          xs: '105.3vh',
          md: '105.3vh',
          sm: '100vh',
          xs: '100vh',
        },
        overflowX:'hidden'
      }}> 
      <Grid container>
        <Grid item md={6}>
          <Stack
          direction="column"        
          justifyContent="top"
          spacing={2}
          sx={{
            minHeight: '100vh',
            paddingTop: '30vh',
            paddingLeft: '7%'
          }}>
          <Fade bottom delay={50}>
            <Typography 
              variant='h3'
              fontFamily="Sofia Sans Condensed"
              sx={{
                color:"white"
              }}>
              PERSONAL <br/> <strong>INSURANCE</strong>
            </Typography>
          </Fade>
          <Fade bottom delay={300}>
            <Typography 
              variant="h6"
              fontFamily="Nunito"
              sx={{
                display:{
                  lg: 'block',
                  md: 'block',
                  sm: 'none',
                  xs: 'none',
                },                
                width: {
                  lg: '45vw',
                  md: '45vw',
                  sm: '80vw',
                  xs: '80vw',
                },
                color:"white",
                textUnderlineOffset: '20%',
                textDecorationThickness: '1px',
              }}>
              Insurance coverage designed for you and your family. Protect your home, property, vehicles, and assets with customized insurance solutions            
            </Typography> 
            <Typography 
              variant="body2"
              fontFamily="Nunito"
              sx={{
                display:{
                  lg: 'none',
                  md: 'none',
                  sm: 'block',
                  xs: 'block',
                },
                width: {
                  lg: '45vw',
                  md: '45vw',
                  sm: '80vw',
                  xs: '80vw',
                },
                color:"white",
                textUnderlineOffset: '20%',
                textDecorationThickness: '1px',
              }}>
              Insurance coverage designed for you and your family. Protect your home, property, vehicles, and assets with customized insurance solutions            
            </Typography>           
          </Fade>
          <Stack 
            spacing={1}
            direction={{
              lg:"row",
              md:"row",
              sm:"column",
              xs:"column"
            }}>
            <Fade bottom delay={400}>
                <Button 
                    size='large'
                    variant="outlined" 
                    onClick={()=>{navigate("/QuoteNow")}}
                    sx={{
                        fontFamily:"Nunito",   
                        fontWeight:"bolder",
                        display:{
                            lg: 'block',
                            md: 'block',
                            sm: 'none',
                            xs: 'none',
                        },
                        borderColor: "transparent",
                        backgroundColor:'white', 
                        color: "#0C709B", 
                        marginTop: '1vh',
                        padding: '15px 25px',
                        '&:hover': {
                            backgroundColor: 'white',
                            borderColor: "transparent",
                        }
                    }}>
                    Quote Now
                </Button>  
                <Button 
                    size='small'
                    variant="outlined" 
                    onClick={()=>{navigate("/QuoteNow")}}
                    sx={{
                        fontFamily:"Nunito",   
                        fontWeight:"bolder",
                        display:{
                            lg: 'none',
                            md: 'none',
                            sm: 'block',
                            xs: 'block',
                        },
                        borderColor: "transparent",
                        backgroundColor:'white', 
                        color: "#0C709B", 
                        marginTop: '1vh',
                        width: '70%',
                        padding: '7px 10px',
                        '&:hover': {
                            backgroundColor: 'white',
                            borderColor: "transparent",
                        }
                    }}>
                    Quote Now
                </Button>              
            </Fade>        
            <Fade bottom delay={500}>
                <Button 
                    size='large'
                    variant="outlined" 
                    onClick={()=>{navigate('/RequestConsultation')}}
                    sx={{
                        fontFamily:"Nunito",   
                        fontWeight:"bolder",
                        display:{
                            lg: 'block',
                            md: 'block',
                            sm: 'none',
                            xs: 'none',
                        },
                        borderColor: "transparent",
                        backgroundColor:'white', 
                        color: "#0C709B", 
                        marginTop: '1vh',
                        padding: '15px 25px',
                        '&:hover': {
                            backgroundColor: 'white',
                            borderColor: "transparent",
                        }
                    }}>
                    Request Insurance Consultation
                </Button>  
                <Button 
                    size='small'
                    variant="outlined" 
                    onClick={()=>{navigate('/RequestConsultation')}}
                    sx={{
                        fontFamily:"Nunito",   
                        fontWeight:"bolder",
                        display:{
                            lg: 'none',
                            md: 'none',
                            sm: 'block',
                            xs: 'block',
                        },
                        borderColor: "transparent",
                        backgroundColor:'white', 
                        color: "#0C709B", 
                        marginTop: '-10px',
                        width: '70%',
                        padding: '7px 10px',
                        '&:hover': {
                            backgroundColor: 'white',
                            borderColor: "transparent",
                        }
                    }}>
                    Request Consultation
                </Button>              
            </Fade>
          </Stack>
          </Stack> 
        </Grid>
        <Grid item md={6} paddingTop='22vh' 
          sx={{
            display:{
              xs:"none",
              sm:"none",
              md:"block",
              lg:"block"
            },
            textAlign: 'center'
          }}>         
           <Box
            component="img"
            sx={{
              height: '400px',
            }}
            alt="Personal Insurance."
            src={PersonalHeader}
          />        
        </Grid>
      </Grid>         
    </Box>            
  )
}




        