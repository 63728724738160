import { Stack, Typography, Box, Grid, Link} from '@mui/material';

const ContactOfficeToReportClaim = ()=>{
    return (
        <Grid>
            <Typography 
                variant='body1'
                fontFamily="Nunito"
                fontWeight="bold"
                sx={{color:"#0C709B"}}>
                Please contact our representative at 
                <Link href="mailto:service@modernshoreins.com?subject=Claim Notice"
                    sx={{
                        paddingLeft: "1%",
                        paddingRight: "1%",
                        color:'#0C709B',
                        textDecoration: 'none',
                        '&:hover': {
                            color: '#5c9ab5',
                        }
                    }}>
                service@modernshoreins.com
                </Link> 
            </Typography>
            <Typography 
                variant='body1'
                fontFamily="Nunito"
                fontWeight="bold"
                sx={{color:"#0C709B"}}>
                You can also call us at 
                <Link href="tel:7273710014" 
                    sx={{
                        paddingLeft: "1%",
                        color:'#0C709B',
                        textDecoration: 'none',
                        '&:hover': {
                            color: '#5c9ab5',
                        }
                    }}>
                    (727) 371-0014
                </Link>
            </Typography>  
        </Grid>
    )
}

export default ContactOfficeToReportClaim