import * as React from 'react';
import { Button, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


export default function Service({title, description, imageSrc, clickHandler}) {
  return (
    <Card 
        sx={{
          borderBottom:'4px solid #0C709B', 
          paddingBottom: '10px',
          borderRadius:'10px 10px 0px 0px',
        }}>
        <CardMedia
            component="img"
            height="140"
            image={imageSrc}
            alt={title}
          />           
        <CardContent>         
            <Typography 
                gutterBottom 
                variant="h4" 
                component="div" 
                fontFamily="Nunito"
                sx={{
                    fontFamily:"Nunito",
                    fontWeight: "bolder",
                    color:'#0C709B',
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none"
                    }
                }}>
                {title}
            </Typography>
            <Typography 
                gutterBottom 
                variant="h5" 
                component="div" 
                fontFamily="Nunito"
                sx={{
                    fontFamily:"Nunito",
                    fontWeight: "bolder",
                    color:'#0C709B',
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                      lg: "block"
                    }
                }}>
                {title}
            </Typography>            
            <Typography variant="body2" 
                sx={{
                    color:'#0C709B', 
                    fontFamily:"Nunito",
                    fontWeight: "bolder",
                    minHeight: {
                      lg:"10vh",
                      md:"10vh",
                      sm:"0vh",
                      xs:"0vh"
                    },
                  }}>
                {description}
            </Typography>
        </CardContent>
        <CardActions>
            <Button 
                onClick={clickHandler}
                sx={{
                  color:'#0C709B', marginLeft: 1,
                  fontFamily:"Nunito",
                  fontWeight:"bolder"
                }}
                size="small"  endIcon={<ArrowForwardIcon/>} >
                Learn more
            </Button>
        </CardActions>
    </Card>    
  );
}
