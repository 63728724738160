import React from 'react'
import { Box, Grid} from '@mui/material';
import ApplyNowForm from './ApplyNowForm';

export default function ApplyNowHeader({backgroundImage}) {
  return (
    <Box 
        component="div" 
        sx={{
            minHeight: '100vh'
        }}>
        <Grid container>
            <Grid item md={6} sm={12} xs={12} 
                sx={{
                    display:{
                        sm: "none",
                        xs: "none",
                        md: "block",
                        lg: "block"
                    }
                }}>
                <Box 
                    component="div" 
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        minHeight: '100vh',
                        overflowX:'hidden'
                    }}>
                </Box>
            </Grid> 
            <Grid item md={6} sm={12} xs={12}>
                <ApplyNowForm/>
            </Grid>              
        </Grid>   
    </Box>               
  )
}




        