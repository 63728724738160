import axios from 'axios';
import { GetBlogPosts } from "./index";

//Get Blog Posts      
export const getBlogPost = async (insururanceType = "") =>{
    let response = await axios.get(GetBlogPosts  + '&fetchImages=true&fetchBodies=false&labels=' + insururanceType + 
                                  '&orderBy=published&sortOption=descending&status=live');     
    return response.data;
};

//Get Paginated Blog Posts   
export const getPaginatedBlogPost = async (insururanceType = "") =>{
    let response = await axios.get(GetBlogPosts  + '&fetchImages=true&fetchBodies=false&labels=' + insururanceType + 
                                  '&orderBy=published&sortOption=descending&status=live&maxResults=10');     
    return response.data;
};