import React from 'react'
import { Box, Grid, Typography, TextField, Button } from '@mui/material';
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { Snackbar, Alert, Backdrop } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ClearIcon from '@mui/icons-material/Clear';
import ReCAPTCHA from "react-google-recaptcha";
import FileUpload from 'react-material-file-upload';
import NumberFormat from 'react-number-format';
import { isValidEmailAddress, isValidPostalCode } from '../utils/utilityFunctions';
import { useEffect } from 'react';
import { getBase64 } from '../utils/utilityFunctions/getBase64';
import { getCarrierList, getPolicyList, createLeadInSalesForce } from "../apiClient/insurancePolicies";
import loader from '../assets/images/Loader.gif'
import moment from 'moment';


export default function MoveToModernShoreForm() {
  const currentDate = moment(new Date()).format('YYYY-MM-DD')

  const [carriers, setCarriers] = useState([])
  const [policyTypes, setPolicyTypes] = useState([])

  const [isClientDetailSectionVisible, setIsClientDetailSectionVisible] = useState(true)
  const [isChoseTypeVisible, setIsChoseTypeVisible] = useState(false)
  const [isSubmitCurrentPolicySectionVisible, setIsSubmitCurrentPolicySectionVisible] = useState(false)
  const [withoutPolicyDocumentSectionVisible, setIsWithoutPolicyDocumentSectionVisible] = useState(false)

  const [isBackdropVisible, setIsBackdropVisible] = React.useState(false);
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertMessageSeverity, setAlertMessageSeverity] = React.useState("info");

  const [name, setName] = useState("")
  const [lastName, setLastName] = useState("")
  const [emailAddress, setEmailAddress] = useState("");     
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState(""); 
  const [phone, setPhone] = useState("")
  const [policyNumber, setPolicyNumber] = useState("")
  const [currentCarrier, setCurrentCarrier] = useState("")
  const [policyType, setPolicyType] = useState("")
  const [files, setFiles] = useState([])

  const [effectiveDate, setEffectiveDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [expirationDate, setExpirationDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const [isNameFocused, setIsNameFocused] = useState(false)
  const [isLastNameFocused, setIsLastNameFocused] = useState(false)
  const [isEmailAddressFocused, setIsEmailAddressFocused] = useState(false)
  const [isPhoneFocused, setIsPhoneFocused] = useState(false)
  const [isAddressFocused, setIsAddressFocused] = useState(false)
  const [isCityFocused, setIsCityFocused] = useState(false)
  const [isStateFocused, setIsStateFocused] = useState(false)
  const [isZipcodeFocused, setIsZipcodeFocused] = useState(false)
  const [isPolicyNumberFocused, setIsPolicyNumberFocused] = useState(false)

  const [isNameTouched, setIsNameTouched] = useState(false)
  const [isLastNameTouched, setIsLastNameTouched] = useState(false)
  const [isEmailAddressTouched, setIsEmailAddressTouched] = useState(false)
  const [isPhoneTouched, setIsPhoneTouched] = useState(false)
  const [isAddressTouched, setIsAddressTouched] = useState(false)
  const [isCityTouched, setIsCityTouched] = useState(false)
  const [isStateTouched, setIsStateTouched] = useState(false)
  const [isZipcodeTouched, setIsZipcodeTouched] = useState(false)
  const [isPolicyNumberTouched, setIsPolicyNumberTouched] = useState(false)

  const nameChangeHandler = (event) =>{
    !isNameTouched && setIsNameTouched(true)
    setName(event.target.value)
  }

  const lastNameChangeHandler = (event) =>{
    !isLastNameTouched && setIsLastNameTouched(true)
    setLastName(event.target.value)
  }

  const emailAddressChangeHandler = (event) =>{
    !isEmailAddressTouched && setIsEmailAddressTouched(true)
    setEmailAddress(event.target.value.trim())
  }

  const policyNumberChangeHandler = (event) =>{
    !isPolicyNumberTouched && setIsPolicyNumberTouched(true)
    setPolicyNumber(event.target.value.trim())
  }

  const phoneChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    if(value != phone)
    {
      !isPhoneTouched && setIsPhoneTouched(true)
      setPhone(value)
    }
  }
  
  const effectiveDateChangeHandler = (event) =>{
    setEffectiveDate(event.target.value)
  }

  const expirationDateChangeHandler = (event) =>{
    setExpirationDate(event.target.value)
  }

  const handleCurrentCarrierChange = (event)=>{
    setCurrentCarrier(event.target.value)
  }

  const policyTypeChangeHandler = (event) =>{
    setPolicyType(event.target.value)
  }

  const addressChangeHandler = (event) =>{
    !isAddressTouched && setIsAddressTouched(true)
    setAddress(event.target.value)
  }

  const cityChangeHandler = (event) =>{
    !isCityTouched && setIsCityTouched(true)
    setCity(event.target.value)
  }
  const stateChangeHandler = (event) =>{
    !isStateTouched && setIsStateTouched(true)
    setState(event.target.value)
  }
  const zipcodeChangeHandler = (values) =>{
    const { formattedValue, value } = values;

    if(value != zipcode)
      {
        !isZipcodeTouched && setIsZipcodeTouched(true)
        setZipcode(value)
      }
  }
      
    const isValidPhone = phone !== null && phone !== undefined && phone.toString().length === 10;

    const [isRecaptcha1Verified, setIsRecaptcha1Verified] = useState(false)
    const [isRecaptcha2Verified, setIsRecaptcha2Verified] = useState(false)


    const isValidFormData = () =>{
      return name && lastName && isValidEmailAddress(emailAddress) && isValidPhone 
    }

    const isValidForm2Data = () =>{
      return address && city && state && isValidPostalCode(zipcode) && policyNumber && currentCarrier && isRecaptcha2Verified && policyType
    }  
    const nextClickHandler = ()=>{
        setIsClientDetailSectionVisible(false)
        setIsChoseTypeVisible(true)
    }

    const onClickSubmitCurrentPolicyHandler = ()=>{
        setIsChoseTypeVisible(false)
        setIsSubmitCurrentPolicySectionVisible(true)
    }

    const onClickContinueWithoutPolicy = ()=>{
      setIsChoseTypeVisible(false)
      setIsWithoutPolicyDocumentSectionVisible(true)
    }

    const resetCompleteForm = ()=>{
      setIsClientDetailSectionVisible(true)
      setIsSubmitCurrentPolicySectionVisible(false)
      setIsWithoutPolicyDocumentSectionVisible(false)
      resetForm()
    }

    const resetForm = () =>{
      setName("")
      setLastName("")
      setEmailAddress("")
      setPhone("")
      setPolicyNumber("")
      setCurrentCarrier("")
      setPolicyType("")
      setAddress("");
      setCity("");
      setState("");
      setZipcode("");
      setFiles([])
      setEffectiveDate(moment(new Date()).format('YYYY-MM-DD'))
      setExpirationDate(moment(new Date()).format('YYYY-MM-DD'))

      setIsNameFocused(false)
      setIsLastNameFocused(false)
      setIsEmailAddressFocused(false)
      setIsPhoneFocused(false)
      setIsPolicyNumberFocused(false)
      setIsAddressFocused(false);
      setIsCityFocused(false);
      setIsStateFocused(false);
      setIsZipcodeFocused(false);
      
      setIsNameTouched(false)
      setIsLastNameTouched(false)
      setIsEmailAddressTouched(false)
      setIsPhoneTouched(false)
      setIsPolicyNumberTouched(false)
      setIsAddressTouched(false);
      setIsCityTouched(false);
      setIsStateTouched(false);
      setIsZipcodeTouched(false);
    }

    const getCarriers = async ()=>{
      try{
        setBackdropVisibilityValue(true)
        const response = await getCarrierList();
        setCarriers(response)  
        setBackdropVisibilityValue(false)
      }
      catch(ex)
      {
        setBackdropVisibilityValue(false)
      }
    }

    const getPolicies = async ()=>{
      try{
        setBackdropVisibilityValue(true)
        const response = await getPolicyList();
        setPolicyTypes(response)  
        setBackdropVisibilityValue(false)
      }
      catch(ex)
      {
        setBackdropVisibilityValue(false)
      }
    }

    const setBackdropVisibilityValue = (value) => {
      setIsBackdropVisible(value);
    };
  
    const setAlertMessageAndVisibility = (message, severity, isVisible)=>{
      setAlertMessage(message)
      setAlertMessageSeverity(severity)
      setIsAlertVisible(isVisible)
      setTimeout(()=>{
        setIsAlertVisible(false)
      },5000)
    }
  
    useEffect(()=>{
      getCarriers();
      getPolicies();
    },[])

    const onClickHandlerWithoutDocument = async () =>{
      try{
        setBackdropVisibilityValue(true)

        const userPolicyType = policyTypes.find(x => x.policyTypeId == policyType).policyTypeName
        const userCurrentCarrier = carriers.find(x => x.carrierId == currentCarrier).carrierName

        const leadVM = {
          "firstName": name,
          "lastName":lastName,
          "email": emailAddress,
          "phone": phone,
          "pageSource": "MOVE TO MODERN SHORE",
          "address":{
              "street": address,
              "city": city,
              "state": state,
              "postalCode": zipcode,
              "country": "US"
          },
          "additionalDetails": [
            {
              "fieldName": "policyNumber",
              "fieldLabel": "Policy Number",
              "fieldValue": policyNumber
            },
            {
              "fieldName": "policyType",
              "fieldLabel": "Policy Type",
              "fieldValue": userPolicyType
            },
            {
              "fieldName":"currentCarrier",
              "fieldLabel":"Current Carrier",
              "fieldValue": userCurrentCarrier
            },
            {
              "fieldName": "effectiveDate",
              "fieldLabel": "Effective Date",
              "fieldValue": effectiveDate
            },
            {
              "fieldName": "expirationDate",
              "fieldLabel": "Expiration Date",
              "fieldValue": expirationDate
            }                                              
          ]
        }
        debugger
        await createLeadInSalesForce(leadVM)
        resetCompleteForm()
        setBackdropVisibilityValue(false)
        setAlertMessageAndVisibility("Request successfully created", "success", true)
      }
      catch(ex)
      {
        setAlertMessageAndVisibility("Request Submission Failed", "error", true)
        setBackdropVisibilityValue(false)
      }
    }

    const onClickHandlerWithDocument = async () =>{
      try{      
        setBackdropVisibilityValue(true)
        const leadVM = {
          "firstName": name,
          "lastName":lastName,
          "email": emailAddress,
          "phone": phone,
          "pageSource": "MOVE TO MODERN SHORE",
          "attachments" : [
              {
                  "fileName": files[0].name,
                  "fileBase64": await getBase64(files[0])
              }
          ]
        }
        await createLeadInSalesForce(leadVM)
        resetCompleteForm()
        setBackdropVisibilityValue(false)
        setAlertMessageAndVisibility("Request successfully created", "success", true)
      }
      catch(ex)
      {
        setAlertMessageAndVisibility("Request Submission Failed", "error", true)
        setBackdropVisibilityValue(false)
      }
    }

    return (
        <Box 
          component="div" 
          sx={{
            backgroundcolor: "#f5f5f5",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minHeight: '105%',
            marginTop: '10vh',
            overflowX:'hidden',
            borderRadius: '10px',
            padding: {
              xs: "10% 10% 10% 10%",
              sm: "10% 10% 2% 10%",
              md: "10% 10% 2% 10%"
            }
          }}>
          <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isBackdropVisible}
              onClick={()=>{setBackdropVisibilityValue(false)}}>
              <Box
                component="img"
                sx={{
                  height: '80px'
                }}      
                alt="loading..."                          
                src={loader}/>
          </Backdrop>
          <Snackbar 
            sx={{
              marginTop: "10vh"
            }}
            anchorOrigin={{ vertical:"top", horizontal: "center" }} 
            open={isAlertVisible} 
            onClose={()=>{isAlertVisible(false)}}>
            <Alert onClose={()=>{isAlertVisible(false)}} severity={alertMessageSeverity} sx={{ width: '100%' }}>
              {alertMessage}
            </Alert>
          </Snackbar>             
          <Typography 
            variant='h3'
            fontFamily="Sofia Sans Condensed"
            sx={{
              color:"#0C709B",
              marginBottom:'20px'
            }}>
            MOVE TO MODERN SHORE
          </Typography>
          <Typography
            variant='body1'
            sx={{
              fontFamily: "Nunito",
              color:"#0C709B",
              display:{
                lg: "block",
                md: "block",
                sm: "none",
                xs: "none"
              }
            }}>
            Making the Move to Modern Shore is Easy! You can keep your same insurance policy and carrier, while gaining the satisfaction of having a dedicated Modern Shore Insurance Advisor on your side. Our friendly and knowledgeable agents are committed to providing you with responsive service and quality insurance guidance. Enter your current Insurance Policy information and Make the Move to Modern Shore today!
          </Typography>
          <Typography
            variant='body2'
            sx={{
              color:"#0C709B",
              display:{
                lg: "none",
                md: "none",
                sm: "block",
                xs: "block"
              }
            }}>
            Making the Move to Modern Shore is Easy! You can keep your same insurance policy and carrier, while gaining the satisfaction of having a dedicated Modern Shore Insurance Advisor on your side. Our friendly and knowledgeable agents are committed to providing you with responsive service and quality insurance guidance. Enter your current Insurance Policy information and Make the Move to Modern Shore today!
          </Typography>
          <br/>
          <br/>
          <Grid container rowSpacing={2} sx={{display: isClientDetailSectionVisible ? 'block' : 'none'}}>
              <Grid item xs={12}>
                <Typography 
                  variant='h4'
                  fontFamily="Sofia Sans Condensed"
                  sx={{
                    color:"#0C709B", marginBottom:"2vh"}}>
                  CLIENT DETAILS
                </Typography>
              </Grid>
              <Grid item xs={12}>
                  <Grid container columnSpacing={2} rowSpacing={2}>
                      <Grid item md={6} sm={12} xs={12}>
                          <TextField
                              fontFamily="Nunito"
                              fullWidth
                              size='small'
                              type="text"
                              value= {name}
                              required
                              onChange= {nameChangeHandler}   
                              onFocus={()=>{setIsNameFocused(true)}}
                              onBlur={()=>{setIsNameFocused(false)}}
                              error= {isNameTouched && !name && !isNameFocused}                     
                              label = "First Name"/>    
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                          <TextField
                              fontFamily="Nunito"
                              fullWidth
                              size='small'
                              type="text"
                              value= {lastName}
                              required
                              onChange= {lastNameChangeHandler}
                              onFocus={()=>{setIsLastNameFocused(true)}}
                              onBlur={()=>{setIsLastNameFocused(false)}}
                              error= {isLastNameTouched && !lastName && !isLastNameFocused}                              
                              label = "Last Name"/>    
                      </Grid>
                  </Grid>
              </Grid> 
              <Grid item xs={12}>
                  <Grid container columnSpacing={2} rowSpacing={2}>
                      <Grid item md={6} sm={12} xs={12}>
                          <TextField
                              fullWidth
                              type="email"
                              size='small'
                              required
                              value= {emailAddress}
                              onChange= {emailAddressChangeHandler}   
                              onFocus={()=>{setIsEmailAddressFocused(true)}}
                              onBlur={()=>{setIsEmailAddressFocused(false)}}
                              error= {isEmailAddressTouched && !isValidEmailAddress(emailAddress) && !isEmailAddressFocused}                        
                              label = "Email"/>  
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                          <NumberFormat
                              sx={{
                                  width: "100%"
                              }}
                              required
                              error= {isPhoneTouched && !isValidPhone && !isPhoneFocused}
                              label="Phone"
                              size="small"
                              type="text" 
                              value={phone} 
                              format="+1 (###) ###-####" 
                              mask="_" 
                              customInput={TextField}
                              onFocus={()=>{setIsPhoneFocused(true)}}
                              onBlur={()=>{setIsPhoneFocused(false)}}
                              onValueChange={phoneChangeHandler}/>   
                      </Grid>
                  </Grid>
              </Grid>               
              <Grid item xs={12} sx={{textAlign:'right', marginTop:"2vh"}}>
                  <Button
                      sx={{
                        fontFamily: "Nunito",
                        fontWeight: "Bolder"
                      }}
                      variant="contained"
                      backgroundcolor= "#0C709B"  
                      endIcon={<NavigateNextIcon />}
                      size="large"
                      onClick={nextClickHandler}
                      disabled= {!isValidFormData()}>
                      Next
                  </Button>   
              </Grid>
          </Grid>
          <Grid container rowSpacing={4} sx={{paddingBottom: "8vh", display: isChoseTypeVisible ? 'block' : 'none'}}>
            <Grid item xs={12} sx={{textAlign:'center', marginTop: '5vh'}}>
                <Button
                      sx={{
                        fontFamily: "Nunito",
                        fontWeight: "Bolder",
                        borderRadius: '5px', 
                        color: '#0C709B'
                      }}                    
                      variant="outlined"
                    backgroundcolor= "#0C709B"  
                    size="large"
                    onClick={onClickSubmitCurrentPolicyHandler}>
                    Submit Current Policy for Transfer
                </Button> 
            </Grid>
            <Grid item xs={12} sx={{textAlign:'center'}}>
                <Typography 
                    sx={{
                      fontFamily: "Nunito",
                      borderRadius: '5px', 
                      color: '#0C709B'
                    }}   
                    variant='h5'
                    color='#0C709B'
                    fontFamily="Sofia Sans Condensed">  
                    OR
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{textAlign:'center'}}>
                <Button
                    variant="outlined"
                    backgroundcolor= "#0C709B"  
                    size="large"
                    onClick={onClickContinueWithoutPolicy}
                    sx={{
                      fontFamily: "Nunito",
                      fontWeight: "Bolder",
                      borderRadius: '5px', 
                      color: '#0C709B'
                    }} >  
                    Continue without Policy Document
                </Button> 
            </Grid>
          </Grid>     
          <Grid container rowSpacing={4} sx={{display: isSubmitCurrentPolicySectionVisible ? 'block' : 'none'}}>
            <Grid item xs={12}>
              <Typography 
                  variant='h4'
                  fontFamily="Sofia Sans Condensed"
                  sx={{color:"#0C709B",
                }}>
                  SUBMIT CURRENT POLICY FOR TRANSFER
              </Typography>
            </Grid>            
            <Grid item xs={12}>
                <FileUpload
                    title="Drag 'n' drop some file here, or click to select file"
                    value={files} 
                    onChange={setFiles} 
                    style={{
                        height:"10px"
                    }}
                    multiple={false}
                    accept="application/pdf"/>
            </Grid>  
            <Grid item xs={12}>
                <ReCAPTCHA
                    size="normal"
                    onChange={()=>{setIsRecaptcha1Verified(true)}}
                    onExpired={()=>{setIsRecaptcha1Verified(false)}}
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/> 
            </Grid>                                           
            <Grid item xs={12}>
                <Grid container>
                    <Grid item md={4} sm={6} xs={6}>
                      <Button
                          sx={{
                            fontFamily: "Nunito",
                            fontWeight: "Bolder",
                            width: "90%"}}
                          disabled={!isRecaptcha1Verified || files.length == 0 }
                          variant="contained"
                          backgroundcolor= "#0C709B"  
                          size="large"  
                          onClick={onClickHandlerWithDocument}                             
                          startIcon={<SendIcon />}                 
                          label = "Submit">  
                          Submit
                      </Button>  
                    </Grid>                   
                    <Grid item md={4} sm={6} xs={6}>
                      <Button
                          variant="outlined" color="error"
                          sx={{width: "90%",
                          fontFamily: "Nunito",
                          fontWeight: "Bolder"}}
                          backgroundcolor= "#0C709B"  
                          size="large"
                          startIcon={<ClearIcon />}
                          onClick={resetCompleteForm}
                          label = "Cancel">  
                          Cancel
                      </Button> 
                    </Grid>                   
                </Grid>                        
            </Grid>
          </Grid>   
          <Grid container rowSpacing={2} sx={{display: withoutPolicyDocumentSectionVisible ? 'block' : 'none'}}>
            <Grid item xs={12}>
              <Typography 
                  variant='h4'
                  fontFamily="Sofia Sans Condensed"
                  sx={{color:"#0C709B",  
                }}>
                  CONTINUE WITHOUT POLICY DOCUMENT
              </Typography>
            </Grid>                       
            <Grid item xs={12}>
                  <TextField
                      fullWidth
                      type="text"
                      size='small'
                      required
                      value= {policyNumber}
                      onChange= {policyNumberChangeHandler}   
                      onFocus={()=>{setIsPolicyNumberFocused(true)}}
                      onBlur={()=>{setIsPolicyNumberFocused(false)}}
                      error= {isPolicyNumberTouched && !policyNumber && !isPolicyNumberFocused}                        
                      label = "Policy Number"/>  
            </Grid>    
            <Grid item xs={12}>
                  <FormControl size="small" sx={{width:"100%"}} required>
                    <InputLabel>Policy Type</InputLabel>
                        <Select
                          sx={{width:"100%"}}                          
                          value={policyType}
                          label="Policy Type"
                          size="small"
                          required
                          onChange={policyTypeChangeHandler}>
                          {
                              policyTypes.map( (type, index) => <MenuItem key={index} value={type.policyTypeId}>{type.policyTypeName}</MenuItem>)
                          }
                        </Select>
                  </FormControl> 
            </Grid>
            <Grid item xs={12}>
                  <FormControl size="small" sx={{width:"100%"}} required>
                    <InputLabel>Current Carrier</InputLabel>
                        <Select
                          sx={{width:"100%"}}                          
                          value={currentCarrier}
                          label="Current Carrier"
                          size="small"
                          required
                          onChange={handleCurrentCarrierChange}>
                          {
                              carriers.map( (type, index) => <MenuItem key={index} value={type.carrierId}>{type.carrierName}</MenuItem>)
                          }
                        </Select>
                  </FormControl> 
            </Grid>            
            <Grid item xs={12}>
                <Grid container columnSpacing={2} rowSpacing={2}>
                  <Grid item md={6} sm={12} xs={12} sx={{width:"100%"}}>
                          <TextField
                              id="startDate" 
                              size="small"
                              fontFamily="Nunito"
                              type="date"
                              sx={{width:"100%"}}
                              InputProps={{inputProps: { max: currentDate }}}
                              onChange= {effectiveDateChangeHandler}                        
                              value= {effectiveDate}
                              fullWidth 
                              label = "Effective Date"/> 
                  </Grid>  
                  <Grid item md={6} sm={12} xs={12} sx={{width:"100%"}}>
                              <TextField
                                  id="startDate" 
                                  size="small"
                                  fontFamily="Nunito"
                                  type="date"
                                  sx={{width:"100%"}}
                                  InputProps={{inputProps: { max: currentDate }}}
                                  onChange= {expirationDateChangeHandler}                        
                                  value= {expirationDate}
                                  fullWidth 
                                  label = "Expiration Date"/> 
                  </Grid> 
                </Grid>
            </Grid>
            <Grid item md={12}>
                      <TextField
                          error={isAddressTouched && !address && !isAddressFocused}
                          required
                          multiline
                          minRows={2}
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          onFocus={()=>{setIsAddressFocused(true)}}
                          onBlur={()=>{setIsAddressFocused(false)}}
                          type="text" 
                          value= {address}
                          onChange= {addressChangeHandler}                        
                          label = "Address"/>
            </Grid>  
            <Grid item xs={12}>
                  <Grid container columnSpacing={2} rowSpacing={2} >
                    <Grid item md={4} sx={{width:"100%"}}>
                        <TextField
                            error={isCityTouched && !city & !isCityFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            type="text"   
                            onFocus={()=>{setIsCityFocused(true)}}
                            onBlur={()=>{setIsCityFocused(false)}}                         
                            value= {city}
                            onChange= {cityChangeHandler}                        
                            label = "City"/> 
                    </Grid>          
                    <Grid item md={4} sx={{width:"100%"}}>
                        <TextField
                            error={isStateTouched && !state & !isStateFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            type="text"   
                            onFocus={()=>{setIsStateFocused(true)}}
                            onBlur={()=>{setIsStateFocused(false)}}                         
                            value= {state}
                            onChange= {stateChangeHandler}                        
                            label = "State"/> 
                    </Grid>  
                    <Grid item md={4} sx={{width:"100%"}}>
                        <NumberFormat
                              sx={{width: "100%"}}
                              error={isZipcodeTouched && !isValidPostalCode(zipcode) & !isZipcodeFocused}
                              required
                              label="Zipcode"
                              size="small"
                              type="text" 
                              value={zipcode} 
                              format="#####" 
                              mask="_" 
                              customInput={TextField}
                              onFocus={()=>{setIsZipcodeFocused(true)}}
                              onBlur={()=>{setIsZipcodeFocused(false)}}   
                              onValueChange={zipcodeChangeHandler}/>                              
                    </Grid>
                  </Grid>
            </Grid>
            <Grid item xs={12}>
                        <ReCAPTCHA
                            size="normal"
                            onChange={()=>{setIsRecaptcha2Verified(true)}}
                            onExpired={()=>{setIsRecaptcha2Verified(false)}}
                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/> 
            </Grid>                          
            <Grid item xs={12}>
              <Grid container>
                <Grid item md={4} sm={6} xs={6}>
                        <Button
                            sx={{width: "90%",
                            fontFamily: "Nunito",
                            fontWeight: "Bolder",}}
                            variant="contained"
                            backgroundcolor= "#0C709B"  
                            disabled={!isValidForm2Data() }
                            size="large"
                            onClick={onClickHandlerWithoutDocument}                               
                            startIcon={<SendIcon />}                 
                            label = "Submit">  
                            Submit
                        </Button>  
                </Grid>  
                <Grid item md={4} sm={6} xs={6}>
                        <Button
                            variant="outlined" color="error"
                            sx={{width: "90%",
                            fontFamily: "Nunito",
                            fontWeight: "Bolder",}}
                            backgroundcolor= "#0C709B"  
                            size="large"
                            startIcon={<ClearIcon />}
                            onClick={resetCompleteForm}
                            label = "Cancel">  
                            Cancel
                        </Button> 
                </Grid>   
              </Grid>            
            </Grid>              
          </Grid>          
        </Box>               
    )
  }
  