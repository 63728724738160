import React, { useEffect } from 'react';

const LightningComponent = ({app, component, divId }) => {
  useEffect(() => {
    const loadLightning = () => {
      if (window.$Lightning) {
        window.$Lightning.use(
          app,
          () => {
            window.$Lightning.createComponent(
              component,
              {},
              divId,
              (cmp) => {
                console.log('LWC component was created');
              }
            );
          },
          'https://modernshoreinsurance.my.salesforce-sites.com/'
        );
      }
    };

    // Load Lightning after the component mounts
    loadLightning();
  }, []);

  return <div id={divId}></div>;
};

export default LightningComponent;
