import React from 'react';
import { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import QuoteNowHeader from '../components/QuoteNowHeader';
import AboutUsHeaderImage from '../assets/images/AboutUsHeader.jpg';
import MapBox from '../components/MapBox'
import QuoteNowBody from '../components/QuoteNowBody';

import FloodInsuranceForm from '../components/FloodInsuranceForm';
import HomeInsuranceForm from '../components/HomeInsuranceForm';
import AutoInsuranceForm from '../components/AutoInsuranceForm';
import BusinessInsuranceForm from '../components/BusinessInsuranceForm';



function QuoteNow({type}) {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  const quoteType = quoteNowType.find(q => q.id == type);
  return (
    <Stack>      
      <section>
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section>  
      <section>
        <QuoteNowHeader backgroundImage={AboutUsHeaderImage}/>   
        {
            quoteType &&
            <QuoteNowBody
                title={quoteType.title}
                QuoteNowForm={quoteType.quoteNowForm}/>  
        }
      </section>
      <section>
        <MapBox 
            latitude={27.770760}
            longitude={-82.637790}/>                           
      </section>
    </Stack>
  )
}

const quoteNowType = [
    {
        id: 1,
        title: "Business Insurance Quote",
        quoteNowForm: BusinessInsuranceForm
    },
    {
        id: 2,
        title: "Auto Insurance Quote",
        quoteNowForm: AutoInsuranceForm
    },
    {
        id: 3,
        title: "Home Insurance Quote",
        quoteNowForm: HomeInsuranceForm
    },
    {
        id: 4,
        title: "Flood Insurance Quote",
        quoteNowForm: FloodInsuranceForm
    },
]

export default QuoteNow