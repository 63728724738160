import React from 'react'
import { Grid, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import { isValidEmailAddress, isValidPostalCode } from '../utils/utilityFunctions';
import NumberFormat from 'react-number-format';
import { useEffect } from 'react';


export default function VenderRequestDocumentForm({requestDocumentChangeHandler, documentType}) {
  useEffect(()=>{
    resetForm();
  },[documentType])

  const [businessName, setBusinessName] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("")
  const [deliveryMethodValue, setDeliveryMethodValue] = useState("")
  const [specialInstructions, setSpecialInstructions] = useState("")

  const [isBusinessNameFocused, setIsBusinessNameFocused] = useState(false)
  const [isNameFocused, setIsNameFocused] = useState(false)
  const [isAddressFocused, setIsAddressFocused] = useState(false)
  const [isCityFocused, setIsCityFocused] = useState(false)
  const [isStateFocused, setIsStateFocused] = useState(false)
  const [isZipcodeFocused, setIsZipcodeFocused] = useState(false)
  const [isDeliveryMethodValueFocused, setIsDeliveryMethodValueFocused] = useState(false)

  const [isBusinessNameTouched, setIsBusinessNameTouched] = useState(false)
  const [isNameTouched, setIsNameTouched] = useState(false)
  const [isAddressTouched, setIsAddressTouched] = useState(false)
  const [isCityTouched, setIsCityTouched] = useState(false)
  const [isStateTouched, setIsStateTouched] = useState(false)
  const [isZipcodeTouched, setIsZipcodeTouched] = useState(false)
  const [isDeliveryMethodValueTouched, setIsDeliveryMethodValueTouched] = useState(false)

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)

  const businessNameChangeHandler = (event) =>{
    !isBusinessNameTouched && setIsBusinessNameTouched(true)
    setBusinessName(event.target.value)
  }

  const nameChangeHandler = (event) =>{
    !isNameTouched && setIsNameTouched(true)
    setName(event.target.value)
  }

  const addressChangeHandler = (event) =>{
    !isAddressTouched && setIsAddressTouched(true)
    setAddress(event.target.value)
  }

  const cityChangeHandler = (event) =>{
    !isCityTouched && setIsCityTouched(true)
    setCity(event.target.value)
  }
  const stateChangeHandler = (event) =>{
    !isStateTouched && setIsStateTouched(true)
    setState(event.target.value)
  }
  const zipcodeChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    if(value != zipcode)
    {
      !isZipcodeTouched && setIsZipcodeTouched(true)
      setZipcode(value)
    }
  }

  const deliveryMethodsValueChangeHandler = (event) =>{
    !isDeliveryMethodValueTouched && setIsDeliveryMethodValueTouched(true)
    setDeliveryMethodValue(event.target.value)
  }

  const specialInstructionsChangeHandler = (event) =>{
    setSpecialInstructions(event.target.value)
  }


  const submitDocumentRequestHandler = ()=>{
    let requestData = {
      accountType: "2",
      category: 'DGR2', 
      documentType: documentType,   
      accountName: businessName,
      details : {
        certificateHolderName : name,
        certificateHolderAddress : {
          address1 : address,
          city : city,
          state : state,
          postalCode : zipcode
        },
        comments : specialInstructions,
        documentDeliveryMethod: deliveryMethod,
        deliveryMethodValue: deliveryMethodValue
      }
    }
    requestDocumentChangeHandler(requestData);
  }

  const isValidFormData = () =>{
    return isRecaptchaVerified && businessName && name  && address && city && state && isValidPostalCode(zipcode) && validateDeliveryMethodValue() && deliveryMethod   
  }

  const validateDeliveryMethodValue = ()=>{
    debugger
        if(deliveryMethod == "Email")
        {
            return isValidEmailAddress(deliveryMethodValue)
        }
        else if(deliveryMethod == "Fax #")
        {
            return deliveryMethodValue?.length == 10
        }
        else if(deliveryMethod == "URL")
        {
          var urlRegex = new RegExp(
            '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$'
          );
          return deliveryMethodValue && urlRegex.test(deliveryMethodValue);
        }
  }

  const resetForm = () =>{
    debugger
    setBusinessName("");
    setName("");
    setAddress("");
    setCity("");
    setState("");
    setZipcode("");
    setDeliveryMethod("")

    setIsBusinessNameFocused(false);
    setIsNameFocused(false);
    setIsAddressFocused(false);
    setIsCityFocused(false);
    setIsStateFocused(false);
    setIsZipcodeFocused(false);

    setIsBusinessNameTouched(false);
    setIsNameTouched(false);
    setIsAddressTouched(false);
    setIsCityTouched(false);
    setIsStateTouched(false);
    setIsZipcodeTouched(false);

    setIsRecaptchaVerified(false);
  }

  return (
    <Grid container rowSpacing={4}>  
        <Grid item xs={12}>
            <Typography                                 
                variant='h4'
                fontFamily="Sofia Sans Condensed"
                sx={{
                    color:"#0C709B"
                }}>
                REQUEST FORM
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item md={12} sx={{width:"100%"}}>
                    <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          INSURED REFERENCE
                      </Typography>                    
                </Grid>
                <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isBusinessNameTouched && !businessName && !isBusinessNameFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          onFocus={()=>{setIsBusinessNameFocused(true)}}
                          onBlur={()=>{setIsBusinessNameFocused(false)}}
                          type="text" 
                          value= {businessName}
                          onChange= {businessNameChangeHandler}                        
                          label = "Insured Business Name"/>
                </Grid>
                <Grid item md={12} sx={{width:"100%"}}>
                    <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          CERTIFICATE HOLDER DETAILS
                      </Typography>                    
                </Grid>      
                <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isNameTouched && !name & !isNameFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsNameFocused(true)}}
                          onBlur={()=>{setIsNameFocused(false)}}                         
                          value= {name}
                          onChange= {nameChangeHandler}                        
                          label = "Name"/> 
                </Grid>                             
                <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isAddressTouched && !address & !isAddressFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsAddressFocused(true)}}
                          onBlur={()=>{setIsAddressFocused(false)}}                         
                          value= {address}
                          onChange= {addressChangeHandler}                        
                          label = "Address"/> 
                </Grid>           
                <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isCityTouched && !city & !isCityFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsCityFocused(true)}}
                          onBlur={()=>{setIsCityFocused(false)}}                         
                          value= {city}
                          onChange= {cityChangeHandler}                        
                          label = "City"/> 
                </Grid>          
                <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isStateTouched && !state & !isStateFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsStateFocused(true)}}
                          onBlur={()=>{setIsStateFocused(false)}}                         
                          value= {state}
                          onChange= {stateChangeHandler}                        
                          label = "State"/> 
                </Grid>  
                <Grid item md={4} sx={{width:"100%"}}>
                      <NumberFormat
                          sx={{width: "100%"}}
                          error={isZipcodeTouched && !isValidPostalCode(zipcode) & !isZipcodeFocused}
                          required
                          label="Zipcode"
                          size="small"
                          type="text" 
                          value={zipcode} 
                          format="#####" 
                          mask="_" 
                          customInput={TextField}
                          onFocus={()=>{setIsZipcodeFocused(true)}}
                          onBlur={()=>{setIsZipcodeFocused(false)}}   
                          onValueChange={zipcodeChangeHandler}/>                       
                </Grid>  
                <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          multiline
                          minRows={3}
                          maxRows={3}
                          type="text"                           
                          value= {specialInstructions}
                          onChange= {specialInstructionsChangeHandler}                        
                          label = "Special Requirements"/> 
                </Grid>     
                <Grid item xs={12}>
                      <ReCAPTCHA
                          size="normal"
                          onChange={()=>{setIsRecaptchaVerified(true)}}
                          onExpired={()=>{setIsRecaptchaVerified(false)}}
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/>   
                </Grid>                                      
                <Grid item md={12} sx={{width:"100%"}}>
                    <FormControl size="small" sx={{ width:"100%" }}>                                
                        <InputLabel>Document Delivery Method</InputLabel>
                          <Select
                                sx={{width:"100%"}} 
                                value={deliveryMethod}
                                label="Document Delivery Method"
                                onChange={(event)=>{setDeliveryMethodValue(""); setDeliveryMethod(event.target.value)}}>
                                <MenuItem key="Email" value="Email" name="Email" >Email</MenuItem>
                                <MenuItem key="Fax #" value="Fax #" name="Fax #" >Fax</MenuItem>
                                <MenuItem key="URL" value="URL" name="Upload" >Upload</MenuItem>
                          </Select>
                    </FormControl>                    
                </Grid> 
                {
                    deliveryMethod && 
                    <Grid item md={12} sx={{width:"100%"}}>
                        <TextField
                            error={isDeliveryMethodValueTouched && !deliveryMethod & !isDeliveryMethodValueFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            type={deliveryMethod === "Fax #" ? "number" : "text"}
                            onFocus={()=>{setIsDeliveryMethodValueFocused(true)}}
                            onBlur={()=>{setIsDeliveryMethodValueFocused(false)}}                         
                            value= {deliveryMethodValue}
                            onChange= {deliveryMethodsValueChangeHandler}                        
                            label = {deliveryMethod}/> 
                </Grid>  
                }                  
                <Grid item md={12} sx={{width:"100%"}}>
                </Grid>                                    
                <Grid item xs={12}>
                      <Button
                          disabled={!isValidFormData()}
                          variant="contained"
                          backgroundColor= "#0C709B" 
                          startIcon={<SendIcon />}
                          size="medium"
                          onClick={submitDocumentRequestHandler}
                          sx={{
                            borderRadius: '20px',
                            fontFamily: "Nunito",
                            fontWeight: "bolder",
                          }}             
                          label = "Claim">  
                          Submit Request
                      </Button>   
                </Grid> 
            </Grid>
        </Grid>       
    </Grid>
  )
}




        



