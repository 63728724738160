import React from 'react';
import { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Seo } from '../components/Seo';
import Navbar from '../components/Navbar';
import { useLocation } from 'react-router-dom';
import { useNavigate} from 'react-router-dom';
import SearchResults from '../components/SearchResults';


function Search() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  
    const query = queryParams.get('query');
    query == null && navigate('/')
    
    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    return (
        <Stack>      
        <Seo
            title="Search"/>                                                          
        <section> 
            <Navbar isNavBarTransparent={false}/>
            <CssBaseline/>
        </section>  
        <section>
            <SearchResults query={query}/>
        </section>
        </Stack>
    )
}

export default Search