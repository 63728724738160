import { Typography, Link } from '@mui/material';


const BusinessInsuranceCoverageChangeForm = ()=>{
    return (
        <Typography 
            variant='body1'
            fontFamily="Nunito"
            fontWeight="bold"
            sx={{
                color:"#0C709B"
            }}>
            Please contact our office directly for Coverage Change at
            <Link href="mailto:service@modernshoreins.com?subject=Claim Notice"
                sx={{
                    paddingLeft: "1%",
                    paddingRight: "1%",
                    color:'#0C709B',
                    textDecoration: 'none',
                    '&:hover': {
                        color: '#5c9ab5',
                    }
                }}>
                service@modernshoreins.com
            </Link> 
        </Typography> 
    )
}

export default BusinessInsuranceCoverageChangeForm