/* global document */
import * as React from 'react';
import Map, {Marker, Popup, FullscreenControl, NavigationControl, ScaleControl} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Grid } from '@mui/material';

// added the following 6 lines.
import mapboxgl from 'mapbox-gl';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MAPBOX_TOKEN = 'pk.eyJ1IjoibXV0ZWViYWhtYWQxNDgiLCJhIjoiY2w1bGUweGg0MDN3eTNvcXZ2ZjQ3Zjk2bCJ9.olaEeIjwhTeBDaWa02gL1Q'; // Set your mapbox token here

function MapBox({longitude, latitude }) {
    const [showPopup, setShowPopup] = React.useState(false);

    return (
        <Grid>
            <Grid 
                variant='body1'
                sx={{
                    backgroundColor: 'rgba(35, 55, 75, 0.9)',
                    color: '#fff',
                    padding: '6px 12px',
                    fontFamily: 'monospace',
                    position: 'absolute',
                    margin: '12px',
                    zIndex: '1',
                    borderRadius: '4px',                
                }}>
                Modern Shore Insurance <br/>
            </Grid>
            <Map
                onClick={()=>{setShowPopup(!showPopup)}}
                initialViewState={{
                    latitude: latitude,
                    longitude: longitude,
                    zoom: 16
                }}
                style={{width: "100%", height: "82vh"}}
                mapStyle="mapbox://styles/mapbox/streets-v9"
                mapboxAccessToken={MAPBOX_TOKEN}
                >
                <Marker longitude={longitude} latitude={latitude} color="#0C709B" />
                <FullscreenControl/>
                <NavigationControl />
                <ScaleControl />
                {   
                    showPopup && (
                    <Popup longitude={longitude} latitude={latitude}
                        anchor="top"
                        onClose={() => setShowPopup(false)}>
                        Modern Shore Insurance
                    </Popup>)
                    }
            </Map>
        </Grid>
    );
}


export default MapBox