import React from 'react';
import { useState, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import AboutUsHeader from '../components/AboutUsHeader';
import AboutUsHeaderImage from '../assets/images/AboutUsHeader.jpg';
import KnowledgeAndResourceFilter from '../components/KnowledgeAndResourceFilter';
import BlogsPreview from '../components/BlogsPreview';
import AboutUsNavbar from '../components/AboutUsNavbar';
import { Seo } from '../components/Seo';

function KnowledgeAndResource() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)
  const [blobType, setBlogType] = useState("")

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>      
      <Seo
          title="Knowledge & Resource"
          description="Discover Engaging and Informative Articles - Explore our Blog for the Latest Insights, Tips, and Trends in [Your Industry/Niche]. Expand Your Knowledge Today!"/>                                                          
      <section>          
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section> 
      <section>
        <AboutUsNavbar selected="KnowledgeResources"/>
      </section> 
      <section id="Knowledge & Research">  
        <Stack
            sx={{
              backgroundColor: "#f5f5f5",
            }}>
            <AboutUsHeader  backgroundImage={AboutUsHeaderImage}/>
            <Box 
              component="div" 
              sx={{
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  minHeight: '100vh',
                  overflowX:'hidden',
                  padding:"7% 10% 5% 10%"
              }}>
              <KnowledgeAndResourceFilter
                  blogType={blobType}
                  setBlogType={setBlogType}/>
              <BlogsPreview insuranceType={blobType}/>
            </Box>
        </Stack>
      </section>
    </Stack>
  )
}

export default KnowledgeAndResource