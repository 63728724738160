import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Stack, Typography, Box, Grid, Snackbar, Alert, InputLabel, MenuItem, FormControl, Select, Backdrop, FormHelperText } from '@mui/material';
import PersonalInsurancePhysicalAddressChangeForm from "./PersonalInsurancePhysicalAddressChangeForm";
import PersonalInsuranceCoverageChangeForm from "./PersonalInsuranceCoverageChangeForm";
import PersonalInsuranceAddMortgageeOrLossPayeeForm from "./PersonalInsuranceAddMortgageeOrLossPayeeForm";
import PersonalInsuranceRemoveMortgageeOrLossPayeeForm from "./PersonalInsuranceRemoveMortgageeOrLossPayeeForm";
import PersonalInsuranceChangeMortgageeOrLossPayeeForm from "./PersonalInsuranceChangeMortgageeOrLossPayeeForm";
import BusinessInsuranceAddAdditionalInterestForm from "./BusinessInsuranceAddAdditionalInterestForm";
import BusinessInsuranceRemoveAdditionalInterestForm from "./BusinessInsuranceRemoveAdditionalInterestForm";
import BusinessInsuranceReplaceAdditionalInterestForm from "./BusinessInsuranceReplaceAdditionalInterestForm";
import InsuranceVehicleAddForm from "./InsuranceVehicleAddForm";
import InsuranceVehicleRemoveForm from "./InsuranceVehicleRemoveForm";
import InsuranceVehicleChangeForm from "./InsuranceVehicleChangeForm";
import InsuranceDriverAddForm from "./InsuranceDriverAddForm";
import InsuranceDriverRemoveForm from "./InsuranceDriverRemoveForm";
import InsuranceDriverChangeForm from "./InsuranceDriverChangeForm";
import PersonalInsuranceMailingAddressChangeForm from "./PersonalInsuranceMailingAddressChangeForm";
import BusinessInsuranceAddressChangeForm from "./BusinessInsuranceAddressChangeForm";
import BusinessInsuranceAddressAddForm from "./BusinessInsuranceAddressAddForm";
import BusinessInsuranceAddressRemoveForm from "./BusinessInsuranceAddressRemoveForm";
import ClientAccessTabs from './ClientAccessTabs';
import BusinessInsuranceCoverageChangeForm from './BusinessInsuranceCoverageChangeForm';
import { getInsuranceDocumentTypes } from "../apiClient/insurancePolicies";
import { requestDocumentChange } from "../apiClient/insurancePolicies";
import loader from '../assets/images/Loader.gif'

const ReportClaimTabs = [
  {
    title: "Personal", index: 0
  },
  {
    title: "Business", index: 1
  }
]
function PolicyChangeRequestSection() {
  const [ selectedPolicyChange, setSelectedPolicyChange] = useState('');
  const [ selectedSubType1, setSelectedSubType1] = useState('');
  const [ selectedSubType2, setSelectedSubType2] = useState('');
  const [ personalPoliciesChangeTypes, setPersonalPoliciesChangeTypes] = useState([]);
  const [ businessPoliciesChangeTypes, setBusinessPoliciesChangeTypes] = useState([]);
  const [ selectedTabValue, setSelectedTabValue] = React.useState(0);
  const [ isBackdropVisible, setIsBackdropVisible] = React.useState(false);
  const [ isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [ alertMessage, setAlertMessage] = React.useState("");
  const [ alertMessageSeverity, setAlertMessageSeverity] = React.useState("info");

  const requestPolicyChangeHandler = async (requestData)=>{
    try{
      setBackdropVisibilityValue(true)

      requestData['accountType'] = selectedTabValue
      requestData['category'] = 'CR'
      requestData['changeRequestType'] = selectedPolicyChange

      const policyChangeTypes = selectedTabValue === 0 ? personalPoliciesChangeTypes : businessPoliciesChangeTypes
      const selectedPolicy = policyChangeTypes?.find(x=> x.name === selectedPolicyChange)

      const selectedType1 = selectedPolicy?.dependantField?.fieldOptions.find(x => x.name === selectedSubType1)
      const subType1FieldName =  selectedPolicy?.dependantField?.fieldName

      if(subType1FieldName)
      {
        requestData[subType1FieldName] = selectedSubType1
        const subType2FieldName =  selectedType1?.dependantField?.fieldName

        if(subType2FieldName)
        {
          requestData[subType2FieldName] = selectedSubType2
        }
      
      }
      await requestDocumentChange(requestData);
      resetForm()      
      setBackdropVisibilityValue(false)
      setAlertMessageAndVisibility( "Policy Change Request Submitted.","success", true)}
    catch(error){
      setAlertMessageAndVisibility("Error Requesting Policy Change.", "error", true)
      setBackdropVisibilityValue(false)
    }
  }

  const resetForm = ()=>{
    let existingValue = selectedPolicyChange
    setSelectedPolicyChange('')
    setTimeout(()=>{
      setSelectedPolicyChange(existingValue)
    },0)
  }


  const setAlertMessageAndVisibility = (message, severity, isVisible)=>{
    setAlertMessage(message)
    setAlertMessageSeverity(severity)
    setIsAlertVisible(isVisible)
    setTimeout(()=>{
      setIsAlertVisible(false)
    },5000)
  }

  const setBackdropVisibilityValue = (value) => {
    setIsBackdropVisible(value);
  };

  const getInsurancePolicyChangeTypes = async ()=>{
    try{
      setBackdropVisibilityValue(true)
      await getPersonalInsurancePolicyChangeTypes()
      await getBusinessInsurancePolicyChangeTypes()
      setBackdropVisibilityValue(false)  
    }
    catch(error)
    {
      setBackdropVisibilityValue(false)
    }
  }

  const getPersonalInsurancePolicyChangeTypes = async ()=>{
    const response = await getInsuranceDocumentTypes(0, 'CR');
    setPersonalPoliciesChangeTypes(response)
  }

  const getBusinessInsurancePolicyChangeTypes = async ()=>{
    const response = await getInsuranceDocumentTypes(1, 'CR');
    setBusinessPoliciesChangeTypes(response)
  }

  const selectedPolicyChangeHandler = (event)=>{
    setSelectedSubType1('')
    setSelectedSubType2('')
    setSelectedPolicyChange(event.target.value)
  }

  const selectedSubTypeChangeHandler = (event)=>{
    setSelectedSubType2('')
    setSelectedSubType1(event.target.value)
  }

  const onTabValueChanges = (event, newValue) => {
    setSelectedSubType1('')
    setSelectedSubType2('')
    setSelectedPolicyChange('')
    setSelectedTabValue(newValue);
  };

  useEffect(()=>{
    getInsurancePolicyChangeTypes()
  },[])


  return (
    <Stack
        spacing={2}>     
        <Typography 
          variant='h3'
          fontFamily="Sofia Sans Condensed"
          sx={{
            color:"white",
          }}>
          REQUEST INSURANCE POLICY CHANGES
        </Typography>
        <Typography 
          variant='body1'
          fontFamily="Nunito"
          sx={{
            color:"white",
          }}>
          At Modern Shore Insurance, requesting a change to your policy is a breeze! By following the prompts below, 
          you are only a few clicks away from submitting your request directly to a Modern Shore Insurance advisor        
        </Typography>
        <br/>
        <br/>
        <Box sx={{ width: "100%" }}>
          <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isBackdropVisible}
              onClick={()=>{setBackdropVisibilityValue(false)}}>
              <Box
                component="img"
                sx={{
                  height: '80px'
                }}   
                alt="loading..."                             
                src={loader}/> 
          </Backdrop>         
          <Snackbar 
            sx={{
              marginTop: "20vh"
            }}
            anchorOrigin={{ vertical:"top", horizontal: "center" }} 
            open={isAlertVisible} 
            onClose={()=>{isAlertVisible(false)}}>
            <Alert onClose={()=>{isAlertVisible(false)}} severity={alertMessageSeverity} sx={{ width: '100%' }}>
              {alertMessage}
            </Alert>
          </Snackbar>          
          <Box sx={{ backgroundColor :  "#D0D9DD",  borderRadius: "10px" }}>
              <ClientAccessTabs 
                tabs={ReportClaimTabs} 
                selectedTabValue={selectedTabValue}
                onTabChange = {onTabValueChanges}/>

                <Box 
                  component="div" 
                  sx={{
                    backgroundColor: "#f5f5f5",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    overflowX:'hidden',
                    padding: {
                      xs: "13% 10% 5% 10%",
                      sm: "8% 10% 5% 10%",
                      md: "5% 5% 5% 5%"
                    }
                  }}>
                  <Grid container columnSpacing={2} rowSpacing={4}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container rowSpacing={4}>
                          <Grid item xs={12}>
                              <Typography                                 
                                  variant='h4'
                                  fontFamily="Sofia Sans Condensed"
                                  sx={{
                                    color:"#0C709B"
                                  }}>
                                  {
                                    ReportClaimTabs[selectedTabValue].title.toUpperCase()
                                  } INSURANCE
                              </Typography>                       
                          </Grid>
                          <Grid item xs={12}>
                          {
                            selectedTabValue === 0 &&
                            <>
                              <FormControl size="small" 
                                sx={{
                                  width:{
                                    md:"60%",
                                    sm:"80%",
                                    xs:"100%"
                                  },
                                  marginBottom: "5%"
                                }}>
                                <InputLabel>Policy Type</InputLabel>
                                <Select
                                  value={selectedPolicyChange}
                                  label="Policy Type"
                                  onChange={selectedPolicyChangeHandler}>
                                  {
                                      personalPoliciesChangeTypes.map((documentType, index)=>{
                                          return (
                                            <MenuItem key={index} value={documentType.name}>{documentType.label}</MenuItem>
                                          )
                                      })
                                  }
                                </Select>
                                <FormHelperText>Select Policy Type</FormHelperText>
                              </FormControl>
                              {
                                personalPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField !== undefined &&
                                <>
                                <FormControl size="small" 
                                  sx={{
                                    width:{
                                      md:"60%",
                                      sm:"80%",
                                      xs:"100%"
                                    },
                                    marginBottom: "5%"
                                  }}>                                  
                                    <InputLabel>{personalPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField.fieldLabel}</InputLabel>
                                    <Select
                                      value={selectedSubType1}
                                      label={ personalPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField.fieldLabel}
                                      onChange={selectedSubTypeChangeHandler}>
                                      {
                                        personalPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField.fieldOptions.map((documentType)=>{
                                            return <MenuItem value={documentType.name}>{documentType.label}</MenuItem>                                            
                                        })
                                      }
                                    </Select>
                                  </FormControl>
                                  {
                                      personalPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField.fieldOptions?.find(x=> x.name === selectedSubType1)?.dependantField !== undefined &&
                                      <FormControl size="small" sx={{width:"60%", marginBottom: "5%"}}>
                                        <InputLabel>
                                          {personalPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField.fieldOptions?.find(x=> x.name === selectedSubType1)?.dependantField.label}
                                        </InputLabel>
                                        <Select
                                          value={selectedSubType2}
                                          label={personalPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField.fieldOptions?.find(x=> x.name === selectedSubType1)?.dependantField.label}
                                          onChange={ (event)=>{setSelectedSubType2(event.target.value)}}>
                                          {
                                            personalPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField.fieldOptions?.find(x=> x.name === selectedSubType1)?.dependantField.fieldOptions.map((documentType)=>{
                                                return <MenuItem value={documentType.name}>{documentType.label}</MenuItem>                                            
                                            })
                                          }
                                        </Select>                                          
                                      </FormControl>
                                  }                                
                                </>
                              }  
                            </>  
                          }
                          {
                            selectedTabValue === 1 &&
                            <>
                              <FormControl size="small" 
                                sx={{
                                  width:{
                                    md:"60%",
                                    sm:"80%",
                                    xs:"100%"
                                  },
                                  marginBottom: "5%"
                                }}>
                                <InputLabel>Policy Type</InputLabel>
                                <Select
                                  value={selectedPolicyChange}
                                  label="Policy Type"
                                  onChange={selectedPolicyChangeHandler}>
                                  {
                                      businessPoliciesChangeTypes.map((documentType)=>{
                                          return (
                                            <MenuItem value={documentType.name}>{documentType.label}</MenuItem>
                                          )
                                      })
                                    }
                                </Select>
                                <FormHelperText>Select Policy Type</FormHelperText>
                              </FormControl>
                              {
                                businessPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField !== undefined &&
                                <>
                                <FormControl size="small" 
                                  sx={{
                                    width:{
                                      md:"60%",
                                      sm:"80%",
                                      xs:"100%"
                                    },
                                    marginBottom: "5%"
                                  }}>                                  
                                    <InputLabel>{businessPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField.fieldLabel}</InputLabel>
                                    <Select
                                      value={selectedSubType1}
                                      label={ businessPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField.fieldLabel}
                                      onChange={selectedSubTypeChangeHandler}>
                                      {
                                        businessPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField.fieldOptions.map((documentType)=>{
                                            return <MenuItem value={documentType.name}>{documentType.label}</MenuItem>                                            
                                        })
                                      }
                                    </Select>
                                  </FormControl>
                                  {
                                      businessPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField.fieldOptions?.find(x=> x.name === selectedSubType1)?.dependantField !== undefined &&
                                      <FormControl size="small" sx={{width:"60%", marginBottom: "5%"}}>
                                        <InputLabel>
                                          {businessPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField.fieldOptions?.find(x=> x.name === selectedSubType1)?.dependantField.fieldLabel}
                                        </InputLabel>
                                        <Select
                                          value={selectedSubType2}
                                          label={businessPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField.fieldOptions?.find(x=> x.name === selectedSubType1)?.dependantField.fieldLabel}
                                          onChange={ (event)=>{setSelectedSubType2(event.target.value)}}>
                                          {
                                            businessPoliciesChangeTypes?.find(x=> x.name === selectedPolicyChange)?.dependantField.fieldOptions?.find(x=> x.name === selectedSubType1)?.dependantField.fieldOptions.map((documentType)=>{
                                                return <MenuItem value={documentType.name}>{documentType.label}</MenuItem>                                            
                                            })
                                          }
                                        </Select>                                          
                                      </FormControl>
                                  }                                
                                </>
                              }  
                            </>                                                                                
                          }  
                          </Grid>  
                        </Grid>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        {
                            selectedTabValue === 0 && selectedPolicyChange === 'CHG_COV' && 
                            <PersonalInsuranceCoverageChangeForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>   
                        }
                        {
                            selectedTabValue === 1 && selectedPolicyChange === 'CHG_COV' && 
                            <BusinessInsuranceCoverageChangeForm/>
                        }
                        {
                            selectedTabValue === 0 && selectedPolicyChange === 'CHG_ADDRESS' && selectedSubType1 === 'CHG_MAIL' &&
                            <PersonalInsuranceMailingAddressChangeForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/> 
                        }
                        {
                            selectedTabValue === 0 && selectedPolicyChange === 'CHG_ADDRESS' && selectedSubType1 === 'CHG_PHY' &&
                            <PersonalInsurancePhysicalAddressChangeForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>                        
                        } 

                        {
                            selectedPolicyChange === 'CHG_VEH' && selectedSubType1 === 'ADD_VEH' &&
                            <InsuranceVehicleAddForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>     
                        } 
                        {
                            selectedPolicyChange === 'CHG_VEH' && selectedSubType1 === 'REMOVE_VEH' &&
                            <InsuranceVehicleRemoveForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>    
                        } 
                        {
                            selectedPolicyChange === 'CHG_VEH' && selectedSubType1 === 'REP_VEH' &&
                            <InsuranceVehicleChangeForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>      
                        } 

                        {
                            selectedPolicyChange === 'CHG_DRI' && selectedSubType1 === 'ADD_DRI' &&
                            <InsuranceDriverAddForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>    
                        } 
                        {
                            selectedPolicyChange === 'CHG_DRI' && selectedSubType1 === 'REMOVE_DRI' &&
                            <InsuranceDriverRemoveForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>      
                        } 
                        {
                            selectedPolicyChange === 'CHG_DRI' && selectedSubType1 === 'REP_DRI' &&
                            <InsuranceDriverChangeForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>    
                        }            

                        {
                            selectedTabValue === 1 && selectedPolicyChange === 'CHG_LOC_ADDRESS' &&  selectedSubType1 === 'CHG_MAIL' &&
                            <BusinessInsuranceAddressChangeForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>    
                        }  
                        {
                            selectedTabValue === 1 && selectedPolicyChange === 'CHG_LOC_ADDRESS' &&  selectedSubType1 === 'CHG_LOC' &&  selectedSubType2 === 'ADD_LOC' &&
                            <BusinessInsuranceAddressAddForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>  
                        }   
                        {
                            selectedTabValue === 1 && selectedPolicyChange === 'CHG_LOC_ADDRESS' &&  selectedSubType1 === 'CHG_LOC' &&  selectedSubType2 === 'REMOVE_LOC' &&
                            <BusinessInsuranceAddressRemoveForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>  
                        }   
                        {
                            selectedTabValue === 1 && selectedPolicyChange === 'CHG_LOC_ADDRESS' &&  selectedSubType1 === 'CHG_LOC' && selectedSubType2 === 'REP_LOC' &&
                            <BusinessInsuranceAddressChangeForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>  
                        }  
                        {
                            selectedTabValue === 0 && selectedPolicyChange === 'CHG_MOR_LP' &&  selectedSubType1 === 'ADD_MOR_LP' &&
                            <PersonalInsuranceAddMortgageeOrLossPayeeForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>   
                        }   
                        {
                            selectedTabValue === 0 && selectedPolicyChange === 'CHG_MOR_LP' &&  selectedSubType1 === 'REMOVE_MOR_LP' && 
                            <PersonalInsuranceRemoveMortgageeOrLossPayeeForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/> 
                        }   
                        {
                            selectedTabValue === 0 && selectedPolicyChange === 'CHG_MOR_LP' &&  selectedSubType1 === 'REPLACE_MOR_LP' && 
                            <PersonalInsuranceChangeMortgageeOrLossPayeeForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/> 
                        }              
                        {
                            selectedTabValue === 1 && selectedPolicyChange === 'CHG_ADD_INT' &&  selectedSubType1 === 'ADD_ADD_INT' && 
                            <BusinessInsuranceAddAdditionalInterestForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>
                        } 
                        {
                            selectedTabValue === 1 && selectedPolicyChange === 'CHG_ADD_INT' &&  selectedSubType1 === 'REMOVE_ADD_INT' && 
                            <BusinessInsuranceRemoveAdditionalInterestForm
                                insuranceType={selectedTabValue}
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>                             
                        } 
                        {
                            selectedTabValue === 1 && selectedPolicyChange === 'CHG_ADD_INT' &&  selectedSubType1 === 'REPLACE_ADD_INT' && 
                            <BusinessInsuranceReplaceAdditionalInterestForm
                                insuranceType={selectedTabValue} 
                                requestPolicyChangeHandler={requestPolicyChangeHandler}/>                           
                        }                                                                                                                                                                                                                                            
                      </Grid>              
                  </Grid>     
                </Box>                               
          </Box>
        </Box>        
    </Stack>
  )
}

export default PolicyChangeRequestSection;