import { Typography, Button } from "@mui/material"
import { useNavigate } from 'react-router';


function DefaultInsuranceServiceSubpage({quoteNowRedirect,subtitles, subtitleVariant = "body1"}) {
  const navigate = useNavigate();

    return (
        <>
            <Typography 
              variant={subtitleVariant}
              fontFamily="Nunito"
              sx={{
                fontWeight: "bold",
                display:{
                  lg: 'block',
                  md: 'block',
                  sm: 'none',
                  xs: 'none',
                },                
                width: {
                  lg: '45vw',
                  md: '45vw',
                  sm: '80vw',
                  xs: '80vw',
                },
                color:"white",
                textUnderlineOffset: '20%',
                textDecorationThickness: '1px',
              }}>
                {
                    subtitles.map(subtitle =>{
                        return (
                            <>
                                {subtitle}
                                <br/>
                                <br/>
                            </>
                        )
                    })
                }
            </Typography> 
            <Typography 
              variant="body2"
              fontFamily="Nunito"
              sx={{
                display:{
                  lg: 'none',
                  md: 'none',
                  sm: 'block',
                  xs: 'block',
                },
                width: {
                  lg: '45vw',
                  md: '45vw',
                  sm: '80vw',
                  xs: '80vw',
                },
                color:"white",
                textUnderlineOffset: '20%',
                textDecorationThickness: '1px',
              }}>
                {
                    subtitles.map(subtitle =>{
                        return (
                            <>
                                {subtitle}
                                <br/>
                                <br/>
                            </>
                        )
                    })
                }
            </Typography>    
            <Button 
                    size='medium'
                    variant="outlined" 
                    onClick={()=>{navigate("/" + quoteNowRedirect)}}
                    sx={{
                      fontFamily:"Nunito",
                      fontWeight:"bolder",
                        display:{
                            lg: 'block',
                            md: 'block',
                            sm: 'none',
                            xs: 'none',
                        },
                        backgroundColor:'white', 
                        borderColor: "transparent",
                        color: "#0C709B", 
                        marginTop: '1vh',
                        padding: '15px 25px',
                        '&:hover': {
                            backgroundColor: 'white',
                            borderColor: "transparent",
                        }
                    }}>
                    Quote Now
            </Button>  
            <Button 
                    size='small'
                    variant="outlined" 
                    onClick={()=>{navigate("/" + quoteNowRedirect)}}
                    sx={{
                      fontFamily:"Nunito",
                      fontWeight:"bolder",
                        display:{
                            lg: 'none',
                            md: 'none',
                            sm: 'block',
                            xs: 'block',
                        },
                        borderColor: "transparent",
                        backgroundColor:'white', 
                        color: "#0C709B", 
                        marginTop: '1vh',
                        width: '70%',
                        padding: '7px 10px',
                        '&:hover': {
                            backgroundColor: 'white',
                            borderColor: "transparent",
                        }
                    }}>
                    Quote Now
            </Button>               
        </>
    )
}

export default DefaultInsuranceServiceSubpage