import React from 'react'
import { Box, Stack, Typography, Link } from '@mui/material';
import SocialMediaIcon from './SocialMediaIcon'
import facebook from '../assets/images/facebook.png'
import instagram from '../assets/images/Instagram_white.png'
import linkedin from '../assets/images/LinkedIn.png'
import twitter from '../assets/images/Twitter-logo.png'

export default function GetInTouch() {
  return (
    <Box 
        component="div" 
        sx={{
        backgroundColor: "#2c9ac9",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '110vh',
        overflowX:'hidden',
        padding: {
            xs: "35% 10% 10% 10%",
            sm: "30% 10% 2% 10%",
            md: "26% 10% 2% 10%"
          }
    }}>
        <Stack>
            <Typography 
                variant="h3" 
                fontFamily="Sofia Sans Condensed"
                component="div"
                sx={{
                    color:"white"               
                }}>
                KEEP IN TOUCH
            </Typography>
            <Typography 
                variant="h5" 
                fontFamily="Nunito"  
                component="div"
                sx={{
                    fontWeight:"bold",
                    marginTop: "4%",
                    color:"white",                    
                }}>
                Modern Shore Insurance
            </Typography>
            <Typography 
                variant="body2" 
                fontFamily="Nunito" 
                component="div"
                sx={{
                    fontWeight:"bold",
                    marginTop: "4%",
                    color:"white",                    
                }}>
                360 Central Ave, STE 470 <br/>
                St. Petersburg, FL 33701     
            </Typography>
            <Typography 
                variant="body2" 
                fontFamily="Nunito" 
                component="div"
                sx={{
                    fontWeight:"bold",
                    marginTop: "4%",
                    color:"white",                    
                }}>
                <Link href="tel:7273710014" 
                    sx={{
                        color:'white',
                        '&:hover': { 
                            color: '#D0D9DD',
                            textDecoration: 'none'
                        }
                    }}>
                    (727) 371-0014
                </Link><br/>
                <Link href="mailto:info@modernshoreins.com"
                    sx={{
                        color:'white',
                        '&:hover': { 
                            color: '#D0D9DD',
                            textDecoration: 'none'
                        }
                    }}>
                    info@modernshoreins.com
                </Link>                             
            </Typography>
            <Typography 
                variant="h5" 
                fontFamily="Nunito" 
                component="div"
                sx={{
                    fontWeight:"bold",
                    marginTop: "4%",
                    color:"white",                    
                }}>
                Office Visiting Hours
            </Typography>
            <Typography 
                variant="body2" 
                fontFamily="Nunito" 
                component="div"
                sx={{
                    fontWeight:"bold",
                    marginTop: "4%",
                    color:"white",                    
                }}>
                Monday - Friday: 9am - 5pm
            </Typography>
            <br/>
            <Stack direction="row" spacing={2}>
                <SocialMediaIcon
                    url="https://www.facebook.com/modernshore/"
                    alt="Facebook"
                    src={facebook}/>
                <SocialMediaIcon
                    url="https://twitter.com/shoremodern?s=21&t=cgrsoHYTgM2amIrCZAc2NA"
                    alt="Twitter"
                    src={twitter}/>
                <SocialMediaIcon
                    url="https://www.instagram.com/modern_shore_ins/"
                    alt="Instagram"
                    src={instagram}/>
                <SocialMediaIcon
                    url="https://www.linkedin.com/company/modern-shore-insurance/"
                    alt="Linkedin"
                    src={linkedin}/>             
            </Stack>
        </Stack>    
    </Box>                     
  )
}




        

