import React from 'react';
import { useState, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import BusinessInsuranceHeader from '../components/BusinessInsuranceHeader';
import BusinessInsuranceBody from '../components/BusinessInsuranceBody';
import { Seo } from '../components/Seo';

function BusinessInsurance() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>      
      <Seo
          title="Business Insurance"
          description="Companies come in all shapes and sizes. Secure your business with an insurance portfolio designed to fit the needs of your unique operation"/>                                                          
      <section>        
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section> 
      <section>
        <Stack>
            <BusinessInsuranceHeader/>
            <BusinessInsuranceBody/>
        </Stack>
      </section>
    </Stack>
  )
}

export default BusinessInsurance