import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import { Link, Grid } from '@mui/material';
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const insurancePoliciesTableHeaders = [
    "Policy Number","Carrier","Effective Date","Expiration Date"
]
const carrierInformationTableHeaders = [
    "Phone","Email","Website"
]
const associatedLocationsTableHeader = [
    "Address"
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0C709B",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

function Row(props) {
  const { row } = props;
  const [ open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
            <Typography variant="body2" gutterBottom component="div">
                {row.policyNumber}
            </Typography>                             
        </TableCell>   
        <TableCell component="th" scope="row">
            <Typography variant="body2" gutterBottom component="div">
                {row.carrier.name}
            </Typography>                             
        </TableCell>   
        <TableCell component="th" scope="row">
            <Typography variant="body2" gutterBottom component="div">
                {row.effectiveDate}
            </Typography>                             
        </TableCell>
        <TableCell component="th" scope="row">
            <Typography variant="body2" gutterBottom component="div">
                {row.expirationDate}
            </Typography>                             
        </TableCell>        
      </TableRow> 
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {row.carrier.name}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                    <TableRow>
                    {
                        carrierInformationTableHeaders.map(header =>{
                            return <TableCell>{header}</TableCell>
                        })
                    }                      
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={row.carrier.phone}>
                        <TableCell component="th" scope="row">
                            {
                                 row.carrier.phone !== null && 
                                <Typography variant="caption" gutterBottom component="div">
                                    <Link href={"tel:" + row.carrier.phone} 
                                        sx={{
                                            color:'black',
                                            textDecoration: 'none',
                                        }}>
                                        <Grid container rowSpacing={1}>
                                            <Grid item xs={3} sm={4} md={3} alignContent="right">
                                                <LocalPhoneIcon fontSize="small"/>     
                                            </Grid>
                                            <Grid item xs={9} sm={8} md={9} alignContent="left">
                                                {row.carrier.phone}   
                                            </Grid>
                                        </Grid>                
                                    </Link>                                      
                                </Typography> 
                            }
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {
                                row.carrier.email !== null && 
                                <Typography variant="caption" gutterBottom component="div">
                                    <Link href={"mailto:" + row.carrier.email} 
                                        sx={{
                                            color:'black',
                                            textDecoration: 'none',
                                        }}>
                                        <Grid container rowSpacing={1}>
                                            <Grid item xs={2} sm={4} md={2} alignContent="right">
                                                <EmailIcon fontSize="small"/>    
                                            </Grid>
                                            <Grid item xs={10} sm={8} md={10} alignContent="left">
                                                {row.carrier.email}   
                                            </Grid>
                                        </Grid>                                    
                                    </Link>                                      
                                </Typography> 
                            }
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {
                                row.carrier.website !== null && 
                                <Typography variant="caption" gutterBottom component="div">  
                                    <Link href={ row.carrier.website} 
                                        target="_blank"
                                        sx={{
                                            color:'black',
                                            textDecoration: 'none',
                                        }}>
                                                                            
                                        {row.carrier.website !== "" && <LanguageIcon  fontSize="small"/> }
                                    </Link>                              
                                </Typography> 
                            }
                        </TableCell>                                                                                 
                    </TableRow>
                </TableBody>
              </Table>
              <br/>
              <Typography variant="h6" gutterBottom component="div">
                Associated Locations
              </Typography>
              {
                row.associatedLocations.length > 0 ?
                <Table size="small" aria-label="purchases">
                    <TableHead>
                        <TableRow>
                        {
                            associatedLocationsTableHeader.map(header =>{
                                return <TableCell>{header}</TableCell>
                            })
                        }                      
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            row.associatedLocations.map((location)=>{
                                return (
                                    location !== null && 
                                    <TableRow key={location}>
                                        <TableCell component="th" scope="row">
                                            <Typography variant="caption" component="div">
                                                {location}                                      
                                            </Typography> 
                                        </TableCell>                                                                                 
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table> : 
                <Typography variant="body2"  component="div" sx={{marginLeft: "2%"}}>
                    No Location Found.                                     
                </Typography> 
              }
              <br/>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export default function InsurancePoliciesTable({insurancePolicies}) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper} sx={{maxHeight: "51vh"}}>
                <Table stickyHeader size="small" >
                    <TableHead>
                    <TableRow>
                        <StyledTableCell />
                        {
                            insurancePoliciesTableHeaders.map(header =>{
                                return <StyledTableCell>{header}</StyledTableCell>
                            })
                        }
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {insurancePolicies
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => (
                            <Row key={row.policyNumber} row={row} />
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container>
                <Grid item xs={12}>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={insurancePolicies.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}/>
                </Grid>
            </Grid>
        </Paper>
    );
}
