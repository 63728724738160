import * as React from 'react';
import { Button , Card, CardActionArea, CardContent, Typography, CardMedia, CardActions, Box} from '@mui/material';
import BlogLabels from './BlogLabels.jsx'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BlogDefaultImage from '../assets/images/BlogDefault.png'
import { useState } from 'react';

export default function BlogPreview({title, imageSrc, externalLink, labels, publishedDate}) {    
    const [imgSrc, setImgSrc] = useState(imageSrc);
    const handleError = () => {
        setImgSrc(BlogDefaultImage);
      };

    return (
        <Card 
            sx={{
                borderBottom:'4px solid #0C709B', 
                paddingBottom: '10px',
                borderRadius:'10px 10px 0px 0px'  
            }}>
            <CardMedia
                component="img"
                onError={handleError}
                height="140"
                image={imgSrc}
                alt={title}/>
            <CardContent>
                <Box 
                    sx={{
                        color:'#0C709B',
                        width: '100%',
                        display: '-webkit-box',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        '-webkit-box-orient': 'vertical',
                        '-webkit-line-clamp': 2,
                        marginBottom: "3vh",
                    }}>
                    <Typography 
                        sx={{
                            fontWeight:"bolder" ,
                            lineHeight: '1.2em',
                            maxHeight: '2.2em',
                        }}
                        gutterBottom 
                        fontFamily="Nunito"  
                        variant="h6" component="div">
                        {title}
                    </Typography>
                </Box>
                <BlogLabels labels={labels}/>
            </CardContent>
            <CardActions>
                <Button 
                    onClick={()=>{window.open(externalLink, '_blank');}}
                    size="small" 
                    sx={{
                        color:'#0C709B', 
                        marginLeft: 1,
                        fontFamily:"Nunito" ,
                        fontWeight:"bolder" 
                    }} 
                    endIcon={<ArrowForwardIcon/>} >
                    Read more
                </Button>
            </CardActions>
        </Card>    
    );
}
