import React from 'react'
import { Grid, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import { isValidEmailAddress, isValidPostalCode } from '../utils/utilityFunctions';
import NumberFormat from 'react-number-format';
import { useEffect } from 'react';

export default function LenderRequestDocumentForm({requestDocumentChangeHandler, documentType}) {
  useEffect(()=>{
    resetForm();
  },[documentType])

  const [borrowerName, setBorrowerName] = useState("");
  const [loanNumber, setLoanNumber] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [locationAddress, setLocationAddress] = useState("");
  const [locationCity, setLocationCity] = useState("");
  const [locationState, setLocationState] = useState("");
  const [locationZipcode, setLocationZipcode] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("")
  const [deliveryMethodValue, setDeliveryMethodValue] = useState("")

  const [isBorrowerNameFocused, setIsBorrowerNameFocused] = useState(false)
  const [isLoanNumberFocused, setIsLoanNumberFocused] = useState(false)
  const [isNameFocused, setIsNameFocused] = useState(false)
  const [isAddressFocused, setIsAddressFocused] = useState(false)
  const [isCityFocused, setIsCityFocused] = useState(false)
  const [isStateFocused, setIsStateFocused] = useState(false)
  const [isZipcodeFocused, setIsZipcodeFocused] = useState(false)
  const [isLocationAddressFocused, setIsLocationAddressFocused] = useState(false)
  const [isLocationCityFocused, setIsLocationCityFocused] = useState(false)
  const [isLocationStateFocused, setIsLocationStateFocused] = useState(false)
  const [isLocationZipcodeFocused, setIsLocationZipcodeFocused] = useState(false)
  const [isDeliveryMethodValueFocused, setIsDeliveryMethodValueFocused] = useState(false)

  const [isBorrowerNameTouched, setIsBorrowerNameTouched] = useState(false)
  const [isLoanNumberTouched, setIsLoanNumberTouched] = useState(false)
  const [isNameTouched, setIsNameTouched] = useState(false)
  const [isAddressTouched, setIsAddressTouched] = useState(false)
  const [isCityTouched, setIsCityTouched] = useState(false)
  const [isStateTouched, setIsStateTouched] = useState(false)
  const [isZipcodeTouched, setIsZipcodeTouched] = useState(false)
  const [isLocationAddressTouched, setIsLocationAddressTouched] = useState(false)
  const [isLocationCityTouched, setIsLocationCityTouched] = useState(false)
  const [isLocationStateTouched, setIsLocationStateTouched] = useState(false)
  const [isLocationZipcodeTouched, setIsLocationZipcodeTouched] = useState(false)
  const [isDeliveryMethodValueTouched, setIsDeliveryMethodValueTouched] = useState(false)

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)

  const borrowerNameChangeHandler = (event) =>{
    !isBorrowerNameTouched && setIsBorrowerNameTouched(true)
    setBorrowerName(event.target.value)
  }

  const loanNumberChangeHandler = (event) =>{
    !isLoanNumberTouched && setIsLoanNumberTouched(true)
    setLoanNumber(event.target.value.trim())
  }

  const nameChangeHandler = (event) =>{
    !isNameTouched && setIsNameTouched(true)
    setName(event.target.value)
  }
  
  const addressChangeHandler = (event) =>{
    !isAddressTouched && setIsAddressTouched(true)
    setAddress(event.target.value)
  }

  const cityChangeHandler = (event) =>{
    !isCityTouched && setIsCityTouched(true)
    setCity(event.target.value)
  }

  const stateChangeHandler = (event) =>{
    !isStateTouched && setIsStateTouched(true)
    setState(event.target.value)
  }

  const zipcodeChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    if(value != zipcode)
    {
      !isZipcodeTouched && setIsZipcodeTouched(true)
      setZipcode(value)
    }
  }
  
  const locationAddressChangeHandler = (event) =>{
    !isLocationAddressTouched && setIsLocationAddressTouched(true)
    setLocationAddress(event.target.value)
  }

  const locationCityChangeHandler = (event) =>{
    !isLocationCityTouched && setIsLocationCityTouched(true)
    setLocationCity(event.target.value)
  }

  const locationStateChangeHandler = (event) =>{
    !isLocationStateTouched && setIsLocationStateTouched(true)
    setLocationState(event.target.value)
  }

  const locationZipcodeChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    if(value != locationZipcode)
    {
      !isLocationZipcodeTouched && setIsLocationZipcodeTouched(true)
      setLocationZipcode(value)
    }
  }
  const deliveryMethodsValueChangeHandler = (event) =>{
    !isDeliveryMethodValueTouched && setIsDeliveryMethodValueTouched(true)
    setDeliveryMethodValue(event.target.value)
  }

  const submitDocumentRequestHandler = ()=>{
    let requestData = {
      accountType: "3",
      category: 'DGR2', 
      documentType: documentType,   
      accountName: borrowerName,
      details : {
        loanNumber: loanNumber,
        mortgageeOrLienHolderClauseName : name,
        mortgageeOrLienHolderClauseAddress : {
          address1 : address,
          city : city,
          state : state,
          postalCode : zipcode
        },
        locationAddress : {
          address1 : locationAddress,
          city : locationCity,
          state : locationState,
          postalCode : locationZipcode
        },
        documentDeliveryMethod: deliveryMethod,
        deliveryMethodValue: deliveryMethodValue
      }
    }
    requestDocumentChangeHandler(requestData);
  }

  const isValidFormData = () =>{
    debugger
    return isRecaptchaVerified && borrowerName && loanNumber && name && address && city && state && isValidPostalCode(zipcode)  && 
           validateDeliveryMethodValue() && deliveryMethod && locationAddress && locationCity && locationState && 
           isValidPostalCode(locationZipcode)   
  }

  const validateDeliveryMethodValue = ()=>{
    debugger
    if(deliveryMethod == "Email")
    {
        return isValidEmailAddress(deliveryMethodValue)
    }
    else if(deliveryMethod == "Fax #")
    {
        return deliveryMethodValue?.length == 10
    }
    else if(deliveryMethod == "URL")
    {
      var urlRegex = new RegExp(
        '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$'
      );
      return deliveryMethodValue && urlRegex.test(deliveryMethodValue);
    }
  }

  const resetForm = () =>{
    setBorrowerName("");
    setLoanNumber("");
    setName("");
    setAddress("");
    setCity("");
    setState("");
    setDeliveryMethod("")
    setLocationAddress("");
    setLocationCity("");
    setLocationState("");

    setIsBorrowerNameFocused(false);
    setIsLoanNumberFocused(false);
    setIsNameFocused(false);
    setIsAddressFocused(false);
    setIsCityFocused(false);
    setIsStateFocused(false);
    setIsLocationAddressFocused(false);
    setIsLocationCityFocused(false);
    setIsLocationStateFocused(false);

    setIsBorrowerNameTouched(false);
    setIsLoanNumberTouched(false);
    setIsNameTouched(false);
    setIsAddressTouched(false);
    setIsCityTouched(false);
    setIsStateTouched(false);
    setIsLocationAddressTouched(false);
    setIsLocationCityTouched(false);
    setIsLocationStateTouched(false);

    setIsZipcodeFocused(false);
    setZipcode("");
    setIsZipcodeTouched(false);

    setIsLocationZipcodeFocused(false);
    setLocationZipcode("");
    setIsLocationZipcodeTouched(false);
  }

  return (
    <Grid container rowSpacing={4}>  
        <Grid item xs={12}>
            <Typography                                 
                variant='h4'
                fontFamily="Sofia Sans Condensed"
                sx={{
                    color:"#0C709B"
                }}>
                REQUEST FORM
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item md={12} sx={{width:"100%"}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          BORROWER REFERENCE
                      </Typography>                  
                </Grid>
                <Grid item md={6} sx={{width:"100%"}}>
                    <TextField
                        error={isBorrowerNameTouched && !borrowerName && !isBorrowerNameFocused}
                        required
                        size="small"
                        fontFamily="Nunito"
                        sx={{width:"100%"}}
                        onFocus={()=>{setIsBorrowerNameFocused(true)}}
                        onBlur={()=>{setIsBorrowerNameFocused(false)}}
                        type="text" 
                        value= {borrowerName}
                        onChange= {borrowerNameChangeHandler}                        
                        label = "Borrower Name"/>
                </Grid>
                <Grid item md={6} sx={{width:"100%"}}>
                      <TextField
                          error={isLoanNumberTouched && !loanNumber & !isLoanNumberFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsLoanNumberFocused(true)}}
                          onBlur={()=>{setIsLoanNumberFocused(false)}}                         
                          value= {loanNumber}
                          onChange= {loanNumberChangeHandler}                        
                          label = "Loan Number"/> 
                </Grid>
                <Grid item md={12} sx={{width:"100%"}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          LOCATION
                      </Typography>                  
                </Grid>                               
                <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isLocationAddressTouched && !locationAddress & !isLocationAddressFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsLocationAddressFocused(true)}}
                          onBlur={()=>{setIsLocationAddressFocused(false)}}                         
                          value= {locationAddress}
                          onChange= {locationAddressChangeHandler}                        
                          label = "Location Address"/> 
                </Grid>           
                <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isLocationCityTouched && !locationCity & !isLocationCityFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsLocationCityFocused(true)}}
                          onBlur={()=>{setIsLocationCityFocused(false)}}                         
                          value= {locationCity}
                          onChange= {locationCityChangeHandler}                        
                          label = "Location City"/> 
                </Grid>          
                <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isLocationStateTouched && !locationState & !isLocationStateFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsLocationStateFocused(true)}}
                          onBlur={()=>{setIsLocationStateFocused(false)}}                         
                          value= {locationState}
                          onChange= {locationStateChangeHandler}                        
                          label = "Location State"/> 
                </Grid>  
                <Grid item md={4} sx={{width:"100%"}}>
                      <NumberFormat
                          sx={{width: "100%"}}
                          error={isLocationZipcodeTouched && !isValidPostalCode(locationZipcode) & !isLocationZipcodeFocused}
                          required
                          label="Location Zipcode"
                          size="small"
                          type="text" 
                          value={locationZipcode} 
                          format="#####" 
                          mask="_" 
                          customInput={TextField}
                          onFocus={()=>{setIsLocationZipcodeFocused(true)}}
                          onBlur={()=>{setIsLocationZipcodeFocused(false)}}   
                          onValueChange={locationZipcodeChangeHandler}/>                       
                </Grid>                 
                <Grid item md={12} sx={{width:"100%"}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          MORTGAGEE OR LIENHOLDER CLAUSE
                      </Typography>                  
                </Grid>         
                <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isNameTouched && !name & !isNameFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsNameFocused(true)}}
                          onBlur={()=>{setIsNameFocused(false)}}                         
                          value= {name}
                          onChange= {nameChangeHandler}                        
                          label = "Name"/> 
                </Grid>                           
                <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isAddressTouched && !address & !isAddressFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsAddressFocused(true)}}
                          onBlur={()=>{setIsAddressFocused(false)}}                         
                          value= {address}
                          onChange= {addressChangeHandler}                        
                          label = "Address"/> 
                </Grid>           
                <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isCityTouched && !city & !isCityFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsCityFocused(true)}}
                          onBlur={()=>{setIsCityFocused(false)}}                         
                          value= {city}
                          onChange= {cityChangeHandler}                        
                          label = "City"/> 
                </Grid>          
                <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isStateTouched && !state & !isStateFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsStateFocused(true)}}
                          onBlur={()=>{setIsStateFocused(false)}}                         
                          value= {state}
                          onChange= {stateChangeHandler}                        
                          label = "State"/> 
                </Grid>  
                <Grid item md={4} sx={{width:"100%"}}>
                      <NumberFormat
                          sx={{width: "100%"}}
                          error={isZipcodeTouched && !isValidPostalCode(zipcode) & !isZipcodeFocused}
                          required
                          label="Zipcode"
                          size="small"
                          type="text" 
                          value={zipcode} 
                          format="#####" 
                          mask="_" 
                          customInput={TextField}
                          onFocus={()=>{setIsZipcodeFocused(true)}}
                          onBlur={()=>{setIsZipcodeFocused(false)}}   
                          onValueChange={zipcodeChangeHandler}/>                       
                </Grid>                                                                                     
                <Grid item xs={12}>
                      <ReCAPTCHA
                          size="normal"
                          onChange={()=>{setIsRecaptchaVerified(true)}}
                          onExpired={()=>{setIsRecaptchaVerified(false)}}
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/>   
                </Grid>        
                <Grid item md={12} sx={{width:"100%"}}>
                    <FormControl size="small" sx={{ width:"100%" }}>                                
                          <InputLabel>Document Delivery Method</InputLabel>
                          <Select
                                sx={{width:"100%"}} 
                                value={deliveryMethod}
                                label="Document Delivery Method"
                                onChange={(event)=>{setDeliveryMethodValue(""); setDeliveryMethod(event.target.value)}}>
                                <MenuItem key="Email" value="Email" name="Email" >Email</MenuItem>
                                <MenuItem key="Fax #" value="Fax #" name="Fax #" >Fax</MenuItem>
                                <MenuItem key="URL" value="URL" name="Upload" >Upload</MenuItem>
                          </Select>
                    </FormControl>                    
                </Grid> 
                {
                    deliveryMethod && 
                    <Grid item md={12} sx={{width:"100%"}}>
                        <TextField
                            error={isDeliveryMethodValueTouched && !deliveryMethod & !isDeliveryMethodValueFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            type={deliveryMethod === "Fax #" ? "number" : "text"}
                            onFocus={()=>{setIsDeliveryMethodValueFocused(true)}}
                            onBlur={()=>{setIsDeliveryMethodValueFocused(false)}}                         
                            value= {deliveryMethodValue}
                            onChange= {deliveryMethodsValueChangeHandler}                        
                            label = {deliveryMethod}/> 
                    </Grid>  
                }                                                 
                <Grid item xs={12}>
                      <Button
                          disabled={!isValidFormData()}
                          variant="contained"
                          backgroundColor= "#0C709B" 
                          startIcon={<SendIcon />}
                          size="medium"
                          onClick={submitDocumentRequestHandler}
                          sx={{
                            borderRadius: '20px',
                            fontFamily: "Nunito",
                            fontWeight: "bolder",
                          }}             
                          label = "Claim">  
                          Submit Request
                      </Button>   
                </Grid> 
            </Grid>
        </Grid>       
    </Grid>
  )
}




        

