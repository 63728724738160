import React from 'react'
import { Box, Stack, Typography, Button } from '@mui/material';
import Fade from 'react-reveal/Fade';
import { useNavigate} from 'react-router-dom';

const title = "WELCOME TO MODERN SHORE";
const subtitle = "Life is better when you have an advisor on your side. Our dedicated team strives to provide peace of mind through responsive service and quality insurance guidance. If you are interested in working with a friendly and knowledgeable advisor, dedicated to your insurance needs, then make a move to Modern Shore"

export default function WelcomeHeader({backgroundImage}) {
  const navigate = useNavigate()
  return (
    <Box 
      component="div" 
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: {
          xs:'right 80% bottom 45%',
          sm:'right 80% bottom 45%',
          md:'center',
          lg:'center'
        },
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '110vh',
        overflowX:'hidden',
      }}> 
      <Stack
        direction="column"
        alignItems="left"
        justifyContent="center"
        spacing={2}
        sx={{
          minHeight: "110vh",
          backgroundColor: 'rgba(0,0,0, 0.6)',     
          paddingLeft: '10vw',
        }}>
        <Fade delay={50}>
          <Typography 
            variant='h3'
            fontFamily="Sofia Sans Condensed"
            sx={{
              color:"white",
              marginTop: {
                lg:'10vh',
                md:'10vh',
                sm:'20vh',
                xs:'20vh',
              }
            }}>
            WELCOME TO MODERN SHORE
          </Typography>                                   
        </Fade>
        <Fade delay={300}>
          <Typography 
            variant='body1'
            fontFamily="Nunito"
            sx={{
              display: {
                lg: "block",
                md: "block",
                sm: "block",
                xs: "none"
              },
              color:"white",
              maxWidth: '40vw',
              textAlign: 'left'
            }}>
            {subtitle}      
          </Typography> 
          <Typography 
            variant='body2'
            fontFamily="Nunito"
            sx={{
              display: {
                lg: "none",
                md: "none",
                sm: "none",
                xs: "block"
              },
              color:"white",
              maxWidth: '80vw',
              textAlign: 'left'
            }}>
            {subtitle}      
          </Typography>            
        </Fade>
        <Fade delay={500}>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                display:{
                  xs:"none",
                  sm:"none",
                  md:"block",
                  lg:"block"
                }
              }}
              alignItems="left">
              <Button
                onClick={ ()=>{navigate('/MoveToModernShore')}}             
                sx={{
                  fontFamily:"Nunito",
                  borderColor:'white',
                  color:'white',
                  fontSize: 18,
                  '&:hover': {
                    borderColor:'white',
                  }
                }}                
                size='large'
                variant="outlined">
                Move to Modern Shore
              </Button>
              <Button
                onClick={ ()=>{navigate('/RequestConsultation')}}            
                sx={{
                  fontFamily:"Nunito",
                  borderColor:'white',
                  color:'white',
                  fontSize: 18,
                  '&:hover': {
                    borderColor:'white',
                  }
                }}
                size='large'
                variant="outlined">
                Request a Consultation
              </Button>
            </Stack> 
            <Stack
              direction="column"
              spacing={2}
              sx={{
                display:{
                  xs:"block",
                  sm:"block",
                  md:"none",
                  lg:"none"
                }
              }}
              alignItems="left">
              <Button
                onClick={ ()=>{navigate('/MoveToModernShore')}}             
                sx={{
                  fontFamily:"Nunito",
                  borderColor:'white',
                  color:'white',
                  '&:hover': {
                    borderColor:'white',
                  }
                }}                
                size='medium'
                variant="outlined">
                Move to Modern Shore
              </Button>
              <Button
                onClick={ ()=>{navigate('/RequestConsultation')}}            
                sx={{
                  fontFamily:"Nunito",
                  borderColor:'white',
                  color:'white',
                  '&:hover': {
                    borderColor:'white',
                  }
                }}
                size='medium'
                variant="outlined">
                Request a Consultation
              </Button>
            </Stack>             
        </Fade>
      </Stack>           
    </Box>                    
  )
}




        