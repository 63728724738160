import React from 'react';
import { useState, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import LenderVenderConnectHeader from '../components/LenderVenderConnectHeader'
import ClientAccessImageBackGround from '../assets/images/ClientAccessImageBackGround.jpg';
import LenderVenderRequestDocumentsSection from '../components/LenderVenderRequestDocumentsSection';
import { Seo } from '../components/Seo';


function VenderConnect({documentType = "COI"}) {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>      
      <Seo
          title="Vender Connect"
          description="At Modern Shore Insurance, requesting your insurance documentation is a breeze! By following the prompts below, you are only a few clicks away from submitting your request directly to a Modern Shore Insurance advisor"/>                                                          
      <section>          
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section> 
      <section id="VenderConnect">  
        <LenderVenderConnectHeader backgroundImage={ClientAccessImageBackGround} type="VENDER"/>
      </section>
      <section id="VenderConnectForm">
        <Box 
          component="div" 
          sx={{
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundImage: "linear-gradient(#0C709B, #B6ECF6);",
              minHeight: '150vh',
              overflowX:'hidden',
              padding:"7% 10% 5% 7%"
            }}>
            <LenderVenderRequestDocumentsSection
              type="Business Insurance"
              documentType={documentType}/>     
        </Box> 
      </section>
    </Stack>
  )
}

export default VenderConnect