import React from 'react'
import { Box, Grid, Typography, TextField, Button, Stack } from '@mui/material';
import { Seo } from './Seo';
import { useState, useEffect } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import { isValidEmailAddress } from '../utils/utilityFunctions';
import NumberFormat from 'react-number-format';
import FileUpload from 'react-material-file-upload';
import moment from 'moment';
import { isValidPostalCode } from '../utils/utilityFunctions';
import { getBase64 } from '../utils/utilityFunctions/getBase64';

function FloodInsuranceForm({quoteNowClickHandler}) {

  useEffect(()=>{
    document.title = "Flood Insurance Quote"
    window.scrollTo(0, 0)
  },[])

  const [name, setName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [dateOfBirth, setDateOfBirth] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [propertyAddress, setPropertyAddress] = useState("")
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");  
  const [occupation, setOccupation] = useState("")
  const [isNewHomePurchase, setIsNewHomePurchase] = useState("")
  const [elevationCertificate, setElevationCertificate] = useState("")
  const [currentInsuranceCompany, setCurrentInsuranceCompany] = useState("")
  const [additionalDetail, setAdditionalDetail] = useState("")
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)
  const [files, setFiles] = useState([])

  const [isNameFocused, setNameFocused] = useState(false)
  const [isLastNameFocused, setLastNameFocused] = useState(false)
  const [isEmailAddressFocused, setIsEmailAddressFocused] = useState(false)
  const [isPhoneFocused, setIsPhoneFocused] = useState(false)
  const [isAddressFocused, setIsAddressFocused] = useState(false)
  const [isCityFocused, setIsCityFocused] = useState(false)
  const [isStateFocused, setIsStateFocused] = useState(false)
  const [isZipcodeFocused, setIsZipcodeFocused] = useState(false)  
  const [isNewHomePurchaseFocused, setIsNewHomePurchaseFocused] = useState(false)
  const [isCurrentInsuranceCompanyFocused, setIsCurrentInsuranceCompanyFocused] = useState("")

  const [isNameTouched, setNameTouched] = useState(false)
  const [isLastNameTouched, setLastNameTouched] = useState(false)
  const [isEmailAddressTouched, setIsEmailAddressTouched] = useState(false)
  const [isPhoneTouched, setIsPhoneTouched] = useState(false)
  const [isAddressTouched, setIsAddressTouched] = useState(false)
  const [isCityTouched, setIsCityTouched] = useState(false)
  const [isStateTouched, setIsStateTouched] = useState(false)
  const [isZipcodeTouched, setIsZipcodeTouched] = useState(false)  
  const [isNewHomePurchaseTouched, setIsNewHomePurchaseTouched] = useState(false)
  const [isCurrentInsuranceCompanyTouched, setIsCurrentInsuranceCompanyTouched] = useState("")


  const nameChangeHandler = (event) =>{
    !isNameTouched && setNameTouched(true)
    setName(event.target.value)
  }

  const lastNameChangeHandler = (event) =>{
    !isLastNameTouched && setLastNameTouched(true)
    setLastName(event.target.value)
  }

  const emailAddressChangeHandler = (event) =>{
    !isEmailAddressTouched && setIsEmailAddressTouched(true)
    setEmail(event.target.value.trim())
  }

  const addressChangeHandler = (event) =>{
    !isAddressTouched && setIsAddressTouched(true)
    setPropertyAddress(event.target.value)
  }

  const phoneChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    if(value != phone)
    {
      setPhone(value)
    }
  }

  const newHomePurchaseChangeHandler = (event) =>{
    !isNewHomePurchaseTouched && setIsNewHomePurchaseTouched(true)
    setIsNewHomePurchase(event.target.value)
  }

  const currentInsuranceCompanyChangeHandler = (event) =>{
    !isCurrentInsuranceCompanyTouched && setIsCurrentInsuranceCompanyTouched(true)
    setCurrentInsuranceCompany(event.target.value)
  }

  const cityChangeHandler = (event) =>{
    !isCityTouched && setIsCityTouched(true)
    setCity(event.target.value)
  }
  const stateChangeHandler = (event) =>{
    !isStateTouched && setIsStateTouched(true)
    setState(event.target.value)
  }
  const zipcodeChangeHandler = (values) =>{
    const { formattedValue, value } = values;

    if(value != zipcode)
      {
        !isZipcodeTouched && setIsZipcodeTouched(true)
        setZipcode(value)
      }
  }

  const formClickHandler = async ()=>{
    const attachments = []
    for(let file in files)
    {
       let fileBase64 = await getBase64(files[file])
       attachments.push({
          "fileName": files[file].name,
          "fileBase64": fileBase64
       })
    }


    const requestBody = {
      "firstName": name,
      "lastName": lastName,
      "email": email,
      "phone": phone,
      "pageSource": "FLOOD INSURANCE INFORMATION",
      "address":{
          "street": propertyAddress,
          "city": city,
          "state": state,
          "postalCode": zipcode,
          "country": "US"
      },
      "attachments" : attachments,      
      "additionalDetails":[
        {
          "fieldName": "occupation",
          "fieldLabel": "Occupation",
          "fieldValue": occupation
        },
        {
          "fieldName": "dateOfBirth",
          "fieldLabel": "Date of Birth",
          "fieldValue": dateOfBirth
        },
        {
          "fieldName": "isNewHomePurchase",
          "fieldLabel": "Is this a New Home Purchase?",
          "fieldValue": isNewHomePurchase
        },
        {
          "fieldName": "elevationCertificate",
          "fieldLabel": "Do you have an Elevation Certificate?",
          "fieldValue": elevationCertificate
        },
        {
          "fieldName": "currentInsuranceCompany",
          "fieldLabel": "Current Insurance Company",
          "fieldValue": currentInsuranceCompany
        },   
        {
          "fieldName": "additionalDetail",
          "fieldLabel": "Any Additional Details?",
          "fieldValue": additionalDetail
        }                                        
      ],
    }
    await quoteNowClickHandler(requestBody, resetForm)  
  }

  const resetForm = () =>{
    setName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setPropertyAddress("");
    setCity("");
    setState("");
    setZipcode("");
    setOccupation("");
    setDateOfBirth(moment(new Date()).format('YYYY-MM-DD'));
    setIsNewHomePurchase("");
    setElevationCertificate("");
    setCurrentInsuranceCompany("");
    setAdditionalDetail("");
    setFiles([]);

    setNameFocused(false);
    setIsEmailAddressFocused(false);
    setIsPhoneFocused(false);
    setIsAddressFocused(false);
    setIsNewHomePurchaseFocused(false);
    setIsCurrentInsuranceCompanyFocused(false);
    setIsCityFocused(false);
    setIsStateFocused(false);
    setIsZipcodeFocused(false);

    setNameTouched(false);
    setIsEmailAddressTouched(false);
    setIsPhoneTouched(false);
    setIsAddressTouched(false);
    setIsNewHomePurchaseTouched(false);
    setIsCurrentInsuranceCompanyTouched(false);
    setIsCityTouched(false);
    setIsStateTouched(false);
    setIsZipcodeTouched(false);
  }
  
  const isValidPhone = phone !== null && phone !== undefined && phone.toString().length === 10;
  const isValidData = isValidEmailAddress(email) && isRecaptchaVerified && name && isValidPhone && isNewHomePurchase 
                      && currentInsuranceCompany && lastName && city && state && zipcode

  return (
    <Stack>      
      <Seo
          title="Flood Insurance Quote"
          description="Safeguard your property from floods with tailored insurance coverage. Request a flood insurance quote today for reliable protection and peace of mind."/>                                                          
      <section id="FloodInsuranceQuote">  
        <Box 
          component="div" 
          sx={{
            backgroundColor: "#f5f5f5",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minHeight: '100vh',
            overflowX:'hidden',
            textAlign: 'center',
            padding: {
              xs: "8% 6% 5% 6%",
              sm: "8% 6% 5% 6%",
              md: "8% 10% 5% 10%"
            }
          }}>            
          <Typography 
            variant='h3'
            fontFamily="Sofia Sans Condensed"
            sx={{
              color:"#0C709B",
              marginBottom: '4vh'
            }}>
            FLOOD INSURANCE INFORMATION
          </Typography>  
          <br/>
          <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                  <Grid container columnSpacing={2} rowSpacing={2}>
                      <Grid item md={6} sm={12} xs={12}>
                          <TextField
                              error={isNameTouched && !name && !isNameFocused}
                              onFocus={()=>{setNameFocused(true)}}
                              onBlur={()=>{setNameFocused(false)}}                                
                              required
                              fontFamily="Nunito"
                              fullWidth
                              type="text"
                              size="small"
                              value= {name}
                              onChange= {nameChangeHandler}                        
                              label = "First Name"/>    
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                          <TextField
                              error={isLastNameTouched && !lastName && !isLastNameFocused}
                              onFocus={()=>{setLastNameFocused(true)}}
                              onBlur={()=>{setLastNameFocused(false)}}                                
                              required
                              fontFamily="Nunito"
                              fullWidth
                              type="text"
                              size="small"
                              value= {lastName}
                              onChange= {lastNameChangeHandler}                        
                              label = "Last Name"/>    
                      </Grid>                 
                  </Grid>
              </Grid>   
              <Grid item xs={12}>
                  <Grid container columnSpacing={2} rowSpacing={2}>
                      <Grid item md={6} sm={12} xs={12}>
                          <TextField 
                              required
                              error={isEmailAddressTouched && !isValidEmailAddress(email) && !isEmailAddressFocused}
                              fontFamily="Nunito"
                              fullWidth
                              size="small"
                              value= {email}
                              onFocus={()=>{setIsEmailAddressFocused(true)}}
                              onBlur={()=>{setIsEmailAddressFocused(false)}}
                              type="text" 
                              onChange= {emailAddressChangeHandler}    
                              label = "Email"/>  
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                          <NumberFormat
                                  error={isPhoneTouched && !isValidPhone && !isPhoneFocused}                        
                                  onFocus={()=>{setIsPhoneFocused(true)}}
                                  onBlur={()=>{setIsPhoneFocused(false)}}                           
                                  required
                                  sx={{
                                      width: "100%"
                                  }}
                                  label="Phone"
                                  type="text" 
                                  size="small"
                                  value={phone} 
                                  format="+1 (###) ###-####" 
                                  mask="_" 
                                  customInput={TextField}
                                  onValueChange={phoneChangeHandler}/>                        
                      </Grid>                                   
                  </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                          id="startDate" 
                          fontFamily="Nunito"
                          type="date"
                          sx={{width:"100%"}}
                          InputProps={{inputProps: { max: dateOfBirth }}}
                          onChange= {(event)=>{setDateOfBirth(event.target.value)}}                        
                          value= {dateOfBirth}
                          fullWidth 
                          size="small"
                          label = "Date of Birth"/>                     
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                          fontFamily="Nunito"
                          fullWidth
                          size="small"
                          type="text"
                          value= {occupation}
                          onChange= {(e)=>{setOccupation(e.target.value)}}                        
                          label = "Occupation"/>                      
                    </Grid>                  
                </Grid>
              </Grid>          
              <Grid item xs={12}>
                  <TextField
                      error={isAddressTouched && !propertyAddress && !isAddressFocused}
                      onFocus={()=>{setIsAddressFocused(true)}}
                      onBlur={()=>{setIsAddressFocused(false)}}                
                      required
                      multiline
                      fullWidth
                      size="small"
                      fontFamily="Nunito"
                      minRows={2}
                      value= {propertyAddress}
                      onChange= {addressChangeHandler}      
                      label = "Property Address"/>   
              </Grid>
              <Grid item xs={12}>
                    <Grid container columnSpacing={2} rowSpacing={2} >
                      <Grid item md={4} sx={{width:"100%"}}>
                          <TextField
                              error={isCityTouched && !city & !isCityFocused}
                              required
                              size="small"
                              fontFamily="Nunito"
                              sx={{width:"100%"}}
                              type="text"   
                              onFocus={()=>{setIsCityFocused(true)}}
                              onBlur={()=>{setIsCityFocused(false)}}                         
                              value= {city}
                              onChange= {cityChangeHandler}                        
                              label = "City"/> 
                      </Grid>          
                      <Grid item md={4} sx={{width:"100%"}}>
                          <TextField
                              error={isStateTouched && !state & !isStateFocused}
                              required
                              size="small"
                              fontFamily="Nunito"
                              sx={{width:"100%"}}
                              type="text"   
                              onFocus={()=>{setIsStateFocused(true)}}
                              onBlur={()=>{setIsStateFocused(false)}}                         
                              value= {state}
                              onChange= {stateChangeHandler}                        
                              label = "State"/> 
                      </Grid>  
                      <Grid item md={4} sx={{width:"100%"}}>
                          <NumberFormat
                                sx={{width: "100%"}}
                                error={isZipcodeTouched && !isValidPostalCode(zipcode) & !isZipcodeFocused}
                                required
                                label="Zipcode"
                                size="small"
                                type="text" 
                                value={zipcode} 
                                format="#####" 
                                mask="_" 
                                customInput={TextField}
                                onFocus={()=>{setIsZipcodeFocused(true)}}
                                onBlur={()=>{setIsZipcodeFocused(false)}}   
                                onValueChange={zipcodeChangeHandler}/>                              
                      </Grid>
                    </Grid>
              </Grid> 
              <Grid item xs={12}>
                  <TextField
                      size="small"
                      error={isNewHomePurchaseTouched && !isNewHomePurchase && !isNewHomePurchaseFocused}
                      onFocus={()=>{setIsNewHomePurchaseFocused(true)}}
                      onBlur={()=>{setIsNewHomePurchaseFocused(false)}}
                      required
                      fullWidth
                      fontFamily="Nunito"
                      type="text" 
                      value= {isNewHomePurchase}
                      onChange= {newHomePurchaseChangeHandler}      
                      label = "Is this a New Home Purchase (If Yes, when is the closing date)?"/>   
              </Grid>  
              <Grid item xs={12}>
                  <TextField
                      size="small"
                      fullWidth
                      fontFamily="Nunito"
                      value= {elevationCertificate}
                      onChange= {(e)=>{setElevationCertificate(e.target.value)}}      
                      label = "Do you have an Elevation Certificate (If Yes, Please attach below)"/>   
              </Grid>    
              <Grid item xs={12}>
                  <TextField
                      size="small"
                      error={isCurrentInsuranceCompanyTouched && !currentInsuranceCompany && !isCurrentInsuranceCompanyFocused}
                      onFocus={()=>{setIsCurrentInsuranceCompanyFocused(true)}}
                      onBlur={()=>{setIsCurrentInsuranceCompanyFocused(false)}}
                      required                
                      fullWidth
                      fontFamily="Nunito"
                      value= {currentInsuranceCompany}
                      onChange= {currentInsuranceCompanyChangeHandler}    
                      label = "Current Insurance Company (Please Attach Policy or Dec Page below if available)"/>   
              </Grid>                              
              <Grid item xs={12}>
                  <FileUpload
                      label="Attachments"
                      title="Attach Inspections a/o Policy"
                      value={files} 
                      onChange={setFiles} 
                      style={{
                          height:"10px"
                      }}
                      multiple={true}/>
              </Grid>   
              <Grid item xs={12}>
                  <TextField
                      fullWidth
                      size="small"
                      fontFamily="Nunito"
                      value= {additionalDetail}
                      onChange= {(e)=>{setAdditionalDetail(e.target.value)}}      
                      label = "Any Additional Details? (prior claims, current conditions, etc)"/>   
              </Grid>                  
              <Grid item xs={12}>
                  <ReCAPTCHA
                      size="normal"
                      onChange={()=>{setIsRecaptchaVerified(true)}}
                      onExpired={()=>{setIsRecaptchaVerified(false)}}
                      sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/> 
              </Grid>                                           
              <Grid item xs={12} textAlign="start">
                  <Button
                      variant="contained"
                      backgroundColor= "#0C709B"  
                      startIcon={<SendIcon />}
                      size="large"                    
                      disabled = {!isValidData}
                      onClick={formClickHandler}
                      sx={{borderRadius: '20px', marginTop: '2vh'}}     
                      label = "Submit">  
                      Send
                  </Button>   
              </Grid>
          </Grid>        
        </Box>   
      </section>
    </Stack>                 
  )
}

export default FloodInsuranceForm