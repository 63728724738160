import React from 'react'
import Fade from 'react-reveal/Fade';
import { Button, Grid, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import BlogsPreview from '../components/BlogsPreview';
import { useNavigate } from 'react-router';


const Item = styled(Paper)(({ theme }) => ({
    color: "white",
    backgroundColor: "#0C709B",
    padding: 20,
    margin: 4,
    border: '1px solid white',
    borderRadius: '10px',
    '&:hover': {
    }
  }));

export default function PersonalInsuranceBody() {
    const baseDelay = 50
    const interval = 50
    const navigate = useNavigate()


    const serviceOnlickHandler = (e, externalQuoteNow, quoteNow)=>{
        e.stopPropagation(); 
        externalQuoteNow ? window.location.replace(externalQuoteNow) :  quoteNow ? navigate('/' + quoteNow) : navigate('/QuoteNow')       
    }
    return (
        <>
            <Grid container 
                spacing={4}
                sx={{
                    padding: "10% 5%",
                    backgroundColor: 'white'
                }}>
                <Grid item xs={12}>
                    <Typography 
                        sx={{
                            marginBottom: "2%"
                        }}
                        variant="h3"
                        textAlign="center"
                        fontFamily="Sofia Sans Condensed"  
                        color="#0C709B">
                        PERSONAL INSURANCE PRODUCTS
                    </Typography>
                </Grid>
                {
                    personalItems.map((item, index)=>{
                        return (
                            <Grid key={index} item lg={4} md={4} sm={4} xs={12}>
                                <Fade bottom delay={baseDelay + (interval * index)}>
                                    <Grid onClick={(e)=>{ item.redirectUrl != null && navigate('/' + item.redirectUrl)}}>
                                        <Item 
                                            sx={{minHeight: '34vh','&:hover': {cursor:'pointer'}}}>
                                            <Typography
                                                fontWeight="bolder"
                                                fontFamily="Nunito"  
                                                variant='h4'>
                                                {item.name}
                                            </Typography>
                                            <br/>
                                            <Typography
                                                fontFamily="Nunito"                                    
                                                variant='body1'>
                                                {item.description}
                                            </Typography>
                                            <Button
                                                size="large"
                                                variant='outlined'
                                                onClick={(e)=>{ serviceOnlickHandler(e, item.externalQuoteNow, item.quoteNow) }}      
                                                sx={{
                                                    fontFamily:"Nunito",   
                                                    color: 'white',
                                                    backgroundColor: "#F1735D" ,                    
                                                    marginTop: '15%',
                                                    textTransform: 'capitalize',
                                                    '&:hover': { 
                                                        backgroundColor: '#F1735D',
                                                    }
                                                }}>
                                                Quote Now
                                            </Button>
                                        </Item>
                                    </Grid>
                                </Fade>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Box 
              component="div" 
              sx={{
                  backgroundColor: "#f5f5f5",
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  minHeight: '100vh',
                  overflowX:'hidden',
                  padding:"4% 10% 5% 10%"
              }}>
              <BlogsPreview
                insuranceType="Personal Insurance"/>
            </Box>                       
        </>        
    )
}

const personalItems = [
    {
        name: "HOME",
        description: "Homeowners Insurance provides peace of mind that your most precious asset, your home, is protected.",
        redirectUrl: "HomeInsurance",
        quoteNow: "home-insurance-quote"
    },
    {
        name: "AUTO",
        description: "Auto insurance covers damage to your vehicle and protects you financially if you're liable for someone else's injuries or damages.",
        redirectUrl: "AutoInsurance",
        quoteNow: "auto-insurance-quote"
    },
    {
        name: "FLOOD",
        description: "Flood insurance protects residential and commercial property against damage from rising water.",
        redirectUrl: "FloodInsurance",
        quoteNow: "flood-insurance-quote"    
    },
    {
        name: "CONDO",
        description: "Condo Insurance provides peace of mind that your home and personal belongings are covered.",
        redirectUrl: "CondoInsurance",
        quoteNow: "home-insurance-quote"
    },
    {
        name: "BOAT & MARINE",
        description: "Boat, Watercraft, or Personal Marine insurance provides coverage for boats, yachts, and other types of watercraft.",
        redirectUrl: "BoatInsurance"
    },
    {
        name: "UMBRELLA",
        description: "Umbrella insurance provides an additional layer liability coverage beyond your primary insurance policies such as homeowner's or auto insurance.",
        redirectUrl: "UmbrellaInsurance"
    },
    {
        name: "LIFE",
        description: "Life insurance provides financial support to your loved ones on the death of the insured or after a set period.",
        redirectUrl: "LifeInsurance",
        externalQuoteNow: "https://agents.ethoslife.com/invite/669c0"
    },
    {
        name: "JEWELRY & VALUABLES",
        description: "Jewelry and valuables insurance provides coverage for high-value items such as jewelry, watches, fine art, antiques, and other collectibles.",
        redirectUrl: "JewelryInsurance"
    },
    {
        name: "PERSONAL LIABILITY",
        description: "Personal liability insurance is designed to help protect your personal assets, such as your home and savings, in the event of a lawsuit.",
        redirectUrl: "PersonalLiabilityInsurance"
    },
    {
        name: "RENTAL PROPERTY",
        description: "Rental property insurance, also known as landlord insurance, provides coverage for individuals who rent out their properties to tenants.",
        redirectUrl: "RentalInsurance"
    },
    {
        name: "MOBILE & MANUFACTURED HOME",
        description: "Mobile and manufactured home insurance provides coverage for homes that are constructed off-site and transported to their permanent location.",
        redirectUrl: "MobileHomeInsurance"
    },
    {
        name: "RENTERS INSURANCE",
        description: "Renters insurance provides personal property coverage for individuals who rent a home or apartment.",
        redirectUrl: "RentersInsurance"
    },
    {
        name: "RV & MOTOR HOME",
        description: "RV (Recreational Vehicle) and Motor Home Insurance provide coverage for your recreational vehicle, motor home, or camper van.",
        redirectUrl: "MotorHomeInsurance"
    },
    {
        name: "MOTORCYCLE",
        description: "Motorcycle insurance covers damage to your motorcycle and protects you financially if you're liable for someone else's injuries or damages.",
        redirectUrl: "MotorcycleInsurance"
    },
    {
        name: "GOLF CART",
        description: "Golf cart insurance provides coverage for golf carts and other Low Speed Vehicles.",
        redirectUrl: "GolfCartInsurance"
    }
]
