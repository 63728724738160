import React from 'react';
import { useState, useEffect } from 'react';
import { Stack, Box, Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import ClientAccessNavBar from '../components/ClientAccessNavBar';
import ClientAccessHeader from '../components/ClientAccessHeader'
import ClientAccessImageBackGround from '../assets/images/ClientAccessImageBackGround.jpg';
import RequestDocumentsSection from '../components/RequestDocumentsSection';
import { Seo } from '../components/Seo';

function RequestDocuments({type = 0, documentType = ""}) {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>      
      <Seo
          title="Request Documents"
          description="At Modern Shore Insurance, requesting your insurance documentation is a breeze! By following the prompts below, you are only a few clicks away from submitting your request directly to a Modern Shore Insurance advisor"/>                                                          
      <section>          
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section>
      <section>
        <ClientAccessNavBar selected="RequestDocuments"/>
      </section>  
      <section id="RequestDocuments">  
        <ClientAccessHeader backgroundImage={ClientAccessImageBackGround}/>
      </section>
      <section id="RequestDocumentsForm">
        <Box 
          component="div" 
          sx={{
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundImage: "linear-gradient(#0C709B, #B6ECF6);",
              minHeight: '150vh',
              overflowX:'hidden',
              padding:"7% 10% 5% 7%"
            }}>
            <RequestDocumentsSection
              type={type}
              documentType={documentType}/>     
        </Box> 
      </section>
    </Stack>
  )
}

export default RequestDocuments