import React from 'react';
import { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import AboutUsHeader from '../components/AboutUsHeader';
import OurStorySection from '../components/OurStory';
import OurMissionSection from '../components/OurMission';
import AboutUsHeaderImage from '../assets/images/AboutUsHeader.jpg';
import AboutUsNavbar from '../components/AboutUsNavbar';
import { Seo } from '../components/Seo';

function OurStory() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>
      <Seo
          title="Our Story"
          description="Founded in St. Petersburg, Florida, Modern Shore Insurance is rooted in community relationships and solidified with national strength"/>                                          
      <section>
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section> 
      <section>
        <AboutUsNavbar selected="OurStory"/>
      </section> 
      <section id="OurStory">  
        <Stack>
            <AboutUsHeader backgroundImage={AboutUsHeaderImage}/>
            <OurStorySection/>
            <OurMissionSection/>
        </Stack>
      </section>
    </Stack>
  )
}

export default OurStory