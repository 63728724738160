import React from 'react';
import { AppBar, Toolbar, Box, Typography, Button, Grid, Menu, MenuItem  } from '@mui/material';
import { useNavigate} from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


const clientAccessOptions = [
    { title: "Report a Claim", key: "ReportClaim", externalLink: null, variant: "body1"},
    { title: "Make a Payment", key: "MakePayment", externalLink: "https://modernshoreins.epaypolicy.com/", variant: "body1"},
    { title: "Request Documents", key: "RequestDocuments", externalLink: null, variant: "body1"},
    { title: "Policy Change Request", key: "PolicyChangeRequest", externalLink: null, variant: "caption"},
    { title: "Contact Customer Service", key: "ContactCustomerService", externalLink: null, variant: "caption"},
];

const ClientAccessNavbar = ({selected}) => { 
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigateTo = (page)=>{
        setAnchorEl(null);
        navigate(page)
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar       
                position="fixed"  
                sx={{        
                    display: {xs:'none', sm: 'none', md: 'block'},            
                    backgroundColor :  "#D0D9DD",                    
                    boxShadow :  "block",
                    paddingLeft: "3%",
                    marginTop: {
                        xs: "70px",
                        sm: "80px",
                        md: "75px"
                    },
                }}>
                <Toolbar>
                    <Button
                        onClick={ ()=>{navigate('/ReportClaim')}}>
                        <Typography
                            sx={{      
                                fontWeight: 1000,                      
                                color: "#0C709B",
                                fontWeight: 1000
                            }}    
                            fontFamily="Nunito"
                            variant="h6">
                            CLIENT CONNECT
                        </Typography>
                    </Button>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1 }}> 
                    </Typography>
                    {
                        clientAccessOptions.map((menu, index)=>{
                            return (
                                <Typography
                                    key={index}
                                    sx={{
                                        paddingRight: 5,
                                        textDecoration: selected === menu.key ? 'underline' : 'none',
                                        textUnderlineOffset: '40%',
                                        textDecorationThickness: '1px',
                                        textDecorationColor: "#0C709B",
                                        color:"#0C709B",
                                            '&:hover': {
                                                cursor: 'pointer',
                                            }
                                        }}  
                                        onClick={()=>{menu.externalLink === null ? navigate('/' + menu.key) : window.location.href = menu.externalLink}}      
                                        fontFamily="Nunito"
                                        fontWeight="bold"         
                                        variant="body1">
                                        {menu.title}
                                </Typography>
                            )
                        })
                    }              
                </Toolbar>
            </AppBar>
            <AppBar       
                position="fixed"  
                sx={{        
                    display: {xs:'block', sm: 'block', md: 'none'},            
                    backgroundColor :  "#D0D9DD",                    
                    boxShadow :  "block",
                    minHeight: "50px",
                    paddingLeft: "3%",
                    marginTop: {
                        xs: "69px",
                        sm: "80px"                 
                    },
                    paddingTop: {
                        xs: "1vh",
                        sm: "1vh"                 
                    },
                    paddingBottom: {
                        xs: "1vh",
                        sm: "1vh"                 
                    },
                }}>
                <Grid container>
                    <Grid item xs={5} textAlign="center">
                        <Button
                            onClick={ ()=>{navigate('/OurStory')}}>
                            <Typography
                                sx={{    
                                    fontWeight: 1000,                        
                                    color: "#0C709B"
                                }}    
                                fontFamily="Nunito"
                                variant={clientAccessOptions.find(x => x.key === selected).variant}>
                                CLIENT CONNECT
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={7} textAlign="center">
                        <Grid container>
                            <Grid item xs={10}>
                                <Button>
                                    <Typography
                                        sx={{  
                                            fontWeight: 1000,                          
                                            color: "#0C709B",
                                        }}
                                        onClick={handleClick}
                                        fontFamily="Nunito"
                                        fontWeight="bolder"  
                                        variant={clientAccessOptions.find(x => x.key === selected).variant}>
                                        { clientAccessOptions.find(x => x.key === selected).title }
                                    </Typography>                             
                                </Button>
                            </Grid>
                            <Grid item xs={2} textAlign="left">
                                <Typography                                
                                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    sx={{     
                                        marginTop: "4%",             
                                        color: "#0C709B",
                                    }}    
                                    fontFamily="Sofia Sans Condensed"
                                    variant="body2">
                                    { open ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
                                </Typography>
                            </Grid>                                
                        </Grid>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}>
                            {
                                clientAccessOptions.map((menu, index)=>{
                                    return (
                                        <MenuItem
                                            key={index}
                                            onClick={()=>{menu.externalLink === null ? navigate('/' + menu.key) : window.location.href = menu.externalLink}}>
                                            <Typography
                                                sx={{
                                                    color:"#0C709B",
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                        }
                                                    }}  
                                                    fontFamily="Nunito"
                                                    fontWeight="bold"         
                                                    variant="body2">
                                                    {menu.title}
                                            </Typography>
                                        </MenuItem>
                                    )
                                })
                            }                                   
                        </Menu>                        
                    </Grid>                    
                </Grid>                                          
            </AppBar>
        </Box>   
    )
};

export default ClientAccessNavbar