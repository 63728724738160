import React from 'react'
import { useEffect } from 'react';
import { Grid, Typography, TextField, Button } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import NumberFormat from 'react-number-format';
import { isValidPostalCode, isValidEmailAddress } from '../utils/utilityFunctions';
import { PolicyChangeDisclaimerSection } from './PolicyChangeDisclaimerSection';



export default function BusinessInsuranceAddressAddForm({insuranceType, requestPolicyChangeHandler}) {
  useEffect(()=>{
    resetForm();
  },[insuranceType])
  
  const [businessName, setBusinessName] = useState("");
  const [contactName, setContactName] = useState("");
  const [emailAddress, setEmailAddress] = useState(""); 
  const [newAddress, setNewAddress] = useState("");
  const [newAddress2, setNewAddress2] = useState("");
  const [newCity, setNewCity] = useState("");
  const [newState, setNewState] = useState("");
  const [newZipcode, setNewZipcode] = useState("");

  const [isBusinessNameFocused, setIsBusinessNameFocused] = useState(false)
  const [isContactNameFocused, setIsContactNameFocused] = useState(false)
  const [isEmailAddressFocused, setIsEmailAddressFocused] = useState(false)
  const [isNewAddressFocused, setIsNewAddressFocused] = useState(false)
  const [isNewCityFocused, setIsNewCityFocused] = useState(false)
  const [isNewStateFocused, setIsNewStateFocused] = useState(false)
  const [isNewZipcodeFocused, setIsNewZipcodeFocused] = useState(false)

  const [isBusinessNameTouched, setIsBusinessNameTouched] = useState(false)
  const [isContactNameTouched, setIsContactNameTouched] = useState(false)
  const [isEmailAddressTouched, setIsEmailAddressTouched] = useState(false)
  const [isNewAddressTouched, setIsNewAddressTouched] = useState(false)
  const [isNewCityTouched, setIsNewCityTouched] = useState(false)
  const [isNewStateTouched, setIsNewStateTouched] = useState(false)
  const [isNewZipcodeTouched, setIsNewZipcodeTouched] = useState(false)

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)

  const businessNameChangeHandler = (event) =>{
    !isBusinessNameTouched && setIsBusinessNameTouched(true)
    setBusinessName(event.target.value)
  }

  const emailAddressChangeHandler = (event) =>{
    !isEmailAddressTouched && setIsEmailAddressTouched(true)
    setEmailAddress(event.target.value.trim())
  }

  const contactNameChangeHandler = (event) =>{
    !isContactNameTouched && setIsContactNameTouched(true)
    setContactName(event.target.value)
  }

  const newAddressChangeHandler = (event) =>{
    !isNewAddressTouched && setIsNewAddressTouched(true)
    setNewAddress(event.target.value)
  }

  const newAddress2ChangeHandler = (event) =>{
    setNewAddress2(event.target.value)
  }

  const newCityChangeHandler = (event) =>{
    !isNewCityTouched && setIsNewCityTouched(true)
    setNewCity(event.target.value)
  }
  const newStateChangeHandler = (event) =>{
    !isNewStateTouched && setIsNewStateTouched(true)
    setNewState(event.target.value)
  }
  const newZipcodeChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    !isNewZipcodeTouched && setIsNewZipcodeTouched(true)
    setNewZipcode(value)
  }

  const submitAddressChangeRequestHandler = async ()=>{
    let requestData = {
      contactName: contactName,
      email: emailAddress,
      accountName: businessName,
      details : {
        addAddress: {
          address1: newAddress,
          address2: newAddress2,
          city: newCity,
          state: newState,
          postalCode: newZipcode
        }
      }
    }
    await requestPolicyChangeHandler(requestData)
  }

  const isValidFormData = () =>{
    return isRecaptchaVerified && businessName && isValidEmailAddress(emailAddress) && 
           contactName && newAddress && newCity && 
           newState && isValidPostalCode(newZipcode)
  }

  const resetForm = () =>{
    setBusinessName("");
    setContactName("");
    setEmailAddress("");
    setNewAddress2("");
    setNewCity("");
    setNewState("");
    setNewZipcode("");

    setIsBusinessNameFocused(false);
    setIsContactNameFocused(false);
    setIsEmailAddressFocused(false);
    setIsNewAddressFocused("");
    setIsNewCityFocused("");
    setIsNewStateFocused("");
    setIsNewZipcodeFocused("");

    setIsBusinessNameTouched(false);
    setIsContactNameTouched(false);
    setIsEmailAddressTouched(false);
    setIsNewAddressTouched("");
    setIsNewCityTouched("");
    setIsNewStateTouched("");
    setIsNewZipcodeTouched("");

    setIsRecaptchaVerified(false)
  }

  return (
    <Grid container rowSpacing={4}>  
        <Grid item xs={12}>
            <Typography                                 
                variant='h4'
                fontFamily="Sofia Sans Condensed"
                sx={{
                    color:"#0C709B"
                }}>
                REQUEST FORM
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container rowSpacing={2} columnSpacing={2}>
                <>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          CLIENT DETAILS
                      </Typography>
                    </Grid>
                    <Grid item md={12} sx={{width:"100%"}}>
                        <TextField
                            error={isBusinessNameTouched && !businessName && !isBusinessNameFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            onFocus={()=>{setIsBusinessNameFocused(true)}}
                            onBlur={()=>{setIsBusinessNameFocused(false)}}
                            type="text" 
                            value= {businessName}
                            onChange= {businessNameChangeHandler}                        
                            label = "Business Name"/>
                    </Grid>   
                    <Grid item md={6} sx={{width:"100%"}}>
                        <TextField
                            error={isContactNameTouched && !contactName && !isContactNameFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            onFocus={()=>{setIsContactNameFocused(true)}}
                            onBlur={()=>{setIsContactNameFocused(false)}}
                            type="text" 
                            value= {contactName}
                            onChange= {contactNameChangeHandler}                        
                            label = "Contact Name"/>
                    </Grid>
                    <Grid item md={6} sx={{width:"100%"}}>
                        <TextField
                            error={isEmailAddressTouched && !isValidEmailAddress(emailAddress) && !isEmailAddressFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            onFocus={()=>{setIsEmailAddressFocused(true)}}
                            onBlur={()=>{setIsEmailAddressFocused(false)}}
                            type="text" 
                            value= {emailAddress}
                            onChange= {emailAddressChangeHandler}                        
                            label = "Email Address"/> 
                    </Grid>
                </> 
                <>  
                    <Grid item md={12} sx={{width:"100%", marginTop: 2}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          ADD ADDRESS
                      </Typography>
                    </Grid>     
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isNewAddressTouched && !newAddress && !isNewAddressFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          onFocus={()=>{setIsNewAddressFocused(true)}}
                          onBlur={()=>{setIsNewAddressFocused(false)}}
                          type="text" 
                          value= {newAddress}
                          onChange= {newAddressChangeHandler}                        
                          label = "Address"/>
                    </Grid>     
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text" 
                          value= {newAddress2}
                          onChange= {newAddress2ChangeHandler}                        
                          label = "Address 2"/>
                    </Grid>
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isNewCityTouched && !newCity & !isNewCityFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsNewCityFocused(true)}}
                          onBlur={()=>{setIsNewCityFocused(false)}}                         
                          value= {newCity}
                          onChange= {newCityChangeHandler}                        
                          label = "City"/> 
                    </Grid>          
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isNewStateTouched && !newState & !isNewStateFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsNewStateFocused(true)}}
                          onBlur={()=>{setIsNewStateFocused(false)}}                         
                          value= {newState}
                          onChange= {newStateChangeHandler}                        
                          label = "State"/> 
                    </Grid>  
                    <Grid item md={4} sx={{width:"100%"}}>
                      <NumberFormat
                          sx={{width: "100%"}}
                          error={isNewZipcodeTouched && !newZipcode & !isNewZipcodeFocused}
                          required
                          label="Zipcode"
                          size="small"
                          type="text" 
                          value={newZipcode} 
                          format="#####" 
                          mask="_" 
                          customInput={TextField}
                          onFocus={()=>{setIsNewZipcodeFocused(true)}}
                          onBlur={()=>{setIsNewZipcodeFocused(false)}}   
                          onValueChange={newZipcodeChangeHandler}/>                       
                    </Grid>                                                                     
                </>        
                <PolicyChangeDisclaimerSection/>                                                                                                                                               
                <Grid item xs={12}>
                      <ReCAPTCHA
                          size="normal"
                          onChange={()=>{setIsRecaptchaVerified(true)}}
                          onExpired={()=>{setIsRecaptchaVerified(false)}}
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/>   
                </Grid>                                       
                <Grid item xs={12}>
                      <Button
                          disabled={!isValidFormData()}
                          variant="contained"
                          backgroundColor= "#0C709B" 
                          startIcon={<SendIcon />}
                          size="medium"
                          onClick={submitAddressChangeRequestHandler}
                          sx={{
                            borderRadius: '20px',
                            fontFamily: "Nunito",
                            fontWeight: "bolder",
                          }}                             
                          label = "Claim">  
                          Submit Request
                      </Button>   
                </Grid> 
            </Grid>
        </Grid>       
    </Grid>
  )
}




        

