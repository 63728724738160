import React from 'react'
import { useEffect } from 'react';
import { Grid, Typography, TextField, Button, Select, InputLabel, MenuItem, FormControl } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import { PolicyChangeDisclaimerSection } from './PolicyChangeDisclaimerSection';

const policyTypes = ["Home", "Flood", "Auto", "Boat", "Umbrella"]

export default function PersonalInsuranceCoverageChangeForm({insuranceType, selectedPolicyChange, requestPolicyChangeHandler}) {  
  useEffect(()=>{
    resetForm();
  },[insuranceType, selectedPolicyChange])

  const [insuredName, setInsuredName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [selectedPolicyType, setSelectedPolicyType] = useState("");
  const [specialCoverageRequest, setSpecialCoverageRequest] = useState("");

  const [isInsuredNameFocused, setIsInsuredNameFocused] = useState(false)
  const [isEmailAddressFocused, setIsEmailAddressFocused] = useState(false)
  const [isSpecialCoverageRequestFocused, setIsSpecialCoverageRequestFocused] = useState(false)

  const [isInsuredNameTouched, setIsInsuredNameTouched] = useState(false)
  const [isEmailAddressTouched, setIsEmailAddressTouched] = useState(false)
  const [isSpecialCoverageRequestTouched, setIsSpecialCoverageRequestTouched] = useState(false)

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)
  const isInsuredNameValid = insuredName !== null && insuredName !== undefined && insuredName !== "";
  const isEmailAddressValid = emailAddress !== null && emailAddress !== undefined && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress);
  const isSelectedPolicyTypeValid = selectedPolicyType !== null && selectedPolicyType !== undefined && selectedPolicyType !== "";
  const isSpecialCoverageRequestValid = specialCoverageRequest !== null && specialCoverageRequest !== undefined && specialCoverageRequest !== "";

  const insuredNameChangeHandler = (event) =>{
    !isInsuredNameTouched && setIsInsuredNameTouched(true)
    setInsuredName(event.target.value)
  }

  const emailAddressChangeHandler = (event) =>{
    !isEmailAddressTouched && setIsEmailAddressTouched(true)
    setEmailAddress(event.target.value.trim())
  }

  const handlePolicyTypeChange = (event) => {
    setSelectedPolicyType(event.target.value);
  }

  const specialCoverageRequestChangeHandler = (event) =>{
    !isSpecialCoverageRequestTouched && setIsSpecialCoverageRequestTouched(true)
    setSpecialCoverageRequest(event.target.value)
  }


  const submitCoverageChangeRequestHandler = async ()=>{
    let requestData = {
      accountType: insuranceType,
      category: 'CR',
      changeRequestType: selectedPolicyChange,      
      contactName: insuredName,
      email: emailAddress,
      accountName: insuredName,
      details : {
        policyType: selectedPolicyType,
        specialCoverageRequest: specialCoverageRequest
      }
    }
    await requestPolicyChangeHandler(requestData)
  }

  const isValidFormData = () =>{
    return isRecaptchaVerified && isInsuredNameValid && isEmailAddressValid && isSpecialCoverageRequestValid && isSelectedPolicyTypeValid
  }

  const resetForm = () =>{
    setInsuredName("")
    setEmailAddress("")
    setSelectedPolicyType("")
    setSpecialCoverageRequest("")

    setIsInsuredNameFocused("")
    setIsEmailAddressFocused("")
    setIsSpecialCoverageRequestFocused("")

    setIsInsuredNameTouched("")
    setIsEmailAddressTouched("")
    setIsSpecialCoverageRequestTouched("")

    setIsRecaptchaVerified(false)
  }

  return (
    <Grid container rowSpacing={4}>  
        <Grid item xs={12}>
            <Typography                                 
                variant='h4'
                fontFamily="Sofia Sans Condensed"
                sx={{
                    color:"#0C709B"
                }}>
                REQUEST FORM
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container rowSpacing={2} columnSpacing={2}>
                <>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          CLIENT DETAILS
                      </Typography>
                    </Grid>
                    <Grid item md={6} sx={{width:"100%"}}>
                    <TextField
                        error={isInsuredNameFocused && !isInsuredNameValid && !isInsuredNameFocused}
                        required
                        size="small"
                        fontFamily="Nunito"
                        sx={{width:"100%"}}
                        onFocus={()=>{setIsInsuredNameFocused(true)}}
                        onBlur={()=>{setIsInsuredNameFocused(false)}}
                        type="text" 
                        value= {insuredName}
                        onChange= {insuredNameChangeHandler}                        
                        label = "Insured Name"/>
                    </Grid>
                    <Grid item md={6} sx={{width:"100%"}}>
                      <TextField
                          error={isEmailAddressTouched && !isEmailAddressValid & !isEmailAddressFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsEmailAddressFocused(true)}}
                          onBlur={()=>{setIsEmailAddressFocused(false)}}                         
                          value= {emailAddress}
                          onChange= {emailAddressChangeHandler}                        
                          label = "Email"/> 
                    </Grid>
                </>
                <>
                    <Grid item md={12} sx={{width:"100%", marginTop: 2}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          POLICY TYPE
                      </Typography>
                    </Grid>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <FormControl size="small" sx={{width:"100%"}}>
                        <InputLabel>Policy Type</InputLabel>
                        <Select
                          sx={{width:"100%"}}
                          value={selectedPolicyType}
                          label="Policy Type"
                          size="small"
                          required
                          onChange={handlePolicyTypeChange}>
                                {
                                    policyTypes.map((policyType)=>{
                                        return (
                                        <MenuItem value={policyType}>{policyType}</MenuItem>
                                        )
                                    })
                                }
                        </Select>
                      </FormControl> 
                    </Grid>                                        
                </>  
                <Grid item md={12} sx={{width:"100%"}}>
                    <TextField
                        error={isSpecialCoverageRequestTouched && !isSpecialCoverageRequestValid & !isSpecialCoverageRequestFocused}
                        required
                        size="small"
                        fontFamily="Nunito"
                        sx={{width:"100%"}}
                        type="text"  
                        multiline  
                        minRows={3}
                        maxRows={3}
                        onFocus={()=>{setIsSpecialCoverageRequestFocused(true)}}
                        onBlur={()=>{setIsSpecialCoverageRequestFocused(false)}}                         
                        value= {specialCoverageRequest}
                        onChange= {specialCoverageRequestChangeHandler}                        
                        label = "Special Coverage Request"/> 
                </Grid>  
                <PolicyChangeDisclaimerSection/>                                                                                                                                  
                <Grid item xs={12}>
                      <ReCAPTCHA
                          size="normal"
                          onChange={()=>{setIsRecaptchaVerified(true)}}
                          onExpired={()=>{setIsRecaptchaVerified(false)}}
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/>   
                </Grid>                                       
                <Grid item xs={12}>
                      <Button
                          disabled={!isValidFormData()}
                          variant="contained"
                          backgroundColor= "#0C709B" 
                          startIcon={<SendIcon />}
                          size="medium"
                          onClick={submitCoverageChangeRequestHandler}
                          sx={{
                            borderRadius: '20px',
                            fontFamily: "Nunito",
                            fontWeight: "bolder",
                          }}            
                          label = "Claim">  
                          Submit Request
                      </Button>   
                </Grid> 
            </Grid>
        </Grid>       
    </Grid>
  )
}




        

