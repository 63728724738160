import React from 'react'
import { Box, Grid} from '@mui/material';
import ScheduleConsultationForm from './ScheduleConsultationForm';
import RequestConsultation from '../assets/images/RequestConsultation.jpg';

export default function RequestConsultationHeader({backgroundImage}) {
  return (
    <Box 
        component="div" 
        sx={{
            minHeight: '100vh'
        }}>
        <Grid container>
            <Grid item md={6} sm={12} xs={12} 
                sx={{
                    display:{
                        sm: "none",
                        xs: "none",
                        md: "block",
                        lg: "block"
                    }
                }}>
                <Box 
                    component="div" 
                    sx={{
                        backgroundImage: `url(${RequestConsultation})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        minHeight: '100vh',
                        overflowX:'hidden'
                    }}>
                </Box>
            </Grid> 
            <Grid item md={6} sm={12} xs={12}>
                <ScheduleConsultationForm/>
            </Grid>              
        </Grid>   
    </Box>               
  )
}




        