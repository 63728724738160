import React from 'react';
import { useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import { Seo } from '../components/Seo';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import BlogsPreview from '../components/BlogsPreview'
import ServicesSubPageHeader from '../components/ServicesSubPageHeader';
import DefaultInsuranceServiceSubpage from '../components/DefaultInsuranceServiceSubpage'
import IndustriesInsuranceServiceSubpage from '../components/IndustriesInsuranceServiceSubpage'
import BoatImage from '../assets/images/Boat.png'
import AutoImage from '../assets/images/Car-Insurance.png'
import LifeImage from '../assets/images/Life-Insurance.png'
import UmbrellaImage from '../assets/images/Umbrella.png'
import PersonalLiabilityImage from '../assets/images/Personal-Liability_insurance.png'
import LiquorLiabilityImage from '../assets/images/Liquor-Liability-.png'
import SpecialEventImage from '../assets/images/Special-Event_Insurance.png'
import ProfessionalServicesImage from '../assets/images/Professional-services.png'
import HealthcareInsuranceImage from '../assets/images/Healthcare_Insurance.png'
import BusinessHeader from '../assets/images/Business-Insurance-Header.png'
import PersonalHeader from '../assets/images/PersonalInsuranceHeader.png'
import HRHeader from '../assets/images/Human-Resources-Header.png'
import BusinessOwnerHeader from '../assets/images/BusinessOwners.png'
import MotorcycleHeader from '../assets/images/Motorcycle.png'
import CommercialPropertyImage from '../assets/images/Commercial-Property.png'
import ConstructionHeader from '../assets/images/Construction.png'
import RVMotorHomeHeader from '../assets/images/RVMotorHome.png'
import GolfCartHeader from '../assets/images/GolfCart.png'
import FloodHeader from '../assets/images/flood.png'
import GeneralLiabilityHeader from '../assets/images/GeneralLiability.png'
import WorkersCompensationHeader from '../assets/images/WorkersCompensation.png'
import ProfessionalLiabilityHeader from '../assets/images/ProfessionalLiability.png'
import MarineHeader from '../assets/images/Marine.png'
import RealEstateHeader from '../assets/images/RealEstate.png'
import JewleryInsuranceHeader from '../assets/images/Jewlery-Insurance.png'
import ManifactureHomeHeader from '../assets/images/ManifactureHome.png'
import RestaurantInsuranceHeader from '../assets/images/RestaurantInsurance.png'
import TransportationCommercialAutoHeader from '../assets/images/TransportationCommercialAuto.png'

function ServicesSubPage({type}) {
    const subPage = serviceSubPagesList.find(x => x.type == type)
    const title = subPage.title
    const headerImage = subPage.headerImage
    const subtitles = subPage.subtitles
    const subtitleVariant = subPage.subtitleVariant
    const childComponent = subPage.childComponent
    const bulletPoints = subPage.bulletPoints
    const bottomLine = subPage.bottomLine
    const quoteNowRedirect = subPage.quoteNowRedirect
    
    useEffect(()=>{
        document.title = title
        window.scrollTo(0, 0)
    },[])

    return (
        <Stack>      
            <Seo
                title={title}
                description={subtitles[0]}/>                                                          
            <section>                  
                <Navbar isNavBarTransparent={false}/>
                <CssBaseline/>
            </section> 
            <section>
                <Stack>
                    <ServicesSubPageHeader 
                        title={title} 
                        ChildComponent={childComponent}
                        subtitles={subtitles}
                        subtitleVariant={subtitleVariant}
                        bulletPoints={bulletPoints}
                        headerImage={headerImage}
                        bottomLine={bottomLine}
                        quoteNowRedirect={quoteNowRedirect}/>
                    <Box 
                        component="div" 
                        sx={{
                            backgroundColor: "#f5f5f5",
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            minHeight: '100vh',
                            overflowX:'hidden',
                            padding:"4% 10% 5% 10%"
                        }}>
                        <BlogsPreview
                            insuranceType={title}/>
                    </Box>  
                </Stack>
            </section>
        </Stack>
    )
}

const serviceSubPagesList = [
    {
        type: "HomeInsurance",
        title: "Home Insurance",
        headerImage: PersonalHeader,
        subtitles : [
            "Homeowners insurance is designed to protect your most precious asset ‐ your home. These package policies protect against economic loss to residences as well as household personal property. Homeowners policies also commonly provide protection for legal liabilities related to a homeowner and their household."
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "home-insurance-quote"
    },  
    {
        type: "AutoInsurance",
        title: "Auto Insurance",
        headerImage: AutoImage,
        subtitles : [
            "Auto insurance covers damage to your vehicle and protects you financially if you're liable for someone else's injuries or damages. Auto insurance can also pay for medical bills if you or your passengers are injured in an accident or if you're hit by an uninsured or under insured driver.",
            "Each state has its own car insurance coverage requirements, so it’s important to work with an agent to determine which coverage is the best fit for you."
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "auto-insurance-quote"
    },    
    {
        type: "FloodInsurance",
        title: "Flood Insurance",
        headerImage: FloodHeader,
        subtitles : [
            "Flood insurance protects residential and commercial property against damage from rising water. Flood scan be caused by a variety of factors, including heavy rainfall, storm surges, melting snow, and blocked drainage systems. Flood damage can be extensive and costly to repair, and many standard home insurance policies do not cover flood damage.",
            "Flood insurance is commonly purchased through the Federal Government, via the National Flood Insurance Program (NFIP), however there are many private market insurance carriers who also provide flood insurance protection."            
        ],
        subtitleVariant : "body2",
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "flood-insurance-quote"
    },       
    {
        type: "CondoInsurance",
        title: "Condo Insurance",
        headerImage: CommercialPropertyImage,
        subtitles : [
            "Condo insurance is designed to protect your most precious asset, your home and give you peace of mind. These package policies protect against economic loss to condo or townhouse residences as well as household personal property. Condo insurance policies also commonly provide protection for legal liabilities related to a condo owner and their household."
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "home-insurance-quote"    
    },
    {
        type: "BoatInsurance",
        title: "Boat Insurance",
        headerImage: BoatImage,
        subtitles : [
            "Boat, Watercraft, or Personal Marine insurance provides coverage for boats, yachts, and other types of watercraft. This insurance can help protect boat owners from financial losses due to accidents, theft, or other damages to their vessel.",
            "Boat and marine insurance policies can be customized to fit the needs of the individual boat owner, including the type of boat, its value, and how it is used. If you own a boat or other watercraft, it's important to consider purchasing boat and marine insurance to protect your investment and help minimize financial risks associated with accidents or other incidents on the water."    
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"        
    },
    {
        type: "UmbrellaInsurance",
        title: "Umbrella Insurance",
        headerImage: UmbrellaImage,
        subtitles : [
            "Umbrella insurance provides an additional layer of liability coverage beyond your primary insurance policies, such as homeowner's or auto insurance. Umbrella insurance can provide coverage for a variety of situations, including personal injuries, property damage, and lawsuits when damages exceed the limits of your primary insurance policies."            
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"        
    },
    {
        type: "LifeInsurance",
        title: "Life Insurance",
        headerImage: LifeImage,
        subtitles : [
            "Life insurance policies come in a variety of types, including term life insurance, whole life insurance, and universal life insurance. The type and amount of life insurance coverage you need depends on your individual circumstances and the financial needs of your beneficiaries.",
            "Life insurance can provide peace of mind and financial security for you and your loved ones. It's important to carefully consider your options and choose a policy that best fits your needs and budget."    
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"            
    },
    {
        type: "JewelryInsurance",
        title: "Jewelry Insurance",
        headerImage: JewleryInsuranceHeader,
        subtitles : [
            "Jewelry and valuables insurance provides coverage for high-value items such as jewelry, watches, fine art, antiques, and other collectibles. These items may not be covered under a standard homeowner's insurance policy, or the amount of coverage may be insufficient when compared to the value of these items.",
            "Jewelry and valuables insurance can be added as an endorsement to a homeowner's insurance policy, or it can be purchased as a standalone policy. The cost of the insurance will depend on the value of the insured items, as well as the deductible and coverage limits selected."
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"            
    },
    {
        type: "PersonalLiabilityInsurance",
        title: "Personal Liability Insurance",
        headerImage: PersonalLiabilityImage,
        subtitles : [
            "Personal liability insurance is designed to help protect your personal assets, such as your home and savings, in the event of a lawsuit. Personal liability insurance typically provides coverage for bodily injury or property damage that you or your family members cause to others, both on your property and off. It can also provide coverage for legal defense costs, regardless of whether the claim has merit or not.",
            "It's important to carefully consider your liability risks and the amount of coverage you need when purchasing personal liability insurance. Having adequate coverage can provide peace of mind and financial protection in the event of unexpected accidents or incidents."
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"            
    },
    {
        type: "RentalInsurance",
        title: "Rental Insurance",
        headerImage: PersonalHeader,
        subtitles : [
            "Rental property insurance, also known as landlord insurance, provides coverage for individuals who rent out their properties to tenants. This type of insurance typically provides coverage for the dwelling, and any personal property that is owned by the landlord. Rental property insurance may also provide liability coverage for the landlord as well as coverage for loss of rental income, which can help landlords recover lost rental revenue if the property becomes uninhabitable.",
            "Rental property insurance can be placed for property owners who rent their dwellings as a long-term lease, or for short-term rental units. Insurance carriers have different guidelines pertaining to long-term rental and short-term rental insurance, so it’s important to speak with an independent insurance agent to distinguish the difference and ensure that you have an insurance policy that fits your rental arrangement."    
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"            
    },
    {
        type: "MobileHomeInsurance",
        title: "Mobile & Manufactured Home Insurance",
        headerImage: ManifactureHomeHeader,
        subtitles : [
            "Mobile and manufactured home insurance provides coverage for homes that are constructed off-site and transported to their permanent location. Mobile and manufactured home insurance typically covers the home itself, as well as any personal property that is located inside the home, such as furniture and appliances. It may also provide liability coverage, which can help protect homeowners from financial damages if someone is injured on their property and files a lawsuit."    
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"            
    },
    {
        type: "RentersInsurance",
        title: "Renters Insurance",
        headerImage: PersonalHeader,
        subtitles : [
            "Renters insurance provides personal property coverage for individuals who rent a home or apartment. Renters insurance typically provides coverage for personal property, such as furniture, electronics, and clothing, if they are damaged or destroyed by a covered event, such as a fire, theft, or vandalism. Renters insurance may also provide liability coverage, which can help protect renters from financial damages if someone is injured on their property and files a lawsuit."
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"            
    },
    {
        type: "MotorHomeInsurance",
        title: "Motor Home Insurance",
        headerImage: RVMotorHomeHeader,
        subtitles : [
            "RV (Recreational Vehicle) and Motor Home Insurance provide coverage for your recreational vehicle, motor home, or camper van. These policies are designed to protect you against financial loss if your RV or motor home is involved in an accident, stolen, or damaged due to unforeseen events such as fire, storms, or natural disasters."    
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"            
    },
    {
        type: "MotorcycleInsurance",
        title: "Motorcycle Insurance",
        headerImage: MotorcycleHeader,
        subtitles : [
            "Motorcycle insurance covers damage to your motorcycle and protects you financially if you're liable for someone else's injuries or damages. Just like car insurance, motorcycle insurance policies typically include liability coverage, which covers the cost of damage or injury to others if the policyholder is found to be at fault for an accident. Additionally, motorcycle insurance may include coverage for damage to the motorcycle itself, as well as medical payment's coverage for injuries sustained in an accident."    
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"            
    },
    {
        type: "GolfCartInsurance",
        title: "Golf Cart Insurance",
        headerImage: GolfCartHeader,
        subtitles : [
            "Golf cart insurance provides coverage for golf carts and other Low Speed Vehicles. Golf cart insurance is similar to car insurance in that it can provide coverage for liability, collision, and comprehensive losses.",
            "It’s important to consider several factors when looking for golf cart insurance, including local laws, and state registration status. Many states specifically require auto insurance coverage if your golf cart is registered for road use. If your golf cart is only operated personal insurance can help  your private property, for personal use, then you may consider physical damage coverage to the cart from unforeseen damages."    
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"            
    }
    ,
    {
        type: "GeneralLiabilityInsurance",
        title: "General Liability Insurance",
        headerImage: GeneralLiabilityHeader,
        subtitles : [
            "General liability insurance provides protection against claims or lawsuits alleging bodily injury, property damage, or personal injury. This type of insurance can cover a wide range of scenarios, such as slip-and-fall accidents on a business property, damage caused by products or services, or advertising claims that are deemed false or misleading. General liability insurance can also help cover legal expenses, including court costs and settlements, up to the limits of the policy."    
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "business-insurance-quote"    
    },
    {
        type: "BusinessOwnersPolicyInsurance",
        title: "Business Owners Policy Insurance",
        headerImage: BusinessOwnerHeader,
        subtitles : [
            "A Business Owner's Policy (BOP) combines property insurance, liability insurance, and business interruption insurance into a single policy. Business Owners Policies are designed for small and medium-sized businesses to streamline coverage offerings for common business exposures. Packaging these lines of insurance coverage together under one policy generally reduces gaps and provides a comprehensive insurance solution for businesses."
        ],
        subtitleVariant : "body2",
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "business-insurance-quote"      
    },
    {
        type: "CommercialPropertyInsurance",
        title: "Commercial Property Insurance",
        headerImage: CommercialPropertyImage,
        subtitles : [
            "Commercial property insurance provides coverage for businesses and their physical assets, such as buildings, equipment, inventory, and furniture. Commercial property insurance can protect businesses from financial loss resulting from damage or destruction to their property caused by perils such as fire, theft, vandalism, or natural disasters.",
            "The cost of commercial property insurance can vary depending on several factors, such as the type of business, the size of the property, the location of the business, the value of the assets being insured, and the level of coverage chosen. It's important for businesses to consult with an independent insurance agent when considering their property insurance options."    
        ],
        childComponent: DefaultInsuranceServiceSubpage ,
        quoteNowRedirect: "business-insurance-quote"     
    },
    {
        type: "CommercialAutoInsurance",
        title: "Commercial Auto Insurance",
        headerImage: TransportationCommercialAutoHeader,
        subtitles : [
            "Commercial Auto Insurance, or Business Auto Insurance, provides coverage for vehicles used for business purposes. Businesses that utilize delivery trucks, company cars, or other vehicles used to transport employees or equipment, are required to purchase commercial auto insurance. Commercial auto insurance is different from personal auto insurance, which only covers personal use of a vehicle.",
            "Commercial auto insurance has a wide variety of coverage options and forms for coverage, so it’s important to consult with an independent insurance agent."
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "business-insurance-quote"      
    },    
    {
        type: "WorkersCompensationInsurance",
        title: "Workers Compensation Insurance",
        headerImage: WorkersCompensationHeader,
        subtitles : [
            "Workers’ Compensation insurance provides benefits such as medical expenses, rehabilitation costs, and lost wages for employees who are injured or become ill as a result of their work. Workers' compensation insurance is required by state law in most states in the United States, and the cost can vary depending on a number of factors, including the type of work being performed, the number of employees, and the history of workplace injuries.",
            "It’s also important to distinguish the difference between employer's liability exposures at the state level and the employer's liability exposures on a federal level. Workers Compensation is a statutory coverage, however employers in the marine industry (or other federally related industries) may also need additional coverage for USL&H or Jones Act exposures; specifically if employees are engaged in operations on or around navigable waterways, or on commercial vessels. It's important for employers to carefully evaluate their insurance needs with an independent insurance agent and ensure compliance with all state and federal laws related to workers' compensation insurance."
        ],
        subtitleVariant:"body2",
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "business-insurance-quote"      
    },
    {
        type: "LiquorLiabilityInsurance",
        title: "Liquor Liability Insurance",
        headerImage: LiquorLiabilityImage ,
        subtitles : [
            "Liquor liability insurance provides coverage for businesses that sell or serve alcoholic beverages, such as bars, restaurants, and liquor stores. Liquor liability insurance is designed to protect businesses from financial losses resulting from claims of bodily injury or property damage caused by an intoxicated person who was served alcohol by the business.",
            "The cost of liquor liability insurance can vary depending on a number of factors, including the type of business, the location of the business, the volume of alcohol sales, and the level of coverage chosen. It's important for businesses that serve or sell alcohol to carefully evaluate their insurance needs and to consult with an independent insurance agent regarding their liquor liability exposure."
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "business-insurance-quote"      
    },
    {
        type: "ProfessionalLiabilityInsurance",
        title: "Professional Liability Insurance",
        headerImage: ProfessionalLiabilityHeader ,
        subtitles : [
            "Professional liability insurance, also known as errors and omissions (E&O) insurance, provides coverage for professionals who provide advice or specialized services to clients. Professional liability insurance is designed to protect professionals from financial losses resulting from claims of negligence, errors, or omissions in the performance of their professional duties.",
            "Professional liability insurance can provide coverage for a wide range of professionals, including doctors, lawyers, accountants, consultants, and others. The policy may cover claims related to professional negligence, errors in judgment, or failure to provide services in accordance with industry standards."
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "business-insurance-quote"      
    },
    {
        type: "CyberLiabilityInsurance",
        title: "Cyber Liability Insurance",
        headerImage: BusinessHeader,
        subtitles : [
            "Cyber liability insurance provides coverage for businesses in the event of data breaches or other cyber-related incidents. Cyber liability insurance can provide coverage for a wide range of expenses, including legal fees, forensic investigations, notification costs, credit monitoring, and public relations efforts. The policy may also cover costs associated with business interruption or lost income resulting from a cyber-attack.",
            "The cost of cyber liability insurance can vary depending on a number of factors, including the size of the business, the type of industry, the amount of data collected and stored, and the level of coverage chosen. It's important for businesses to consult with an independent insurance agent regarding financial risks and coverage options associated with cyber-attacks."
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "business-insurance-quote"      
    },

    {
        type: "RestaurantInsurance",
        title: "Restaurant Insurance",
        headerImage: RestaurantInsuranceHeader,
        subtitles: [
            "As a restaurant owner, it's important to have insurance to protect yourself against the risks and liabilities associated with running a business. Here are some common types of insurance that a restaurant may need:"
        ],
        bulletPoints: [
            'General liability insurance',
            'Property insurance',
            'Workers compensation insurance',
            'Liquor liability insurance',
            'Business interruption insurance'
        ],
        bottomLine: "It's important to speak with an independent insurance agent to determine the specific insurance needs of your restaurant or bar.",
        childComponent: IndustriesInsuranceServiceSubpage,
        quoteNowRedirect: "business-insurance-quote"      
    },
    {
        type: "ConstructionInsurance",
        title: "Construction Insurance",
        headerImage: ConstructionHeader ,
        subtitles: [
            'Construction companies typically need a variety of insurance policies to protect their businesses, employees, and assets. Some of the most common types of insurance that construction companies may need to include:'
        ],
        bulletPoints: [
            'General liability insurance',
            'Workers compensation insurance',
            'Property insurance',
            'Builders Risk Insurance',
            'Professional Liability Insurance',
            'Commercial Auto Insurance'
        ],
        bottomLine: "The specific insurance needs of a construction company may vary depending on factors such as the size of the company, the types of projects undertaken, and the state and local regulations. It's important to consult with an experienced independent insurance agent to discuss your company's risks and determine the appropriate coverage.",
        childComponent: IndustriesInsuranceServiceSubpage,
        quoteNowRedirect: "business-insurance-quote"      
    },
    {
        type: "MarineInsurance",
        title: "Marine Insurance",
        headerImage: MarineHeader,
        subtitles: [
            'Marine companies typically need a variety of insurance policies to protect their businesses, employees, and assets. Some of the most common types of insurance that marine companies may need to include:'
        ],
        bulletPoints: [
            'Marine General liability insurance',
            'Protection and Indemnity (P&I) Insurance',
            'Marine Hull Insurance',
            'Marine Cargo Insurance: ',
            'Workers Compensation / USL&H / Jones Act',
            'Professional Liability Insurance',
        ],
        bottomLine: "The specific insurance needs of a marine company vary widely depending on the specific marine industry, the size of the company, the types of vessels operated, and the geographic location. It's important to consult with an experienced independent insurance agent to discuss your company's risks and determine the appropriate coverage.",
        childComponent: IndustriesInsuranceServiceSubpage,
        quoteNowRedirect: "business-insurance-quote"      
    },
    {
        type: "RealEstateInsurance",
        title: "Real Estate Insurance",
        headerImage: RealEstateHeader,
        subtitles: [
            'Real estate companies typically need a variety of insurance policies to protect their businesses, employees, and assets. Some of the most common types of insurance that real estate companies may need include:'
        ],
        bulletPoints: [
            'Property Insurance',
            'General Liability Insurance',
            'Errors and Omissions Insurance',
            'Cyber Liability Insurance',
            'Workers Compensation Insurance',
            'Directors and Officers (D&O) Insurance'
        ],
        bottomLine: "The specific insurance needs of a real estate company may vary depending on factors such as the size of the company, the types of properties managed, and the geographic location. It's important to consult with an experienced independent insurance agent to discuss your company's risks and determine the appropriate coverage.",
        childComponent: IndustriesInsuranceServiceSubpage,
        quoteNowRedirect: "business-insurance-quote"      
    },
    {
        type: "HealthcareInsurance",
        title: "Healthcare Insurance",
        headerImage: HealthcareInsuranceImage ,
        subtitles: [
            'Healthcare companies typically need a variety of insurance policies to protect their businesses, employees, and assets. Some of the most common types of insurance that healthcare companies may need to include:'
        ],
        bulletPoints: [
            'Medical Malpractice Insurance (Professional Liability)',
            'General Liability Insurance',
            'Cyber Liability Insurance',
            'Workers Compensation Insurance',
            'Property Insurance',
            'Business Interruption Insurance'
        ],
        bottomLine: "The specific insurance needs of a healthcare company may vary depending on factors such as the size of the company, the types of healthcare services provided, and the geographic location. It's important to consult with an experienced independent insurance agent to discuss your company's risks and determine the appropriate coverage.",
        childComponent: IndustriesInsuranceServiceSubpage,
        quoteNowRedirect: "business-insurance-quote"      
    },
    {
        type: "TransporationInsurance",
        title: "Transporation Insurance",
        headerImage: TransportationCommercialAutoHeader,
        subtitles: [
            'Transportation companies typically need a variety of insurance policies to protect their businesses, employees, and assets. Some of the most common types of insurance that transportation companies may need include:'
        ],
        bulletPoints: [
            'Commercial Auto Insurance',
            'Cargo Insurance',
            'General Liability Insurance',
            'Workers Compensation Insurance',
            'Umbrella Liability Insurance',
            'Property Insurance'
        ],
        bottomLine: "The specific insurance needs of a transportation company may vary depending on factors such as the size of the company, the types of vehicles and cargo transported, and the geographic location. It's important to consult with an experienced independent insurance agent to discuss your company's risks and determine the appropriate coverage.",
        childComponent: IndustriesInsuranceServiceSubpage,
        quoteNowRedirect: "business-insurance-quote"      
    },
    {
        type: "ServiceCompaniesInsurance",
        title: "Service Companies Insurance",
        headerImage: ProfessionalServicesImage,
        subtitles: [
            'Professional services companies typically need a range of insurance policies to protect against different types of risks. Here are some of the most common types of insurance that professional services companies may need:'
        ],
        bulletPoints: [
            'Professional Liability Insurance',
            'General Liability Insurance',
            'Cyber Liability Insurance',
            'Property Insurance',
            'Business Interruption Insurance',
            'Workers Compensation Insurance',
        ],
        bottomLine: "The specific types and amount of insurance needed will depend on the size and nature of the professional services company, as well as the level of risk associated with its operations. It's important to consult with an experienced independent insurance agent to discuss your company's risks and determine the appropriate coverage.",
        childComponent: IndustriesInsuranceServiceSubpage,
        quoteNowRedirect: "business-insurance-quote"      
    }
    ,
    {
        type: "SpecialEventInsurance",
        title: "Special Event Insurance",
        headerImage: SpecialEventImage ,
        subtitles: [
            'Special events, such as weddings, concerts, festivals, and trade shows, typically require insurance coverage to protect against unexpected incidents and liabilities. Here are some of the most common types of insurance that special events may need:'
        ],
        bulletPoints: [
            'Event Liability Insurance',
            'Event Cancellation Insurance',
            'Event Property Insurance',
            'Liquor Liability Insurance',
            'Event Participant Insurance',
        ],
        bottomLine: "The specific types and amount of insurance needed will depend on the size and nature of the event, as well as the level of risk associated with its operations. It's important to consult with an experienced independent insurance agent to discuss specific risks and determine the appropriate coverage.",
        childComponent: IndustriesInsuranceServiceSubpage,
        quoteNowRedirect: "business-insurance-quote"      
    },
    {
        type: "HealthInsurance",
        title: "Health Insurance",
        headerImage: HRHeader,
        subtitles : [
            "Group Health Insurance can provide your workforce with coverage for a range of medical services, including doctor visits, hospital stays, prescription drugs, and some preventative care services.",
            "Group insurance offerings can be a valuable employee benefit, helping to attract and retain talented workers. It can also provide employees with access to important healthcare services, which can lead to improved health outcomes and producdivity.",
            "If you're considering implementing a group insurance plan for your company, it's important to carefully review your opƟons with an independent benefits consultant."
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"                
    },
    {
        type: "DentalInsurance",
        title: "Dental Insurance ",
        headerImage: HRHeader,
        subtitles : [
            "Group Dental Insurance can provide your workforce with coverage for a range of dental services, including routine exams, cleanings, X-rays, fillings, and other common procedures.",
            "Group insurance offerings can be a valuable employee benefit, helping to attract and retain talented workers. It can also provide employees with access to important healthcare services, which can lead to improved health outcomes and producdivity.",
            "If you're considering implementing a group insurance plan for your company, it's important to carefully review your opƟons with an independent benefits consultant."
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"                
    },
    {
        type: "VisionInsurance",
        title: "Vision Insurance",
        headerImage: HRHeader,
        subtitles : [
            "Group Vision Insurance can provide your workforce with coverage for routine eye exams, prescription eyewear, and other vision-related expenses.",
            "Group insurance offerings can be a valuable employee benefit, helping to attract and retain talented workers. It can also provide employees with access to important healthcare services, which can lead to improved health outcomes and productivity.",
            "If you're considering implementing a group insurance plan for your company, it's important to carefully review your options with an independent benefits consultant."    
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"                
    },
    {
        type: "SupplementalInsurance",
        title: "Supplemental Insurance",
        headerImage: HRHeader,
        subtitles : [
            "Group Supplemental Insurance benefits are designed to help fill the gaps in traditional health insurance coverage and provide additional financial protection against unexpected medical expenses.",
            "Supplemental insurance can include a range of options, such as dental and vision insurance, disability insurance, life insurance, accident insurance, critical illness insurance, and hospital indemnity insurance. These benefits may be paid for entirely by the employer or organization, or they may be partially or fully funded by employees through payroll deductions.",
            "Group supplemental benefits can be a valuable employee benefit, helping to attract and retain talented workers. They can also provide employees with access to important services and financial protection, which can lead to improved overall well-being."
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"                
    },
    {
        type: "RetirementBenefitPlans",
        title: "Retirement Benefit Plans",
        headerImage: HRHeader,
        subtitles : [
            "Retirement Benefit Plans are designed to help employees save and invest for their retirement and can include a range of options, such as employer-sponsored 401(k) plans, pension plans, and profit-sharing plans.",
            "Group retirement benefits can be a valuable tool to attract and retain talented employees, as well as help the workforce prepare for retirement. Employers may also be able to take advantage of tax benefits by offering retirement plans.",
            "If you're considering implementing employee benefits for your company, it's important to carefully review your options with an independent benefits consultant."
        ],
        childComponent: DefaultInsuranceServiceSubpage,
        quoteNowRedirect: "QuoteNow"                
    }
]

export default ServicesSubPage