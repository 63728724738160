import React from 'react';
import { useState, useEffect } from 'react';
import { Stack, Box, Typography, Grid, TextField, Button } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import GetInTouch from '../components/GetInTouchSection';
import ContactCustomerServiceSection from '../components/ContactCustomerServiceSection';
import ClientAccessNavBar from '../components/ClientAccessNavBar';
import { Seo } from '../components/Seo';

function ContactCustomerService() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>      
      <Seo
          title="Contact Customer Service"
          description="Reach Out to Us - Contact Our Team for Inquiries, Support, or Assistance. We're Here to Help! Get in Touch Today!"/>                                                          
      <section>           
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section> 
      <section>
        <ClientAccessNavBar selected="ContactCustomerService"/>
        <CssBaseline/>
      </section> 
      <section id="Contact Us">  
        <Stack>
            <Box 
              component="div" 
              sx={{
                  minHeight: '100vh'
              }}>
              <Grid container>
                <Grid item md={6} sm={12} xs={12}>
                  <ContactCustomerServiceSection/>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <GetInTouch/>
                </Grid>             
              </Grid>    
          </Box>
        </Stack>
      </section>
    </Stack>
  )
}

export default ContactCustomerService