import * as React from "react";
import { Stack, Typography, Box, Grid, Snackbar, Alert, Tabs, Tab, Link } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ClientAccessTabs from './ClientAccessTabs';
import ReportPersonalClaimToInsurance from './ReportPersonalClaimToInsurance';
import ReportBusinessClaimToInsurance from './ReportBusinessClaimToInsurance';
import InsurancePoliciesTable from "./InsurancePoliciesTable";
import { getPersonalInsurancePolicies, getBusinessInsurancePolicies } from "../apiClient/insurancePolicies";
import ContactOfficeToReportClaim from './ContactOfficeToReportClaim'

const ReportClaimsSubTabs = [
  {
    title: "Insurance Policies", index: 0
  },
  {
    title: "Associated Locations", index: 1
  }
]

const ReportClaimTabs = [
  {
    title: "Personal", index: 0
  },
  {
    title: "Business", index: 1
  }
]
function ReportClaimToInsurance() {

  const [insurancePolicies, setInsurancePolicies] = React.useState([]);
  const [selectedTabValue, setSelectedTabValue] = React.useState(0);
  const [selectedSubTabValue, setSelectedSubTabValue] = React.useState(0);
  const [isBackdropVisible, setIsBackdropVisible] = React.useState(false);
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertMessageSeverity, setAlertMessageSeverity] = React.useState("info");
  const [showNoRecordFoundSection, setShowNoRecordFoundSection] = React.useState(false);

  const setBackdropVisibilityValue = (value) => {
    setIsBackdropVisible(value);
  };

  const onTabValueChanges = (event, newValue) => {
    setSelectedTabValue(newValue);
    setInsurancePolicies([])
  };
  const onSubTabValueChanges = (event, newValue) => {
    setSelectedSubTabValue(newValue);
  };
  const getPersonalPoliciesHandler = async (clientLastName, email, zipCode)=>{
    try{
      setShowNoRecordFoundSection(false)
      setBackdropVisibilityValue(true)
      const requestData = {
        lastName: clientLastName,
        email: email,
        postalCode: zipCode,
      }
      const response =  await getPersonalInsurancePolicies(requestData);
      setInsurancePolicies(response)
      setBackdropVisibilityValue(false)
      setAlertMessageAndVisibility(response.length > 0 ? response.length + " Personal Policies Fetched." : "No policy found.","success", true)
    }
    catch(error){
      setInsurancePolicies([])
      if(error?.response?.status == 404)
      {
        setShowNoRecordFoundSection(true)
        setAlertMessageAndVisibility("Record not Found.", "error", true)
      }
      else{
        setAlertMessageAndVisibility("Error Fetching Personal Policies.", "error", true)
      }                                       
      setBackdropVisibilityValue(false)
    }
  }
  
  const getBusinessPoliciesHandler = async (feinNumber, phone, zipCode)=>{
    try{
      setShowNoRecordFoundSection(false)
      setBackdropVisibilityValue(true)
      const requestData = {
        fein: feinNumber,
        phone: phone,
        postalCode: zipCode
      };
      const response = await getBusinessInsurancePolicies(requestData);
      setInsurancePolicies(response)
      setBackdropVisibilityValue(false)
      setAlertMessageAndVisibility(response.length > 0 ? response.length + " Business Policies Fetched." : "No policy found.","success", true)    
    }
    catch(error){
      setInsurancePolicies([])
      if(error?.response?.status == 404)
      {
        setShowNoRecordFoundSection(true)
        setAlertMessageAndVisibility("Record not Found.", "error", true)
      }
      else{
        setAlertMessageAndVisibility("Error Fetching Business Policies.", "error", true)
      }  
      setBackdropVisibilityValue(false)
    }    
  }

  const setAlertMessageAndVisibility = (message, severity, isVisible)=>{
    setAlertMessage(message)
    setAlertMessageSeverity(severity)
    setIsAlertVisible(isVisible)
    setTimeout(()=>{
      setIsAlertVisible(false)
    },2000)
  }
  return (
    <Stack
        spacing={2}>     
        <Typography 
          variant='h2'
          fontFamily="Sofia Sans Condensed"
          sx={{
            color:"white",
          }}>
          REPORT A CLAIM 
        </Typography>
        <Typography 
          variant='h5'
          fontFamily="Sofia Sans Condensed"
          sx={{
            color:"white",
          }}>
          DIRECT TO THE INSURANCE CARRIER
        </Typography>     
        <Typography 
          variant='body1'
          fontFamily="Nunito"
          sx={{
            color:"white",
          }}>
          The most efficient method of reporting a claim is direct to the carrier. By completing the form
          below you will be prompted with the direct carrier claim contact for 24 hour claim reporting. Our office
          will also be notified so that we can monitor the status of your claim and provide assistance during the
          process.           
        </Typography>
        <br/>
        <br/>
        <Box sx={{ width: "100%" }}>
          <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isBackdropVisible}
              onClick={()=>{setBackdropVisibilityValue(false)}}>
              <CircularProgress color="inherit" />
          </Backdrop>

          <Snackbar 
            sx={{
              marginTop: "20vh"
            }}
            anchorOrigin={{ vertical:"top", horizontal: "center" }} 
            open={isAlertVisible} 
            onClose={()=>{isAlertVisible(false)}}>
            <Alert onClose={()=>{isAlertVisible(false)}} severity={alertMessageSeverity} sx={{ width: '100%' }}>
              {alertMessage}
            </Alert>
          </Snackbar>          
          <Box sx={{ backgroundColor :  "#D0D9DD",  borderRadius: "10px" }}>
              <ClientAccessTabs 
                tabs={ReportClaimTabs} 
                selectedTabValue={selectedTabValue}
                onTabChange = {onTabValueChanges}/>

                <Box 
                  component="div" 
                  sx={{
                    backgroundColor: "#f5f5f5",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    overflowX:'hidden',
                    padding: {
                      xs: "13% 10% 5% 10%",
                      sm: "8% 10% 5% 10%",
                      md: "5% 5% 5% 5%"
                    }
                  }}>
                  <Grid container rowSpacing={5} columnSpacing={15}>
                      <Grid item xs={12}>
                        {
                            selectedTabValue === 1 &&
                            <Typography 
                                variant='h4'
                                fontFamily="Sofia Sans Condensed"
                                sx={{
                                  color:"#0C709B"
                                }}>
                                BUSINESS INSURANCE POLICIES
                            </Typography>
                        }                        
                        {
                            selectedTabValue === 0 &&
                            <Typography                                 
                                variant='h4'
                                fontFamily="Sofia Sans Condensed"
                                sx={{
                                  color:"#0C709B"
                                }}>
                                PERSONAL INSURANCE POLICIES
                            </Typography>
                        }
                      </Grid>
                      <Grid item md={5} sm={12} xs={12}>
                        { selectedTabValue === 0 && <ReportPersonalClaimToInsurance getPersonalPoliciesHandler={getPersonalPoliciesHandler}/> }
                        { selectedTabValue === 1 && <ReportBusinessClaimToInsurance getBusinessPoliciesHandler={getBusinessPoliciesHandler}/> }  
                      </Grid>
                      <Grid item md={7} sm={12} xs={12}>
                        { 
                          insurancePolicies.length > 0  && 
                          <Tabs
                              sx={{
                                marginBottom: "4%",
                                marginTop: "-15px"
                              }}
                              value={selectedSubTabValue}
                              onChange={onSubTabValueChanges}
                              variant="scrollable"
                              scrollButtons={false}>
                              {
                                ReportClaimsSubTabs
                                .filter((subTab)=>{
                                    return (subTab.index === 0  && insurancePolicies.length > 0)
                                })
                                .map((subTab)=>{
                                    return <Tab label={subTab.title} />
                                })
                              }                                                           
                          </Tabs>
                        }
                        { insurancePolicies.length > 0  && selectedSubTabValue === 0 && <InsurancePoliciesTable insurancePolicies={insurancePolicies}/> } 
                        { 
                          showNoRecordFoundSection &&
                          <Typography 
                                variant='body1'
                                fontFamily="Nunito"
                                fontWeight="bold"
                                sx={{
                                    color:"#0C709B"
                                }}>
                              Thank you for submitting your request. In order to better assist, please contact our office directly.
                              Please contact our office directly at
                              <Link href="mailto:service@modernshoreins.com?subject=Claim Notice"
                                  sx={{
                                      paddingLeft: "1%",
                                      paddingRight: "1%",
                                      color:'#0C709B',
                                      textDecoration: 'none',
                                      '&:hover': {
                                          color: '#5c9ab5',
                                      }
                                  }}>
                              service@modernshoreins.com
                              </Link> 
                          </Typography>                           
                        }                                   
                      </Grid>                      
                  </Grid>
                </Box>                               
          </Box>
        </Box>   
    </Stack>
  )
}

export default ReportClaimToInsurance