import React from 'react';
import { useState, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import WelcomeNavbar from '../components/WelcomeNavbar';
import WelcomeHeader from '../components/WelcomeHeader';
import WelcomeHeaderImage from '../assets/images/WelcomeHeader.jpg';
import { Seo } from '../components/Seo';

function Welcome() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(true)

  useEffect(()=>{
    window.addEventListener('scroll', handleScroll);
    return(()=>{
      window.removeEventListener('scroll', handleScroll);
    })
  },[])
    
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  const handleScroll = ()=>{
    setIsNavBarTransparent(window.scrollY === 0)
  }

  return (
    <Stack>      
      <Seo
          title="Welcome to Modern Shore"
          description="Life is better when you have an advisor on your side. Our dedicated team strives to provide peace of mind through responsive service and quality insurance guidance"/>                                                          
      <section> 
        <WelcomeNavbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section>  
      <section>
        <WelcomeHeader backgroundImage={WelcomeHeaderImage}/>
      </section>
    </Stack>
  )
}

export default Welcome