import React from 'react'
import { Box, Grid, Typography, TextField, Button, Snackbar, Alert } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import { isValidEmailAddress } from '../utils/utilityFunctions';
import NumberFormat from 'react-number-format';
import loader from '../assets/images/Loader.gif'
import { createLeadInSalesForce } from "../apiClient/insurancePolicies";


export default function ContactUsSection() {
  const [ isBackdropVisible, setIsBackdropVisible] = React.useState(false);
  const [ isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [ alertMessage, setAlertMessage] = React.useState("");
  const [ alertMessageSeverity, setAlertMessageSeverity] = React.useState("info");

  const [name, setName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [description, setDescription] = useState("")
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)

  const [isNameTouched, setIsNameTouched] = useState(false)
  const [isNameFocused, setIsNameFocused] = useState(false)
  
  const [isLastNameTouched, setIsLastNameTouched] = useState(false)
  const [isLastNameFocused, setIsLastNameFocused] = useState(false)

  const [isEmailTouched, setIsEmailTouched] = useState(false)
  const [isEmailFocused, setIsEmailFocused] = useState(false)

  const [isPhoneTouched, setIsPhoneTouched] = useState(false)
  const [isPhoneFocused, setIsPhoneFocused] = useState(false)

  const [isDescriptionTouched, setIsDescriptionTouched] = useState(false)
  const [isDescriptionFocused, setIsDescriptionFocused] = useState(false)

  const nameChangeHandler = (event) =>{
    !isNameTouched && setIsNameTouched(true)
    setName(event.target.value)
  }

  const lastNameChangeHandler = (event) =>{
    !isLastNameTouched && setIsLastNameTouched(true)
    setLastName(event.target.value)
  }

  const emailChangeHandler = (event) =>{
    !isEmailTouched && setIsEmailTouched(true)
    setEmail(event.target.value.trim())
  }

  const descriptionChangeHandler = (event) =>{
    !isDescriptionTouched && setIsDescriptionTouched(true)
    setDescription(event.target.value)
  }

  const phoneChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    if(value != phone)
    {
      !isPhoneTouched && setIsPhoneTouched(true)
      setPhone(value)
    }
  }

  const setBackdropVisibilityValue = (value) => {
    setIsBackdropVisible(value);
  };

  const setAlertMessageAndVisibility = (message, severity, isVisible)=>{
    setAlertMessage(message)
    setAlertMessageSeverity(severity)
    setIsAlertVisible(isVisible)
    setTimeout(()=>{
      setIsAlertVisible(false)
    },5000)
  }

  const onSubmitForm = async ()=>{
    try{
      setBackdropVisibilityValue(true)

      const leadVM = {
        "firstName": name,
        "lastName": lastName,
        "email": email,
        "phone": phone,
        "description": description,
        "pageSource": "CONTACT US"
      }
      await createLeadInSalesForce(leadVM)

      resetForm()      
      setBackdropVisibilityValue(false)
      setAlertMessageAndVisibility( "Request Submitted","success", true)}
    catch(error){
      setAlertMessageAndVisibility("Request Submission Failed", "error", true)
      setBackdropVisibilityValue(false)
    }
  }

  const resetForm = () =>{
    setName("")
    setLastName("")
    setEmail("")
    setPhone("")
    setDescription("")
    setIsNameTouched(false)
    setIsNameFocused(false)
    setIsLastNameTouched(false)
    setIsLastNameFocused(false)
    setIsEmailTouched(false)
    setIsEmailFocused(false)
    setIsPhoneTouched(false)
    setIsPhoneFocused(false)
    setIsDescriptionTouched(false)
    setIsDescriptionFocused(false)
  }

  const isValidPhone = phone !== null && phone !== undefined && phone.toString().length === 10;
  const isValidData = name && lastName && isValidEmailAddress(email) && isRecaptchaVerified && isValidPhone && description

  return (
      <Box 
        component="div" 
        sx={{
          backgroundColor: "#f5f5f5",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          minHeight: '110vh',
          overflowX:'hidden',
          padding: {
            xs: "50% 10% 10% 10%",
            sm: "30% 10% 2% 10%",
            md: "26% 10% 2% 10%"
          }
        }}>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isBackdropVisible}
            onClick={()=>{setBackdropVisibilityValue(false)}}>
            <Box
              component="img"
              sx={{
                height: '80px'
              }} 
              alt="loading..."               
              src={loader}/>
        </Backdrop>
        <Snackbar 
          sx={{
            marginTop: "20vh"
          }}
          anchorOrigin={{ vertical:"top", horizontal: "center" }} 
          open={isAlertVisible} 
          onClose={()=>{isAlertVisible(false)}}>
          <Alert onClose={()=>{isAlertVisible(false)}} severity={alertMessageSeverity} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>            
        <Typography 
          variant='h3'
          fontFamily="Sofia Sans Condensed"
          sx={{
            color:"#0C709B",
          }}>
          CONTACT US
        </Typography>
        <br/>
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            required
                            error={isNameTouched && !name && !isNameFocused}
                            fontFamily="Nunito"
                            size='small'
                            fullWidth
                            type="text"
                            onFocus={()=>{setIsNameFocused(true)}}
                            onBlur={()=>{setIsNameFocused(false)}}
                            onChange= {nameChangeHandler}    
                            value= {name}
                            label = "First Name"/>    
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            required
                            error={isLastNameTouched && !lastName && !isLastNameFocused}
                            fontFamily="Nunito"
                            size='small'
                            fullWidth
                            type="text"
                            onFocus={()=>{setIsLastNameFocused(true)}}
                            onBlur={()=>{setIsLastNameFocused(false)}}
                            onChange= {lastNameChangeHandler}    
                            value= {lastName}
                            label = "Last Name"/>    
                    </Grid>
                </Grid>
            </Grid>   
            <Grid item xs={12}>
                <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField 
                            required
                            error={isEmailTouched && !isValidEmailAddress(email) && !isEmailFocused}
                            fontFamily="Nunito"
                            fullWidth
                            size='small'
                            value= {email}
                            onFocus={()=>{setIsEmailFocused(true)}}
                            onBlur={()=>{setIsEmailFocused(false)}}
                            type="text" 
                            onChange= {emailChangeHandler}    
                            label = "Email"/>  
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <NumberFormat
                          sx={{
                              width: "100%"
                          }}
                          required
                          error= {isPhoneTouched && !isValidPhone && !isPhoneFocused}
                          label="Phone"
                          size="small"
                          type="text" 
                          value={phone} 
                          format="+1 (###) ###-####" 
                          mask="_" 
                          customInput={TextField}
                          onFocus={()=>{setIsPhoneFocused(true)}}
                          onBlur={()=>{setIsPhoneFocused(false)}}
                          onValueChange={phoneChangeHandler}/>                       
                    </Grid>                    
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    error={isDescriptionTouched && !description && !isDescriptionFocused}
                    multiline
                    fullWidth
                    fontFamily="Nunito"
                    minRows={3}
                    value= {description}
                    onFocus={()=>{setIsDescriptionFocused(true)}}
                    onBlur={()=>{setIsDescriptionFocused(false)}}
                    onChange= {descriptionChangeHandler}    
                    label = "What are your insurance needs?"/>   
            </Grid>
            <Grid item xs={12}>
                <ReCAPTCHA
                    size="normal"
                    onChange={()=>{setIsRecaptchaVerified(true)}}
                    onExpired={()=>{setIsRecaptchaVerified(false)}}
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/> 
            </Grid>                                                          
            <Grid item xs={12} >
                <Button
                    variant="contained"
                    backgroundcolor= "#0C709B"  
                    onClick={onSubmitForm}
                    startIcon={<SendIcon />}
                    disabled = {!isValidData}
                    size="large"
                    sx={{borderRadius: '20px'}}     
                    label = "Send">  
                    Send
                </Button>   
            </Grid>
        </Grid>        
      </Box>               
  )
}
