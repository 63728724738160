import React from 'react'
import { Box, Typography, Stack, Grid, Container } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { HashLink } from 'react-router-hash-link';
import businessInsuranceImage from '../assets/images/business-insurance.png'
import personalInsuranceImage from '../assets/images/personal-insurance.png'
import hrAndbenifitsImage from '../assets/images/hrAndbenifits.png'
import LandingPageBackground from '../assets/images/LandingPageBackgroundImage.jpg'
import Fade from 'react-reveal/Fade';
import { useNavigate} from 'react-router-dom';


function TitleSection() {
  const navigate = useNavigate()

  const servicesDesktop = [
    { title: 'Personal Insurance', clickHandler: ()=>{navigate('/PersonalInsurance')}, imageSrc: personalInsuranceImage , borderRadius: '20px 0px 0px 20px'},
    { title: 'Business Insurance', clickHandler: ()=>{navigate('/BusinessInsurance')}, imageSrc: businessInsuranceImage , borderRadius: '0px 0px 0px 0px'},
    { title: 'Hr & Benefits',  clickHandler: ()=>{navigate('/HR&Benefits')}, imageSrc: hrAndbenifitsImage , borderRadius: '0px 20px 20px 0px'},
  
  ]
  return (

    <Box 
      component="div" 
      sx={{
        backgroundImage: `url(${LandingPageBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        overflowX:'hidden'
      }}>
      <Stack
        align="center"
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{
          minHeight: "100vh",
          backgroundColor: 'rgba(0,0,0, 0.2)',     
          paddingTop: {
            lg: '15vh',
            sm: '15vh',
            xs: '15vh'
          },
        }}>
        <Fade bottom duration={1200}>
          <Typography 
            variant='h2'
            fontFamily="Sofia Sans Condensed"            
            sx={{
              color:"white"
            }}>
            MODERN SHORE INSURANCE
          </Typography>
        </Fade>
        <Grid 
          direction="column"
          alignItems="center"
          justifyContent="center"
          container>
          <Grid item md={12}>
            <ButtonGroup
                size="large" 
                aria-label="large button group" 
                sx={{          
                  margin: "1%",   
                  display: { xs: 'none', md: 'flex', lg: 'flex' },
              }}>
              <Grid container>
                {
                  servicesDesktop.map((service, index)=>{
                    return (
                      <Grid key={index} item md={4}>
                        <Button variant="text" 
                          onClick={service.clickHandler}
                          sx={{
                              color:"#0C709B", 
                              backgroundColor:"white",
                              padding: "10% 10%",
                              borderRadius: service.borderRadius,                  
                              '&:hover': {
                                color:"#0C709B", 
                                backgroundColor:"#f5f5f5",                    
                              }
                          }}>
                          <Grid container>
                              <Grid item md={4}>
                                <img alt={service.title} src={service.imageSrc} height="40px" />  
                              </Grid>
                              <Grid item md={8}>
                                <Typography           
                                  fontFamily="Nunito"  
                                  fontWeight="bold">
                                  {service.title}
                                </Typography>
                              </Grid>                        
                          </Grid>
                        </Button>
                      </Grid>
                    );
                  })
                }                              
              </Grid>              
            </ButtonGroup>
            <ButtonGroup
                orientation='vertical'
                size="large" 
                aria-label="large button group" 
                sx={{
                  borderRadius:"100px",
                  margin: "1% 4%",
                  padding: "4% 2%",
                  display: { xs: 'flex', md: 'none', lg: 'none' },
              }}>
              <Button variant="text"
                onClick={()=>{navigate('/PersonalInsurance')}}  
                sx={{
                    color:"#0C709B", 
                    backgroundColor:"white",
                    padding: "10% 7%",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",                    
                    '&:hover': {
                      backgroundColor: "white"
                    }
                }}>
                <Container sx={{width:"30%"}}>
                  <img alt="Personal Insurance" src={businessInsuranceImage} height="30px"/>  
                </Container>
                <Typography 
                    fontFamily="Nunito"  
                    fontWeight="bold"
                    sx={{width:"70%"}} >
                    Personal Insurance
                </Typography>
              </Button>
              <Button variant="text" 
                onClick={()=>{navigate('/BusinessInsurance')}} 
                sx={{
                  color:"#0C709B", 
                  backgroundColor:"white",
                  padding: "3% 7%",
                  '&:hover': {
                    backgroundColor: "white"
                  }
                }}>
                <Container sx={{width:"30%"}}>
                  <img alt="Business Insurance"  src={personalInsuranceImage} height="30px" />  
                </Container>
                <Typography 
                    fontFamily="Nunito"  
                    fontWeight="bold"
                    sx={{width:"70%"}} >
                    Business Insurance
                </Typography>                
              </Button>
              <Button variant="text" 
                onClick={()=>{navigate('/HR&Benefits')}} 
                sx={{
                  color:"#0C709B", 
                  backgroundColor:"white",
                  padding: "10% 7%",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",                  
                  '&:hover': {
                    backgroundColor: "white"
                  }
                }}>
                <Container sx={{width:"30%"}}>
                  <img alt="Hr & Benefits" src={hrAndbenifitsImage} height="30px" />  
                </Container>
                <Typography 
                    fontFamily="Nunito" 
                    fontWeight="bold"
                    sx={{width:"70%"}} >
                    Hr & Benefits
                </Typography>                
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid
          sx={{
            align: "center",
          }}>
          <HashLink smooth to="/Home#services">
            <ExpandMoreOutlinedIcon 
              sx={{ 
                color: 'white',
                fontSize: "50px"
              }}/>
          </HashLink>          
        </Grid>       
      </Stack>      
      
    </Box>
  )
}

export default TitleSection