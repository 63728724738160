import React from 'react';
import { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import ApplyNowHeader from '../components/ApplyNowHeader';
import ApplyNowImage from '../assets/images/ApplyNowPage.jpg';
import { Seo } from '../components/Seo';

function ApplyNow() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>      
      <Seo
          title="Apply Now"
          description="Apply Now to submit your information to our hiring manager. We will review and contact you regarding potential opportunities within our company"/>                                                          
      <section>          
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section> 
      <ApplyNowHeader backgroundImage={ApplyNowImage}/>
    </Stack>
  )
}

export default ApplyNow