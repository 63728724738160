import React from 'react';
import { useState, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import RequestConsultationHeader from '../components/RequestConsultationHeader';
import RequestConsultationImage from '../assets/images/RequestConsultation.jpg';
import { Seo } from '../components/Seo';

function RequestConsultation() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>      
      <Seo
          title="Request a Consultation"
          description="Book a Consultation with Our Experts - Get Personalized Advice and Solutions for Your Needs. Schedule an Appointment Today!"/>                                                          
      <section>          
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section> 
      <RequestConsultationHeader backgroundImage={RequestConsultationImage}/>
    </Stack>
  )
}

export default RequestConsultation