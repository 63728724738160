import React from 'react'
import { Box, Grid } from '@mui/material';
import InsuranceBlockSection from './InsuranceBlockSection';
import BlogsPreview from '../components/BlogsPreview';


export default function BusinessInsuranceBody() {
    return (
        <>
            <Grid container>
                <Grid item md={6} sm={12} xs={12}>
                    <Box 
                        component="div" 
                        sx={{
                            backgroundColor: 'white',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            minHeight: '100vh',
                            overflowX:'hidden'
                        }}> 
                        <InsuranceBlockSection
                            title="BUSINESS INSURANCE PRODUCTS"
                            delay={250}
                            blockItems= {businessItems}/>
                    </Box>                
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    <Box 
                        component="div" 
                        sx={{
                            backgroundColor: {
                                md: 'white',  
                                sm: '#f5f5f5',
                                xs: '#f5f5f5'
                            },
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            minHeight: '100vh',
                            overflowX:'hidden'
                        }}> 
                        <InsuranceBlockSection
                            title="SPECIALIZED INDUSTRIES"
                            delay={500}
                            blockItems= {specializedIndustriesItems}/>                 
                    </Box>                 
                </Grid>
            </Grid>          
            <Box 
              component="div" 
              sx={{
                  backgroundColor: "#f5f5f5",
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  minHeight: '100vh',
                  overflowX:'hidden',
                  padding:"4% 10% 5% 10%"
              }}>
              <BlogsPreview
                insuranceType="Business Insurance"/>            
            </Box>           
        </>         
    )
}

const businessItems = [
    {
        name: "GENERAL LIABILITY",
        description: "General liability insurance provides protection against claims or lawsuits alleging bodily injury, property damage, or personal injury.",
        redirectUrl: "GeneralLiabilityInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "BUSINESS OWNERS POLICY (BOP)",
        description: "A Business Owners Policy (BOP) combines property insurance, liability insurance, and business interruption insurance into a single policy.",
        redirectUrl: "BusinessOwnersPolicyInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "COMMERCIAL PROPERTY",
        description: "Commercial property insurance provides coverage for businesses and their physical assets, such as buildings, equipment, inventory, and furniture.",
        redirectUrl: "CommercialPropertyInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "COMMERCIAL AUTO",
        description: "Commercial Auto Insurance, or Business Auto Insurance, provides coverage for vehicles used for business purposes.",
        redirectUrl: "CommercialAutoInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "WORKERS COMPENSATION",
        description: "Workers Compensation insurance provides benefits such as medical expenses, rehabilitation costs, and lost wages for employees who are injured or become ill as a result of their work.",
        redirectUrl: "WorkersCompensationInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "LIQUOR LIABILITY",
        description: "Liquor liability insurance provides coverage for businesses that sell or serve alcoholic beverages, such as bars, restaurants, and liquor stores.",
        redirectUrl: "LiquorLiabilityInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "PROFESSIONAL LIABILITY",
        description: "Professional liability insurance, also known as errors and omissions (E&O) insurance, provides coverage for professionals who provide advice or specialized services to clients.",
        redirectUrl: "ProfessionalLiabilityInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "CYBER LIABILITY",
        description: "Cyber liability insurance provides coverage for businesses in the event of data breaches or other cyber-related incidents.",
        redirectUrl: "CyberLiabilityInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "OTHERS",
        description: "As an independent insurance agency, we are affiliated with many reputable insurance companies offering a wide variety of insurance products. If you are in the market for business insurance, we are here to offer personalized service as well as access to mulƟple insurance carriers and pricing opƟons.",
        redirectUrl: "business-insurance-quote",
        quoteNow: "business-insurance-quote"
    }
]

const specializedIndustriesItems = [
    {
        name: "RESTAURANT/BARS/CLUBS",
        description: "Click to review the common insurance needs for the hospitality industry…",
        redirectUrl: "RestaurentInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "CONSTRUCTION",
        description: "Click to review the common insurance needs for the construction industry…",
        redirectUrl: "ConstructionInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "MARINE",
        description: "Click to review the common insurance needs for the marine industry…",
        redirectUrl: "MarineInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "REAL ESTATE",
        description: "Click to review the common insurance needs for the Real Estate industry…",
        redirectUrl: "RealEstateInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "HEALTHCARE",
        description: "Click to review the common insurance needs for the healthcare industry…",
        redirectUrl: "HealthcareInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "TRANSPORTATION",
        description: "Click to review the common insurance needs for the transportation industry…",
        redirectUrl: "TransporationInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "PROFESSIONAL SERVICES",
        description: "Click to review the common insurance needs for the professional services industry…",
        redirectUrl: "ServiceCompaniesInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "SPECIAL EVENTS",
        description: "Click to review the common insurance needs for special events…",
        redirectUrl: "SpecialEventInsurance",
        quoteNow: "business-insurance-quote"
    },
    {
        name: "OTHERS",
        description: "As an independent insurance agency, we serve clients across many different industries. We are here to serve your business insurance needs by providing personalized service and advocacy.",
        redirectUrl: "business-insurance-quote",
        quoteNow: "business-insurance-quote"
    }
]


