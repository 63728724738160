import React from 'react'
import { Box, Grid, Typography, TextField, Button } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import NumberFormat from 'react-number-format';

export default function ReportPersonalClaimToInsurance({getPersonalPoliciesHandler}) {

  const [clientLastName, setClientLastName] = useState("")
  const [email, setEmail] = useState("")
  const [zipCode, setZipCode] = useState("")

  const [isClientLastNameFocused, setIsClientLastNameFocused] = useState(false)
  const [isEmailFocused, setIsEmailFocused] = useState(false)
  const [isZipCodeFocused, setIsZipCodeFocused] = useState(false)

  const [isClientLastNameTouched, setIsClientLastNameTouched] = useState(false)
  const [isEmailTouched, setIsEmailTouched] = useState(false)
  const [isZipCodeTouched, setIsZipCodeTouched] = useState(false)

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)

  const isLastNameValid = clientLastName !== null && clientLastName !== undefined && clientLastName !== "";
  const isEmailValid = email !== null && email !== undefined && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  const isValidZipCode = zipCode !== null && zipCode !== undefined && zipCode.toString().length === 5;


  const clientLastNameChangeHandler = (event) =>{
    !isClientLastNameTouched && setIsClientLastNameTouched(true)
    setClientLastName(event.target.value)
  }

  const emailChangeHandler = (event) =>{
    !isEmailTouched && setIsEmailTouched(true)
    setEmail(event.target.value)
  }

  const zipCodeChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    !isZipCodeTouched && setIsZipCodeTouched(true)
    setZipCode(value)
  }

  return (
        <Grid container rowSpacing={4}>
            <Grid item xs={12}>
                <TextField
                    error={isClientLastNameTouched && !isLastNameValid && !isClientLastNameFocused}
                    required
                    size="small"
                    fontFamily="Nunito"
                    fullWidth
                    onFocus={()=>{setIsClientLastNameFocused(true)}}
                    onBlur={()=>{setIsClientLastNameFocused(false)}}
                    type="text" 
                    value= {clientLastName}
                    onChange= {clientLastNameChangeHandler}                        
                    label = "Client Last Name"/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    error={isEmailTouched && !isEmailValid & !isEmailFocused}
                    required
                    size="small"
                    fontFamily="Nunito"
                    fullWidth
                    type="text"   
                    onFocus={()=>{setIsEmailFocused(true)}}
                    onBlur={()=>{setIsEmailFocused(false)}}                         
                    value= {email}
                    onChange= {emailChangeHandler}                        
                    label = "Email"/> 
            </Grid>                  
            <Grid item xs={12}>
                <NumberFormat
                    sx={{
                          width: "100%"
                    }}
                    error={isZipCodeTouched && !isValidZipCode && !isZipCodeFocused}
                    required
                    label="Mailing Zip Code"
                    size="small"
                    type="text" 
                    value={zipCode} 
                    format="#####" 
                    mask="_" 
                    customInput={TextField}
                    onFocus={()=>{setIsZipCodeFocused(true)}}
                    onBlur={()=>{setIsZipCodeFocused(false)}}
                    onValueChange={zipCodeChangeHandler}/>  
            </Grid>  
            <Grid item xs={12}>
                <ReCAPTCHA
                  size="normal"
                  onChange={()=>{setIsRecaptchaVerified(true)}}
                  onExpired={()=>{setIsRecaptchaVerified(false)}}
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                />   
            </Grid>                                       
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    backgroundcolor= "#0C709B" 
                    onClick={()=>{getPersonalPoliciesHandler(clientLastName, email, zipCode)}} 
                    startIcon={<SendIcon />}
                    size="medium"
                    disabled= {!isRecaptchaVerified || !isValidZipCode || !isLastNameValid || !isEmailValid}
                    sx={{
                      borderRadius: '20px',
                      fontFamily: "Nunito",
                      fontWeight: "bolder",
                    }}                       
                    label = "Claim">  
                    Get Policy Information
                </Button>   
            </Grid>
        </Grid>        
  )
}
