import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button,Typography, Grid} from '@mui/material'
import { useNavigate } from 'react-router';


const InsuranceBlockSection = ({title, delay, blockItems}) => {

    const settings = {
        animation: "swipe",
        autoPlay: false,
        cycleNavigation: true,
        duration: delay,
        indicators: true,
        navButtonsAlwaysInvisible: false,
        navButtonsAlwaysVisible: true,
        animation: 'slide',
        autoPlay: true
    }

    return (
        <Grid sx={{ marginTop: "20vh", color: "#494949" }}>
            <Typography
                fontFamily="Sofia Sans Condensed"  
                color="#0C709B"
                sx={{ 
                    textAlign:"center",
                    marginBottom: '7vh'
                }}
                variant="h3">
                {title}
            </Typography>
            <Grid container>
                <Grid item lg={2} md={1} sm={1} xs={1}/>
                <Grid item lg={8} md={10} sm={10} xs={10}>
                    <Carousel sx={{width: '100%'}} {...settings}>
                        {
                            blockItems.map((item, index) => {
                                return <Project item={item} key={index}/>
                            })
                        }
                    </Carousel>
                </Grid> 
                <Grid item lg={2} md={1} sm={1} xs={1}/>
            </Grid>
        </Grid>
    )
}


function Project({item}) {
    const navigate = useNavigate()

    const serviceOnlickHandler = (e, externalQuoteNow, quoteNow)=>{
        e.stopPropagation(); 
        externalQuoteNow ? window.location.replace(externalQuoteNow) : quoteNow ? navigate('/' + quoteNow) :  navigate('/QuoteNow')      
    }
    return (
        <Paper
            onClick={()=>{ item.redirectUrl != null && navigate('/' + item.redirectUrl)}}      
            sx={{
                backgroundColor: "#0C709B" ,
                maxWidth: '100%',
                position: 'relative',
                minHeight: '350px',
                overflow: 'hidden',
                padding: '40px 60px',
                cursor: 'pointer'
            }}
            elevation={10}>
            <Typography sx={{color: "white", fontFamily: "Nunito", fontWeight: "bolder"}} variant='h4'>{item.name}</Typography>
            <br/>
            <Typography sx={{fontFamily:"Nunito",color: "white", fontFamily: "Nunito"}} variant='body1'>{item.description}</Typography>

            <Button
                size="large"
                variant='outlined'
                onClick={(e)=>{ serviceOnlickHandler(e, item.externalQuoteNow, item.quoteNow) }}      
                sx={{
                    fontFamily:"Nunito",
                    color: 'white',
                    backgroundColor: "#F1735D" ,                    
                    marginTop: '40px',
                    textTransform: 'capitalize',
                    '&:hover': { 
                        backgroundColor: '#F1735D',
                      }
                }}>
                Quote Now
            </Button>
        </Paper>
    )
}

export default InsuranceBlockSection;
