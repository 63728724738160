import React from 'react'
import { Box, Stack, Typography } from '@mui/material';
import Fade from 'react-reveal/Fade';


export default function ClientAccessHeader({backgroundImage}) {
  return (
    <Box 
      component="div" 
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        overflowX:'hidden',
        marginTop: '10vh'
      }}> 
      <Stack
        direction="column"
        alignItems="top"
        justifyContent="top"
        spacing={2}
        sx={{
          backgroundColor: 'rgba(0,0,0, 0.4)',
          minHeight: '60vh',
          paddingTop: '25vh',
          paddingLeft: '7%'
        }}> 
        <Fade bottom delay={50}>
          <Typography 
            variant='h3'
            paddingRight= '1vw'
            fontFamily="Sofia Sans Condensed"
            sx={{
              color:"white"
            }}>
          CLIENT CONNECT
          </Typography>
        </Fade>
        <Fade bottom delay={300}>
          <Typography 
            variant='h6'
            fontFamily="Nunito"
            paddingRight= '1vw'
            sx={{
              fontWeight:"bolder",
              color:"white",
              textUnderlineOffset: '20%',
              textDecorationThickness: '1px',
            }}>
          Convenient Access at your Fingertips!
          </Typography> 
        </Fade>            
      </Stack>          
    </Box>                    
  )
}




        