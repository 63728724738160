import React from 'react'
import { useEffect } from 'react';
import { Grid, Typography, TextField, Button, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import NumberFormat from 'react-number-format';
import { isValidEmailAddress, isValidPostalCode } from '../utils/utilityFunctions';
import { PolicyChangeDisclaimerSection } from './PolicyChangeDisclaimerSection';

const policyTypes = [
  {
    value: "Home or Flood",
    key : "HOF"
  },
  {
    key: "AUTO",
    value : "Auto"
  },
  {
    key: "BOAT",
    value : "Boat"
  },
]
export default function PersonalInsuranceRemoveMortgageeOrLossPayeeForm({insuranceType, requestPolicyChangeHandler}) {
  useEffect(()=>{
    resetForm();
  },[insuranceType])

  const [insuredName, setInsuredName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [policyType, setPolicyType] = useState("");
  const [lenderName, setLenderName] = useState("");
  const [lenderAddress, setLenderAddress] = useState("");
  const [lenderAddress2, setLenderAddress2] = useState("");
  const [lenderCity, setLenderCity] = useState("");
  const [lenderState, setLenderState] = useState("");
  const [lenderZipcode, setLenderZipcode] = useState("");
  const [loanNumber, setLoanNumber] = useState("");

  const [isInsuredNameFocused, setIsInsuredNameFocused] = useState(false)
  const [isEmailAddressFocused, setIsEmailAddressFocused] = useState(false)
  const [isLenderNameFocused, setIsLenderNameFocused] = useState(false)
  const [isLenderAddressFocused, setIsLenderAddressFocused] = useState(false)
  const [isLenderCityFocused, setIsLenderCityFocused] = useState(false)
  const [isLenderStateFocused, setIsLenderStateFocused] = useState(false)
  const [isLenderZipcodeFocused, setIsLenderZipcodeFocused] = useState(false)
  const [isLoanNumberFocused, setIsLoanNumberFocused] = useState(false)

  const [isInsuredNameTouched, setIsInsuredNameTouched] = useState(false)
  const [isEmailAddressTouched, setIsEmailAddressTouched] = useState(false)
  const [isLenderNameTouched, setIsLenderNameTouched] = useState(false)
  const [isLenderAddressTouched, setIsLenderAddressTouched] = useState(false)
  const [isLenderCityTouched, setIsLenderCityTouched] = useState(false)
  const [isLenderStateTouched, setIsLenderStateTouched] = useState(false)
  const [isLenderZipcodeTouched, setIsLenderZipcodeTouched] = useState(false)
  const [isLoanNumberTouched, setIsLoanNumberTouched] = useState(false)

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)

  const insuredNameChangeHandler = (event) =>{
    !isInsuredNameTouched && setIsInsuredNameTouched(true)
    setInsuredName(event.target.value)
  }

  const emailAddressChangeHandler = (event) =>{
    !isEmailAddressTouched && setIsEmailAddressTouched(true)
    setEmailAddress(event.target.value.trim())
  }

  const lenderNameChangeHandler = (event) =>{
    !isLenderNameTouched && setIsLenderNameFocused(true)
    setLenderName(event.target.value)
  }

  const lenderAddressChangeHandler = (event) =>{
    !isLenderAddressTouched && setIsLenderAddressTouched(true)
    setLenderAddress(event.target.value)
  }

  const lenderAddress2ChangeHandler = (event) =>{
    setLenderAddress2(event.target.value)
  }

  const lenderCityChangeHandler = (event) =>{
    !isLenderCityTouched && setIsLenderCityTouched(true)
    setLenderCity(event.target.value)
  }
  const lenderStateChangeHandler = (event) =>{
    !isLenderStateTouched && setIsLenderStateTouched(true)
    setLenderState(event.target.value)
  }
  const lenderZipcodeChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    !isLenderZipcodeTouched && setIsLenderZipcodeTouched(true)
    setLenderZipcode(value)
  }

  const loanNumberChangeHandler = (event) =>{
    !isLoanNumberTouched && setIsLoanNumberTouched(true)
    setLoanNumber(event.target.value)
  }

  const handlePolicyTypeChange = (event)=>{
    setPolicyType(event.target.value)
  }

  const submitDocumentRequestHandler = ()=>{
    let requestData = { 
      contactName: insuredName,
      email: emailAddress,
      accountName: insuredName,
      policyType: policyType,
      details : {
        removeLender: {
          name: lenderName,
          address1: lenderAddress,
          address2: lenderAddress2,
          city: lenderCity,
          state: lenderState,
          postalCode: lenderZipcode,
          loanNumber: loanNumber
        }
      }
    }
    requestPolicyChangeHandler(requestData);    
  }

  const isValidFormData = () =>{
    return isRecaptchaVerified && insuredName && isValidEmailAddress(emailAddress) && 
           lenderName && lenderAddress && lenderCity && 
           lenderState && isValidPostalCode(lenderZipcode) && loanNumber
  }

  const resetForm = () =>{
    setInsuredName("");
    setEmailAddress("");
    setPolicyType("");
    setLenderName("");
    setLenderAddress("");
    setLenderAddress2("");
    setLenderCity("");
    setLenderState("");
    setLenderZipcode("");
    setLoanNumber("");

    setIsInsuredNameFocused(false);
    setIsEmailAddressFocused(false);
    setIsLenderNameFocused(false);
    setIsLenderAddressFocused(false);
    setIsLenderCityFocused(false);
    setIsLenderStateFocused(false);
    setIsLenderZipcodeFocused(false);
    setIsLoanNumberFocused(false);

    setIsInsuredNameTouched(false);
    setIsEmailAddressTouched(false);
    setIsLenderNameTouched(false);
    setIsLenderAddressTouched(false);
    setIsLenderCityTouched(false);
    setIsLenderStateTouched(false);
    setIsLenderZipcodeTouched(false);
    setIsLoanNumberTouched(false);

    setIsRecaptchaVerified(false);
  }

  return (
    <Grid container rowSpacing={4}>  
        <Grid item xs={12}>
            <Typography                                 
                variant='h4'
                fontFamily="Sofia Sans Condensed"
                sx={{
                    color:"#0C709B"
                }}>
                REQUEST FORM
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container rowSpacing={2} columnSpacing={2}>
                <>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          CLIENT DETAILS
                      </Typography>
                    </Grid>
                    <Grid item md={6} sx={{width:"100%"}}>
                    <TextField
                        error={isInsuredNameFocused && !insuredName && !isInsuredNameFocused}
                        required
                        size="small"
                        fontFamily="Nunito"
                        sx={{width:"100%"}}
                        onFocus={()=>{setIsInsuredNameFocused(true)}}
                        onBlur={()=>{setIsInsuredNameFocused(false)}}
                        type="text" 
                        value= {insuredName}
                        onChange= {insuredNameChangeHandler}                        
                        label = "Insured Name"/>
                    </Grid>
                    <Grid item md={6} sx={{width:"100%"}}>
                      <TextField
                          error={isEmailAddressTouched && !isValidEmailAddress(emailAddress) & !isEmailAddressFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsEmailAddressFocused(true)}}
                          onBlur={()=>{setIsEmailAddressFocused(false)}}                         
                          value= {emailAddress}
                          onChange= {emailAddressChangeHandler}                        
                          label = "Email"/> 
                    </Grid>
                </>
                <>
                    <Grid item md={12} sx={{width:"100%", marginTop: 2}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          POLICY TYPE
                      </Typography>
                    </Grid>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <FormControl size="small" sx={{width:"100%"}}>
                        <InputLabel>Policy Type</InputLabel>
                        <Select
                          sx={{width:"100%"}}
                          value={policyType}
                          label="Policy Type"
                          size="small"
                          required
                          onChange={handlePolicyTypeChange}>
                          {
                              policyTypes.map( type => <MenuItem value={type.key}>{type.value}</MenuItem>)
                          }
                        </Select>
                      </FormControl> 
                    </Grid>                                        
                </>                                
                <>  
                    <Grid item md={12} sx={{width:"100%", marginTop: 2}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          REMOVE LENDER
                      </Typography>
                    </Grid>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isLenderNameTouched && !lenderName && !isLenderNameFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          onFocus={()=>{setIsLenderNameFocused(true)}}
                          onBlur={()=>{setIsLenderNameFocused(false)}}
                          type="text" 
                          value= {lenderName}
                          onChange= {lenderNameChangeHandler}                        
                          label = "Name"/>
                    </Grid>        
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isLenderAddressTouched && !lenderAddress && !isLenderAddressFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          onFocus={()=>{setIsLenderAddressFocused(true)}}
                          onBlur={()=>{setIsLenderAddressFocused(false)}}
                          type="text" 
                          value= {lenderAddress}
                          onChange= {lenderAddressChangeHandler}                        
                          label = "Address"/>
                    </Grid>     
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text" 
                          value= {lenderAddress2}
                          onChange= {lenderAddress2ChangeHandler}                        
                          label = "Address 2"/>
                    </Grid> 
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isLenderCityTouched && !lenderCity & !isLenderCityFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsLenderCityFocused(true)}}
                          onBlur={()=>{setIsLenderCityFocused(false)}}                         
                          value= {lenderCity}
                          onChange= {lenderCityChangeHandler}                        
                          label = "City"/> 
                    </Grid>          
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isLenderStateTouched && !lenderState & !isLenderStateFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsLenderStateFocused(true)}}
                          onBlur={()=>{setIsLenderStateFocused(false)}}                         
                          value= {lenderState}
                          onChange= {lenderStateChangeHandler}                        
                          label = "State"/> 
                    </Grid>  
                    <Grid item md={4} sx={{width:"100%"}}>
                      <NumberFormat
                          sx={{width: "100%"}}
                          error={isLenderZipcodeTouched && !isValidPostalCode(lenderZipcode) & !isLenderZipcodeFocused}
                          required
                          label="Zipcode"
                          size="small"
                          type="text" 
                          value={lenderZipcode} 
                          format="#####" 
                          mask="_" 
                          customInput={TextField}
                          onFocus={()=>{setIsLenderZipcodeFocused(true)}}
                          onBlur={()=>{setIsLenderZipcodeFocused(false)}}   
                          onValueChange={lenderZipcodeChangeHandler}/>                       
                    </Grid>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          sx={{width: "100%"}}
                          error={isLoanNumberTouched && !loanNumber & !isLoanNumberFocused}
                          required
                          label="Loan Number"
                          size="small"
                          type="text" 
                          value={loanNumber} 
                          onFocus={()=>{setIsLoanNumberFocused(true)}}
                          onBlur={()=>{setIsLoanNumberFocused(false)}}   
                          onChange={loanNumberChangeHandler}/>                       
                    </Grid>                                                                       
                </>
                <PolicyChangeDisclaimerSection/>                                                              
                <Grid item xs={12}>
                      <ReCAPTCHA
                          size="normal"
                          onChange={()=>{setIsRecaptchaVerified(true)}}
                          onExpired={()=>{setIsRecaptchaVerified(false)}}
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/>   
                </Grid>                                       
                <Grid item xs={12}>
                      <Button
                          disabled={!isValidFormData()}
                          variant="contained"
                          backgroundColor= "#0C709B" 
                          startIcon={<SendIcon />}
                          size="medium"
                          onClick={submitDocumentRequestHandler}
                          sx={{
                            borderRadius: '20px',
                            fontFamily: "Nunito",
                            fontWeight: "bolder",
                          }}            
                          label = "Claim">  
                          Submit Request
                      </Button>   
                </Grid> 
            </Grid>
        </Grid>       
    </Grid>
  )
}




        

