import React from 'react';
import { useState, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import PersonalInsuranceHeader from '../components/PersonalInsuranceHeader';
import PersonalInsuranceBody from '../components/PersonalInsuranceBody';
import { Seo } from '../components/Seo';

function PersonalInsurance() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>  
      <Seo
          title="Personal Insurance"
          description="Insurance coverage designed for you and your family. Protect your home, property, vehicles, and assets with customized insurance solutions"/>                                                          
      <section>
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section> 
      <section>
        <Stack>
            <PersonalInsuranceHeader/>
            <PersonalInsuranceBody/>
        </Stack>
      </section>
    </Stack>
  )
}

export default PersonalInsurance