import React from 'react'
import { Box, Grid, Typography, TextField, Button, Snackbar, Alert } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import { isValidEmailAddress } from '../utils/utilityFunctions';
import NumberFormat from 'react-number-format';
import loader from '../assets/images/Loader.gif'
import FileUpload from 'react-material-file-upload';
import { createJobApplication } from "../apiClient/insurancePolicies";
import { getBase64 } from '../utils/utilityFunctions/getBase64';

export default function ApplyNowForm() {
  const [ isBackdropVisible, setIsBackdropVisible] = React.useState(false);
  const [ isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [ alertMessage, setAlertMessage] = React.useState("");
  const [ alertMessageSeverity, setAlertMessageSeverity] = React.useState("");

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [description, setDescription] = useState("")
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)
  const [files, setFiles] = useState([])

  const [isEmailTouched, setIsEmailTouched] = useState(false)
  const [isEmailFocused, setIsEmailFocused] = useState(false)

  const emailChangeHandler = (event) =>{
    !isEmailTouched && setIsEmailTouched(true)
    setEmail(event.target.value.trim())
  }

  const setBackdropVisibilityValue = (value) => {
    setIsBackdropVisible(value);
  };

  const setAlertMessageAndVisibility = (message, severity, isVisible)=>{
    setAlertMessage(message)
    setAlertMessageSeverity(severity)
    setIsAlertVisible(isVisible)
    setTimeout(()=>{
      setIsAlertVisible(false)
    },5000)
  }

  const phoneChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    if(value != phone)
    {
      setPhone(value)
    }
  }

  const onSubmitForm = async ()=>{
    try{
      setBackdropVisibilityValue(true)
        
      const leadVM = {
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "phone": phone,
        "background": description,
        "resume" : await getBase64(files[0])
      }
      await createJobApplication(leadVM)

      resetForm()      
      setBackdropVisibilityValue(false)
      setAlertMessageAndVisibility( "Request Submitted","success", true)}
    catch(error){
      setAlertMessageAndVisibility("Request Submission Failed", "error", true)
      setBackdropVisibilityValue(false)
    }
  }

  const resetForm = () =>{
    setFirstName("")
    setLastName("")
    setEmail("")
    setPhone("")
    setDescription("")
    setIsEmailTouched(false)
    setIsEmailFocused(false)
  }

  const isValidData = isValidEmailAddress(email) && isRecaptchaVerified && firstName && lastName && phone && description && files.length > 0

  return (
      <Box 
        component="div" 
        sx={{
          backgroundColor: "#f5f5f5",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh',
          overflowX:'hidden',
          padding: {
            xs: "35% 10% 10% 10%",
            sm: "30% 10% 2% 10%",
            md: "15% 10% 2% 10%"
          }
        }}>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isBackdropVisible}
            onClick={()=>{setBackdropVisibilityValue(false)}}>
            <Box
              component="img"
              sx={{
                height: '80px'
              }}
              alt="loading..."                
              src={loader}/>
        </Backdrop>
        <Snackbar 
          sx={{
            marginTop: "10vh"
          }}
          anchorOrigin={{ vertical:"top", horizontal: "center" }} 
          open={isAlertVisible} 
          onClose={()=>{isAlertVisible(false)}}>
          <Alert onClose={()=>{isAlertVisible(false)}} severity={alertMessageSeverity} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>             
        <Typography 
          variant='h3'
          fontFamily="Sofia Sans Condensed"
          sx={{
            color:"#0C709B",
            marginBottom: '4px'
          }}>
          SUBMIT YOUR APPLICATION
        </Typography>  
        <Typography
            variant='body1'
            sx={{
              fontFamily: "Nunito",
              color:"#0C709B",
              display:{
                lg: "block",
                md: "block",
                sm: "none",
                xs: "none"
              }
            }}>
            If you are looking to join our team, we are interested in hearing for you.                
        </Typography>
        <Typography
            variant='body2'
            sx={{
              color:"#0C709B",
              display:{
                lg: "none",
                md: "none",
                sm: "block",
                xs: "block"
              }
            }}>
            If you are looking to join our team, we are interested in hearing for you.
        </Typography>
        <br/>
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            required
                            fontFamily="Nunito"
                            fullWidth
                            size='small'
                            type="text"
                            value= {firstName}
                            onChange= {(e)=>{setFirstName(e.target.value)}}                        
                            label = "First Name"/>    
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            required
                            fontFamily="Nunito"
                            fullWidth
                            size='small'
                            type="text"
                            value= {lastName}
                            onChange= {(e)=>{setLastName(e.target.value)}}                        
                            label = "Last Name"/>    
                    </Grid>                    

                </Grid>
            </Grid>   
            <Grid item xs={12}>
                <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField 
                            required
                            error={isEmailTouched && !isValidEmailAddress(email) && !isEmailFocused}
                            fontFamily="Nunito"
                            fullWidth
                            size='small'
                            value= {email}
                            onFocus={()=>{setIsEmailFocused(true)}}
                            onBlur={()=>{setIsEmailFocused(false)}}
                            type="text" 
                            onChange= {emailChangeHandler}    
                            label = "Email"/>  
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <NumberFormat
                            required
                            sx={{
                                width: "100%"
                            }}
                            label="Phone"
                            size="small"
                            type="text" 
                            value={phone} 
                            format="+1 (###) ###-####" 
                            mask="_" 
                            customInput={TextField}
                            onValueChange={phoneChangeHandler}/>  
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    multiline
                    fullWidth
                    fontFamily="Nunito"
                    minRows={4}
                    value= {description}
                    onChange= {(e)=>{setDescription(e.target.value)}}      
                    label = "Background Experience"/>   
            </Grid>
            <Grid item xs={12}>
                <FileUpload
                    required
                    label="Resume"
                    title="Drop your Resume here or Select a file"
                    value={files} 
                    onChange={setFiles} 
                    style={{
                        height:"10px"
                    }}
                    multiple={false}
                    maxFileSize={ 300 * 1024 * 1024}
                    accept=".doc,.docx,.pdf,.txt,.rtf"/>
            </Grid>
            <Typography
            variant='body2'
            sx={{
                marginTop: "4px",
                fontFamily: "Nunito",
                fontStyle: "italic",
                color:"#0C709B"
            }}>
            Accepted file types: doc, docx, pdf, txt, rtf, Max. file size: 300MB, Max. files: 1.                
        </Typography>                       
            <Grid item xs={12}>
                <ReCAPTCHA
                    size="normal"
                    onChange={()=>{setIsRecaptchaVerified(true)}}
                    onExpired={()=>{setIsRecaptchaVerified(false)}}
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/> 
            </Grid>                                           
            <Grid item xs={12} >
                <Button
                    variant="contained"
                    backgroundColor= "#0C709B"  
                    startIcon={<SendIcon />}
                    size="large"
                    disabled = {!isValidData}
                    onClick={onSubmitForm}
                    sx={{borderRadius: '20px'}}     
                    label = "Submit">  
                    Submit
                </Button>   
            </Grid>
        </Grid>        
      </Box>               
  )
}
