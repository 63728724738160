import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles';
import LandingPage from './pages/LandingPage';
import OurStory from './pages/OurStory';
import ContactUs from './pages/ContactUs';
import JoinOurTeam from './pages/JoinOurTeam';
import PageNotFound from './pages/PageNotFound';
import KnowledgeAndResource from './pages/KnowledgeAndResource';
import Welcome from './pages/Welcome';
import MoveToModernShore from './pages/MoveToModernShore';
import RequestConsultation from './pages/RequestConsultation';
import ApplyNow from './pages/ApplyNow';
import ContactCustomerService from './pages/ContactCustomerService';
import PolicyChangeRequest from './pages/PolicyChangeRequest';
import ReportClaim from './pages/ReportClaim';
import BusinessInsurance from './pages/BusinessInsurance';
import PersonalInsurance from './pages/PersonalInsurance';
import HRAndBenefits from './pages/HRAndBenefits';
import RequestDocuments from './pages/RequestDocuments';
import LenderConnect from './pages/LenderConnect';
import VenderConnect from './pages/VenderConnect';
import QuoteNow from './pages/QuoteNow';
import theme from './assets/theme.js'
import ScrollToTop from "react-scroll-to-top";
import Footer from './components/Footer';
import AgencyAffiliation from './pages/AgencyAffiliation';
import ServicesSubPage from './pages/ServicesSubPage';
import Search from './pages/Search';
import { Seo } from './components/Seo';


const App = () => {
  return (
    <ThemeProvider theme= {theme}>
        <Seo
            title="Modern Shore Insurance"
            description="Modern Shore Insurance is an independent insurance firm offering personal & family insurance products as well as business insurance & employer services."/>       
        <ScrollToTop smooth color="#0C709B" />
        <Router>
          <Routes>
              <Route exact path="/" element={<LandingPage/>}/>
              <Route exact path="/Home" element={<LandingPage/>}/>
              <Route exact path="/OurStory" element={<OurStory/>}/>
              <Route exact path="/JoinOurTeam" element={<JoinOurTeam/>}/>
              <Route exact path="/ContactUs" element={<ContactUs/>}/>
              <Route exact path="/QuoteNow" element={<ContactUs/>}/>
              <Route exact path="/KnowledgeResources" element={<KnowledgeAndResource/>}/>
              <Route exact path="/ContactCustomerService" element={<ContactCustomerService/>}/>
              <Route exact path="/PolicyChangeRequest" element={<PolicyChangeRequest/>}/>
              <Route exact path="/ReportClaim" element={<ReportClaim/>}/>
              <Route exact path="/RequestDocuments" element={<RequestDocuments/>}/>        
              <Route exact path="/evidence" element={<LenderConnect/>}/>        
              <Route exact path="/coi" element={<VenderConnect/>}/>        

              <Route exact path="/Welcome" element={<Welcome/>}/>        
              <Route exact path="/MoveToModernShore" element={<MoveToModernShore/>}/>  
              <Route exact path="/RequestConsultation" element={<RequestConsultation/>}/>  
              <Route exact path="/PersonalInsurance" element={<PersonalInsurance/>}/>                                           
              <Route exact path="/BusinessInsurance" element={<BusinessInsurance/>}/>          
              <Route exact path="/HR&Benefits" element={<HRAndBenefits/>}/>                                                                            
              <Route exact path="/AgencyAffiliation" element={<AgencyAffiliation/>}/> 
              <Route exact path="/ApplyNow" element={<ApplyNow/>}/> 
              
              <Route exact path="/HomeInsurance" element={<ServicesSubPage type="HomeInsurance"/>}/>     
              <Route exact path="/AutoInsurance" element={<ServicesSubPage type="AutoInsurance"/>}/>     
              <Route exact path="/FloodInsurance" element={<ServicesSubPage type="FloodInsurance"/>}/>     
              <Route exact path="/CondoInsurance" element={<ServicesSubPage type="CondoInsurance"/>}/>     
              <Route exact path="/BoatInsurance" element={<ServicesSubPage type="BoatInsurance"/>}/>     
              <Route exact path="/UmbrellaInsurance" element={<ServicesSubPage type="UmbrellaInsurance"/>}/>     
              <Route exact path="/LifeInsurance" element={<ServicesSubPage type="LifeInsurance"/>}/>     
              <Route exact path="/JewelryInsurance" element={<ServicesSubPage type="JewelryInsurance"/>}/>     
              <Route exact path="/FloodInsurance" element={<ServicesSubPage type="FloodInsurance"/>}/>     
              <Route exact path="/PersonalLiabilityInsurance" element={<ServicesSubPage type="PersonalLiabilityInsurance"/>}/>     
              <Route exact path="/RentalInsurance" element={<ServicesSubPage type="RentalInsurance"/>}/>     
              <Route exact path="/MobileHomeInsurance" element={<ServicesSubPage type="MobileHomeInsurance"/>}/>     
              <Route exact path="/RentersInsurance" element={<ServicesSubPage type="RentersInsurance"/>}/>     
              <Route exact path="/MotorHomeInsurance" element={<ServicesSubPage type="MotorHomeInsurance"/>}/>     
              <Route exact path="/MotorcycleInsurance" element={<ServicesSubPage type="MotorcycleInsurance"/>}/>     
              <Route exact path="/GolfCartInsurance" element={<ServicesSubPage type="GolfCartInsurance"/>}/> 

              <Route exact path="/GeneralLiabilityInsurance" element={<ServicesSubPage type="GeneralLiabilityInsurance"/>}/>     
              <Route exact path="/BusinessOwnersPolicyInsurance" element={<ServicesSubPage type="BusinessOwnersPolicyInsurance"/>}/>     
              <Route exact path="/CommercialPropertyInsurance" element={<ServicesSubPage type="CommercialPropertyInsurance"/>}/>     
              <Route exact path="/CommercialAutoInsurance" element={<ServicesSubPage type="CommercialAutoInsurance"/>}/>     
              <Route exact path="/WorkersCompensationInsurance" element={<ServicesSubPage type="WorkersCompensationInsurance"/>}/>     
              <Route exact path="/LiquorLiabilityInsurance" element={<ServicesSubPage type="LiquorLiabilityInsurance"/>}/>     
              <Route exact path="/ProfessionalLiabilityInsurance" element={<ServicesSubPage type="ProfessionalLiabilityInsurance"/>}/>     
              <Route exact path="/CyberLiabilityInsurance" element={<ServicesSubPage type="CyberLiabilityInsurance"/>}/>   

              <Route exact path="/RestaurentInsurance" element={<ServicesSubPage type="RestaurantInsurance"/>}/>     
              <Route exact path="/ConstructionInsurance" element={<ServicesSubPage type="ConstructionInsurance"/>}/>     
              <Route exact path="/MarineInsurance" element={<ServicesSubPage type="MarineInsurance"/>}/>     
              <Route exact path="/RealEstateInsurance" element={<ServicesSubPage type="RealEstateInsurance"/>}/>     
              <Route exact path="/HealthcareInsurance" element={<ServicesSubPage type="HealthcareInsurance"/>}/>     
              <Route exact path="/TransporationInsurance" element={<ServicesSubPage type="TransporationInsurance"/>}/>     
              <Route exact path="/ServiceCompaniesInsurance" element={<ServicesSubPage type="ServiceCompaniesInsurance"/>}/>  
              <Route exact path="/SpecialEventInsurance" element={<ServicesSubPage type="SpecialEventInsurance"/>}/>                             

              <Route exact path="/HealthInsurance" element={<ServicesSubPage type="HealthInsurance"/>}/>     
              <Route exact path="/DentalInsurance" element={<ServicesSubPage type="DentalInsurance"/>}/>     
              <Route exact path="/VisionInsurance" element={<ServicesSubPage type="VisionInsurance"/>}/>     
              <Route exact path="/SupplementalInsurance" element={<ServicesSubPage type="SupplementalInsurance"/>}/>  
              <Route exact path="/RetirementBenefitPlans" element={<ServicesSubPage type="RetirementBenefitPlans"/>}/>     
              <Route exact path="/Search" element={<Search/>}/>     
                                                             
              <Route exact path="/business-insurance-quote" 
                  element={
                    <QuoteNow
                      type={1}/>
                  }/>   
              <Route exact path="/auto-insurance-quote" 
                  element={
                    <QuoteNow
                      type={2}/>
                  }/>   
              <Route exact path="/home-insurance-quote" 
                  element={
                    <QuoteNow
                      type={3}/>
                  }/>   
              <Route exact path="/flood-insurance-quote" 
                  element={
                    <QuoteNow
                      type={4}/>
                  }/>                                                      

              <Route exact path="/certificate-of-insurance-1" 
                  element={
                    <RequestDocuments 
                        type={1}
                        documentType='COI'/>
                  }/>  
              <Route path="*" element={<PageNotFound/>}/>
          </Routes>
          <Footer/>
          <section>
          </section>
        </Router>
    </ThemeProvider>    
  );
}

export default App;
