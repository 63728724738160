import React from 'react';
import { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import AgencyAffiliationHeader from '../components/AgencyAffiliationHeader';
import { Seo } from '../components/Seo';

function AgencyAffiliation() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>      
      <Seo
          title="Agency Affiliation"
          description="Partnering with Modern Shore Insurance is a great opportunity to achieve the growth that you desire while reducing the administrative burden"/>                                                          
      <section>          
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section> 
      <AgencyAffiliationHeader/>
    </Stack>
  )
}

export default AgencyAffiliation