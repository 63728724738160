import TitleSection from '../components/TitleSection';
import ServicesSection from '../components/ServicesSection';
import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import Navbar from '../components/Navbar';
import CssBaseline from '@mui/material/CssBaseline';
import { Seo } from '../components/Seo';

function LandingPage() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(true)
  useEffect(()=>{
    window.addEventListener('scroll', handleScroll);
    return(()=>{
      window.removeEventListener('scroll', handleScroll);
    })
  },[])
  
  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  const handleScroll = ()=>{
    setIsNavBarTransparent(window.scrollY === 0)
  }
  return (
    <Stack>     
      <Seo
        title="Modern Shore Insurance"
        description="A New Wave of Protection"/>        
      <section>        
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section> 
      <section>
        <TitleSection/>
      </section>
      <section id="services">
        <ServicesSection /> 
      </section>
    </Stack>
  )
}

export default LandingPage