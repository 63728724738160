import React from 'react'
import { Stack } from '@mui/material';
import { Seo } from './Seo'
import { useEffect } from 'react';
import LightningForm from './LightningForm'

function AutoInsuranceForm({quoteNowClickHandler}) {
  useEffect(()=>{
    document.title = "Auto Insurance Quote"
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>      
      <Seo
          title="Auto Insurance Quote"
          description="Get the best auto insurance coverage at competitive rates. Request a personalized quote today for reliable protection on the road."/>                                                          
      <section id="AutoInsuranceQuote">  
        <LightningForm 
          app="c:LightningExternalForm"
          component="c:autoInsuranceQuoteForm"
          divId="autoInsuranceForm"/> 
      </section>
    </Stack>                  
  )
}

export default AutoInsuranceForm