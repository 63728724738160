import { Grid, Typography } from '@mui/material';

export const PolicyChangeDisclaimerSection = ()=>{
    return (
        <Grid item md={12} sx={{width:"100%"}}>
            <Typography                                 
                variant='caption'
                fontFamily="Nunito"
                sx={{
                    fontWeight:"bold",
                    color:"#FF0000"
                }}>
                Disclaimer : 
                Please note that this web form submission is simply a notification request to your agent. 
                Insurance coverage cannot be bound, amended, or cancelled via electronic web submission. 
                You cannot bind, alter, or cancel coverage without confirmation from an authorized representative. 
                Do not assume that coverage has been adjusted until confirmation has been provided by your agent.
            </Typography>
        </Grid> 
    )
}