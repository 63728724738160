import * as React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent"
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 60,
    width: "100%",
    color: "#0C709B",
    backgroundColor: "#0C709B"
  }
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontFamily: "Nunito",
    fontWeight: "bolder",
    fontSize: theme.typography.pxToRem(20),
    marginRight: theme.spacing(1),
    color: "rgb(12, 112, 155, 0.7)",
    "&.Mui-selected": {
      color: "#0C709B"
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgb(12, 112, 155, 0.1)"
    }
  })
);
export default function ReportClaimToInsuranceTabs({tabs, selectedTabValue, onTabChange}) {
  return (
    <StyledTabs
        sx={{
          paddingTop: "0.5%",
          paddingLeft: "2%"
        }}
        value={selectedTabValue}
        onChange={onTabChange}
        aria-label="styled tabs example">
        {
            tabs.map((tab, index) =>{
                return (
                    <StyledTab key={index} label={tab.title}/>
                )
            })
        }          
    </StyledTabs>
  );
}
