import React from 'react';
import { useState, useEffect } from 'react';
import { Stack, Box, Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import ClientAccessNavBar from '../components/ClientAccessNavBar';
import ClientAccessHeader from '../components/ClientAccessHeader'
import ClientAccessImageBackGround from '../assets/images/ClientAccessImageBackGround.jpg';
import PolicyChangeRequestSection from '../components/PolicyChangeRequestSection.jsx'
import { Seo } from '../components/Seo';

function PolicyChangeRequest() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>      
      <Seo
          title="Policy Change Request"
          description="At Modern Shore Insurance, requesting a change to your policy is a breeze! By following the prompts below, you are only a few clicks away from submitting your request directly to a Modern Shore Insurance advisor"/>                                                          
      <section>           
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section>
      <section>
        <ClientAccessNavBar selected="PolicyChangeRequest"/>
      </section>  
      <section id="PolicyChangeRequest">  
      <Stack>
            <ClientAccessHeader backgroundImage={ClientAccessImageBackGround}/>
            <Box 
              component="div" 
              sx={{
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: "linear-gradient(#0C709B, #B6ECF6);",
                  minHeight: '150vh',
                  overflowX:'hidden',
                  padding:"7% 10% 5% 7%"
              }}>
              <PolicyChangeRequestSection/>     
            </Box>          
        </Stack>
      </section>
    </Stack>
  )
}

export default PolicyChangeRequest