import React from 'react'
import { useEffect } from 'react';
import { Grid, Typography, TextField, Button, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import { isValidEmailAddress, getYearsList } from '../utils/utilityFunctions';
import { PolicyChangeDisclaimerSection } from './PolicyChangeDisclaimerSection';



export default function InsuranceVehicleAddForm({insuranceType, requestPolicyChangeHandler}) {
  useEffect(()=>{
    resetForm();
  },[insuranceType])

  const [insuredName, setInsuredName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [contactName, setContactName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [vehicleVIN, setVehicleVIN] = useState("");
  const [vehicleYear, setVehicleYear] = useState("");
  const [vehicleMake, setVehicleMake] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");

  const [isInsuredNameFocused, setIsInsuredNameFocused] = useState(false)
  const [isBusinessNameFocused, setIsBusinessNameFocused] = useState(false)
  const [isContactNameFocused, setIsContactNameFocused] = useState(false)
  const [isEmailAddressFocused, setIsEmailAddressFocused] = useState(false)
  const [isVehicleVINFocused, setIsVehicleVINFocused] = useState(false)
  const [isVehicleMakeFocused, setIsVehicleMakeFocused] = useState(false)
  const [isVehicleModelFocused, setIsVehicleModelFocused] =useState(false)

  const [isInsuredNameTouched, setIsInsuredNameTouched] = useState(false)
  const [isBusinessNameTouched, setIsBusinessNameTouched] = useState(false)
  const [isContactNameTouched, setIsContactNameTouched] = useState(false)
  const [isEmailAddressTouched, setIsEmailAddressTouched] = useState(false)
  const [isVehicleVINTouched, setIsVehicleVINTouched] = useState(false)
  const [isVehicleMakeTouched, setIsVehicleMakeTouched] = useState(false)
  const [isVehicleModelTouched, setIsVehicleModelTouched] =useState(false)

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)

  const insuredNameChangeHandler = (event) =>{
    !isInsuredNameTouched && setIsInsuredNameTouched(true)
    setInsuredName(event.target.value)
  }

  const businessNameChangeHandler = (event) =>{
    !isBusinessNameTouched && setIsBusinessNameTouched(true)
    setBusinessName(event.target.value)
  }

  const emailAddressChangeHandler = (event) =>{
    !isEmailAddressTouched && setIsEmailAddressTouched(true)
    setEmailAddress(event.target.value.trim())
  }

  const contactNameChangeHandler = (event) =>{
    !isContactNameTouched && setIsContactNameTouched(true)
    setContactName(event.target.value)
  }

  const vehicleVINChangeHandler = (event) =>{
    !isVehicleVINTouched && setIsVehicleVINTouched(true)
    setVehicleVIN(event.target.value)
  }

  const vehicleYearChangeHandler = (event) =>{
    setVehicleYear(event.target.value)
  }

  const vehicleModelChangeHandler = (event) =>{
    !isVehicleModelTouched && setIsVehicleModelTouched(true)
    setVehicleModel(event.target.value)
  }

  const vehicleMakeChangeHandler = (event) =>{
    !isVehicleMakeTouched && setIsVehicleMakeTouched(true)
    setVehicleMake(event.target.value)
  }

  const submitDocumentRequestHandler = async ()=>{
    let requestData = {
      contactName: insuranceType === 1 ? contactName : insuredName,
      email: emailAddress,
      accountName: insuranceType === 1 ? businessName : insuredName,
      details : {
        newVehicle: {
          vin: vehicleVIN,
          year: vehicleYear,
          make: vehicleMake,
          model: vehicleModel
        }      
      }
    }
    await requestPolicyChangeHandler(requestData);
  }


  const isValidFormData = () =>{
    let isValid = isRecaptchaVerified && isValidEmailAddress(emailAddress) && vehicleVIN && 
                  vehicleYear && vehicleMake && vehicleModel 

    if(insuranceType === 1 )
    {
      isValid = businessName && contactName && isValid 
    }
    else if(insuranceType === 0)
    {
      isValid = insuredName && isValid 
    }
    return isValid
  }

  const resetForm = () =>{
    setInsuredName("");
    setBusinessName("");
    setContactName("");
    setEmailAddress("");
    setVehicleVIN("");
    setVehicleYear("");
    setVehicleMake("");
    setVehicleModel("");

    setIsInsuredNameFocused(false);
    setIsBusinessNameFocused(false);
    setIsContactNameFocused(false);
    setIsEmailAddressFocused(false);
    setIsVehicleVINFocused(false);
    setIsVehicleMakeFocused(false);
    setIsVehicleModelFocused(false);

    setIsInsuredNameTouched(false);
    setIsBusinessNameTouched(false);
    setIsContactNameTouched(false);
    setIsEmailAddressTouched(false);
    setIsVehicleVINTouched(false);
    setIsVehicleMakeTouched(false);
    setIsVehicleModelTouched(false);

    setIsRecaptchaVerified(false);
  }

  return (
    <Grid container rowSpacing={4}>  
        <Grid item xs={12}>
            <Typography                                 
                variant='h4'
                fontFamily="Sofia Sans Condensed"
                sx={{
                    color:"#0C709B"
                }}>
                REQUEST FORM
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container rowSpacing={2} columnSpacing={2}>
                {
                  insuranceType === 0 &&
                  <>
                      <Grid item md={12} sx={{width:"100%"}}>
                        <Typography                                 
                            variant='h6'
                            fontFamily="Sofia Sans Condensed"
                            sx={{
                                color:"#0C709B"
                            }}>
                            CLIENT DETAILS
                        </Typography>
                      </Grid>
                      <Grid item md={6} sx={{width:"100%"}}>
                        <TextField
                            error={isInsuredNameFocused && !insuredName && !isInsuredNameFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            onFocus={()=>{setIsInsuredNameFocused(true)}}
                            onBlur={()=>{setIsInsuredNameFocused(false)}}
                            type="text" 
                            value= {insuredName}
                            onChange= {insuredNameChangeHandler}                        
                            label = "Insured Name"/>
                      </Grid>
                      <Grid item md={6} sx={{width:"100%"}}>
                        <TextField
                            error={isEmailAddressTouched && !isValidEmailAddress(emailAddress) & !isEmailAddressFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            type="text"   
                            onFocus={()=>{setIsEmailAddressFocused(true)}}
                            onBlur={()=>{setIsEmailAddressFocused(false)}}                         
                            value= {emailAddress}
                            onChange= {emailAddressChangeHandler}                        
                            label = "Email"/> 
                      </Grid>
                  </>                  
                }
                {
                  insuranceType === 1 &&
                  <>
                      <Grid item md={12} sx={{width:"100%"}}>
                        <Typography                                 
                            variant='h6'
                            fontFamily="Sofia Sans Condensed"
                            sx={{
                                color:"#0C709B"
                            }}>
                            CLIENT DETAILS
                        </Typography>
                      </Grid>
                      <Grid item md={12} sx={{width:"100%"}}>
                          <TextField
                              error={isBusinessNameTouched && !businessName && !isBusinessNameFocused}
                              required
                              size="small"
                              fontFamily="Nunito"
                              sx={{width:"100%"}}
                              onFocus={()=>{setIsBusinessNameFocused(true)}}
                              onBlur={()=>{setIsBusinessNameFocused(false)}}
                              type="text" 
                              value= {businessName}
                              onChange= {businessNameChangeHandler}                        
                              label = "Business Name"/>
                      </Grid>   
                      <Grid item md={6} sx={{width:"100%"}}>
                          <TextField
                              error={isContactNameTouched && !contactName && !isContactNameFocused}
                              required
                              size="small"
                              fontFamily="Nunito"
                              sx={{width:"100%"}}
                              onFocus={()=>{setIsContactNameFocused(true)}}
                              onBlur={()=>{setIsContactNameFocused(false)}}
                              type="text" 
                              value= {contactName}
                              onChange= {contactNameChangeHandler}                        
                              label = "Contact Name"/>
                      </Grid>
                      <Grid item md={6} sx={{width:"100%"}}>
                          <TextField
                              error={isEmailAddressTouched && !isValidEmailAddress(emailAddress) && !isEmailAddressFocused}
                              required
                              size="small"
                              fontFamily="Nunito"
                              sx={{width:"100%"}}
                              onFocus={()=>{setIsEmailAddressFocused(true)}}
                              onBlur={()=>{setIsEmailAddressFocused(false)}}
                              type="text" 
                              value= {emailAddress}
                              onChange= {emailAddressChangeHandler}                        
                              label = "Email Address"/> 
                      </Grid>
                  </>
                }
                <>  
                    <Grid item md={12} sx={{width:"100%", marginTop: 2}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          ADD VEHICLE
                      </Typography>
                    </Grid>   
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isVehicleVINTouched && !vehicleVIN & !isVehicleVINFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsVehicleVINFocused(true)}}
                          onBlur={()=>{setIsVehicleVINFocused(false)}}                         
                          value= {vehicleVIN}
                          onChange= {vehicleVINChangeHandler}                        
                          label = "VIN"/> 
                    </Grid>            
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isVehicleMakeTouched && !vehicleMake & !isVehicleMakeFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsVehicleMakeFocused(true)}}
                          onBlur={()=>{setIsVehicleMakeFocused(false)}}                         
                          value= {vehicleMake}
                          onChange= {vehicleMakeChangeHandler}                        
                          label = "Make"/> 
                    </Grid>   
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isVehicleModelTouched && !vehicleModel & !isVehicleModelFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsVehicleModelFocused(true)}}
                          onBlur={()=>{setIsVehicleModelFocused(false)}}                         
                          value= {vehicleModel}
                          onChange= {vehicleModelChangeHandler}                        
                          label = "Model"/> 
                    </Grid>   
                    <Grid item md={4} sx={{width:"100%"}}>
                        <FormControl required size="small" sx={{width:"100%"}}>
                              <InputLabel>Year</InputLabel>
                              <Select
                                required
                                value={vehicleYear}
                                label="Year"
                                onChange= {vehicleYearChangeHandler}>                        
                                {
                                    getYearsList().map((year)=>{
                                        return (
                                          <MenuItem value={year}>{year}</MenuItem>
                                        )
                                    })
                                  }
                              </Select>
                        </FormControl>                        
                    </Grid>                                                                                          
                </>  
                <PolicyChangeDisclaimerSection/>                                                                                                                                     
                <Grid item xs={12}>
                      <ReCAPTCHA
                          size="normal"
                          onChange={()=>{setIsRecaptchaVerified(true)}}
                          onExpired={()=>{setIsRecaptchaVerified(false)}}
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/>   
                </Grid>                                       
                <Grid item xs={12}>
                      <Button
                          disabled={!isValidFormData()}
                          variant="contained"
                          backgroundColor= "#0C709B" 
                          startIcon={<SendIcon />}
                          size="medium"
                          onClick={submitDocumentRequestHandler}
                          sx={{
                            borderRadius: '20px',
                            fontFamily: "Nunito",
                            fontWeight: "bolder",
                          }}            
                          label = "Claim">  
                          Submit Request
                      </Button>   
                </Grid> 
            </Grid>
        </Grid>       
    </Grid>
  )
}




        

