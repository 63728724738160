import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, Stack, Backdrop, Button } from '@mui/material';
import BlogPreview from './BlogPreview';
import { getBlogPost, getPaginatedBlogPost } from '../apiClient/blogs';
import loader from '../assets/images/Loader.gif'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function BlogsPreview({insuranceType = ""}) {
    const [blogs, setBlogs] = useState([])
    const [ isBackdropVisible, setIsBackdropVisible] = useState(false);
    const [ isLoadMoreVisible, setIsLoadMoreVisible] = useState(false);


    const fetchBlogs = async (paginated = true) => {
        setBackdropVisibilityValue(true)
        if(paginated)
        {
            const blogList = await getPaginatedBlogPost(insuranceType);
            blogList.items ? setBlogs(blogList.items) : setBlogs([])   
            if(blogList.nextPageToken)
            {
                setIsLoadMoreVisible(true)
            }
        }
        else{
            const blogList = await getBlogPost(insuranceType);
            blogList.items ? setBlogs(blogList.items) : setBlogs([])   
            setIsLoadMoreVisible(false)
        }   
        setBackdropVisibilityValue(false)   
    };
    
    const setBackdropVisibilityValue = (value) => {
        setIsBackdropVisible(value);
    };

    useEffect(() => {
        fetchBlogs();
    }, [insuranceType]);

    return (
        <Box 
            component="div" 
            sx={{
                paddingTop: '10vh',
                paddingBottom: '5vh'
            }}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isBackdropVisible}
              onClick={()=>{setBackdropVisibilityValue(false)}}>
              <Box
                component="img"
                sx={{
                  height: '80px'
                }}   
                alt="loading..."                             
                src={loader}/> 
            </Backdrop> 
            <Stack spacing={2}>
                <Typography
                    fontFamily="Sofia Sans Condensed"  
                    color="#0C709B"
                    sx={{ textAlign:"left"
                }}
                    variant="h2">
                    BLOGS
                </Typography>
                <Grid container 
                    rowSpacing={2}
                    columnSpacing={{
                        lg:2,
                        mg:2,
                        sm:1,
                        xs:1
                    }}>
                    {
                        blogs.length > 0 ?
                            blogs.map((blog, index)=>{
                                return(
                                    <Grid key={index} item md={4} sm={12} xs={12}>
                                        <BlogPreview 
                                            title={blog.title} 
                                            imageSrc={blog.images[0].url} 
                                            externalLink={blog.url}
                                            publishedDate={blog.published}
                                            labels={blog.labels ? blog.labels : [] }/>
                                    </Grid>
                                )
                            }):
                            <Box
                                m={1}
                                display="flex"
                                justifyContent="center"
                                alignItems="center">
                                <Typography
                                    fontFamily="Nunito"  
                                    color="#0C709B"
                                    sx={{
                                        fontWeight: "bolder",
                                        textAlign:"center"
                                    }}
                                    variant="h5">
                                    No Blog Found
                                </Typography>  
                            </Box>                            
                    }
                </Grid>                                                            
            </Stack>   
            {
                isLoadMoreVisible &&
                <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <Button
                        sx={{
                            marginTop: '8vh'
                        }}
                        fontFamily="Nunito"  
                        startIcon={<ExpandMoreIcon />}
                        onClick={ () =>{fetchBlogs(false)}}>
                        Load more
                    </Button>   
                </Box>
            }       
        </Box>
    )
}
