import * as React from "react";
import { useState } from "react";
import { FormHelperText, Stack, Typography, Box, Grid, Snackbar, Alert, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import ClientAccessTabs from './ClientAccessTabs';
import RequestDocumentForm from "./RequestDocumentForm";
import { getInsuranceDocumentTypes } from "../apiClient/insurancePolicies";
import { requestDocumentChange } from "../apiClient/insurancePolicies";
import { useEffect } from "react";
import loader from '../assets/images/Loader.gif'

const RequestDocumentsTabs = [
  {
    title: "Personal", index: 1
  },
  {
    title: "Business", index: 0
  }
]
function RequestDocumentsSection({type, documentType}) {
  const [ selectedDocumentType, setSelectedDocumentType] = useState('');
  const [ personalPoliciesDocumentTypes, setPersonalPoliciesDocumentTypes] = useState([]);
  const [ businessPoliciesDocumentTypes, setBusinessPoliciesDocumentTypes] = useState([]);
  const [ selectedTabValue, setSelectedTabValue] = React.useState(0);
  const [ isBackdropVisible, setIsBackdropVisible] = React.useState(false);
  const [ isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [ alertMessage, setAlertMessage] = React.useState("");
  const [ alertMessageSeverity, setAlertMessageSeverity] = React.useState("info");


  const getInsurancePolicyDocumentTypes = async ()=>{
    try{
      setSelectedTabValue(type)
      setSelectedDocumentType(documentType)
      
      setBackdropVisibilityValue(true)
      await getPersonalInsurancePolicyDocumentTypes()
      await getBusinessInsurancePolicyDocumentTypes()
      setBackdropVisibilityValue(false)
    }
    catch(ex)
    {
      setBackdropVisibilityValue(false)
    }
  }
  const getPersonalInsurancePolicyDocumentTypes = async ()=>{
    const response = await getInsuranceDocumentTypes(0, 'DGR');
    setPersonalPoliciesDocumentTypes(response)   
  }

  const getBusinessInsurancePolicyDocumentTypes = async ()=>{
    const response = await getInsuranceDocumentTypes(1, 'DGR');
    setBusinessPoliciesDocumentTypes(response)
  }

  const requestDocumentChangeHandler = async (requestData)=>{
    try{
      setBackdropVisibilityValue(true)
      await requestDocumentChange(requestData);
      resetForm()      
      setBackdropVisibilityValue(false)
      setAlertMessageAndVisibility( "Policy Document Request Submitted.","success", true)}
    catch(error){
      setAlertMessageAndVisibility("Error Requesting Policy Document.", "error", true)
      setBackdropVisibilityValue(false)
    }
  }

  const resetForm = ()=>{
    let existingValue = selectedDocumentType
    setSelectedDocumentType('')
    setTimeout(()=>{
      setSelectedDocumentType(existingValue)
    },0)
  }
  
  const onTabValueChanges = async (event, newValue) => {
    setSelectedDocumentType('');
    setSelectedTabValue(newValue);
  };

  const setBackdropVisibilityValue = (value) => {
    setIsBackdropVisible(value);
  };

  const setAlertMessageAndVisibility = (message, severity, isVisible)=>{
    setAlertMessage(message)
    setAlertMessageSeverity(severity)
    setIsAlertVisible(isVisible)
    setTimeout(()=>{
      setIsAlertVisible(false)
    },5000)
  }

  useEffect(()=>{
    getInsurancePolicyDocumentTypes()
  },[])

  return (
    <Stack
        spacing={2}>     
        <Typography 
          variant='h3'
          fontFamily="Sofia Sans Condensed"
          sx={{
            color:"white",
          }}>
          REQUEST A DOCUMENT
        </Typography>
        <Typography 
          variant='body1'
          fontFamily="Nunito"
          sx={{
            color:"white",
          }}>
          At Modern Shore Insurance, requesting your insurance documentation is a breeze! By following the prompts below, 
          you are only a few clicks away from submitting your request directly to a Modern Shore Insurance advisor         
        </Typography>
        <br/>
        <br/>
        <Box sx={{ width: "100%" }}>
          <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isBackdropVisible}
              onClick={()=>{setBackdropVisibilityValue(false)}}>
              <Box
                component="img"
                sx={{
                  height: '80px'
                }}   
                alt="loading..."                             
                src={loader}/>
          </Backdrop>
          <Snackbar 
            sx={{
              marginTop: "20vh"
            }}
            anchorOrigin={{ vertical:"top", horizontal: "center" }} 
            open={isAlertVisible} 
            onClose={()=>{isAlertVisible(false)}}>
            <Alert onClose={()=>{isAlertVisible(false)}} severity={alertMessageSeverity} sx={{ width: '100%' }}>
              {alertMessage}
            </Alert>
          </Snackbar>          
          <Box sx={{ backgroundColor :  "#D0D9DD",  borderRadius: "10px" }}>
              <ClientAccessTabs 
                tabs={RequestDocumentsTabs} 
                selectedTabValue={selectedTabValue}
                onTabChange = {onTabValueChanges}/>
                
                <Box 
                  component="div" 
                  sx={{
                    backgroundColor: "#f5f5f5",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    overflowX:'hidden',
                    padding: {
                      xs: "13% 10% 5% 10%",
                      sm: "8% 10% 5% 10%",
                      md: "5% 5% 5% 5%"
                    }
                  }}>
                  <Grid container columnSpacing={2} rowSpacing={4}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Grid container rowSpacing={4}>
                          <Grid item xs={12}>
                              <Typography                                 
                                  variant='h4'
                                  fontFamily="Sofia Sans Condensed"
                                  sx={{
                                    color:"#0C709B"
                                  }}>
                                  {
                                    RequestDocumentsTabs[selectedTabValue].title.toUpperCase()
                                  } INSURANCE
                              </Typography>                       
                          </Grid>
                          <Grid item xs={12}>
                          {
                            selectedTabValue === 0 &&
                            <FormControl size="small" 
                              sx={{
                                width:{
                                  md:"60%",
                                  sm:"80%",
                                  xs:"100%"
                                },
                                marginBottom: "5%"
                              }}>                              
                              <InputLabel>Document Type</InputLabel>
                              <Select
                                value={selectedDocumentType}
                                label="Document Type"
                                onChange={(event)=>{setSelectedDocumentType(event.target.value)}}>
                                {
                                  personalPoliciesDocumentTypes.map((document, index)=>{
                                      return (
                                        <MenuItem key={index} value={document.name} name={document.name}>{document.label}</MenuItem>
                                      )
                                  })
                                }
                              </Select>
                              <FormHelperText>Select Document Type</FormHelperText>
                            </FormControl>
                          }
                          {
                            selectedTabValue === 1 &&
                            <FormControl size="small" 
                              sx={{
                                width:{
                                  md:"60%",
                                  sm:"80%",
                                  xs:"100%"
                                },
                                marginBottom: "5%"
                              }}>                                
                              <InputLabel>Document Type</InputLabel>
                              <Select 
                                value={selectedDocumentType}
                                label="Document Type"
                                onChange={(event)=>{setSelectedDocumentType(event.target.value)}}>
                                  {
                                    businessPoliciesDocumentTypes.map((document, index)=>{
                                        return (
                                          <MenuItem key={index} value={document.name} name={document.name}>{document.label}</MenuItem>
                                        )
                                    })
                                  }
                              </Select>
                              <FormHelperText>Select Document Type</FormHelperText>
                            </FormControl>
                          }  
                          </Grid>  
                        </Grid>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                          <RequestDocumentForm
                            requestDocumentChangeHandler={requestDocumentChangeHandler}
                            insuranceType={selectedTabValue}
                            documentType={selectedDocumentType}/>
                      </Grid>              
                  </Grid>
                </Box>                               
          </Box>
        </Box>        
    </Stack>
  )
}

export default RequestDocumentsSection