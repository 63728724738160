import React from 'react'
import { useEffect } from 'react';
import { Grid, Typography, TextField, Button } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import NumberFormat from 'react-number-format';
import { isValidEmailAddress, isValidPostalCode } from '../utils/utilityFunctions';
import { PolicyChangeDisclaimerSection } from './PolicyChangeDisclaimerSection';


export default function PersonalInsuranceMailingAddressChangeForm({insuranceType,requestPolicyChangeHandler}) {
  useEffect(()=>{
    resetForm();
  },[insuranceType])

  const [insuredName, setInsuredName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [newMailingAddress, setNewMailingAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");

  const [isInsuredNameFocused, setIsInsuredNameFocused] = useState(false)
  const [isEmailAddressFocused, setIsEmailAddressFocused] = useState(false)
  const [isNewMailingAddressFocused, setIsNewMailingAddressFocused] = useState(false)
  const [isCityFocused, setIsCityFocused] = useState(false)
  const [isStateFocused, setIsStateFocused] = useState(false)
  const [isZipcodeFocused, setIsZipcodeFocused] = useState(false)

  const [isInsuredNameTouched, setIsInsuredNameTouched] = useState(false)
  const [isEmailAddressTouched, setIsEmailAddressTouched] = useState(false)
  const [isNewMailingAddressTouched, setIsNewMailingAddressTouched] = useState(false)
  const [isCityTouched, setIsCityTouched] = useState(false)
  const [isStateTouched, setIsStateTouched] = useState(false)
  const [isZipcodeTouched, setIsZipcodeTouched] = useState(false)

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)

  const insuredNameChangeHandler = (event) =>{
    !isInsuredNameTouched && setIsInsuredNameTouched(true)
    setInsuredName(event.target.value)
  }

  const emailAddressChangeHandler = (event) =>{
    !isEmailAddressTouched && setIsEmailAddressTouched(true)
    setEmailAddress(event.target.value.trim())
  }

  const newMailingAddressChangeHandler = (event) =>{
    !isNewMailingAddressTouched && setIsNewMailingAddressFocused(true)
    setNewMailingAddress(event.target.value)
  }

  const address2ChangeHandler = (event) =>{
    setAddress2(event.target.value)
  }

  const cityChangeHandler = (event) =>{
    !isCityTouched && setIsCityTouched(true)
    setCity(event.target.value)
  }
  const stateChangeHandler = (event) =>{
    !isStateTouched && setIsStateTouched(true)
    setState(event.target.value)
  }
  const zipcodeChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    !isZipcodeTouched && setIsZipcodeTouched(true)
    setZipcode(value)
  }

  const submitAddressChangeRequestHandler = async ()=>{
    let requestData = { 
      contactName: insuredName,
      email: emailAddress,
      accountName: insuredName,
      details : {
        newMailingAddress: {
          address1: newMailingAddress,
          address2: address2,
          city: city,
          state: state,
          postalCode: zipcode
        }
      }
    }
    await requestPolicyChangeHandler(requestData)
  }

  const isValidFormData = () =>{
    return isRecaptchaVerified && insuredName && isValidEmailAddress(emailAddress) && 
           newMailingAddress && city && state && isValidPostalCode(zipcode)
  }

  const resetForm = () =>{
    setInsuredName("");
    setEmailAddress("");
    setNewMailingAddress("");
    setAddress2("");
    setCity("");
    setState("");
    setZipcode("");

    setIsInsuredNameFocused("");
    setIsEmailAddressFocused("");
    setIsNewMailingAddressFocused("");
    setIsCityFocused("");
    setIsStateFocused("");
    setIsZipcodeFocused("");

    setIsInsuredNameTouched("");
    setIsEmailAddressTouched("");
    setIsNewMailingAddressTouched("");
    setIsCityTouched("");
    setIsStateTouched("");
    setIsZipcodeTouched("");

    setIsRecaptchaVerified(false)
  }

  return (
    <Grid container rowSpacing={4}>  
        <Grid item xs={12}>
            <Typography                                 
                variant='h4'
                fontFamily="Sofia Sans Condensed"
                sx={{
                    color:"#0C709B"
                }}>
                REQUEST FORM
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container rowSpacing={2} columnSpacing={2}>
                <>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          CLIENT DETAILS
                      </Typography>
                    </Grid>
                    <Grid item md={6} sx={{width:"100%"}}>
                    <TextField
                        error={isInsuredNameFocused && !insuredName && !isInsuredNameFocused}
                        required
                        size="small"
                        fontFamily="Nunito"
                        sx={{width:"100%"}}
                        onFocus={()=>{setIsInsuredNameFocused(true)}}
                        onBlur={()=>{setIsInsuredNameFocused(false)}}
                        type="text" 
                        value= {insuredName}
                        onChange= {insuredNameChangeHandler}                        
                        label = "Insured Name"/>
                    </Grid>
                    <Grid item md={6} sx={{width:"100%"}}>
                      <TextField
                          error={isEmailAddressTouched && !isValidEmailAddress(emailAddress) & !isEmailAddressFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsEmailAddressFocused(true)}}
                          onBlur={()=>{setIsEmailAddressFocused(false)}}                         
                          value= {emailAddress}
                          onChange= {emailAddressChangeHandler}                        
                          label = "Email"/> 
                    </Grid>
                </>
                <>  
                    <Grid item md={12} sx={{width:"100%", marginTop: 2}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          NEW MAILING ADDRESS
                      </Typography>
                    </Grid>     
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isNewMailingAddressTouched && !newMailingAddress && !isNewMailingAddressFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          onFocus={()=>{setIsNewMailingAddressFocused(true)}}
                          onBlur={()=>{setIsNewMailingAddressFocused(false)}}
                          type="text" 
                          value= {newMailingAddress}
                          onChange= {newMailingAddressChangeHandler}                        
                          label = "Address"/>
                    </Grid>     
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text" 
                          value= {address2}
                          onChange= {address2ChangeHandler}                        
                          label = "Address 2"/>
                    </Grid>
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isCityTouched && !city & !isCityFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsCityFocused(true)}}
                          onBlur={()=>{setIsCityFocused(false)}}                         
                          value= {city}
                          onChange= {cityChangeHandler}                        
                          label = "City"/> 
                    </Grid>          
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isStateTouched && !state & !isStateFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsStateFocused(true)}}
                          onBlur={()=>{setIsStateFocused(false)}}                         
                          value= {state}
                          onChange= {stateChangeHandler}                        
                          label = "State"/> 
                    </Grid>  
                    <Grid item md={4} sx={{width:"100%"}}>
                      <NumberFormat
                          sx={{width: "100%"}}
                          error={isZipcodeTouched && !isValidPostalCode(zipcode) & !isZipcodeFocused}
                          required
                          label="Zipcode"
                          size="small"
                          type="text" 
                          value={zipcode} 
                          format="#####" 
                          mask="_" 
                          customInput={TextField}
                          onFocus={()=>{setIsZipcodeFocused(true)}}
                          onBlur={()=>{setIsZipcodeFocused(false)}}   
                          onValueChange={zipcodeChangeHandler}/>                       
                    </Grid>                                                                     
                </>                
                <PolicyChangeDisclaimerSection/>                                                                                                                       
                <Grid item xs={12}>
                      <ReCAPTCHA
                          size="normal"
                          onChange={()=>{setIsRecaptchaVerified(true)}}
                          onExpired={()=>{setIsRecaptchaVerified(false)}}
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/>   
                </Grid>                                       
                <Grid item xs={12}>
                      <Button
                          disabled={!isValidFormData()}
                          variant="contained"
                          backgroundColor= "#0C709B" 
                          startIcon={<SendIcon />}
                          size="medium"
                          onClick={submitAddressChangeRequestHandler}
                          sx={{
                            borderRadius: '20px',
                            fontFamily: "Nunito",
                            fontWeight: "bolder",
                          }}            
                          label = "Claim">  
                          Submit Request
                      </Button>   
                </Grid> 
            </Grid>
        </Grid>       
    </Grid>
  )
}




        

