import * as React from "react";
import { Stack, Typography, Box, Grid, Link} from '@mui/material';
import ContactOfficeToReportClaim from './ContactOfficeToReportClaim'

function ReportClaimThroughAgent() {
  return (
    <Stack
        spacing={2}>     
        <Typography 
          variant='h2'
          fontFamily="Sofia Sans Condensed"
          sx={{
            color:"white",
          }}>
          REPORT A CLAIM 
        </Typography>
        <Typography 
          variant='h5'
          fontFamily="Sofia Sans Condensed"
          sx={{
            color:"white",
          }}>
          THROUGH YOUR AGENT
        </Typography>
        <br/>
        <Grid container>
            <Grid item md={7} sm={12} xs={12}>
                <Box 
                    component="div" 
                    sx={{
                        backgroundColor: "#f5f5f5",
                        borderRadius: "10px",
                        overflowX:'hidden',
                        padding: {
                            xs: "13% 10% 5% 10%",
                            sm: "8% 10% 5% 10%",
                            md: "5% 5% 5% 5%"
                        }
                    }}>     
                    <Typography 
                        variant='h4'
                        fontFamily="Sofia Sans Condensed"
                        sx={{
                            color:"#0C709B"
                        }}>
                        CONTACT OUR OFFICE
                    </Typography> 
                    <br/>       
                    <ContactOfficeToReportClaim/>                            
                </Box>   
            </Grid>
        </Grid>   
       
    </Stack>
  )
}

export default ReportClaimThroughAgent