import React from 'react';
import { Stack, Typography, Button } from '@mui/material';

function KnowledgeAndResourceFilter({blogType, setBlogType}) {
  return (
    <Stack
        spacing={2}>     
        <Typography 
          variant='h4'
          fontFamily="Sofia Sans Condensed"
          sx={{
            color:"#0C709B",
          }}>
          FILTER BY:
        </Typography>
        <Stack 
          direction={{
            lg:"row",
            md:"row",
            sm:"column",
            xs:"column"
          }}
          spacing={2}>
        {
            ['Personal Insurance','Business Insurance','HR & Employee Benefits']
            .map((key)=>{
                return (
                    <Button
                        key={key} 
                        sx={{
                            fontFamily:"Nunito",
                            fontWeight:"bolder",
                            borderRadius:"20px",
                            color: blogType == key ? "white" : "#0C709B",
                            borderColor: "#0C709B",
                        }}
                        onClick={()=>{blogType == key ? setBlogType("") : setBlogType(key)}}
                        size="large"
                        variant= {blogType == key ? "contained" : "outlined"} >
                        {key}
                    </Button>    
                )
            })

        }
        </Stack>
    </Stack>
  )
}

export default KnowledgeAndResourceFilter