import React from 'react'
import { Box, Stack, Typography, Grid } from '@mui/material';
import logoLight from '../assets/images/logo_2.png'
import Fade from 'react-reveal/Fade';


const ourMission = "Modern Shore Insurance offers a personalized approach to providing financial protection by providing sound insurance advice while comparing the best insurance coverage options from reliable insurance companies. Our professional advisors focus on insurance knowledge and community relationships, giving our firm the strength to succeed and the passion to care!"

export default function OurMission() {
  return (
    <>
      <Box 
          component="div" 
          sx={{
            backgroundColor: '#2c9ac9',
            minHeight: '100vh',
            overflowX:'hidden',
            display:{
              xs:"none",
              sm:"none",
              md:"block",
              ld:"block"
            } 
          }}>
          <Stack
            align="left"
            direction="column"
            alignItems="left"
            justifyContent="center"
            paddingLeft="15%"
            paddingRight="15%"
            spacing={2}
            sx={{
              paddingTop: "25vh",
            }}>
            <Box 
                component="div" 
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: "10px",
                  overflowX:'hidden',
                  padding: {
                        xs: "13% 10% 5% 10%",
                        sm: "8% 10% 5% 10%",
                        md: "5% 5% 5% 5%"
                  }
              }}>
              <Grid container>
                  <Grid item md={8} lg={8}>
                      <Stack>
                          <Fade top delay={50}>                    
                            <Typography 
                              variant='h2'
                              fontFamily="Sofia Sans Condensed"
                              sx={{
                                color:"#0C709B",
                                textUnderlineOffset: '20%',
                                textDecorationThickness: '1px',
                              }}>
                              OUR MISSION
                            </Typography >
                        </Fade>
                        <Fade top delay={70}>    
                            <Typography 
                              variant='h4'
                              fontFamily="Nunito"
                              fontStyle="italic"
                              sx={{
                                color:"#0C709B",
                                width:"50vw",
                                textAlign: "left",
                                fontWeight: "bold",
                                textUnderlineOffset: '20%',
                                textDecorationThickness: '1px',
                                marginTop: '1vh',
                                maxWidth: '100%',
                                marginBottom: '1vh'
                              }}>
                              “Inspiring a new perspective on financial protection”
                            </Typography >                                          
                        </Fade>
                        <Fade top delay={80}>    
                            <Typography 
                              variant='h6'
                              fontFamily="Nunito"
                              sx={{
                                color:"#0C709B",
                                width:"50vw",
                                textAlign: "left",
                                fontWeight: "bold",
                                textUnderlineOffset: '20%',
                                textDecorationThickness: '1px',
                                width: '100%',
                                marginTop: '2vh',
                                textAlign: 'justify'
                              }}>
                              {ourMission}
                            </Typography >                           
                        </Fade>                       
                      </Stack>
                  </Grid>
                  <Grid item md={4} lg={4} sx={{textAlign: 'center', marginTop: '6vh'}}>
                    <Box
                      component="img"
                      sx={{   
                        height: 200
                      }}
                      alt="Modern SHore Insurance"
                      src={logoLight}/>  
                  </Grid>
              </Grid>
            </Box>  
          </Stack> 
      </Box> 
      <Box 
        component="div" 
        sx={{
          backgroundColor: '#2c9ac9',
          minHeight: '100vh',
          overflowX:'hidden',
          display:{
            xs:"block",
            sm:"block",
            md:"none",
            ld:"none"
          } 
        }}>
        <Stack
          align="left"
          direction="column"
          alignItems="left"
          justifyContent="center"
          paddingLeft="10%"
          paddingRight="10%"
          spacing={2}
          sx={{
            paddingTop: "25vh",
          }}>
          <Fade top delay={50}>
              <Typography 
                variant='h2'
                fontFamily="Sofia Sans Condensed"
                sx={{
                  color:"white",
                  textUnderlineOffset: '20%',
                  textDecorationThickness: '1px',
                }}>
                OUR MISSION
              </Typography >
          </Fade>
          <Fade top delay={70}>
              <Typography 
                variant='h4'
                fontFamily="Nunito"
                fontStyle="italic"
                sx={{
                  color:"white",
                  width:"80vw",
                  textAlign: "left",
                  textUnderlineOffset: '20%',
                  textDecorationThickness: '1px',
                }}>
                  “Inspiring a new perspective on financial protection”
              </Typography > 
          </Fade>
          <Fade top delay={80}>
              <Typography 
                variant='body1'
                fontFamily="Nunito"
                sx={{
                  color:"white",
                  width:"80vw",
                  textAlign: "left",
                  textUnderlineOffset: '20%',
                  textDecorationThickness: '1px',
                }}>
                {ourMission}
              </Typography >               
          </Fade>                       
        </Stack> 
      </Box>     
    </>   
  )
}




        