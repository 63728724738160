import React from 'react'
import { useEffect } from 'react';
import { Grid, Typography, TextField, Button } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import NumberFormat from 'react-number-format';
import { isValidPostalCode, isValidEmailAddress } from '../utils/utilityFunctions';
import { PolicyChangeDisclaimerSection } from './PolicyChangeDisclaimerSection';



export default function BusinessInsuranceAddressRemoveForm({insuranceType, requestPolicyChangeHandler}) {
  useEffect(()=>{
    resetForm();
  },[insuranceType])

  const [businessName, setBusinessName] = useState("");
  const [contactName, setContactName] = useState("");
  const [emailAddress, setEmailAddress] = useState(""); 
  const [previousAddress, setPreviousAddress] = useState("");
  const [previousAddress2, setPreviousAddress2] = useState("");
  const [previousCity, setPreviousCity] = useState("");
  const [previousState, setPreviousState] = useState("");
  const [previousZipcode, setPreviousZipcode] = useState("");

  const [isBusinessNameFocused, setIsBusinessNameFocused] = useState(false)
  const [isContactNameFocused, setIsContactNameFocused] = useState(false)
  const [isEmailAddressFocused, setIsEmailAddressFocused] = useState(false)
  const [isPreviousAddressFocused, setIsPreviousAddressFocused] = useState(false)
  const [isPreviousCityFocused, setIsPreviousCityFocused] = useState(false)
  const [isPreviousStateFocused, setIsPreviousStateFocused] = useState(false)
  const [isPreviousZipcodeFocused, setIsPreviousZipcodeFocused] = useState(false)

  const [isBusinessNameTouched, setIsBusinessNameTouched] = useState(false)
  const [isContactNameTouched, setIsContactNameTouched] = useState(false)
  const [isEmailAddressTouched, setIsEmailAddressTouched] = useState(false)
  const [isPreviousAddressTouched, setIsPreviousAddressTouched] = useState(false)
  const [isPreviousCityTouched, setIsPreviousCityTouched] = useState(false)
  const [isPreviousStateTouched, setIsPreviousStateTouched] = useState(false)
  const [isPreviousZipcodeTouched, setIsPreviousZipcodeTouched] = useState(false)

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)

  const businessNameChangeHandler = (event) =>{
    !isBusinessNameTouched && setIsBusinessNameTouched(true)
    setBusinessName(event.target.value)
  }

  const emailAddressChangeHandler = (event) =>{
    !isEmailAddressTouched && setIsEmailAddressTouched(true)
    setEmailAddress(event.target.value.trim())
  }

  const contactNameChangeHandler = (event) =>{
    !isContactNameTouched && setIsContactNameTouched(true)
    setContactName(event.target.value)
  }

  const previousAddressChangeHandler = (event) =>{
    !isPreviousAddressTouched && setIsPreviousAddressFocused(true)
    setPreviousAddress(event.target.value)
  }

  const previousAddress2ChangeHandler = (event) =>{
    setPreviousAddress2(event.target.value)
  }

  const previousCityChangeHandler = (event) =>{
    !isPreviousCityTouched && setIsPreviousCityTouched(true)
    setPreviousCity(event.target.value)
  }
  const previousStateChangeHandler = (event) =>{
    !isPreviousStateTouched && setIsPreviousStateTouched(true)
    setPreviousState(event.target.value)
  }
  const previousZipcodeChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    !isPreviousZipcodeTouched && setIsPreviousZipcodeTouched(true)
    setPreviousZipcode(value)
  }

  const submitAddressChangeRequestHandler = async ()=>{
    let requestData = {
      contactName: contactName,
      email: emailAddress,
      accountName: businessName,
      details : {
        removeAddress: {
          address1: previousAddress,
          address2: previousAddress2,
          city: previousCity,
          state: previousState,
          postalCode: previousZipcode
        }
      }
    }
    await requestPolicyChangeHandler(requestData)
  }

  const isValidFormData = () =>{
    return isRecaptchaVerified && businessName && isValidEmailAddress(emailAddress) && 
           contactName && previousAddress && previousCity && 
           previousState && isValidPostalCode(previousZipcode)
  }

  const resetForm = () =>{
    setBusinessName("");
    setContactName("");
    setEmailAddress("");
    setPreviousAddress2("");
    setPreviousCity("");
    setPreviousState("");
    setPreviousZipcode("");

    setIsBusinessNameFocused(false);
    setIsContactNameFocused(false);
    setIsEmailAddressFocused(false);
    setIsPreviousAddressFocused("");
    setIsPreviousCityFocused("");
    setIsPreviousStateFocused("");
    setIsPreviousZipcodeFocused("");

    setIsBusinessNameTouched(false);
    setIsContactNameTouched(false);
    setIsEmailAddressTouched(false);
    setIsPreviousAddressTouched("");
    setIsPreviousCityTouched("");
    setIsPreviousStateTouched("");
    setIsPreviousZipcodeTouched("");

    setIsRecaptchaVerified(false)
  }

  return (
    <Grid container rowSpacing={4}>  
        <Grid item xs={12}>
            <Typography                                 
                variant='h4'
                fontFamily="Sofia Sans Condensed"
                sx={{
                    color:"#0C709B"
                }}>
                REQUEST FORM
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container rowSpacing={2} columnSpacing={2}>
                <>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          CLIENT DETAILS
                      </Typography>
                    </Grid>
                    <Grid item md={12} sx={{width:"100%"}}>
                        <TextField
                            error={isBusinessNameTouched && !businessName && !isBusinessNameFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            onFocus={()=>{setIsBusinessNameFocused(true)}}
                            onBlur={()=>{setIsBusinessNameFocused(false)}}
                            type="text" 
                            value= {businessName}
                            onChange= {businessNameChangeHandler}                        
                            label = "Business Name"/>
                    </Grid>   
                    <Grid item md={6} sx={{width:"100%"}}>
                        <TextField
                            error={isContactNameTouched && !contactName && !isContactNameFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            onFocus={()=>{setIsContactNameFocused(true)}}
                            onBlur={()=>{setIsContactNameFocused(false)}}
                            type="text" 
                            value= {contactName}
                            onChange= {contactNameChangeHandler}                        
                            label = "Contact Name"/>
                    </Grid>
                    <Grid item md={6} sx={{width:"100%"}}>
                        <TextField
                            error={isEmailAddressTouched && !isValidEmailAddress(emailAddress) && !isEmailAddressFocused}
                            required
                            size="small"
                            fontFamily="Nunito"
                            sx={{width:"100%"}}
                            onFocus={()=>{setIsEmailAddressFocused(true)}}
                            onBlur={()=>{setIsEmailAddressFocused(false)}}
                            type="text" 
                            value= {emailAddress}
                            onChange= {emailAddressChangeHandler}                        
                            label = "Email Address"/> 
                    </Grid>
                </>
                <>  
                    <Grid item md={12} sx={{width:"100%", marginTop: 2}}>
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          REMOVE ADDRESS
                      </Typography>
                    </Grid>     
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isPreviousAddressTouched && !previousAddress && !isPreviousAddressFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          onFocus={()=>{setIsPreviousAddressFocused(true)}}
                          onBlur={()=>{setIsPreviousAddressFocused(false)}}
                          type="text" 
                          value= {previousAddress}
                          onChange= {previousAddressChangeHandler}                        
                          label = "Address"/>
                    </Grid>     
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text" 
                          value= {previousAddress2}
                          onChange= {previousAddress2ChangeHandler}                        
                          label = "Address 2"/>
                    </Grid>
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isPreviousCityTouched && !previousCity & !isPreviousCityFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsPreviousCityFocused(true)}}
                          onBlur={()=>{setIsPreviousCityFocused(false)}}                         
                          value= {previousCity}
                          onChange= {previousCityChangeHandler}                        
                          label = "City"/> 
                    </Grid>          
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isPreviousStateTouched && !previousState & !isPreviousStateFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsPreviousStateFocused(true)}}
                          onBlur={()=>{setIsPreviousStateFocused(false)}}                         
                          value= {previousState}
                          onChange= {previousStateChangeHandler}                        
                          label = "State"/> 
                    </Grid>  
                    <Grid item md={4} sx={{width:"100%"}}>
                      <NumberFormat
                          sx={{width: "100%"}}
                          error={isPreviousZipcodeTouched && !isValidPostalCode(previousZipcode) & !isPreviousZipcodeFocused}
                          required
                          label="Zipcode"
                          size="small"
                          type="text" 
                          value={previousZipcode} 
                          format="#####" 
                          mask="_" 
                          customInput={TextField}
                          onFocus={()=>{setIsPreviousZipcodeFocused(true)}}
                          onBlur={()=>{setIsPreviousZipcodeFocused(false)}}   
                          onValueChange={previousZipcodeChangeHandler}/>                       
                    </Grid>                                                                     
                </>  
                <PolicyChangeDisclaimerSection/>                                                                                                                                                      
                <Grid item xs={12}>
                      <ReCAPTCHA
                          size="normal"
                          onChange={()=>{setIsRecaptchaVerified(true)}}
                          onExpired={()=>{setIsRecaptchaVerified(false)}}
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/>   
                </Grid>                                       
                <Grid item xs={12}>
                      <Button
                          disabled={!isValidFormData()}
                          variant="contained"
                          backgroundColor= "#0C709B" 
                          startIcon={<SendIcon />}
                          size="medium"
                          onClick={submitAddressChangeRequestHandler}
                          sx={{
                            borderRadius: '20px',
                            fontFamily: "Nunito",
                            fontWeight: "bolder",
                          }}                                  
                          label = "Claim">  
                          Submit Request
                      </Button>   
                </Grid> 
            </Grid>
        </Grid>       
    </Grid>
  )
}




        

