import React from 'react';
import { useState, useEffect } from 'react';
import { Stack, Box, Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import ReportClaimToInsurance from '../components/ReportClaimToInsurance';
import ClientAccessNavBar from '../components/ClientAccessNavBar';
import ClientAccessHeader from '../components/ClientAccessHeader'
import ClientAccessImageBackGround from '../assets/images/ClientAccessImageBackGround.jpg';
import ReportClaimThroughAgent from '../components/ReportClaimThroughAgent';     
import { Seo } from '../components/Seo';

function ReportClaim() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>      
      <Seo
          title="Report a Claim"
          description="At Modern Shore Insurance, you can either report a claim directly to the insurance carrier or through your agent"/>                                                          
      <section>          
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section>
      <section>
        <ClientAccessNavBar selected="ReportClaim"/>
      </section>  
      <section id="ReportClaim">  
        <Stack>
            <ClientAccessHeader backgroundImage={ClientAccessImageBackGround}/>
            <Box 
              component="div" 
              sx={{
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: "linear-gradient(#0C709B, #B6ECF6);",
                  minHeight: '150vh',
                  overflowX:'hidden',
                  padding:"7% 10% 5% 7%"
              }}>
              <ReportClaimToInsurance/>     
            </Box>  
            <Box 
              component="div" 
              sx={{
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: "linear-gradient(#0C709B, #B6ECF6);",
                  minHeight: '100vh',
                  overflowX:'hidden',
                  padding:"7% 10% 5% 7%"
              }}>
              <ReportClaimThroughAgent/>
            </Box>                        
        </Stack>
      </section>
    </Stack>
  )
}

export default ReportClaim