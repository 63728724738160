
import React from 'react';
import { Stack, Typography, Button, Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import { useEffect } from 'react';

function PageNotFound() {
  function goBack() {
    window.history.back();
  }
  useEffect(()=>{
    document.title = "Not Found"
    window.scrollTo(0, 0)
  },[])


  return (
    <Stack sx={{height: '100vh'}}>      
      <section>
        <Navbar isNavBarTransparent={false}/>
        <CssBaseline/>
      </section> 
      <section id="PageNotFound">  
        <Stack
            sx={{
                textAlign: "center",
                marginTop: "20vh"
            }}>
            <Typography 
                sx={{
                  marginBottom: "2vh",
                }}
                color="#0C709B"
                variant='h4'
                fontWeight='bold'
                fontFamily="Nunito">
                Page Not Found
            </Typography>   
            <Typography 
                sx={{
                  marginBottom: "1vh",
                }}            
                color="#0C709B"
                variant='body1'
                fontWeight='bold'
                fontFamily="Nunito">
                The link may be broken, or the page may have been removed.
            </Typography>      
            <Box
                m={1}
                display="flex"
                color="#0C709B"
                justifyContent="center"
                alignItems="center">
                <Button
                    variant="contained"
                    onClick={goBack}>
                    Go Back
                </Button>   
              </Box> 
        </Stack>
      </section>
    </Stack>
  )
}

export default PageNotFound