import React from 'react';
import { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import HRAndBenefitsHeader from '../components/HRAndBenefitsHeader';
import HRAndBenefitsBody from '../components/HRAndBenefitsBody';
import { Seo } from '../components/Seo';

function HRAndBenefits() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>      
      <Seo
          title="HR and Benefits"
          description="Full‐service solutions for your business. Meet the demands of the modern workplace with quality Employee Benefits, Payroll Services, Human Resource Support and more"/>                                                          
      <section>          
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section> 
      <section>
        <Stack>
            <HRAndBenefitsHeader/>
            <HRAndBenefitsBody/>
        </Stack>
      </section>
    </Stack>
  )
}

export default HRAndBenefits