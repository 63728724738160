import React from 'react'
import { Box, Grid, Button, Typography, Stack} from '@mui/material';
import ApplyNow from '../assets/images/ApplyNow.jpg';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router';

export default function JoinOurTeamHeader() {
    const navigate = useNavigate();
    return (
        <Box 
            component="div" 
            sx={{
                height: '100vh',
            }}>
            <Grid container>
                <Grid item md={4} sm={12} xs={12} 
                    sx={{
                        display:{
                            sm: "none",
                            xs: "none",
                            md: "block",
                            lg: "block"
                        }
                    }}>
                    <Box 
                        component="div" 
                        sx={{
                            backgroundImage: `url(${ApplyNow})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            height: '100vh',
                            backgroundSize: '100% 100%',
                            overflowX:'hidden'
                        }}>
                    </Box>
                </Grid> 
                <Grid item md={8} sm={12} xs={12}>
                    <Stack
                        direction="column"
                        alignItems="top"
                        justifyContent="top"
                        spacing={6}
                        sx={{
                            backgroundColor: "#2c9ac9",
                            height: '100vh',
                            padding: {
                                lg: "20% 10% 32% 10%",
                                md: "20% 10% 32% 10%",
                                sm: "20% 10% 32% 10%",
                                xs: "40% 10% 32% 10%"
                            },
                        }}>             
                        <Typography 
                            variant='h3'
                            fontFamily="Sofia Sans Condensed"
                            sx={{
                                color:"white"
                            }}>
                            JOIN <br/><strong>OUR TEAM</strong>
                        </Typography>                
                        <Typography 
                            variant='body1'
                            fontFamily="Nunito"
                            sx={{
                                color:"white",
                                fontWeight: 'bolder',
                            }}>
                            We are always looking for talented and reliable professionals to join our growing team. Whether
                            you are interested in starting your insurance career or taking your skills to the next level, we
                            would like to hear from you.
                            <br/><br/>
                            Apply Now to submit your information to our hiring
                            manager. We will review and contact you regarding potential opportunities within our company.
                        </Typography> 
                        <br/>
                        <Button
                            variant="outlined"
                            backgroundcolor= "white"  
                            onClick={()=>{navigate("/ApplyNow")}}
                            startIcon={<SendIcon />}
                            sx={{
                                fontFamily:"Nunito",
                                fontWeight:"bolder",                                
                                color: "white",
                                borderColor: "white",
                                size:"large",
                                width: {
                                    lg:"30%",
                                    md:"30%",
                                    sm:"75%",
                                    xs:"100%"
                                },
                                '&:hover': { 
                                    color: "white",
                                    borderColor: "white",
                                }
                            }}
                            label = "Apply Now">  
                            Apply Now
                        </Button>  
                    </Stack>                  
                </Grid>              
            </Grid>   
        </Box>               
    )
}




        