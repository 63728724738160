import { Box, Typography, Grid, Stack, Link, Button  } from '@mui/material';
import logoDark from '../assets/images/logo_4.png'
import { useNavigate} from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SocialMediaIcon from './SocialMediaIcon'
import facebook from '../assets/images/facebook.png'
import instagram from '../assets/images/Instagram_white.png'
import linkedin from '../assets/images/LinkedIn.png'
import twitter from '../assets/images/Twitter-logo.png'



const servicesOptions = [
    { title: "Personal Insurance", key: "PersonalInsurance", externalLink: null},
    { title: "Business Insurance", key: "BusinessInsurance", externalLink: null},
    { title: "HR & Benefits", key: "HR&Benefits", externalLink: null},
];
const aboutUsOptions = [
    { title: "Our Story", key: "OurStory", externalLink: null},
    { title: "Knowledge & Resources", key: "KnowledgeResources", externalLink: null},
    { title: "Welcome to Modern Shore", key: "Welcome", externalLink: null},
    { title: "Join our Team", key: "JoinOurTeam", externalLink: null},
    { title: "Agency Affiliation", key: "AgencyAffiliation", externalLink: null},
    { title: "Contact Us", key: "ContactUs", externalLink: null}
];
const clientAccessOptions = [
    { title: "Report a Claim", key: "ReportClaim", externalLink: null},
    { title: "Make a Payment", key: "MakePayment", externalLink: "https://modernshoreins.epaypolicy.com/"},
    { title: "Request Documents", key: "RequestDocuments", externalLink: null},
    { title: "Policy Change Request", key: "PolicyChangeRequest", externalLink: null},
    { title: "Contact Customer Service", key: "ContactCustomerService", externalLink: null},
];

const Footer = ()=>{
    const navigate = useNavigate();
    const [termsVisible, setIsTermsVisible] = useState(false)
    const [legalTermsVisible, setIsLegalTermsVisible] = useState(false)

    return (
        <Box 
            component="div" 
            sx={{
                backgroundColor: '#0C709B',
                padding:{
                    md: '5% 7% 3% 7%',
                    sm: '5% 7% 3% 7%',
                    xs: '10% 15% 10% 15%',
                },
                borderTop: '1px solid white'
            }}>   
            <Grid container rowSpacing={4} columnSpacing={2}>
                <Grid item md={2} sm={12} xs={12}>
                    <Box
                        component="img"
                        sx={{
                            height: "100px",     
                            cursor:'pointer'
                        }}
                        onClick={()=> navigate('/')}
                        alt="Modern Shore Insurance"
                        src={logoDark}/>
                </Grid>  
                <Grid item md={2} sm={6} xs={12}>
                    <Stack>
                        <Typography
                            display={{
                                lg: "none",
                                md: "none",
                                sm: "none",
                                xs: "block"
                            }}
                            noWrap                            
                            sx={{
                                fontFamily: "Nunito",
                                color: "#B6ECF6",
                                fontWeight: 1000,
                                marginBottom: "20px",
                                width: '60%',
                                borderBottom: '1px solid #B6ECF6'
                            }}
                            variant="body1">
                            Services
                        </Typography>
                        <Typography
                            display={{
                                lg: "block",
                                md: "block",
                                sm: "block",
                                xs: "none"
                            }}
                            sx={{
                                fontFamily: "Nunito",
                                color: "#B6ECF6",
                                fontWeight: 1000,
                                marginBottom: "20px",
                                width: '60%',
                                borderBottom: '1px solid #B6ECF6'
                            }}
                            variant="h5">
                            Services
                        </Typography>
                        {
                            servicesOptions.map((option, index)=>{
                                return (
                                    <Grid key={index}>
                                        <Typography
                                            display={{
                                                md: "block",
                                                sm: "none",
                                                xs: "none"
                                            }}
                                            variant="body1"
                                            sx={{
                                                fontFamily: "Nunito",
                                                color: "white",
                                                cursor:'pointer'                                          
                                            }}>
                                            {
                                                option.externalLink === null ?
                                                <Link sx={{color:"white"}} href={option.key}>
                                                    {option.title}
                                                </Link> :
                                                <Link sx={{color:"white"}} href={option.externalLink}>
                                                    {option.title}
                                                </Link> 
                                            }                                        
                                        </Typography>  
                                        <Typography
                                            display={{
                                                md: "none",
                                                sm: "block",
                                                xs: "block"
                                            }}
                                            variant="body2"
                                            sx={{
                                                fontFamily: "Nunito",
                                                color: "white",
                                                cursor:'pointer'                                          
                                            }}>
                                            {
                                                option.externalLink === null ?
                                                <Link sx={{color:"white"}} href={option.key}>
                                                    {option.title}
                                                </Link> :
                                                <Link sx={{color:"white"}} href={option.externalLink}>
                                                    {option.title}
                                                </Link> 
                                            }                                          
                                        </Typography>                                                                                
                                    </Grid>      
                                )
                            })
                        }                       
                    </Stack>
                </Grid> 
                <Grid item md={2} sm={6} xs={12}>
                    <Stack>
                        <Typography
                            display={{
                                lg: "none",
                                md: "none",
                                sm: "none",
                                xs: "block"
                            }}
                            sx={{
                                fontFamily: "Nunito",
                                color: "#B6ECF6",
                                fontWeight: 1000,
                                marginBottom: "20px",
                                width: '60%',
                                borderBottom: '1px solid #B6ECF6'
                            }}
                            variant="body1">
                            About us
                        </Typography>
                        <Typography
                            display={{
                                lg: "block",
                                md: "block",
                                sm: "block",
                                xs: "none"
                            }}
                            sx={{
                                fontFamily: "Nunito",
                                color: "#B6ECF6",
                                fontWeight: 1000,
                                marginBottom: "20px",
                                width: '60%',
                                borderBottom: '1px solid #B6ECF6'
                            }}
                            variant="h5">
                            About us
                        </Typography>
                        {
                            aboutUsOptions.map((option, index)=>{
                                return(
                                    <Grid key={index}>
                                        <Typography
                                            display={{
                                                md: "block",
                                                sm: "none",
                                                xs: "none"
                                            }}
                                            variant="body1"
                                            sx={{
                                                fontFamily: "Nunito",
                                                color: "white",
                                                cursor:'pointer'                                          
                                            }}>
                                            {
                                                option.externalLink === null ?
                                                <Link sx={{color:"white"}} href={option.key}>
                                                    {option.title}
                                                </Link> :
                                                <Link sx={{color:"white"}} href={option.externalLink}>
                                                    {option.title}
                                                </Link> 
                                            }  
                                        </Typography>  
                                        <Typography
                                            display={{
                                                fontFamily: "Nunito",
                                                md: "none",
                                                sm: "block",
                                                xs: "block"
                                            }}
                                            variant="body2"
                                            sx={{
                                                color: "white",
                                                cursor:'pointer'                                          
                                            }}>
                                            {
                                                option.externalLink === null ?
                                                <Link sx={{color:"white"}} href={option.key}>
                                                    {option.title}
                                                </Link> :
                                                <Link sx={{color:"white"}} href={option.externalLink}>
                                                    {option.title}
                                                </Link> 
                                            }  
                                        </Typography>                                                                                
                                    </Grid>  
                                )
                            })
                        }                       
                    </Stack>
                </Grid> 
                <Grid item md={3} sm={6} xs={12}>
                    <Stack>
                        <Typography
                            display={{
                                lg: "none",
                                md: "none",
                                sm: "none",
                                xs: "block"
                            }}                            
                            sx={{
                                fontFamily: "Nunito",
                                color: "#B6ECF6",
                                fontWeight: 1000,
                                marginBottom: "20px",
                                width: '60%',
                                borderBottom: '1px solid #B6ECF6'
                            }}
                            variant="body1">
                            Client Connect
                        </Typography>
                        <Typography
                            display={{
                                lg: "block",
                                md: "block",
                                sm: "block",
                                xs: "none"
                            }}
                            sx={{
                                fontFamily: "Nunito",
                                color: "#B6ECF6",
                                fontWeight: 1000,
                                marginBottom: "20px",
                                width: '60%',
                                borderBottom: '1px solid #B6ECF6'
                            }}
                            variant="h5">
                            Client Connect
                        </Typography>
                        {
                            clientAccessOptions.map((option, index)=>{
                                return(
                                    <Grid key={index}>
                                        <Typography
                                            display={{
                                                md: "block",
                                                sm: "none",
                                                xs: "none"
                                            }}
                                            variant="body1"
                                            sx={{
                                                fontFamily: "Nunito",
                                                color: "white",
                                                cursor:'pointer'                                          
                                            }}>
                                            {
                                                option.externalLink === null ?
                                                <Link sx={{color:"white"}} href={option.key}>
                                                    {option.title}
                                                </Link> :
                                                <Link sx={{color:"white"}} href={option.externalLink}>
                                                    {option.title}
                                                </Link> 
                                            }  
                                        </Typography>  
                                        <Typography
                                            display={{
                                                fontFamily: "Nunito",
                                                md: "none",
                                                sm: "block",
                                                xs: "block"
                                            }}
                                            variant="body2"
                                            sx={{
                                                color: "white",
                                                cursor:'pointer'                                          
                                            }}>
                                            {
                                                option.externalLink === null ?
                                                <Link sx={{color:"white"}} href={option.key}>
                                                    {option.title}
                                                </Link> :
                                                <Link sx={{color:"white"}} href={option.externalLink}>
                                                    {option.title}
                                                </Link> 
                                            }  
                                        </Typography>                                                                                
                                    </Grid>     
                                )
                            })
                        }                                                                      
                    </Stack>
                </Grid>  
                <Grid item md={3} sm={6} xs={12}
                    sx={{
                        borderLeft: {
                            md: "1px solid #B6ECF6",
                            sm: "0px",
                            xs: "0px"
                        },
                        paddingLeft: {
                            md: "30px",
                            sm: "0px",
                            xs: "0px"
                        }
                    }}>
                    <Stack>
                        <Typography
                            display={{
                                lg: "none",
                                md: "none",
                                sm: "none",
                                xs: "block"
                            }}                            
                            sx={{
                                fontFamily: "Nunito",
                                color: "#B6ECF6",
                                fontWeight: 1000,
                                marginBottom: "20px",
                                width: '60%',
                                borderBottom: '1px solid #B6ECF6'
                            }}
                            variant="body1">
                            Contact us
                        </Typography>
                        <Typography
                            display={{
                                lg: "block",
                                md: "block",
                                sm: "block",
                                xs: "none"
                            }}
                            sx={{
                                fontFamily: "Nunito",
                                color: "#B6ECF6",
                                fontWeight: 1000,
                                marginBottom: "20px",
                                width: '60%',
                                borderBottom: '1px solid #B6ECF6'
                            }}
                            variant="h5">
                            Contact us
                        </Typography>
                        <Stack sx={{marginBottom: '10px'}}>
                            <Typography
                                sx={{
                                    fontFamily: "Nunito",
                                    color: "#B6ECF6",
                                }}
                                variant="body1">
                                Find us
                            </Typography>
                            <Typography
                                sx={{
                                    color: "white",
                                    fontFamily: "Nunito",
                                }}
                                variant="body2">                                
                                360 Central Ave, STE 470 <br/>
                                St. Petersburg, FL 33701                                                              
                            </Typography>
                        </Stack>   
                        <Stack sx={{marginBottom: '10px'}}>                           
                            <Typography
                                sx={{
                                    fontFamily: "Nunito",
                                    color: "#B6ECF6",
                                }}
                                variant="body1">
                                Call us
                            </Typography>   
                            <Typography variant="body2">                                
                                <Link href="tel:7273710014" 
                                    sx={{
                                        fontFamily: "Nunito",
                                        color:'white',
                                        '&:hover': { 
                                            color: '#D0D9DD',
                                            textDecoration: 'none'
                                        }
                                    }}>
                                    (727) 371-0014
                                </Link>                                                       
                            </Typography>                             
                        </Stack>   
                        <Stack sx={{marginBottom: '30px'}}>
                            <Typography
                                sx={{
                                    fontFamily: "Nunito",
                                    color: "#B6ECF6",
                                }}
                                variant="body1">
                                Write us
                            </Typography>  
                            <Typography variant="body2">                                
                                <Link href="mailto:info@modernshoreins.com"
                                    sx={{
                                        fontFamily: "Nunito",
                                        color:'white',
                                        '&:hover': { 
                                            color: '#D0D9DD',
                                            textDecoration: 'none'
                                        }
                                    }}>
                                    info@modernshoreins.com
                                </Link>                              
                            </Typography>                                                         
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <SocialMediaIcon
                                url="https://www.facebook.com/modernshore/"
                                alt="Facebook"
                                src={facebook}/>
                            <SocialMediaIcon
                                url="https://twitter.com/shoremodern?s=21&t=cgrsoHYTgM2amIrCZAc2NA"
                                alt="Twitter"
                                src={twitter}/>
                            <SocialMediaIcon
                                url="https://www.instagram.com/modern_shore_ins/"
                                alt="Instagram"
                                src={instagram}/>
                            <SocialMediaIcon
                                url="https://www.linkedin.com/company/modern-shore-insurance/"
                                alt="Linkedin"
                                src={linkedin}/>                                                                   
                        </Stack>                                                                                                                                              
                    </Stack>
                </Grid>                                              
            </Grid>
            <Grid container sx={{marginTop: "5%", borderTop: '1px solid #B6ECF6', paddingTop: '15px'}}>
                <Grid item md={2} sm={12} xs={12}/>
                <Grid item md={8} sm={12} xs={12}>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}
                            sx={{
                                textAlign: 'center',                                
                            }}>
                            <Typography variant="body1" 
                                sx={{
                                    fontFamily: "Nunito",
                                    color: '#B6ECF6',
                                    display: {
                                        lg: 'block',
                                        md: 'block',
                                        sm: 'none',
                                        xs: 'none'
                                    }
                                }}>
                                Copyright © 2023 Modern Shore Insurance<br/>
                                License# L113062
                            </Typography>
                            <Typography variant="body2" 
                                sx={{
                                    fontFamily: "Nunito",
                                    color: '#B6ECF6',
                                    display: {
                                        lg: 'none',
                                        md: 'none',
                                        sm: 'block',
                                        xs: 'block'
                                    }
                                }}>
                                Copyright © 2023 Modern Shore Insurance<br/>
                                License# L113062
                            </Typography>
                        </Grid>
                        <Grid item xs={12}
                            sx={{
                                color: '#B6ECF6',
                                textAlign: 'justify',
                                marginBottom: '10px'
                            }}>
                            <Typography variant="body1" 
                                sx={{
                                    fontFamily: "Nunito",
                                    display: {
                                        lg: 'block',
                                        md: 'block',
                                        sm: 'none',
                                        xs: 'none'
                                    }
                                }}>
                                All information and content on this website is subject to applicable statutes and regulations, furnished "as is," without warranty of any kind, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or noninfringement. See additional terms & conditions at links below.
                            </Typography>
                            <Typography variant="body2"
                                sx={{
                                    fontFamily: "Nunito",
                                    display: {
                                        lg: 'none',
                                        md: 'none',
                                        sm: 'block',
                                        xs: 'block'
                                    }
                                }}>                            
                                All information and content on this website is subject to applicable statutes and regulations, furnished "as is," without warranty of any kind, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or noninfringement. See additional terms & conditions at links below.
                            </Typography>                            
                        </Grid>
                        <Grid item xs={12}
                            sx={{
                                color: '#B6ECF6',
                                textAlign: 'justify',
                                borderTop: '1px solid white'
                            }}>
                            <Button 
                                size="small"
                                endIcon={legalTermsVisible ? <ExpandLessIcon sx={{marginTop: '-10px'}}/> : <ExpandMoreIcon sx={{marginTop: '-10px'}} />}
                                sx={{color: '#B6ECF6', paddingLeft: '0px', paddingTop: '0px'}} 
                                onClick={()=>{setIsLegalTermsVisible(!legalTermsVisible)}}>
                                <Typography variant="body1"
                                    sx={{
                                        fontFamily: "Nunito",
                                        marginBottom: '10px', 
                                        display: {
                                            lg: 'block',
                                            md: 'block',
                                            sm: 'none',
                                            xs: 'none'
                                        }
                                    }}>                                    
                                    Terms and Conditions                                
                                </Typography>   
                                <Typography variant="body2"
                                    sx={{
                                        fontFamily: "Nunito",
                                        marginBottom: '10px', 
                                        display: {
                                            lg: 'none',
                                            md: 'none',
                                            sm: 'block',
                                            xs: 'block'
                                        }
                                    }}>                                    
                                    Terms and Conditions                                  
                                </Typography>                                                            
                            </Button>
                            <Collapse in={legalTermsVisible}>
                                <Typography variant="body2" sx={{color: 'white',fontFamily: "Nunito"}}>
                                    <strong> Agreement between User and www.modernshoreins.com </strong><br/> 

                                    Welcome to www.modernshoreins.com. The www.modernshoreins.com website (the "Site") is
                                    comprised of various web pages operated by MBA Equity Partners LLC, DBA Modern Shore
                                    Insurance ("Modern Shore Insurance"). www.modernshoreins.com is offered to you conditioned
                                    on your acceptance without modification of the terms, conditions, and notices contained herein (the
                                    "Terms"). Your use of www.modernshoreins.com constitutes your agreement to all such Terms.
                                    Please read these terms carefully, and keep a copy of them for your reference. <br/><br/>
                                    www.modernshoreins.com is an E-Commerce Site. <br/><br/>
                                    The purpose of this website is to promote the services offered by MBA Equity Partners LLC. The
                                    website is developed to provide consumers with information related to the company services. <br/><br/>

                                    <strong> Electronic Communications </strong> <br/>
                                    Visiting www.modernshoreins.com or sending emails to Modern Shore Insurance constitutes
                                    electronic communications. You consent to receive electronic communications and you agree that
                                    all agreements, notices, disclosures and other communications that we provide to you
                                    electronically, via email and on the Site, satisfy any legal requirement that such communications be
                                    in writing. <br/><br/>

                                    <strong> Children Under Thirteen </strong> <br/>
                                    Modern Shore Insurance does not knowingly collect, either online or offline, personal information
                                    from persons under the age of thirteen. If you are under 18, you may use
                                    www.modernshoreins.com only with permission of a parent or guardian.  <br/><br/>

                                    <strong> Links to Third Party Sites/Third Party Services </strong> <br/>
                                    www.modernshoreins.com may contain links to other websites ("Linked Sites"). The Linked Sites
                                    are not under the control of Modern Shore Insurance and Modern Shore Insurance is not
                                    responsible for the contents of any Linked Site, including without limitation any link contained in a
                                    Linked Site, or any changes or updates to a Linked Site. Modern Shore Insurance is providing
                                    these links to you only as a convenience, and the inclusion of any link does not imply endorsement
                                    by Modern Shore Insurance of the site or any association with its operators. <br/><br/>
                                    Certain services made available via www.modernshoreins.com are delivered by third party sites
                                    and organizations. By using any product, service or functionality originating from the
                                    www.modernshoreins.com domain, you hereby acknowledge and consent that Modern Shore
                                    Insurance may share such information and data with any third party with whom Modern Shore
                                    Insurance has a contractual relationship to provide the requested product, service or functionality
                                    on behalf of www.modernshoreins.com users and customers.  <br/><br/>

                                    <strong> No Unlawful or Prohibited Use/Intellectual Property </strong> <br/>
                                    You are granted a non-exclusive, non-transferable, revocable license to access and use
                                    www.modernshoreins.com strictly in accordance with these terms of use. As a condition of your
                                    use of the Site, you warrant to Modern Shore Insurance that you will not use the Site for any
                                    purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner
                                    which could damage, disable, overburden, or impair the Site or interfere with any other party's use
                                    and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information
                                    through any means not intentionally made available or provided for through the Site. <br/><br/>
                                    All content included as part of the Service, such as text, graphics, logos, images, as well as the
                                    compilation thereof, and any software used on the Site, is the property of Modern Shore Insurance
                                    or its suppliers and protected by copyright and other laws that protect intellectual property and
                                    proprietary rights. You agree to observe and abide by all copyright and other proprietary notices,
                                    legends or other restrictions contained in any such content and will not make any changes thereto. <br/><br/>
                                    You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
                                    derivative works, or in any way exploit any of the content, in whole or in part, found on the Site.
                                    Modern Shore Insurance content is not for resale. Your use of the Site does not entitle you to
                                    make any unauthorized use of any protected content, and in particular you will not delete or alter
                                    any proprietary rights or attribution notices in any content. You will use protected content solely
                                    for your personal use, and will make no other use of the content without the express written
                                    permission of Modern Shore Insurance and the copyright owner. You agree that you do not
                                    acquire any ownership rights in any protected content. We do not grant you any licenses, express
                                    or implied, to the intellectual property of Modern Shore Insurance or our licensors except as
                                    expressly authorized by these Terms.  <br/><br/>

                                    <strong> International Users </strong> <br/>
                                    The Service is controlled, operated and administered by Modern Shore Insurance from our offices
                                    within the USA. If you access the Service from a location outside the USA, you are responsible
                                    for compliance with all local laws. You agree that you will not use the Modern Shore Insurance
                                    Content accessed through www.modernshoreins.com in any country or in any manner prohibited
                                    by any applicable laws, restrictions or regulations. <br/><br/>

                                    <strong> Indemnification </strong> <br/>
                                    You agree to indemnify, defend and hold harmless Modern Shore Insurance, its officers, directors,
                                    employees, agents and third parties, for any losses, costs, liabilities and expenses (including
                                    reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or
                                    services, any user postings made by you, your violation of any terms of this Agreement or your
                                    violation of any rights of a third party, or your violation of any applicable laws, rules or regulations.
                                    Modern Shore Insurance reserves the right, at its own cost, to assume the exclusive defense and
                                    control of any matter otherwise subject to indemnification by you, in which event you will fully
                                    cooperate with Modern Shore Insurance in asserting any available defenses. <br/><br/>

                                    <strong> Liability Disclaimer </strong> <br/>
                                    THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
                                    AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR
                                    TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE 
                                    INFORMATION HEREIN. MBA EQUITY PARTNERS LLC, DBA MODERN SHORE
                                    INSURANCE AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR
                                    CHANGES IN THE SITE AT ANY TIME. <br/><br/>
                                    MBA EQUITY PARTNERS LLC, DBA MODERN SHORE INSURANCE AND/OR ITS
                                    SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY,
                                    RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE
                                    INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
                                    CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT
                                    PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE,
                                    PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS"
                                    WITHOUT WARRANTY OR CONDITION OF ANY KIND. MBA EQUITY PARTNERS
                                    LLC, DBA MODERN SHORE INSURANCE AND/OR ITS SUPPLIERS HEREBY
                                    DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
                                    INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS,
                                    INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
                                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT. <br/><br/>
                                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                                    SHALL MBA EQUITY PARTNERS LLC, DBA MODERN SHORE INSURANCE AND/OR
                                    ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
                                    SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER
                                    INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR
                                    PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
                                    PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE
                                    OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE
                                    SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
                                    RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING
                                    OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT,
                                    NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF MBA EQUITY
                                    PARTNERS LLC, DBA MODERN SHORE INSURANCE OR ANY OF ITS SUPPLIERS
                                    HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
                                    STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                                    LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
                                    LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY
                                    PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE
                                    AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE. <br/><br/>
                                    
                                    <strong> Termination/Access Restriction </strong> <br/>
                                    Modern Shore Insurance reserves the right, in its sole discretion, to terminate your access to the
                                    Site and the related services or any portion thereof at any time, without notice. To the maximum
                                    extent permitted by law, this agreement is governed by the laws of the State of Florida and you
                                    hereby consent to the exclusive jurisdiction and venue of courts in Florida in all disputes arising out
                                    of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not 
                                    give effect to all provisions of these Terms, including, without limitation, this section. <br/><br/>
                                    You agree that no joint venture, partnership, employment, or agency relationship exists between
                                    you and Modern Shore Insurance as a result of this agreement or use of the Site. Modern Shore
                                    Insurance's performance of this agreement is subject to existing laws and legal process, and
                                    nothing contained in this agreement is in derogation of Modern Shore Insurance's right to comply
                                    with governmental, court and law enforcement requests or requirements relating to your use of the
                                    Site or information provided to or gathered by Modern Shore Insurance with respect to such use.
                                    If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable
                                    law including, but not limited to, the warranty disclaimers and liability limitations set forth above,
                                    then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable
                                    provision that most closely matches the intent of the original provision and the remainder of the
                                    agreement shall continue in effect. <br/><br/>
                                    Unless otherwise specified herein, this agreement constitutes the entire agreement between the user
                                    and Modern Shore Insurance with respect to the Site and it supersedes all prior or
                                    contemporaneous communications and proposals, whether electronic, oral or written, between the
                                    user and Modern Shore Insurance with respect to the Site. A printed version of this agreement and
                                    of any notice given in electronic form shall be admissible in judicial or administrative proceedings
                                    based upon or relating to this agreement to the same extent and subject to the same conditions as
                                    other business documents and records originally generated and maintained in printed form. It is the
                                    express wish to the parties that this agreement and all related documents be written in English. <br/><br/>

                                    <strong> Changes to Terms </strong> <br/>
                                    Modern Shore Insurance reserves the right, in its sole discretion, to change the Terms under which
                                    www.modernshoreins.com is offered. The most current version of the Terms will supersede all
                                    previous versions. Modern Shore Insurance encourages you to periodically review the Terms to
                                    stay informed of our updates.  <br/><br/>

                                    <strong> Contact Us </strong> <br/>
                                    Modern Shore Insurance welcomes your questions or comments regarding the Terms: <br/><br/>
                                    MBA Equity Partners LLC, DBA Modern Shore Insurance <br/>
                                    360 Central Avenue, STE 470 <br/>
                                    St. Petersburg, Florida 33701  <br/><br/>
                                    Email Address: <br/>
                                    info@modernshoreins.com  <br/><br/>
                                    Telephone number: <br/>
                                    7273710014 <br/><br/>
                                    Effective as of June 01, 2023
                                </Typography>
                            </Collapse>
                        </Grid>
                    </Grid>               
                </Grid>
                <Grid item md={2} sm={12} xs={12}/>
            </Grid>
        </Box>
      );
};

export default Footer;