import React from 'react'
import { Box, Stack, Typography } from '@mui/material';
import Fade from 'react-reveal/Fade';

export default function QuoteNowHeader({backgroundImage}) {
  return (
    <Box 
      component="div" 
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        overflowX:'hidden',
        marginTop: '6vh'
      }}> 
      <Stack
        direction="column"
        alignItems="top"
        justifyContent="top"
        spacing={2}
        sx={{
          backgroundColor: 'rgba(0,0,0, 0.5)',
          minHeight: '60vh',
          paddingTop: '25vh',
          paddingLeft: '7%'
        }}>
        <Fade bottom delay={50}>
          <Typography 
            variant='h3'
            fontFamily="Sofia Sans Condensed"
            sx={{
              color:"white",
              paddingRight: '10%',
            }}>
            INSURANCE QUOTE REQUEST
          </Typography>
          <Fade bottom delay={300}>
          <Typography 
            variant='h6'
            fontFamily="Nunito"
            sx={{
              fontWeight:"bolder",
              color:"white",
              paddingRight: '10%',
              textUnderlineOffset: '20%',
              textDecorationThickness: '1px',
            }}>
            Our agents are ready to assist! Please provide your information below and a Modern Shore agent will provide you with your insurance options
          </Typography> 
        </Fade>  
        </Fade>  
      </Stack>          
    </Box>                    
  )
}




        