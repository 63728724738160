import { createTheme, responsiveFontSizes  } from '@mui/material/styles';

let theme = createTheme({
    components: {
        MuiButton: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              ...(ownerState.variant === 'contained' &&
                ownerState.color === 'primary' && {
                  backgroundColor: '#0C709B',
                  '&:hover': { 
                    backgroundColor: '#0C709B',
                  }
                }),
            }),
          },
        },
    },
});
theme = responsiveFontSizes(theme);

export default theme