import React from 'react';
import { Stack, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useNavigate} from 'react-router-dom';
import { useState } from 'react';



function SearchResults({query}) {
    const [searchQuery, setSearchQuery] = useState(query)
    const navigate = useNavigate();

    const filteredList = searchingList.filter(item =>
        item.title.toLowerCase().includes(query.toLowerCase()) ||
        item.description.toLowerCase().includes(query.toLowerCase())
    );   
    
    const handleSearch = (event) => {
        if (event.key === 'Enter') {
          const query = event.target.value;
          navigate(`/Search?query=${query}`)
        }
      };
    const handleSearchChangeHandler = (event) => {
        setSearchQuery(event.target.value)
    };
    return (
        <Stack
            sx={{
                backgroundColor: "#f5f5f5",
                minHeight: '100vh',
                padding: {
                    md: "5% 10% 5% 10%",
                    sm: "15% 10% 5% 10%",
                    xs: "15% 10% 5% 10%",
                },
                marginTop: '10vh'
            }}>
            <Typography                                 
                variant='h2'
                fontFamily="Sofia Sans Condensed"
                sx={{
                    color:"#0C709B",
                    marginBottom:'3vh'
                }}>
                RESULTS
            </Typography> 
            <TextField 
                id="outlined-basic" 
                label="Search" 
                variant="standard" 
                value={searchQuery}
                onChange={handleSearchChangeHandler}
                onKeyDown={handleSearch} 
                sx={{marginBottom:'3vh'}}/>
            {
                filteredList.length > 0 ?
                filteredList?.map(result =>{
                    return (
                        <Card 
                            variant="outlined" 
                            onClick={()=>{navigate(result.redirect)}} 
                            sx={{
                                cursor:'pointer',
                                marginBottom: '2vh'
                            }}>
                            <React.Fragment>
                                <CardContent>
                                    <Typography variant="h5" component="div" fontFamily="Nunito" sx={{color:"#0C709B", marginBottom: '2vh', fontWeight: 'bold'}}>
                                        {result.title}
                                    </Typography>
                                    <Typography variant="body2" fontFamily="Nunito" sx={{color:"#0C709B"}}>
                                        {result.description}
                                    </Typography>
                                </CardContent>
                            </React.Fragment>
                        </Card>       
                    )
                }) :
                <Typography variant="h5" fontFamily="Nunito" sx={{color:"#0C709B", fontWeight: 'bolder'}}>
                    No Result Found
                </Typography>
            }        
        </Stack>
    )
}

const searchingList = [
    {
        redirect: '/AgencyAffiliation',
        title: "Agency Affiliation",
        description: "Partnering with Modern Shore Insurance is a great opportunity to achieve the growth that you desire while reducing the administrative burden"                                                          
    },
    {
        redirect: '/ApplyNow',
        title: "Apply Now",
        description:"Apply Now to submit your information to our hiring manager. We will review and contact you regarding potential opportunities within our company"                                                      
    },
    {
        redirect: '/BusinessInsurance',
        title: "Business Insurance",
        description:"Companies come in all shapes and sizes. Secure your business with an insurance portfolio designed to fit the needs of your unique operation"                                                      
    },
    {
        redirect: '/ContactCustomerService',
        title: "Contact Customer Service",
        description: "Reach Out to Us - Contact Our Team for Inquiries, Support, or Assistance. We're Here to Help! Get in Touch Today!"
    },
    {
        redirect: '/ContactUs',
        title: "Contact Us",
        description: "Reach Out to Us - Contact Our Team for Inquiries, Support, or Assistance. We're Here to Help! Get in Touch Today!"
    },
    {
        redirect: '/HR&Benefits',
        title: "HR and Benefits",
        description: "Full‐service solutions for your business. Meet the demands of the modern workplace with quality Employee Benefits, Payroll Services, Human Resource Support and more"
    },
    {
        redirect: '/JoinOurTeam',
        title: "Join our Team",
        description: "We are always looking for talented and reliable professionals to join our growing team. Whether you are interested in starting your insurance career or taking your skills to the next level, we would like to hear from you"
    },
    {
        redirect: '/KnowledgeResources',
        title: "Knowledge & Resource",
        description: "Discover Engaging and Informative Articles - Explore our Blog for the Latest Insights, Tips, and Trends in [Your Industry/Niche]. Expand Your Knowledge Today!"
    },
    {
        redirect: '/MoveToModernShore',
        title: "Modern Shore Insurance",
        description: "A New Wave of Protection"
    },
    {
        redirect: '',
        title: "Move to Modern Shore",
        description: "Making the Move to Modern Shore is Easy! You can keep your same insurance policy and carrier, while gaining the satisfaction of having a dedicated Modern Shore Insurance Advisor on your side"
    },
    {
        redirect: '/OurStory',
        title: "Our Story",
        description: "Founded in St. Petersburg, Florida, Modern Shore Insurance is rooted in community relationships and solidified with national strength"
    },
    {
        redirect: '/PersonalInsurance',
        title: "Personal Insurance",
        description: "Insurance coverage designed for you and your family. Protect your home, property, vehicles, and assets with customized insurance solutions"
    },
    {
        redirect: '/PolicyChangeRequest',
        title: "Policy Change Request",
        description: "At Modern Shore Insurance, requesting a change to your policy is a breeze! By following the prompts below, you are only a few clicks away from submitting your request directly to a Modern Shore Insurance advisor"
    },
    {
        redirect: '/ReportClaim',
        title: "Report a Claim",
        description: "At Modern Shore Insurance, you can either report a claim directly to the insurance carrier or through your agent"
    },
    {
        redirect: '/RequestConsultation',
        title: "Request a Consultation",
        description: "Book a Consultation with Our Experts - Get Personalized Advice and Solutions for Your Needs. Schedule an Appointment Today!"
    },
    {
        redirect: '/RequestDocuments',
        title: "Request Documents",
        description: "At Modern Shore Insurance, requesting your insurance documentation is a breeze! By following the prompts below, you are only a few clicks away from submitting your request directly to a Modern Shore Insurance advisor"
    },
    {
        redirect: '/Welcome',
        title: "Welcome to Modern Shore",
        description: "Life is better when you have an advisor on your side. Our dedicated team strives to provide peace of mind through responsive service and quality insurance guidance"
    }
]

export default SearchResults

