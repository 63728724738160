import React from 'react';
import { useState, useEffect } from 'react';
import { Stack, Box, Typography, Grid, TextField, Button } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import GetInTouch from '../components/GetInTouchSection';
import ContactUsSection from '../components/ContactUsSection';
import MapBox from '../components/MapBox'
import AboutUsNavbar from '../components/AboutUsNavbar';
import { Seo } from '../components/Seo';

function ContactUs() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>      
      <Seo
          title="Contact Us"
          description="Reach Out to Us - Contact Our Team for Inquiries, Support, or Assistance. We're Here to Help! Get in Touch Today!"/>                                                          
      <section>           
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section> 
      <section>
        <AboutUsNavbar selected="ContactUs"/>
        <CssBaseline/>
      </section> 
      <section id="OurStory">  
        <Stack>
            <Box 
              component="div" 
              sx={{
                  minHeight: '100vh'
              }}>
              <Grid container>
                <Grid item md={6} sm={12} xs={12}>
                  <ContactUsSection/>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <GetInTouch/>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <MapBox 
                    latitude={27.770760}
                    longitude={-82.637790}/>
                </Grid>                
              </Grid>    
          </Box>
        </Stack>
      </section>
    </Stack>
  )
}

export default ContactUs