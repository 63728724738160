import React from 'react'
import { Box, Grid, Button, Typography, Stack} from '@mui/material';
import NextLevel from '../assets/images/NextLevel.jpg';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useNavigate } from 'react-router';

export default function AgencyAffiliationHeader() {
    const navigate = useNavigate();
    return (
        <Box 
            component="div" 
            sx={{
                height: '100vh',
            }}>
            <Grid container>
                <Grid item md={6} sm={12} xs={12} 
                    sx={{
                        display:{
                            sm: "none",
                            xs: "none",
                            md: "block",
                            lg: "block"
                        }
                    }}>
                    <Box 
                        component="div" 
                        sx={{
                            backgroundImage: `url(${NextLevel})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            height: '100vh',
                            overflowX:'hidden'
                        }}>
                    </Box>
                </Grid> 
                <Grid item md={6} sm={12} xs={12}>
                    <Stack
                        direction="column"
                        alignItems="top"
                        justifyContent="top"
                        spacing={6}
                        sx={{
                            backgroundColor: "#2c9ac9",
                            height: '100vh',
                            padding: {
                                lg: "20% 10% 32% 10%",
                                md: "20% 10% 32% 10%",
                                sm: "20% 10% 32% 10%",
                                xs: "40% 10% 32% 10%"
                            },
                        }}>
                        <Typography 
                            variant='h3'
                            fontFamily="Sofia Sans Condensed"
                            sx={{
                                color:"white"
                            }}>
                            TAKE YOUR AGENCY TO THE <strong>NEXT LEVEL</strong>
                        </Typography>                 
                        <Typography 
                            variant='body1'
                            fontFamily="Nunito"
                            sx={{
                                fontWeight: 'bolder',
                                color:"white"
                            }}>
                            Partnering with Modern Shore Insurance is a great opportunity to achieve the growth that you
                            desire while reducing the administrative burden. Capitalize on our technology and back‐office
                            support to allow you to focus on connecting with your clients and generating new business.
                            <br/><br/>
                            Schedule a consultation to discuss potential opportunities.
                        </Typography> 
                        <Button
                            variant="outlined"
                            backgroundColor= "white"  
                            startIcon={<ScheduleIcon />}
                            size="large"
                            sx={{
                                fontFamily:"Nunito",
                                fontWeight:"bolder",
                                color: "white",
                                borderColor: "white",
                                size:"large",
                                width: {
                                    lg:"50%",
                                    md:"50%",
                                    sm:"75%",
                                    xs:"100%"
                                },
                                '&:hover': { 
                                    color: "white",
                                    borderColor: "white",
                                }
                            }}
                            onClick={()=>{navigate("/RequestConsultation")}}
                            label = "Schedule">  
                            Schedule a consultation
                        </Button> 
                    </Stack>                   
                </Grid>              
            </Grid>   
        </Box>               
    )
}




        