import React from 'react';
import { AppBar, Toolbar, Box, Typography, Button, Grid, Menu, MenuItem  } from '@mui/material';
import { useNavigate} from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const aboutUsOptions = [
  { title: "Our Story", key: "OurStory", variant: "body1"},
  { title: "Knowledge & Resources", key: "KnowledgeResources", variant: "body2"},
  { title: "Welcome to Modern Shore", key: "Welcome", variant: "caption"},
  { title: "Join Our Team", key: "JoinOurTeam", variant: "body2"},
  { title: "Agency Affiliation", key: "AgencyAffiliation",  variant: "body2"},
  { title: "Contact Us", key: "ContactUs", variant: "body1"},
];
const AboutUsNavbar = ({selected}) => { 
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigateTo = (page)=>{
        setAnchorEl(null);
        navigate(page)
    }
    return (
        <Box sx={{ flexGrow: 1}}>
            <AppBar       
                position="fixed"  
                sx={{        
                    display: {xs:'none', sm: 'none', md: 'block'},            
                    backgroundColor :  "#D0D9DD",                    
                    boxShadow :  "block",
                    paddingLeft: "3%",
                    marginTop: {
                        xs: "70px",
                        sm: "80px",
                        md: "74px"
                    },
                }}>
                <Toolbar>
                    <Button
                        onClick={ ()=>{navigate('/OurStory')}}>
                        <Typography
                            sx={{      
                                fontWeight: 1000,                      
                                color: "#0C709B"
                            }}    
                            fontFamily="Nunito"
                            fontWeight="bolder"   
                            variant="h6">
                            ABOUT US
                        </Typography>
                    </Button>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1 }}> 
                    </Typography>
                    {
                        aboutUsOptions.map((menu, index)=>{
                            return (
                                <Typography
                                    key={index}
                                    sx={{
                                        paddingRight: 5,
                                        textDecoration: selected === menu.key ? 'underline' : 'none',
                                        textUnderlineOffset: '40%',
                                        textDecorationThickness: '1px',
                                        textDecorationColor: "#0C709B",
                                        fontWeight: selected === menu.key ? 'bolder' : 'bold',
                                        color:"#0C709B",
                                            '&:hover': {
                                                cursor: 'pointer',
                                            }
                                    }}  
                                    onClick={()=>{navigate('/' + menu.key)}}      
                                    fontFamily="Nunito"                                    
                                    variant="body1">
                                    {menu.title}
                                </Typography>
                            )
                        })
                    }              
                </Toolbar>
            </AppBar>
            <AppBar     
                position="fixed"  
                sx={{
                    display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' },
                    alignItem: 'center',            
                    backgroundColor :  "#D0D9DD",                    
                    boxShadow :  "block",
                    minHeight: "50px",
                    paddingLeft: "3%",
                    marginTop: {
                        xs: "69px",
                        sm: "80px"                 
                    },
                    paddingTop: {
                        xs: "1vh",
                        sm: "1vh"                 
                    },
                    paddingBottom: {
                        xs: "1vh",
                        sm: "1vh"                 
                    },
                    alignItems: "center", // Align items vertically
                }}>
                <Grid container alignItems="center">
                    <Grid item xs={6} textAlign="center">
                        <Button
                            onClick={ ()=>{navigate('/OurStory')}}>
                            <Typography
                                sx={{                            
                                    color: "#0C709B",
                                    fontWeight: 1000
                                }}    
                                fontFamily="Nunito"
                                variant="body1">
                                ABOUT US
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={6} textAlign="center">
                        <Grid container>
                            <Grid item xs={8}>
                                <Button>
                                    <Typography
                                        sx={{                            
                                            color: "#0C709B",
                                            fontWeight: 1000
                                        }}
                                        onClick={handleClick}
                                        fontFamily="Nunito"
                                        variant={aboutUsOptions.find(x => x.key === selected).variant}>
                                        { aboutUsOptions.find(x => x.key === selected).title }
                                    </Typography>                             
                                </Button>
                            </Grid>
                            <Grid item xs={4} textAlign="left">
                                <Typography                                
                                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    sx={{               
                                        marginTop: "4%",             
                                        color: "#0C709B",
                                    }}    
                                    fontFamily="Sofia Sans Condensed"
                                    variant="body2">
                                    { open ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
                                </Typography>
                            </Grid>                                
                        </Grid>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}>
                            {
                                aboutUsOptions.map((menu, index)=>{
                                    return (
                                        <MenuItem
                                            key={index}
                                            onClick={()=>{navigateTo('/' + menu.key)}}>
                                            <Typography
                                                sx={{
                                                    color:"#0C709B",
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                        }
                                                    }}  
                                                    fontFamily="Nunito"
                                                    fontWeight="1000"       
                                                    variant="body2">
                                                    {menu.title}
                                            </Typography>
                                        </MenuItem>
                                    )
                                })
                            }                                   
                        </Menu>                        
                    </Grid>                    
                </Grid>                                          
            </AppBar>
        </Box>   
    )
};

export default AboutUsNavbar