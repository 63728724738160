export const BaseURL = process.env.REACT_APP_SALES_FORCE_BASE_URL;

//Report a Claim
export const GetPersonalInsurancePoliciesURL = BaseURL.concat("Policies/GetPersonalPolicies");
export const GetBusinessInsurancePoliciesURL = BaseURL.concat("Policies/GetBusinessPolicies");

//Request document & Policy change Request
export const GetInsurancePolicyDocumentTypesURL = BaseURL.concat("Documents/GetDocumentTypes");
export const RequestDocumentChangeURL = BaseURL.concat("Documents/SubmitRequest");

//Get Carriers
export const GetCarriers = BaseURL.concat("AgentTransfer/getCarriers");

//Get Policies
export const GetPolicies = BaseURL.concat("AgentTransfer/getPolicyTypes")

//Create Leads
export const CreateLead = BaseURL.concat("Utility/GenerateLead")

//Create Job Application
export const CreateJobApplication = BaseURL.concat("Utility/JoinOurTeam")



export const BloggerApiKey = process.env.REACT_APP_BLOOGER_API_KEY
export const BloggerBlogId = process.env.REACT_APP_BLOOG_ID
export const BloggerBaseUrl = "https://www.googleapis.com/blogger/v3/"

//Get Blog Posts
export const GetBlogPosts = BloggerBaseUrl.concat("blogs/" + BloggerBlogId + "/posts?key=" + BloggerApiKey )


