import React from 'react'
import { useEffect } from 'react';
import { Grid, Typography, TextField, Button } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import { isValidEmailAddress, isValidPostalCode } from '../utils/utilityFunctions';
import NumberFormat from 'react-number-format';

export default function RequestDocumentForm({insuranceType, documentType, requestDocumentChangeHandler}) {

  useEffect(()=>{
    resetForm();
  },[insuranceType, documentType])

  const isCertificateDocumentType = documentType ==='COI';
  const isEvidenceDocumentType = documentType === 'EPI';

  const [businessName, setBusinessName] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [certificateOrEvidenceHolderName, setCertificateOrEvidenceHolderName] = useState("");
  const [certificateOrEvidenceHolderAddress, setCertificateOrEvidenceHolderAddress] = useState("");
  const [certificateOrEvidenceHolderCity, setCertificateOrEvidenceHolderCity] = useState("");
  const [certificateOrEvidenceHolderState, setCertificateOrEvidenceHolderState] = useState("");
  const [certificateOrEvidenceHolderZipcode, setCertificateOrEvidenceHolderZipcode] = useState("");

  const [specialInstructions, setSpecialInstructions] = useState("")

  const [isClientNameFocused, setIsClientNameFocused] = useState(false)
  const [isClientEmailFocused, setIsClientEmailFocused] = useState(false)
  const [isBusinessNameFocused, setIsBusinessNameFocused] = useState(false)
  const [isCertificateOrEvidenceHolderNameFocused, setIsCertificateOrEvidenceHolderNameFocused] = useState(false)
  const [isCertificateOrEvidenceHolderAddressFocused, setIsCertificateOrEvidenceHolderAddressFocused] = useState(false)
  const [isCertificateOrEvidenceHolderCityFocused, setIsCertificateOrEvidenceHolderCityFocused] = useState(false)
  const [isCertificateOrEvidenceHolderStateFocused, setIsCertificateOrEvidenceHolderStateFocused] = useState(false)
  const [isCertificateOrEvidenceHolderZipcodeFocused, setIsCertificateOrEvidenceHolderZipcodeFocused] = useState(false)

  const [isClientNameTouched, setIsClientNameTouched] = useState(false)
  const [isClientEmailTouched, setIsClientEmailTouched] = useState(false)
  const [isBusinessNameTouched, setIsBusinessNameTouched] = useState(false)
  const [isCertificateOrEvidenceHolderNameTouched, setIsCertificateOrEvidenceHolderNameTouched] = useState(false)
  const [isCertificateOrEvidenceHolderAddressTouched, setIsCertificateOrEvidenceHolderAddressTouched] = useState(false)
  const [isCertificateOrEvidenceHolderCityTouched, setIsCertificateOrEvidenceHolderCityTouched] = useState(false)
  const [isCertificateOrEvidenceHolderStateTouched, setIsCertificateOrEvidenceHolderStateTouched] = useState(false)
  const [isCertificateOrEvidenceHolderZipcodeTouched, setIsCertificateOrEvidenceHolderZipcodeTouched] = useState(false)

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)

  const businessNameChangeHandler = (event) =>{
    !isBusinessNameTouched && setIsBusinessNameTouched(true)
    setBusinessName(event.target.value)
  }

  const clientNameChangeHandler = (event) =>{
    !isClientNameTouched && setIsClientNameTouched(true)
    setClientName(event.target.value)
  }

  const clientEmailChangeHandler = (event) =>{
    !isClientEmailTouched && setIsClientEmailTouched(true)
    setClientEmail(event.target.value.trim())
  }

  const certificateOrEvidenceHolderNameChangeHandler = (event) =>{
    !isCertificateOrEvidenceHolderAddressTouched && setIsCertificateOrEvidenceHolderNameFocused(true)
    setCertificateOrEvidenceHolderName(event.target.value)
  }

  const certificateOrEvidenceHolderAddressChangeHandler = (event) =>{
    !isCertificateOrEvidenceHolderAddressTouched && setIsCertificateOrEvidenceHolderAddressTouched(true)
    setCertificateOrEvidenceHolderAddress(event.target.value)
  }

  const certificateOrEvidenceHolderCityChangeHandler = (event) =>{
    !isCertificateOrEvidenceHolderCityTouched && setIsCertificateOrEvidenceHolderCityTouched(true)
    setCertificateOrEvidenceHolderCity(event.target.value)
  }
  const certificateOrEvidenceHolderStateChangeHandler = (event) =>{
    !isCertificateOrEvidenceHolderStateTouched && setIsCertificateOrEvidenceHolderStateTouched(true)
    setCertificateOrEvidenceHolderState(event.target.value)
  }
  const certificateOrEvidenceHolderZipcodeChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    !isCertificateOrEvidenceHolderZipcodeTouched && setIsCertificateOrEvidenceHolderZipcodeTouched(true)
    setCertificateOrEvidenceHolderZipcode(value)
  }

  const specialInstructionsChangeHandler = (event) =>{
    setSpecialInstructions(event.target.value)
  }

  const submitDocumentRequestHandler = ()=>{
    let requestData = {
      accountType: insuranceType,
      category: 'DGR2',
      documentType: documentType,      
      contactName: clientName,
      email: clientEmail,
      accountName: insuranceType === 1 ? businessName : clientName
    }
    if(isCertificateDocumentType || isEvidenceDocumentType)
    {
      requestData.details = {
        evidenceOrCertificateHolderName : certificateOrEvidenceHolderName,
        evidenceOrCertificateHolderAddress : {
          address1 : certificateOrEvidenceHolderAddress,
          city : certificateOrEvidenceHolderCity,
          state : certificateOrEvidenceHolderState,
          postalCode : certificateOrEvidenceHolderZipcode
        },
        comments : specialInstructions
      }
    }
    requestDocumentChangeHandler(requestData);
  }

  const isValidFormData = () =>{
    let isValid = isRecaptchaVerified && clientName && isValidEmailAddress(clientEmail)

    if(isValid && insuranceType === 1)
    {
      isValid = businessName
    }
    if(isValid && ( isEvidenceDocumentType || isCertificateDocumentType))
    {
      isValid = certificateOrEvidenceHolderName && certificateOrEvidenceHolderAddress && certificateOrEvidenceHolderCity && 
                certificateOrEvidenceHolderState && isValidPostalCode(certificateOrEvidenceHolderZipcode) 
    }
    return isValid
  }

  const resetForm = () =>{
    setClientName("");
    setClientEmail("");
    setBusinessName("");
    setCertificateOrEvidenceHolderName("");
    setCertificateOrEvidenceHolderAddress("");
    setCertificateOrEvidenceHolderCity("");
    setCertificateOrEvidenceHolderState("");
    setCertificateOrEvidenceHolderZipcode("");
    setSpecialInstructions("");

    setIsClientNameFocused(false);
    setIsBusinessNameFocused(false);
    setIsClientNameFocused(false);
    setIsClientEmailFocused(false);
    setIsCertificateOrEvidenceHolderNameFocused(false);
    setIsCertificateOrEvidenceHolderAddressFocused(false);
    setIsCertificateOrEvidenceHolderCityFocused(false);
    setIsCertificateOrEvidenceHolderStateFocused(false);
    setIsCertificateOrEvidenceHolderZipcodeFocused(false);

    setIsClientNameTouched(false);
    setIsClientEmailTouched(false);
    setIsBusinessNameTouched(false);
    setIsCertificateOrEvidenceHolderNameTouched(false);
    setIsCertificateOrEvidenceHolderAddressTouched(false);
    setIsCertificateOrEvidenceHolderCityTouched(false);
    setIsCertificateOrEvidenceHolderStateTouched(false);
    setIsCertificateOrEvidenceHolderZipcodeTouched(false);

    setIsRecaptchaVerified(false);
  }

  return (
    documentType !== '' &&
    <Grid container rowSpacing={4}>  
        <Grid item xs={12}>
            <Typography                                 
                variant='h4'
                fontFamily="Sofia Sans Condensed"
                sx={{
                    color:"#0C709B"
                }}>
                REQUEST FORM
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container rowSpacing={2} columnSpacing={2}>
                <>
                  <Grid item md={12} sx={{width:"100%"}}>
                    {
                      insuranceType === 0 &&
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          CLIENT DETAILS
                      </Typography>
                    }  
                    {
                      insuranceType === 1 &&
                      <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          BUSINESS DETAILS
                      </Typography>
                    }                      
                  </Grid>
                  {
                    insuranceType === 1 &&
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isBusinessNameTouched && !businessName && !isBusinessNameFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          onFocus={()=>{setIsBusinessNameFocused(true)}}
                          onBlur={()=>{setIsBusinessNameFocused(false)}}
                          type="text" 
                          value= {businessName}
                          onChange= {businessNameChangeHandler}                        
                          label = " Business Name"/>
                    </Grid>
                  }
                  <Grid item md={6} sx={{width:"100%"}}>
                    <TextField
                        error={isClientNameTouched && !clientName && !isClientNameFocused}
                        required
                        size="small"
                        fontFamily="Nunito"
                        sx={{width:"100%"}}
                        onFocus={()=>{setIsClientNameFocused(true)}}
                        onBlur={()=>{setIsClientNameFocused(false)}}
                        type="text" 
                        value= {clientName}
                        onChange= {clientNameChangeHandler}                        
                        label = "Client Name"/>
                  </Grid>
                  <Grid item md={6} sx={{width:"100%"}}>
                      <TextField
                          error={isClientEmailTouched && !isValidEmailAddress(clientEmail) & !isClientEmailFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsClientEmailFocused(true)}}
                          onBlur={()=>{setIsClientEmailFocused(false)}}                         
                          value= {clientEmail}
                          onChange= {clientEmailChangeHandler}                        
                          label = "Client Email"/> 
                  </Grid>                  
                </>
                {
                  (isCertificateDocumentType || isEvidenceDocumentType) &&
                  <>
                    <Grid item md={12} sx={{width:"100%"}}>
                    {
                        isCertificateDocumentType && 
                        <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          CERTIFICATE HOLDER DETAILS
                      </Typography>
                    }
                    {
                        isEvidenceDocumentType && 
                        <Typography                                 
                          variant='h6'
                          fontFamily="Sofia Sans Condensed"
                          sx={{
                              color:"#0C709B"
                          }}>
                          EVIDENCE HOLDER DETAILS
                      </Typography>
                    }                    
                    </Grid>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isCertificateOrEvidenceHolderNameTouched && !certificateOrEvidenceHolderName && !isCertificateOrEvidenceHolderNameFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          onFocus={()=>{setIsCertificateOrEvidenceHolderNameFocused(true)}}
                          onBlur={()=>{setIsCertificateOrEvidenceHolderNameFocused(false)}}
                          type="text" 
                          value= {certificateOrEvidenceHolderName}
                          onChange= {certificateOrEvidenceHolderNameChangeHandler}                        
                          label = "Name"/>
                    </Grid>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          error={isCertificateOrEvidenceHolderAddressTouched && !certificateOrEvidenceHolderAddress & !isCertificateOrEvidenceHolderAddressFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsCertificateOrEvidenceHolderAddressFocused(true)}}
                          onBlur={()=>{setIsCertificateOrEvidenceHolderAddressFocused(false)}}                         
                          value= {certificateOrEvidenceHolderAddress}
                          onChange= {certificateOrEvidenceHolderAddressChangeHandler}                        
                          label = "Address"/> 
                    </Grid>           
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isCertificateOrEvidenceHolderCityTouched && !certificateOrEvidenceHolderCity & !isCertificateOrEvidenceHolderCityFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsCertificateOrEvidenceHolderCityFocused(true)}}
                          onBlur={()=>{setIsCertificateOrEvidenceHolderCityFocused(false)}}                         
                          value= {certificateOrEvidenceHolderCity}
                          onChange= {certificateOrEvidenceHolderCityChangeHandler}                        
                          label = "City"/> 
                    </Grid>          
                    <Grid item md={4} sx={{width:"100%"}}>
                      <TextField
                          error={isCertificateOrEvidenceHolderStateTouched && !certificateOrEvidenceHolderState & !isCertificateOrEvidenceHolderStateFocused}
                          required
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          type="text"   
                          onFocus={()=>{setIsCertificateOrEvidenceHolderStateFocused(true)}}
                          onBlur={()=>{setIsCertificateOrEvidenceHolderStateFocused(false)}}                         
                          value= {certificateOrEvidenceHolderState}
                          onChange= {certificateOrEvidenceHolderStateChangeHandler}                        
                          label = "State"/> 
                    </Grid>  
                    <Grid item md={4} sx={{width:"100%"}}>
                      <NumberFormat
                          sx={{width: "100%"}}
                          error={isCertificateOrEvidenceHolderZipcodeTouched && !isValidPostalCode(certificateOrEvidenceHolderZipcode) & !isCertificateOrEvidenceHolderZipcodeFocused}
                          required
                          label="Zipcode"
                          size="small"
                          type="text" 
                          value={certificateOrEvidenceHolderZipcode} 
                          format="#####" 
                          mask="_" 
                          customInput={TextField}
                          onFocus={()=>{setIsCertificateOrEvidenceHolderZipcodeFocused(true)}}
                          onBlur={()=>{setIsCertificateOrEvidenceHolderZipcodeFocused(false)}}   
                          onValueChange={certificateOrEvidenceHolderZipcodeChangeHandler}/>                       
                    </Grid>
                    <Grid item md={12} sx={{width:"100%"}}>
                      <TextField
                          size="small"
                          fontFamily="Nunito"
                          sx={{width:"100%"}}
                          multiline
                          minRows={3}
                          maxRows={3}
                          type="text"                           
                          value= {specialInstructions}
                          onChange= {specialInstructionsChangeHandler}                        
                          label = "Special Requirements"/> 
                    </Grid>                                                             
                  </>     
                }                                                                    
                <Grid item xs={12}>
                      <ReCAPTCHA
                          size="normal"
                          onChange={()=>{setIsRecaptchaVerified(true)}}
                          onExpired={()=>{setIsRecaptchaVerified(false)}}
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/>   
                </Grid>                                       
                <Grid item xs={12}>
                      <Button
                          disabled={!isValidFormData()}
                          variant="contained"
                          backgroundColor= "#0C709B" 
                          startIcon={<SendIcon />}
                          size="medium"
                          onClick={submitDocumentRequestHandler}
                          sx={{
                            borderRadius: '20px',
                            fontFamily: "Nunito",
                            fontWeight: "bolder",
                          }}             
                          label = "Claim">  
                          Submit Request
                      </Button>   
                </Grid> 
            </Grid>
        </Grid>       
    </Grid>
  )
}




        

