import { Box } from '@mui/material';


const SocialMediaIcon = ({alt, src, url})=>{
    return (
        <Box
            component="img"
            onClick={()=>{
                window.location.href = url
            }}
            sx={{
                  height: 40,
                  '&:hover': { 
                    cursor: 'pointer',
                  }                    
                }}
            alt={alt}
            src={src}
        />
    )
}

export default SocialMediaIcon