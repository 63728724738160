import axios from 'axios';
import { GetPersonalInsurancePoliciesURL, GetBusinessInsurancePoliciesURL,
         GetInsurancePolicyDocumentTypesURL, RequestDocumentChangeURL,
         GetCarriers, GetPolicies, CreateLead, CreateJobApplication,
         CreateQuoteRequest } from "./index";


//Report a Claim        
export const getPersonalInsurancePolicies = async (requestData) =>{
    let response = await axios.post(GetPersonalInsurancePoliciesURL, requestData);     
    return response.data;
};

export const getBusinessInsurancePolicies = async (requestData) =>{
    let response = await axios.post(GetBusinessInsurancePoliciesURL, requestData);     
    return response.data;
};


//Request document & Policy change Request
export const getInsuranceDocumentTypes = async (type, category) =>{
  let response = await axios.get(GetInsurancePolicyDocumentTypesURL + '?type=' + type + '&category=' + category);     
  return response.data;
};

export const requestDocumentChange = async (requestData ) =>{
  let response = await axios.post(RequestDocumentChangeURL, requestData);     
  return response.data;
};

export const getCarrierList = async() =>{
  let response = await axios.get(GetCarriers);     
  return response.data;
}

export const getPolicyList = async() =>{
  let response = await axios.get(GetPolicies);     
  return response.data;
}

export const createLeadInSalesForce = async(requestData) =>{
  let response = await axios.post(CreateLead, requestData);     
  return response.data;
}

export const createJobApplication = async(requestData) =>{
  let response = await axios.post(CreateJobApplication, requestData);     
  return response.data;
}

export const createQuoteRequest = async(requestData) =>{
  let response = await axios.post(CreateLead, requestData);     
  return response.data;
}