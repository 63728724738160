import React from 'react'
import { Box, Grid, Typography, Stack } from '@mui/material';
import Service from './Service';
import businessInsuranceImage from '../assets/images/BusinessInsurance.jpg'
import personalInsuranceImage from '../assets/images/PersonalInsurance.jpg'
import hrAndbenifitsImage from '../assets/images/HR-Benefits.jpg'
import Fade from 'react-reveal/Fade';
import { useNavigate} from 'react-router-dom';

const personalIncDescription = "Insurance coverage designed for you and your family. Protect your home, property, vehicles, and assets with customized insurance solutions."
const businessIncDescription = "Companies come in all shapes and sizes. Secure your business with an insurance portfolio designed to fit the needs of your unique operation."
const hrBenefitDescription = "Full‐service solutions for your business. Meet the demands of the modern workplace with quality Employee Benefits, Payroll Services, Human Resource Support and more."


export default function ServicesSection() {
  const navigate = useNavigate()

  return (
    <Box 
        component="div" 
        sx={{
            backgroundColor:  "#f5f5f5",
            overflowX:'hidden',
            paddingTop: '24vh',
            paddingBottom: '10vh'
        }}>
        <Grid container rowSpacing={6}>
            <Grid item xs={12}>              
                <Typography
                    fontFamily="Sofia Sans Condensed"  
                    color="#0C709B"
                    sx={{ 
                        textAlign:"center",
                        paddingLeft: '10px',
                        paddingRight: '10px'
                    }}
                    variant="h3">
                    WE OFFER A WIDE RANGE OF SERVICES
                </Typography>
            </Grid>
            <Grid item md={2} sm={1} xs={1}/>            
            <Grid item md={8} sm={10} xs={10}>
                <Grid container spacing={2}>
                    <Grid item md={4} sm={12} xs={12}>
                        <Fade bottom>
                            <Service 
                                title="Personal Insurance" 
                                description={personalIncDescription} 
                                imageSrc={personalInsuranceImage}
                                clickHandler={()=>{navigate('/PersonalInsurance')}}/>                            
                        </Fade>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <Fade bottom>
                            <Service 
                                title="Business Insurance" 
                                description={businessIncDescription} 
                                imageSrc={businessInsuranceImage}
                                clickHandler={()=>{navigate('/BusinessInsurance')}}/>                            
                        </Fade>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <Fade bottom>
                            <Service 
                                title="HR & Benefits" 
                                description={hrBenefitDescription} 
                                imageSrc={hrAndbenifitsImage}
                                clickHandler={()=>{navigate('/HR&Benefits')}}/>                            
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={2} sm={1} xs={1}/>            
        </Grid>      
    </Box>
  )
}
