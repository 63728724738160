import React from 'react'
import { Box, Stack, Snackbar, Alert } from '@mui/material';
import { useEffect } from 'react';
import loader from '../assets/images/Loader.gif'
import Backdrop from '@mui/material/Backdrop';
import { createQuoteRequest } from '../apiClient/insurancePolicies';


export default function QuoteNowBody({title, QuoteNowForm}) {
  useEffect(()=>{
    document.title = {title}
  },[])

  const [ isBackdropVisible, setIsBackdropVisible] = React.useState(false);
  const [ isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [ alertMessage, setAlertMessage] = React.useState("");
  const [ alertMessageSeverity, setAlertMessageSeverity] = React.useState("");

  const quoteNowClickHandler = async (requestData, resetForm) =>{
    try
    {
      setBackdropVisibilityValue(true)
      await createQuoteRequest(requestData)
      resetForm()
      setAlertMessageAndVisibility("Quote request submitted","success", true)
      setBackdropVisibilityValue(false)
    }
    catch(ex)
    {
      setAlertMessageAndVisibility("Quote request failed","error", true)
      setBackdropVisibilityValue(false)
    }
  }

  const setBackdropVisibilityValue = (value) => {
    setIsBackdropVisible(value);
  };

  const setAlertMessageAndVisibility = (message, severity, isVisible)=>{
    setAlertMessage(message)
    setAlertMessageSeverity(severity)
    setIsAlertVisible(isVisible)
    setTimeout(()=>{
      setIsAlertVisible(false)
    },5000)
  }

  return (
    <>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isBackdropVisible}
          onClick={()=>{setBackdropVisibilityValue(false)}}>
          <Box
            component="img"
            sx={{
              height: '80px'
            }}
            alt="loading..."                
            src={loader}/>
      </Backdrop>
      <Snackbar 
          sx={{
            marginTop: "10vh"
          }}
          anchorOrigin={{ vertical:"top", horizontal: "center" }} 
          open={isAlertVisible} 
          onClose={()=>{isAlertVisible(false)}}>
          <Alert onClose={()=>{isAlertVisible(false)}} severity={alertMessageSeverity} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
      </Snackbar>     
      <Box 
          component="div" 
          sx={{
            backgroundImage : {
                lg: "linear-gradient(#0C709B, #B6ECF6);",
                md: "linear-gradient(#0C709B, #B6ECF6);",
                sm: "transparent",
                xs: "transparent"
            },
            backgroundColor: {
              lg: "linear-gradient(#0C709B, #B6ECF6);",
              md: "linear-gradient(#0C709B, #B6ECF6);",
              sm: "#f5f5f5",
              xs: "#f5f5f5"
            },
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minHeight: '170vh',
            overflowX:'hidden'        
          }}>
          <Stack
            align="left"
            direction="column"
            alignItems="left"
            spacing={2}
            sx={{
              marginBottom: "20vh",
              paddingTop: "12vh",
              pddingBottom:"10vh",
              paddingLeft:{
                lg:"20%",
                md:"20%",
                sm:"10%",
                xs:"10%"
              },
              paddingRight:{
                lg:"20%",
                md:"20%",
                sm:"10%",
                xs:"10%"
              }           
            }}>
            <QuoteNowForm
                quoteNowClickHandler={quoteNowClickHandler}/>
          </Stack> 
      </Box>  
    </>
  )
}




        

