import React from 'react'
import { Box, Grid, Typography, TextField, Button } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import NumberFormat from 'react-number-format';

export default function ReportBusinessClaimToInsurance({getBusinessPoliciesHandler}) {

  const [formattedFeinNumber, setFormattedFeinNumber] = useState()

  const [feinNumber, setFeinNumber] = useState()
  const [phone, setPhone] = useState()
  const [zipCode, setZipCode] = useState()

  const [isFeinNumberFocused, setIsFeinNumberFocused] = useState(false)
  const [isPhoneFocused, setIsPhoneFocused] = useState(false)
  const [isZipCodeFocused, setIsZipCodeFocused] = useState(false)

  const [isFeinNumberTouched, setIsFeinNumberTouched] = useState(false)
  const [isPhoneTouched, setIsPhoneTouched] = useState(false)
  const [isZipCodeTouched, setIsZipCodeTouched] = useState(false)

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)

  const isValidFein = feinNumber !== null && feinNumber !== undefined && feinNumber.toString().length === 9;
  const isValidPhone = phone !== null && phone !== undefined && phone.toString().length === 10;
  const isValidZipCode = zipCode !== null && zipCode !== undefined && zipCode.toString().length === 5;

  const feinNumberChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    !isFeinNumberTouched && setIsFeinNumberTouched(true)
    setFormattedFeinNumber(formattedValue)
    setFeinNumber(value)
  }

  const phoneChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    !isPhoneTouched && setIsPhoneTouched(true)
    setPhone(value)
  }

  const zipCodeChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    !isZipCodeTouched && setIsZipCodeTouched(true)
    setZipCode(value)
  }

  return (
        <Grid container rowSpacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <NumberFormat
                    sx={{
                          width: "100%"
                    }}
                    error= {isFeinNumberTouched && !isValidFein && !isFeinNumberFocused}
                    required
                    fullwidth
                    label="FEIN"
                    size="small"
                    value={feinNumber}                         
                    format="##-#######" 
                    mask="_"
                    type="text" 
                    customInput={TextField}
                    onFocus={()=>{setIsFeinNumberFocused(true)}}
                    onBlur={()=>{setIsFeinNumberFocused(false)}}
                    onValueChange={feinNumberChangeHandler}/>    
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <NumberFormat
                    sx={{
                          width: "100%"
                    }}
                    error= {isPhoneTouched && !isValidPhone && !isPhoneFocused}
                    required
                    label="Phone Number"
                    size="small"
                    type="text" 
                    value={phone} 
                    format="+1 (###) ###-####" 
                    mask="_" 
                    customInput={TextField}
                    onFocus={()=>{setIsPhoneFocused(true)}}
                    onBlur={()=>{setIsPhoneFocused(false)}}
                    onValueChange={phoneChangeHandler}/> 
            </Grid>                  
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <NumberFormat
                    sx={{
                          width: "100%"
                    }}
                    error={isZipCodeTouched && !isValidZipCode && !isZipCodeFocused}
                    required
                    label="Mailing Zip Code"
                    size="small"
                    type="text" 
                    value={zipCode} 
                    format="#####" 
                    mask="_" 
                    customInput={TextField}
                    onFocus={()=>{setIsZipCodeFocused(true)}}
                    onBlur={()=>{setIsZipCodeFocused(false)}}
                    onValueChange={zipCodeChangeHandler}/>          
            </Grid>  
            <Grid item xs={12}>
                <ReCAPTCHA
                  size="normal"
                  onChange={()=>{setIsRecaptchaVerified(true)}}
                  onExpired={()=>{setIsRecaptchaVerified(false)}}
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                />   
            </Grid>                                       
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    onClick={()=>{getBusinessPoliciesHandler(formattedFeinNumber, phone, zipCode)}} 
                    backgroundColor= "#0C709B"  
                    startIcon={<SendIcon />}
                    size="medium"
                    disabled= {!isRecaptchaVerified || !isValidZipCode || !isValidPhone || !isValidFein}
                    sx={{
                        borderRadius: '20px',
                        fontFamily: "Nunito",
                        fontWeight: "bolder",
                      }}     
                    label = "Claim">  
                    Get Policy Information
                </Button>   
            </Grid>
        </Grid>        
  )
}
