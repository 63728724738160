import React from 'react'
import { Box, Grid } from '@mui/material';
import InsuranceBlockSection from './InsuranceBlockSection';
import BlogsPreview from '../components/BlogsPreview';


export default function HRAndBenefitsBody() {
    return (
        <>
            <Grid container>
                <Grid item md={6} sm={12} xs={12}>
                    <Box 
                        component="div" 
                        sx={{
                            backgroundColor: 'white',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            minHeight: '100vh',
                            overflowX:'hidden'
                        }}> 
                        <InsuranceBlockSection
                            title="EMPLOYEE BENEFIT PROGRAMS"
                            delay={250}
                            blockItems= {employeeBenefitItem}/>
                    </Box>                
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    <Box 
                        component="div" 
                        sx={{
                            backgroundColor: {
                                md: 'white',  
                                sm: '#f5f5f5',
                                xs: '#f5f5f5'
                            },
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            minHeight: '100vh',
                            overflowX:'hidden'
                        }}> 
                        <InsuranceBlockSection
                            title="PAYROLL & HR SERVICES"
                            delay={500}
                            blockItems= {payrollServiceItems}/>                 
                    </Box>                 
                </Grid>
            </Grid>           
            <Box 
              component="div" 
              sx={{
                  backgroundColor: "#f5f5f5",
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  minHeight: '100vh',
                  overflowX:'hidden',
                  padding:"4% 10% 5% 10%"
              }}>
              <BlogsPreview
                insuranceType="HR & Benefits"/>
            </Box>           
        </>         
    )
}

const employeeBenefitItem = [
    {
        name: "HEALTH INSURANCE",
        description: "Group Health Insurance can provide your workforce with coverage for a range of medical services, including doctor visits, hospital stays, prescription drugs, and some preventative care services.",
        redirectUrl: "HealthInsurance"
    },    
    {
        name: "DENTAL INSURANCE",
        description: "Group Dental Insurance can provide your workforce with coverage for a range of dental services, including routine exams, cleanings, X-rays, fillings, and other common procedures.",
        redirectUrl: "DentalInsurance"
    },    
    {
        name: "VISION INSURANCE",
        description: "Group Vision Insurance can provide your workforce with coverage for routine eye exams, prescription eyewear, and other vision-related expenses.",
        redirectUrl: "VisionInsurance"
    },    
    {
        name: "SUPPLEMENTAL INSURANCE",
        description: "Group Supplemental Insurance benefits are designed to help fill the gaps in traditional health insurance coverage and provide additional financial protection against unexpected medical expenses.",
        redirectUrl: "SupplementalInsurance"
    },    
    {
        name: "RETIREMENT BENEFIT PLANS",
        description: "Retirement Benefit Plans are designed to help employees save and invest for their reƟrement and can include a range of opƟons, such as employer-sponsored 401(k) plans, pension plans, and profit-sharing plans.",
        redirectUrl: "RetirementBenefitPlans"
    },]

const payrollServiceItems = [
    {
        name: "PAYROLL PROCESSING",
        description: "Payroll and HR services provide businesses with a range of benefits, including cost savings, increased efficiency, access to specialized expertise, scalability, and risk reduction.",
        redirectUrl: "QuoteNow"
    },
    {
        name: "HIRING & RECRUITING",
        description: "Payroll and HR services provide businesses with a range of benefits, including cost savings, increased efficiency, access to specialized expertise, scalability, and risk reduction.",
        redirectUrl: "QuoteNow"
    },
    {
        name: "BENEFITS ADMINISTRATION",
        description: "Payroll and HR services provide businesses with a range of benefits, including cost savings, increased efficiency, access to specialized expertise, scalability, and risk reduction.",
        redirectUrl: "QuoteNow"
    },    {
        name: "POLICY & PROCEDURE",
        description: "Payroll and HR services provide businesses with a range of benefits, including cost savings, increased efficiency, access to specialized expertise, scalability, and risk reduction.",
        redirectUrl: "QuoteNow"
    },    {
        name: "LIVE HR & SUPPORT SERVICES",
        description: "Payroll and HR services provide businesses with a range of benefits, including cost savings, increased efficiency, access to specialized expertise, scalability, and risk reduction.",
        redirectUrl: "QuoteNow"
    }
]

