import React from 'react'
import Fade from 'react-reveal/Fade';
import { Box, Stack, Typography, Grid } from '@mui/material';

export default function ServicesSubPageHeader({quoteNowRedirect, title, subtitles, bulletPoints, subtitleVariant, headerImage, ChildComponent, bottomLine}) {
  return (
    <Box 
      component="div" 
      sx={{
        backgroundImage: "linear-gradient(#0C709B, #B6ECF6);",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: {
          xs: '105.3vh',
          md: '105.3vh',
          sm: '105.3vh',
          xs: '105.3vh',
        },
        overflowX:'hidden'
      }}> 
      <Grid container>
        <Grid item md={6}>
          <Stack
            direction="column"        
            justifyContent="top"
            spacing={2}
            sx={{
              minHeight: '100vh',
              paddingTop: {
                lg: "30vh",
                md: "30vh",
                sm: "20vh",
                xs: "20vh"
              },
              paddingLeft: '7%'
            }}>
            <Fade bottom delay={50}>
              <Typography 
                variant='h3'
                fontFamily="Sofia Sans Condensed"
                sx={{
                  textTransform: "Uppercase",
                  color:"white"
                }}>
                {title}
              </Typography>
            </Fade>
            <Fade bottom delay={300}>
              <ChildComponent
                  quoteNowRedirect={quoteNowRedirect} 
                  bottomLine={bottomLine}
                  subtitles={subtitles} 
                  subtitleVariant={subtitleVariant}
                  bulletPoints={bulletPoints}/>        
            </Fade>
          </Stack> 
        </Grid>
        <Grid item md={6} paddingTop='22vh' 
          sx={{
            display:{
              xs:"none",
              sm:"none",
              md:"block",
              lg:"block"
            },
            textAlign: 'center'
          }}>         
           <Box
            component="img"
            sx={{
              height: '350px',
            }}
            alt={title}
            src={headerImage}
          />        
        </Grid>
      </Grid>         
    </Box>            
  )
}




        