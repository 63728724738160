import React from 'react'
import { Box, Grid, Typography, TextField, Button, Stack } from '@mui/material';
import { Seo } from './Seo'
import { useState, useEffect } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from "react-google-recaptcha";
import { isValidEmailAddress } from '../utils/utilityFunctions';
import NumberFormat from 'react-number-format';
import FileUpload from 'react-material-file-upload';
import { isValidPostalCode } from '../utils/utilityFunctions';
import { getBase64 } from '../utils/utilityFunctions/getBase64';


function BusinessInsuranceForm({quoteNowClickHandler}) {
  useEffect(()=>{
    document.title = "Business Insurance Quote"
    window.scrollTo(0, 0)
  },[])

  const [compnayName, setCompnayName] = useState("")
  const [dba, setDba] = useState("")
  const [contactAndTitle, setContactAndTitle] = useState("")
  const [phone, setPhone] = useState("")  
  const [email, setEmail] = useState("")
  const [businessAddress, setBusinessAddress] = useState("")
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState(""); 
  const [companyOperations, setCompanyOperations] = useState("")
  const [desiredCoverage, setDesiredCoverage] = useState("")
  const [numberOfEmployees, setNumberOfEmployees] = useState("")
  const [monthlyPayroll, setMonthlyPayroll] = useState("")
  const [monthlySales, setMonthlySales] = useState("")
  const [currentInsuranceCompany, setCurrentInsuranceCompany] = useState("")
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)
  const [files, setFiles] = useState([])

  const [isCompnayNameFocused, setIsCompnayNameFocused] = useState("")
  const [isContactAndTitleFocused, setIsContactAndTitleFocused] = useState("")
  const [isPhoneFocused, setIsPhoneFocused] = useState("")  
  const [isEmailFocused, setIsEmailFocused] = useState("")
  const [isBusinessAddressFocused, setIsBusinessAddressFocused] = useState("")
  const [isCityFocused, setIsCityFocused] = useState(false)
  const [isStateFocused, setIsStateFocused] = useState(false)
  const [isZipcodeFocused, setIsZipcodeFocused] = useState(false)  
  const [isCompanyOperationsFocused, setIsCompanyOperationsFocused] = useState("")
  const [isDesiredCoverageFocused, setIsDesiredCoverageFocused] = useState("")
  const [isNumberOfEmployeesFocused, setIsNumberOfEmployeesFocused] = useState("")
  const [isMonthlyPayrollFocused, setIsMonthlyPayrollFocused] = useState("")
  const [isMonthlySalesFocused, setIsMonthlySalesFocused] = useState("")
  const [isCurrentInsuranceCompanyFocused, setIsCurrentInsuranceCompanyFocused] = useState("")

  const [isCompnayNameTouched, setIsCompnayNameTouched] = useState("")
  const [isContactAndTitleTouched, setIsContactAndTitleTouched] = useState("")
  const [isPhoneTouched, setIsPhoneTouched] = useState("")  
  const [isEmailTouched, setIsEmailTouched] = useState("")
  const [isBusinessAddressTouched, setIsBusinessAddressTouched] = useState("")
  const [isCityTouched, setIsCityTouched] = useState(false)
  const [isStateTouched, setIsStateTouched] = useState(false)
  const [isZipcodeTouched, setIsZipcodeTouched] = useState(false)  
  const [isCompanyOperationsTouched, setIsCompanyOperationsTouched] = useState("")
  const [isDesiredCoverageTouched, setIsDesiredCoverageTouched] = useState("")
  const [isNumberOfEmployeesTouched, setIsNumberOfEmployeesTouched] = useState("")
  const [isMonthlyPayrollTouched, setIsMonthlyPayrollTouched] = useState("")
  const [isMonthlySalesTouched, setIsMonthlySalesTouched] = useState("")
  const [isCurrentInsuranceCompanyTouched, setIsCurrentInsuranceCompanyTouched] = useState("")


  const companyNameChangeHandler = (event) =>{
    !isCompnayNameTouched && setIsCompnayNameTouched(true)
    setCompnayName(event.target.value)
  }

  const contactAndTitleChangeHandler = (event) =>{
    !isContactAndTitleTouched && setIsContactAndTitleTouched(true)
    setContactAndTitle(event.target.value)
  }

  const phoneChangeHandler = (values) =>{
    const { formattedValue, value } = values;
    if(value != phone)
    {
      setPhone(value)
    }
  }
  const emailChangeHandler = (event) =>{
    !isEmailTouched && setIsEmailTouched(true)
    setEmail(event.target.value.trim())
  }

  const businessAddressChangeHandler = (event) =>{
    !isBusinessAddressTouched && setIsBusinessAddressTouched(true)
    setBusinessAddress(event.target.value)
  }

  const companyOperationsChangeHandler = (event) =>{
    !isCompanyOperationsTouched && setIsCompanyOperationsTouched(true)
    setCompanyOperations(event.target.value)
  }

  const desiredCoverageChangeHandler = (event) =>{
    !isDesiredCoverageTouched && setIsDesiredCoverageTouched(true)
    setDesiredCoverage(event.target.value)
  }
  const numberOfEmployeesChangeHandler = (event) =>{
    !isNumberOfEmployeesTouched && setIsNumberOfEmployeesTouched(true)
    setNumberOfEmployees(event.target.value)
  }

  const monthlyPayrollChangeHandler = (event) =>{
    !isMonthlyPayrollTouched && setIsMonthlyPayrollTouched(true)
    setMonthlyPayroll(event.target.value)
  }

  const monthlySalesChangeHandler = (event) =>{
    !isMonthlySalesTouched && setIsMonthlySalesTouched(true)
    setMonthlySales(event.target.value)
  }

  const currentInsuranceCompanyChangeHandler = (event) =>{
    !isCurrentInsuranceCompanyTouched && setIsCurrentInsuranceCompanyTouched(true)
    setCurrentInsuranceCompany(event.target.value)
  }

  const cityChangeHandler = (event) =>{
    !isCityTouched && setIsCityTouched(true)
    setCity(event.target.value)
  }
  const stateChangeHandler = (event) =>{
    !isStateTouched && setIsStateTouched(true)
    setState(event.target.value)
  }
  const zipcodeChangeHandler = (values) =>{
    const { formattedValue, value } = values;

    if(value != zipcode)
      {
        !isZipcodeTouched && setIsZipcodeTouched(true)
        setZipcode(value)
      }
  }

  const formClickHandler = async ()=>{
    const attachments = []
    for(let file in files)
    {
       let fileBase64 = await getBase64(files[file])
       attachments.push({
          "fileName": files[file].name,
          "fileBase64": fileBase64
       })
    }


    const requestBody = {
      "firstName": "",
      "lastName": contactAndTitle,
      "email": email,
      "phone": phone,
      "company" : compnayName,
      "dba" : dba,
      "pageSource": "BUSINESS INSURANCE INFORMATION",
      "address":{
          "street": businessAddress,
          "city": city,
          "state": state,
          "postalCode": zipcode,
          "country": "US"
      },
      "attachments" : attachments,      
      "additionalDetails":[
        {
          "fieldName": "companyOperations",
          "fieldLabel": "Company Operations",
          "fieldValue": companyOperations
        },
        {
          "fieldName": "desiredCoverage",
          "fieldLabel": "Desired Insurance Coverage",
          "fieldValue": desiredCoverage
        },
        {
          "fieldName": "numberOfEmployees",
          "fieldLabel": "How Many Employees Do you Have?",
          "fieldValue": numberOfEmployees
        },
        {
          "fieldName": "monthlyPayroll",
          "fieldLabel": "Estimated Monthly Payroll?",
          "fieldValue": monthlyPayroll
        },
        {
          "fieldName": "monthlySales",
          "fieldLabel": "Average Monthly Revenue/Sales?",
          "fieldValue": monthlySales
        },
        {
          "fieldName": "currentInsuranceCompany",
          "fieldLabel": "Current Insurance Company",
          "fieldValue": currentInsuranceCompany
        },                                        
      ],
    }
    await quoteNowClickHandler(requestBody, resetForm)  
  }

  const resetForm = () =>{
    setCompnayName("");
    setDba("");
    setContactAndTitle ("");
    setPhone("");
    setEmail("");
    setBusinessAddress("");
    setCity("");
    setState("");
    setZipcode("");
    setCompanyOperations("");
    setDesiredCoverage("");
    setNumberOfEmployees("");
    setMonthlyPayroll("");
    setMonthlySales("");
    setCurrentInsuranceCompany("");
    setFiles([]);

    setIsCompnayNameFocused(false);
    setIsContactAndTitleFocused(false);
    setIsPhoneFocused(false);
    setIsEmailFocused(false);
    setIsBusinessAddressFocused(false);
    setIsCompanyOperationsFocused(false);
    setIsDesiredCoverageFocused(false);
    setIsNumberOfEmployeesFocused(false);
    setIsMonthlyPayrollFocused(false);
    setIsMonthlySalesFocused(false);
    setIsCurrentInsuranceCompanyFocused(false);
    setIsCityFocused(false);
    setIsStateFocused(false);
    setIsZipcodeFocused(false);

    setIsCompnayNameTouched(false);
    setIsContactAndTitleTouched(false);
    setIsPhoneTouched(false);
    setIsEmailTouched(false);
    setIsBusinessAddressTouched(false);
    setIsCompanyOperationsTouched(false);
    setIsDesiredCoverageTouched(false);
    setIsNumberOfEmployeesTouched(false);
    setIsMonthlyPayrollTouched(false);
    setIsMonthlySalesTouched(false);
    setIsCurrentInsuranceCompanyTouched(false);
    setIsCityTouched(false);
    setIsStateTouched(false);
    setIsZipcodeTouched(false);
  }

  const isValidPhone = phone !== null && phone !== undefined && phone.toString().length === 10;
  const isValidData = compnayName && contactAndTitle && isValidPhone && isValidEmailAddress(email) && businessAddress && 
                      companyOperations && desiredCoverage && numberOfEmployees && monthlyPayroll && monthlySales &&
                      currentInsuranceCompany && isRecaptchaVerified && city && state && zipcode

  return (
    <Stack>      
      <Seo
          title="Business Insurance Quote"
          description="Secure your business with a tailored insurance plan - get a quote today for comprehensive coverage and peace of mind."/>                                                          
      <section id="BusinessInsuranceQuote">  
        <Box 
          component="div" 
          sx={{
            backgroundColor: "#f5f5f5",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minHeight: '100vh',
            overflowX:'hidden',
            textAlign: 'center',
            padding: {
              xs: "8% 6% 5% 6%",
              sm: "8% 6% 5% 6%",
              md: "8% 10% 5% 10%"
            }
          }}>            
          <Typography 
            variant='h3'
            fontFamily="Sofia Sans Condensed"
            sx={{
              color:"#0C709B",
              marginBottom: '4vh'
            }}>
            BUSINESS INSURANCE INFORMATION
          </Typography>  
          <br/>
          <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                  <Grid container columnSpacing={2} rowSpacing={2} >
                      <Grid item md={6} sx={{width:"100%"}}>
                          <TextField
                              size="small"
                              error={isCompnayNameTouched && !compnayName && !isCompnayNameFocused}
                              onFocus={()=>{setIsCompnayNameFocused(true)}}
                              onBlur={()=>{setIsCompnayNameFocused(false)}}                
                              required
                              fullWidth
                              fontFamily="Nunito"
                              value= {compnayName}
                              onChange= {companyNameChangeHandler}      
                              label = "Company Name"/>                        
                      </Grid>          
                      <Grid item md={6} sx={{width:"100%"}}>
                          <TextField
                              size="small"
                              error={isContactAndTitleTouched && !contactAndTitle && !isContactAndTitleFocused}
                              onFocus={()=>{setIsContactAndTitleFocused(true)}}
                              onBlur={()=>{setIsContactAndTitleFocused(false)}}                
                              required
                              fullWidth
                              fontFamily="Nunito"
                              value= {contactAndTitle}
                              onChange= {contactAndTitleChangeHandler}      
                              label = "Primary Contact Name & Title"/>                        
                      </Grid>
                  </Grid>
              </Grid>        
              <Grid item xs={12}>
                  <Grid container columnSpacing={2} rowSpacing={2} >
                      <Grid item md={6} sx={{width:"100%"}}>
                          <TextField 
                              size="small"
                              required
                              error={isEmailTouched && !isValidEmailAddress(email) && !isEmailFocused}
                              fontFamily="Nunito"
                              fullWidth
                              value= {email}
                              onFocus={()=>{setIsEmailFocused(true)}}
                              onBlur={()=>{setIsEmailFocused(false)}}
                              type="text" 
                              onChange= {emailChangeHandler}    
                              label = "Email"/>                         
                      </Grid>          
                      <Grid item md={6} sx={{width:"100%"}}>
                          <NumberFormat
                              size="small"
                              error={isPhoneTouched && !isValidPhone && !isPhoneFocused}
                              onFocus={()=>{setIsPhoneFocused(true)}}
                              onBlur={()=>{setIsPhoneFocused(false)}}                           
                              required
                              sx={{
                                  width: "100%"
                              }}
                              label="Contact Phone"
                              type="text" 
                              value={phone} 
                              format="+1 (###) ###-####" 
                              mask="_" 
                              customInput={TextField}
                              onValueChange={phoneChangeHandler}/>                            
                      </Grid>
                  </Grid>
              </Grid>     
              <Grid item xs={12}>
                  <TextField
                      size="small"
                      fullWidth
                      fontFamily="Nunito"
                      value= {dba}
                      onChange= {(event)=>{setDba(event.target.value)}}      
                      label = "DBA (If Any)"/>   
              </Grid>             
              <Grid item xs={12}>
                  <TextField
                      size="small"
                      error={isBusinessAddressTouched && !businessAddress && !isBusinessAddressFocused}
                      onFocus={()=>{setIsBusinessAddressFocused(true)}}
                      onBlur={()=>{setIsBusinessAddressFocused(false)}}                
                      required
                      multiline
                      fullWidth
                      fontFamily="Nunito"
                      minRows={2}
                      value= {businessAddress}
                      onChange= {businessAddressChangeHandler}      
                      label = "Business Address"/>   
              </Grid>
              <Grid item xs={12}>
                    <Grid container columnSpacing={2} rowSpacing={2} >
                      <Grid item md={4} sx={{width:"100%"}}>
                          <TextField
                              error={isCityTouched && !city & !isCityFocused}
                              required
                              size="small"
                              fontFamily="Nunito"
                              sx={{width:"100%"}}
                              type="text"   
                              onFocus={()=>{setIsCityFocused(true)}}
                              onBlur={()=>{setIsCityFocused(false)}}                         
                              value= {city}
                              onChange= {cityChangeHandler}                        
                              label = "City"/> 
                      </Grid>          
                      <Grid item md={4} sx={{width:"100%"}}>
                          <TextField
                              error={isStateTouched && !state & !isStateFocused}
                              required
                              size="small"
                              fontFamily="Nunito"
                              sx={{width:"100%"}}
                              type="text"   
                              onFocus={()=>{setIsStateFocused(true)}}
                              onBlur={()=>{setIsStateFocused(false)}}                         
                              value= {state}
                              onChange= {stateChangeHandler}                        
                              label = "State"/> 
                      </Grid>  
                      <Grid item md={4} sx={{width:"100%"}}>
                          <NumberFormat
                                sx={{width: "100%"}}
                                error={isZipcodeTouched && !isValidPostalCode(zipcode) & !isZipcodeFocused}
                                required
                                label="Zipcode"
                                size="small"
                                type="text" 
                                value={zipcode} 
                                format="#####" 
                                mask="_" 
                                customInput={TextField}
                                onFocus={()=>{setIsZipcodeFocused(true)}}
                                onBlur={()=>{setIsZipcodeFocused(false)}}   
                                onValueChange={zipcodeChangeHandler}/>                              
                      </Grid>
                    </Grid>
              </Grid>            
              <Grid item xs={12}>
                  <TextField   
                      error={isCompanyOperationsTouched && !companyOperations && !isCompanyOperationsFocused}
                      onFocus={()=>{setIsCompanyOperationsFocused(true)}}
                      onBlur={()=>{setIsCompanyOperationsFocused(false)}}
                      required
                      multiline
                      fullWidth
                      fontFamily="Nunito"
                      minRows={4}
                      size="small"
                      value= {companyOperations}
                      onChange= {companyOperationsChangeHandler}      
                      label = "Company Operations (What does your business do)?"/>   
              </Grid>   
              <Grid item xs={12}>
                  <TextField   
                      error={isDesiredCoverageTouched && !desiredCoverage && !isDesiredCoverageFocused}
                      onFocus={()=>{setIsDesiredCoverageFocused(true)}}
                      onBlur={()=>{setIsDesiredCoverageFocused(false)}}
                      required
                      fullWidth
                      fontFamily="Nunito"
                      type="text" 
                      size="small"
                      value= {desiredCoverage}
                      onChange= {desiredCoverageChangeHandler}      
                      label = "Desired Insurance Coverage (Liability, Property, Workers Comp, Auto)"/>   
              </Grid>                           
              <Grid item xs={12}>
                  <TextField   
                      error={isNumberOfEmployeesTouched && !numberOfEmployees && !isNumberOfEmployeesFocused}
                      onFocus={()=>{setIsNumberOfEmployeesFocused(true)}}
                      onBlur={()=>{setIsNumberOfEmployeesFocused(false)}}
                      required
                      fullWidth
                      fontFamily="Nunito"
                      type="text" 
                      size="small"
                      value= {numberOfEmployees}
                      onChange= {numberOfEmployeesChangeHandler}      
                      label = "How Many Employees Do you Have?"/>   
              </Grid>   
              <Grid item xs={12}>
                  <TextField
                      error={isMonthlyPayrollTouched && !monthlyPayroll && !isMonthlyPayrollFocused}
                      onFocus={()=>{setIsMonthlyPayrollFocused(true)}}
                      onBlur={()=>{setIsMonthlyPayrollFocused(false)}}
                      required                
                      fullWidth
                      size="small"
                      fontFamily="Nunito"
                      value= {monthlyPayroll}
                      onChange= {monthlyPayrollChangeHandler}    
                      label = "Estimated Monthly Payroll?"/>   
              </Grid>  
              <Grid item xs={12}>
                  <TextField
                      error={isMonthlySalesTouched && !monthlySales && !isMonthlySalesFocused}
                      onFocus={()=>{setIsMonthlySalesFocused(true)}}
                      onBlur={()=>{setIsMonthlySalesFocused(false)}}
                      required                
                      fullWidth
                      size="small"
                      fontFamily="Nunito"
                      value= {monthlySales}
                      onChange= {monthlySalesChangeHandler}    
                      label = "Average Monthly Revenue/Sales?"/>   
              </Grid>  
              <Grid item xs={12}>
                  <TextField
                      error={isCurrentInsuranceCompanyTouched && !currentInsuranceCompany && !isCurrentInsuranceCompanyFocused}
                      onFocus={()=>{setIsCurrentInsuranceCompanyFocused(true)}}
                      onBlur={()=>{setIsCurrentInsuranceCompanyFocused(false)}}
                      required                
                      fullWidth
                      size="small"
                      fontFamily="Nunito"
                      value= {currentInsuranceCompany}
                      onChange= {currentInsuranceCompanyChangeHandler}    
                      label = "Current Insurance Company (Please Attach Policy or Dec Page below if available)"/>   
              </Grid>                                
              <Grid item xs={12}>
                  <FileUpload
                      label="Attachments"
                      title="Attach Current Policy/Dec Page"
                      value={files} 
                      size="small"
                      onChange={setFiles} 
                      style={{
                          height:"10px"
                      }}
                      multiple={true}/>
              </Grid>                   
              <Grid item xs={12}>
                  <ReCAPTCHA
                      size="normal"
                      onChange={()=>{setIsRecaptchaVerified(true)}}
                      onExpired={()=>{setIsRecaptchaVerified(false)}}
                      sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}/> 
              </Grid>                                           
              <Grid item xs={12} textAlign="start">
                  <Button
                      variant="contained"
                      backgroundColor= "#0C709B"  
                      startIcon={<SendIcon />}
                      size="large"                    
                      disabled = {!isValidData}
                      onClick={formClickHandler}
                      sx={{borderRadius: '20px', marginTop: '2vh'}}     
                      label = "Submit">  
                      Send
                  </Button>   
              </Grid>
          </Grid>        
        </Box>  
      </section>
    </Stack>             
  )
}

export default BusinessInsuranceForm