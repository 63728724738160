import React from 'react';
import { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import JoinOurTeamHeader from '../components/JoinOurTeamHeader';
import { Seo } from '../components/Seo';

function JoinOurTeam() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>      
      <Seo
          title="Join our Team"
          description="We are always looking for talented and reliable professionals to join our growing team. Whether you are interested in starting your insurance career or taking your skills to the next level, we would like to hear from you"/>                                                          
      <section>         
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section> 
      <JoinOurTeamHeader/>
    </Stack>
  )
}

export default JoinOurTeam