import React from 'react'
import { Box, Grid } from '@mui/material';
import MoveToModernShoreForm from '../components/MoveToModernShoreForm';
import MoveToModernShoreImage from '../assets/images/MoveToModernShore.jpg';

export default function MoveToModernShoreBody() {
  return (
    <Grid container rowSpacing={8}>
      <Grid item md={6} sm={12} xs={12} 
          sx={{
                display:{
                  sm: "none",
                  xs: "none",
                  md: "block",
                  lg: "block"
                }
          }}>
          <Box 
            component="div" 
            sx={{
                  backgroundImage: `url(${MoveToModernShoreImage})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  height: '140vh',
                  overflowX:'hidden'
            }}>
          </Box>
      </Grid> 
      <Grid item md={6} sm={12} xs={12}>
        <MoveToModernShoreForm/>
      </Grid>          
  </Grid>   
  )
}




        