import React from 'react'
import { Box, Stack, Typography, Grid } from '@mui/material';
import OurStoryImage from '../assets/images/OurStory.jpg';
import Fade from 'react-reveal/Fade';

const ourStory = "Modern Shore Insurance is an independent insurance firm offering personal & family insurance products as well as business insurance & employer services. Founded in St. Petersburg, Florida, Modern Shore Insurance is rooted in community relationships and solidified with national strength. We serve both local clients throughout Florida as well as national clients with multi‐state needs. Modern Shore Insurance is backed by reputable insurance companies from around the world and staffed by knowledgeable advisors who strive to protect the financial assets of our clients."

export default function OurStory() {
  return (
    <>
      <Box 
          component="div" 
          sx={{
            backgroundColor: '#f5f5f5',
            height: {
              lg: '100vh',
              md: '100vh',
              sm: '20vh',
              xs: '20vh'
            }
          }}>
          <Grid 
              container
              paddingLeft="7%"
              paddingRight="7%"
              paddingBottom="20vh"
              spacing={10}
              sx={{
                paddingTop: "16vh",
              }}>
              <Grid item lg={6} md={6} sm={12} xs={12}
                  sx={{
                    display:{
                      xs:"none",
                      sm:"none",
                      md:"block",
                      ld:"block",
                    }, 
                    position: 'relative'
                  }}>
                  <Box
                    component="img"
                    sx={{   
                      position: 'absolute',  
                      top: 100, 
                      left: 130,                       
                      zIndex: 1,
                      maxHeight: '70vh',
                      width: '33vw',
                      textAlign: 'right'
                    }}
                    alt="Our Story"
                    src={OurStoryImage}/>                                          
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack>
                      <Fade top delay={50}>                    
                        <Typography 
                            variant='h2'
                            fontFamily="Sofia Sans Condensed"
                            sx={{
                              color:"#0C709B",
                              textUnderlineOffset: '20%',
                              textDecorationThickness: '1px',
                              marginBottom: {
                                xs: '4vh',
                                sm: '4vh',
                                md: '2vh',
                                lg: '2vh',
                              }, 
                              textAlign: {
                                xs : "center",
                                sm: "center",
                                md: "left",
                                lg: "left"
                              }
                            }}>
                            OUR STORY
                        </Typography>
                      </Fade>
                      <Typography 
                            variant="h6"
                            fontFamily="Nunito"
                            sx={{
                              color:"#0C709B",
                              textUnderlineOffset: '20%',
                              textDecorationThickness: '1px',
                              marginBottom: '5vh',
                              fontWeight: "bold",
                              textAlign: "justify",
                              display: {
                                lg: "block",
                                md: 'block',
                                sm: 'none',
                                xs: 'none'
                              }
                            }}>
                            <Fade right>
                                {ourStory}
                            </Fade>
                        </Typography>
                        <Typography 
                            variant="body1"
                            fontFamily="Nunito"
                            sx={{
                              color:"#0C709B",
                              textUnderlineOffset: '20%',
                              textDecorationThickness: '1px',
                              marginBottom: '5vh',
                              fontWeight: "bold",
                              textAlign: "center",
                              display: {
                                lg: "none",
                                md: 'none',
                                sm: 'block',
                                xs: 'block'
                              }
                            }}>
                            <Fade right>
                                {ourStory}
                            </Fade>
                        </Typography>
                  </Stack>
              </Grid>              
          </Grid>
      </Box>  
      <Box 
          component="div" 
          sx={{
              backgroundColor: '#f5f5f5',
              minHeight: '100vh',
              overflowX:'hidden',
              paddingTop: "15vh",
              paddingBottom: "10vh",
              display:{
                xs:"block",
                sm:"block",
                md:"none",
                ld:"none"
              } 
          }}>
          <Stack
              align="left"
              direction="column"
              alignItems="left"
              paddingLeft="7%"
              spacing={2}>                        
          </Stack> 
      </Box>
    </>
  )
}




        

