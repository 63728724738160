import React from 'react';
import { useState, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar';
import MoveToModernShoreHeader from '../components/MoveToModernShoreHeader';
import MoveToModernShoreImage from '../assets/images/MoveToModernShore.jpg';
import MoveToModernShoreBody from '../components/MoveToModernShoreBody';
import { Seo } from '../components/Seo';

function MoveToModernShore() {
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false)

  useEffect(()=>{
    window.scrollTo(0, 0)
  },[])

  return (
    <Stack>      
      <Seo
          title="Move to Modern Shore"
          description="Making the Move to Modern Shore is Easy! You can keep your same insurance policy and carrier, while gaining the satisfaction of having a dedicated Modern Shore Insurance Advisor on your side"/>                                                          
      <section>          
        <Navbar isNavBarTransparent={isNavBarTransparent}/>
        <CssBaseline/>
      </section> 
      <section>
        <Stack>
          <MoveToModernShoreBody/> 
        </Stack>
      </section>
    </Stack>
  )
}

export default MoveToModernShore