import React, { useEffect } from 'react';
import { useState } from 'react';
import { AppBar, Toolbar, Box, Typography  } from '@mui/material';
import { useNavigate} from 'react-router-dom';
import logoLight from '../assets/images/logo_2.png'
import logoDark from '../assets/images/logo_4.png'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import SearchBar from './SearchBar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { HashLink } from 'react-router-hash-link/dist/react-router-hash-link.cjs.development';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import InfoIcon from '@mui/icons-material/Info';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import CallIcon from '@mui/icons-material/Call';

const servicesOptions = [
  { title: "Personal Insurance", key: "PersonalInsurance", externalLink: null},
  { title: "Business Insurance", key: "BusinessInsurance", externalLink: null},
  { title: "HR & Benefits", key: "HR&Benefits", externalLink: null}
];

const aboutUsOptions = [
  { title: "Our Story", key: "OurStory", externalLink: null},
  { title: "Knowledge & Resources", key: "KnowledgeResources", externalLink: null},
  { title: "Welcome to Modern Shore", key: "Welcome", externalLink: null},
  { title: "Join our Team", key: "JoinOurTeam", externalLink: null},
  { title: "Agency Affiliation", key: "AgencyAffiliation", externalLink: null},
  { title: "Contact Us", key: "ContactUs", externalLink: null}
];
const clientAccessOptions = [
  { title: "Report a Claim", key: "ReportClaim", externalLink: null},
  { title: "Make a Payment", key: "MakePayment", externalLink: "https://modernshoreins.epaypolicy.com/"},
  { title: "Request Documents", key: "RequestDocuments", externalLink: null},
  { title: "Policy Change Request", key: "PolicyChangeRequest", externalLink: null},
  { title: "Contact Customer Service", key: "ContactCustomerService", externalLink: null},
];

const Navbar = ({isNavBarTransparent}) => {
  const navigate = useNavigate();

  const [servicesAnchorEl, setServicesAnchorEl] = React.useState(null);
  const isServicesSubNavBarOpen = Boolean(servicesAnchorEl);
  const [isServicesSubNavBarOpenMobile, setIsServicesSubNavBarOpenMobile] = React.useState(false);

  const [aboutUsAnchorEl, setAboutUsAnchorEl] = React.useState(null);
  const isAboutUsSubNavBarOpen = Boolean(aboutUsAnchorEl);
  const [isAboutUsSubNavBarOpenMobile, setIsAboutUsSubNavBarOpenMobile] = React.useState(false);

  const [clientAccessAnchorEl, setClientAccessAnchorEl] = React.useState(null);
  const isClientAccessSubNavBarOpen = Boolean(clientAccessAnchorEl);
  const [isClientAccessSubNavBarOpenMobile, setClientAccessSubNavBarOpenMobile] = React.useState(false);

  const [isSidebarVisible, setIsSidebarVisible] = useState(false)

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsSidebarVisible(open);
  };  

  const navBarOptions = [
    {
      title: "Home", 
      clickHandler: ()=> navigate('/'), 
      icon: <HomeIcon sx={{color: "#B6ECF6"}}/>,
      subNavBar: null
    },
    {
      title: "Services", 
      clickHandler: (event)=> setServicesAnchorEl(event.currentTarget),
      icon: <RoomServiceIcon sx={{color: "#B6ECF6"}}/>,
      subNavBar : {
        isOpen: isServicesSubNavBarOpen,
        isOpenMobile: isServicesSubNavBarOpenMobile,
        anchorEl: servicesAnchorEl,
        clickHandlerMobile: ()=> setIsServicesSubNavBarOpenMobile(!isServicesSubNavBarOpenMobile),
        closeHandler: ()=> setServicesAnchorEl(null), 
        options: servicesOptions
      }
    },
    {
      title: "About us", 
      clickHandler: (event)=> setAboutUsAnchorEl(event.currentTarget),
      icon: <InfoIcon sx={{color: "#B6ECF6"}}/>,
      subNavBar : {
        isOpen: isAboutUsSubNavBarOpen,
        isOpenMobile: isAboutUsSubNavBarOpenMobile,
        anchorEl: aboutUsAnchorEl,
        clickHandlerMobile: ()=> setIsAboutUsSubNavBarOpenMobile(!isAboutUsSubNavBarOpenMobile),
        closeHandler: ()=> setAboutUsAnchorEl(null), 
        options: aboutUsOptions
      }
    },
    {
      title: "Client Connect", 
      clickHandler: (event)=> setClientAccessAnchorEl(event.currentTarget),
      icon: <PeopleIcon sx={{color: "#B6ECF6"}}/>,
      subNavBar : {
        isOpen: isClientAccessSubNavBarOpen,
        isOpenMobile: isClientAccessSubNavBarOpenMobile,
        anchorEl: clientAccessAnchorEl,
        clickHandlerMobile: ()=> setClientAccessSubNavBarOpenMobile(!isClientAccessSubNavBarOpenMobile),
        closeHandler: ()=> setClientAccessAnchorEl(null), 
        options: clientAccessOptions
      }
    },
    {
      title: "Contact Us", 
      clickHandler: ()=> navigate('/ContactUs'), 
      icon: <CallIcon sx={{color: "#B6ECF6"}}/>,
      subNavBar: null
    }    
  ];
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar 
        sx={{
            position : "fixed",
            backgroundColor : isNavBarTransparent ? "transparent" : "#0C709B",        
            opacity: '1',      
            boxShadow : isNavBarTransparent ? "none" : "block",
            padding: {
              xs: "2% 0%",
              sm: "1% 0%",
              md: "0.4% 0%"
            },
        }}>
          <Toolbar>
              <Box component="img"
                  sx={{
                    height: {
                      xs: isNavBarTransparent ? "100px" : "50px",
                      sm: isNavBarTransparent ? "100px" : "70px",
                      md: isNavBarTransparent ? "120px" : "60px"
                    },
                    marginRight: {
                      xs: "0%",
                      sm: "6%",
                      md: "0%"
                    },                    
                    transition: 'height 0.3s',
                    marginLeft: "3%",
                    cursor:'pointer'
                  }}
                  onClick={()=> navigate('/')}
                  alt="Modern Shore Insurance"
                  src={isNavBarTransparent ? logoLight : logoDark}/>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1, display: { xs:'none', sm: 'block', md: 'block'}}}> 
              </Typography>
              {
                navBarOptions.map((navBarItem,index) =>{
                  return (
                    navBarItem.title != "Contact Us" &&
                    <Grid key={index}>
                      <Typography
                        sx={{
                          display: {xs:'none', sm: 'none', md: 'block'},            
                          paddingRight: 5,
                          fontWeight: "bolder",
                          color: false ? '#0C709B' : 'white' ,
                          '&:hover': {
                            cursor: 'pointer'
                          }
                        }}
                        onClick={navBarItem.clickHandler}
                        fontFamily="Nunito"
                        variant="h6">
                        {navBarItem.title}
                      </Typography>
                      <Typography
                        sx={{
                          display: {xs:'none', sm: 'block', md: 'none'},            
                          paddingRight: 5,
                          color:"white",
                          '&:hover': {
                            cursor: 'pointer'
                          }
                        }}
                        onClick={navBarItem.clickHandler}
                        fontFamily="Nunito"
                        fontWeight="bolder"        
                        variant="body1">     
                        {navBarItem.title}
                      </Typography>
                      {
                        navBarItem.subNavBar !== null &&
                        <Menu      
                          sx={{
                            marginTop: '2vh'
                          }}          
                          id="lock-menu"
                          anchorEl={navBarItem.subNavBar.anchorEl}
                          open={navBarItem.subNavBar.isOpen}
                          onClose={navBarItem.subNavBar.closeHandler}
                          MenuListProps={{
                            'aria-labelledby': 'lock-button',
                            role: 'listbox',
                          }}>
                          {
                            navBarItem.subNavBar.options.map((subNavBarItem, index) => (
                              subNavBarItem.externalLink === null ?
                                <HashLink 
                                  key={index}
                                  smooth                    
                                  style={{textDecoration: 'none'}}
                                  to={"/" + subNavBarItem.key}>
                                  <MenuItem
                                    sx={{
                                      width: "100%",
                                      paddingLeft:"2vw", 
                                      paddingRight:"2vw",               
                                    }}
                                    key={subNavBarItem}>
                                      <Typography
                                        sx={{
                                          color:"#0C709B",
                                        }}
                                        fontFamily="Nunito"
                                        variant="body1"
                                        fontWeight="bolder">
                                        {subNavBarItem.title}
                                      </Typography>
                                  </MenuItem>
                                </HashLink>:
                                <MenuItem
                                  sx={{
                                    width: "100%",
                                    paddingLeft:"2vw", 
                                    paddingRight:"2vw",               
                                  }}
                                  key={subNavBarItem}>
                                  <Typography
                                    sx={{
                                      color:"#0C709B",
                                    }}
                                    onClick={()=> window.location.href = subNavBarItem.externalLink}
                                    fontFamily="Nunito"
                                    variant="body1"
                                    fontWeight="bolder">
                                    {subNavBarItem.title}
                                  </Typography>
                              </MenuItem>                
                            ))
                          }
                        </Menu>  
                      }                                
                    </Grid>
                  )
                })
              }                                       
              <Grid sx={{display: {xs:'none', sm: 'block', md: 'block'}}}>
                <SearchBar />
              </Grid>
              <Grid 
                sx={{display: {xs:'block', sm: 'none', md: 'none' }, 
                marginLeft: isNavBarTransparent ? "55%" : "70%"}}>
                <React.Fragment>
                  <MenuIcon fontSize='large' onClick={toggleDrawer(true)}/>
                  <SwipeableDrawer
                    anchor="right"
                    open={isSidebarVisible}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}>
                    <Box
                      sx={{backgroundColor: '#0C709B', width: 250, height: 1000}}
                      role="presentation">
                      <Grid container sx={{marginTop: '4%'}}>
                        <Grid item sm={4} xs={4}>
                        </Grid> 
                        <Grid item sm={4} xs={4}>
                          <Box component="img"
                            sx={{
                              height: "80px",   
                              textAlign: "center",
                              cursor:'pointer'
                            }}
                            onClick={()=> navigate('/')}
                            alt="Modern Shore Insurance"
                            src={logoDark}/>                          
                        </Grid>  
                      </Grid>
                      <List component="nav">
                        <ListItem>
                          <SearchBar/>
                        </ListItem>
                        {
                          navBarOptions.map((navBarItem, index) =>{
                            return(
                              <Grid key={index}>
                                <ListItem disablePadding sx={{width:"100%", paddingLeft:"2%"}}>
                                  <ListItemButton onClick={()=> navBarItem.subNavBar !== null && navBarItem.subNavBar.clickHandlerMobile() }>
                                    {
                                      navBarItem.icon
                                    }
                                    <ListItemText sx={{paddingLeft: "8%"}}>
                                      {
                                        navBarItem.subNavBar !== null ?
                                          <Typography 
                                            sx={{
                                                color:"#B6ECF6"
                                            }}
                                            variant= "body1"
                                            fontWeight="bold"
                                            fontFamily="Nunito">
                                            {navBarItem.title}
                                          </Typography>  :
                                        <Typography 
                                            sx={{
                                                color:"#B6ECF6"
                                            }}
                                            onClick={navBarItem.clickHandler}
                                            variant= "body1"
                                            fontWeight="bold"
                                            fontFamily="Nunito">
                                            {navBarItem.title}
                                        </Typography>                                  
                                      }                                                                 
                                    </ListItemText>
                                    {navBarItem.subNavBar !== null &&  (navBarItem.subNavBar.isOpenMobile ? <ExpandLess sx={{color: '#B6ECF6'}}  /> : <ExpandMore sx={{color: '#B6ECF6'}}  />)}
                                  </ListItemButton>  
                                </ListItem>
                                {
                                  navBarItem.subNavBar !== null &&  
                                  <Collapse in={navBarItem.subNavBar.isOpenMobile} timeout="auto" unmountOnExit>                              
                                    <List disablePadding>
                                      {
                                        navBarItem.subNavBar.options.map((subNavBarItem, index) => {
                                          return (
                                            subNavBarItem.title != "Contact Us" &&
                                            <ListItem key={index} disablePadding sx={{paddingLeft:"18%"}}>
                                              <ListItemButton >                                        
                                                <ListItemText>
                                                  {
                                                    subNavBarItem.externalLink === null ?
                                                    <HashLink 
                                                      smooth  
                                                      to={"/" +subNavBarItem.key}                 
                                                      style={{textDecoration: 'none'}}>
                                                      <Typography 
                                                        sx={{
                                                            color:"#B6ECF6",
                                                        }}
                                                        variant= "body2"
                                                        fontWeight="bold"
                                                        fontFamily="Nunito">
                                                        {subNavBarItem.title}
                                                      </Typography>                                
                                                    </HashLink> :
                                                    <Typography 
                                                      sx={{
                                                          color:"#B6ECF6",
                                                      }}
                                                      onClick={()=> window.location.href = subNavBarItem.externalLink}
                                                      fontWeight="bold"
                                                      variant= "body2"
                                                      fontFamily="Nunito">
                                                      {subNavBarItem.title}
                                                    </Typography>
                                                  }                                                             
                                                </ListItemText>
                                              </ListItemButton>                         
                                            </ListItem>
                                          )
                                        })
                                      }                                                          
                                    </List>
                                  </Collapse>
                                }
                              </Grid>                               
                            );
                          })
                        }
                      </List>
                      <Divider />
                    </Box>                      
                  </SwipeableDrawer>
                </React.Fragment>
              </Grid>              
            </Toolbar>
        </AppBar>     
    </Box>
  );
};

export default Navbar;